// https://stalniy.github.io/casl/v4/en/guide/intro#basics
// CASL operates on the abilities level, that is what a user can actually do in the application. An ability itself depends on the 4 parameters:
// 1. User Action: Describes what user can actually do in the app. This app uses the basic CRUD operations ("create", "read", "update", "delete")
// 2. Subject: The subject or subject type which you want to check user action on.
// 3. Fields: Used to restrict user action only to matched subject's fields (e.g a user can perform a primary assessment review but not a secondary review)
// 4. Conditions: An object or function which restricts user action only to matched subjects.

export default {
  home: {subject: "Home"},

  // supplier setup
  // NOTE: organisation = suppliers in this case!
  organisation: {subject: "Organisation"},

  // user setup
  project: {subject: "User"},

  // order setup
  order: {subject: "Order"},

  // schedule setup
  schedule: {subject: "Schedule"},

  // assessment setup
  assessment: {
    subject: "Assessment",
  },
  // findingResolution setup
  findingResolution: {
    subject: "FindingResolution",
  },
  dashboard: {
    subject: "Dashboard",
  },
  // user setup
  userManagement: {
    subject: "User",
  },

  legal: {
    subject: "Legal",
  },
};
