<template>
  <div class="lost-connection">
    <i class="fas fa-wifi"></i>
    <span>{{ $t("generalNotices.noInternetConnection") }}</span>
    {{ $t("generalNotices.retryingIn") }} {{ timerCount }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      isConnectionLost: false,
      timerCount: 5,
    };
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > -1) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.timerCount = 5;
        }
      },
      immediate: true,
    },
  },
};
</script>
