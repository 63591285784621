<template>
  <div class="review-details">
    <div class="row">
      <div class="col">
        <h2 style="margin-bottom: 30px">
          <i
            class="dx-icon dx-icon-exportselected"
            style="margin-right: 5px"
          ></i>
          {{ $t("orders.reviewDetails.title") }}
        </h2>
      </div>
      <div class="col-md-auto col-12 order-md-last" v-if="orderStatus">
        <label class="status-label" :class="setStatusClass(orderStatus)">
          {{ $t("orders.reviewDetails.stage") }}
          {{ `: ${formatStatus(orderStatus)}` }}
        </label>
      </div>
    </div>
    <div class="container-breaker">
      <div class="content-block">
        <div class="row" v-if="isInReviewerCapacityVisible">
          <div class="col-lg-4">
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.reviewDetails.isThereReviewerCapacity") }}
              </label>
            </div>
            <dx-select-box
              :value.sync="selectedIsThereReviewerCapacity"
              :data-source="reviewerCapacityOptions"
              stylingMode="outlined"
              display-expr="name"
              value-expr="val"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.reviewDetails.isThereReviewerCapacity'),
                })
              "
              :disabled="
                !$can(
                  'update',
                  $subjects.schedule.subject,
                  'isThereReviewerCapacity',
                )
              "
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t(
                        'orders.reviewDetails.isThereReviewerCapacity',
                      ),
                    })
                  "
                />
              </dx-validator>
            </dx-select-box>
          </div>
          <div class="col-lg-4">
            <label class="required bold-label">
              {{ $t("orders.reviewDetails.leadReviewer") }}
            </label>
            <dx-select-box
              :value.sync="selectedLeadReviewer"
              :data-source="leadReviewers"
              stylingMode="outlined"
              display-expr="fullname"
              value-expr="id"
              :ref="leadReviewerDropdownRef"
              :show-clear-button="showClearButton"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.reviewDetails.leadReviewer'),
                })
              "
              :disabled="
                !$can('update', $subjects.schedule.subject, 'leadReviewer') ||
                isNoReviewerCapacity
              "
              @value-changed="onLeadReviewerChanged($event)"
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.reviewDetails.leadReviewer'),
                    })
                  "
                />
              </dx-validator>
            </dx-select-box>
          </div>
          <div class="col-lg" :ref="componentContainerRef">
            <label class="bold-label">
              {{ $t("orders.reviewDetails.supportReviewers") }}
            </label>
            <dx-tag-box
              :defer-rendering="false"
              :data-source="supportReviewersDataSource"
              display-expr="fullname"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.reviewDetails.supportReviewers'),
                })
              "
              :show-clear-button="true"
              :show-drop-down-button="true"
              :show-selection-controls="true"
              styling-mode="outlined"
              :value.sync="selectedMultipleSupportReviewers"
              value-expr="id"
              :wrapItemText="true"
              :max-displayed-tags="1"
              apply-value-mode="instantly"
              :disabled="
                !$can(
                  'update',
                  $subjects.schedule.subject,
                  'supportReviewers',
                ) || isNoReviewerCapacity
              "
            >
            </dx-tag-box>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 start-date">
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.reviewDetails.startDate") }}
                <DxTooltip
                  :visible.sync="startDateToolTip"
                  :close-on-outside-click="false"
                  target="#divStartDate"
                  position="top"
                >
                  {{ $t("tooltips.schedule.reviewStartDate") }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divStartDate"
                @mouseenter="startDateToolTip = !startDateToolTip"
                @mouseleave="startDateToolTip = !startDateToolTip"
              ></div>
            </div>
            <dx-date-box
              name="startDate"
              stylingMode="outlined"
              :acceptCustomValue="false"
              :openOnFieldClick="true"
              type="date"
              validationMessageMode="auto"
              pickerType="calendar"
              v-model="reviewStartDate"
              :use-mask-behavior="true"
              display-format="dd/MMM/yyyy"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.reviewDetails.startDate'),
                })
              "
              :disabled="
                !$can('update', $subjects.schedule.subject, 'startDate') ||
                orderStatus === 'Submitted' ||
                orderStatus === 'Completed' ||
                !canReschedule ||
                isNoReviewerCapacity
              "
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.reviewDetails.startDate'),
                    })
                  "
                />
              </dx-validator>
            </dx-date-box>
          </div>

          <div class="col-lg-4">
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.reviewDetails.endDate") }}
                <DxTooltip
                  :visible.sync="reviewEndDateToolTip"
                  :close-on-outside-click="false"
                  target="#divReviewEndDate"
                  position="top"
                >
                  {{ $t("tooltips.schedule.reviewEndDate") }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divReviewEndDate"
                @mouseenter="reviewEndDateToolTip = !reviewEndDateToolTip"
                @mouseleave="reviewEndDateToolTip = !reviewEndDateToolTip"
              ></div>
            </div>
            <dx-date-box
              name="endDate"
              stylingMode="outlined"
              :acceptCustomValue="false"
              :openOnFieldClick="true"
              type="date"
              validationMessageMode="auto"
              pickerType="calendar"
              :min.sync="reviewStartDate"
              v-model="reviewEndDate"
              :use-mask-behavior="true"
              display-format="dd/MMM/yyyy"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.reviewDetails.endDate'),
                })
              "
              :disabled="
                !$can('update', $subjects.schedule.subject, 'endDate') ||
                orderStatus === 'Submitted' ||
                orderStatus === 'Completed' ||
                !canReschedule ||
                isNoReviewerCapacity
              "
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.reviewDetails.endDate'),
                    })
                  "
                />
              </dx-validator>
            </dx-date-box>
          </div>
        </div>
      </div>
    </div>
    <div class="container-breaker">
      <div class="content-block">
        <div class="row">
          <div class="col">
            <h2 style="margin-bottom: 30px">
              <i class="far fa-calendar" style="margin-right: 5px"></i>
              {{ $t("orders.estimatedReviewTimeline.title") }}
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.estimatedReviewTimeline.estReportDueDate") }}
                <DxTooltip
                  :visible.sync="estReportDueDateToolTip"
                  :close-on-outside-click="false"
                  target="#divEstReportDueDate"
                  position="top"
                >
                  {{ $t("tooltips.schedule.estimatedReportDueDate") }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divEstReportDueDate"
                @mouseenter="estReportDueDateToolTip = !estReportDueDateToolTip"
                @mouseleave="estReportDueDateToolTip = !estReportDueDateToolTip"
              ></div>
            </div>
            <dx-date-box
              name="estReportDueDate"
              stylingMode="outlined"
              :acceptCustomValue="false"
              :openOnFieldClick="true"
              type="date"
              validationMessageMode="auto"
              pickerType="calendar"
              :min.sync="reviewStartDate"
              v-model="estReportDueDate"
              :use-mask-behavior="true"
              display-format="dd/MMM/yyyy"
              :placeholder="
                $t('shared.select', {
                  dataField: $t(
                    'orders.estimatedReviewTimeline.estReportDueDate',
                  ),
                })
              "
              :disabled="
                !$can(
                  'update',
                  $subjects.schedule.subject,
                  'estReportDueDate',
                ) ||
                orderStatus === 'Submitted' ||
                orderStatus === 'Completed' ||
                isNoReviewerCapacity
              "
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t(
                        'orders.estimatedReviewTimeline.estReportDueDate',
                      ),
                    })
                  "
                />
              </dx-validator>
            </dx-date-box>
          </div>
          <div class="col-lg-4">
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.estimatedReviewTimeline.estCAPDate") }}
                <DxTooltip
                  :visible.sync="estCAPDateToolTip"
                  :close-on-outside-click="false"
                  target="#divEstCapDate"
                  position="top"
                >
                  {{ $t("tooltips.schedule.estimatedCapDate") }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divEstCapDate"
                @mouseenter="estCAPDateToolTip = !estCAPDateToolTip"
                @mouseleave="estCAPDateToolTip = !estCAPDateToolTip"
              ></div>
            </div>
            <dx-date-box
              name="estCAPDate"
              stylingMode="outlined"
              :acceptCustomValue="false"
              :openOnFieldClick="true"
              type="date"
              validationMessageMode="auto"
              pickerType="calendar"
              :min.sync="reviewStartDate"
              v-model="estCAPDate"
              :use-mask-behavior="true"
              display-format="dd/MMM/yyyy"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.estimatedReviewTimeline.estCAPDate'),
                })
              "
              :disabled="
                !$can('update', $subjects.schedule.subject, 'estCAPDate') ||
                orderStatus === 'Submitted' ||
                orderStatus === 'Completed' ||
                isNoReviewerCapacity
              "
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t(
                        'orders.estimatedReviewTimeline.estCAPDate',
                      ),
                    })
                  "
                />
              </dx-validator>
            </dx-date-box>
          </div>
          <div class="col-lg-4">
            <div style="display: flex">
              <label class="required bold-label">
                {{
                  $t("orders.estimatedReviewTimeline.estDeadlineApprovalDate")
                }}
                <DxTooltip
                  :visible.sync="estDeadlineApprovalDateToolTip"
                  :close-on-outside-click="false"
                  target="#divEstDeadlineApprovalDate"
                  position="top"
                >
                  {{ $t("tooltips.schedule.estimatedDeadlineForApproval") }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divEstDeadlineApprovalDate"
                @mouseenter="
                  estDeadlineApprovalDateToolTip =
                    !estDeadlineApprovalDateToolTip
                "
                @mouseleave="
                  estDeadlineApprovalDateToolTip =
                    !estDeadlineApprovalDateToolTip
                "
              ></div>
            </div>
            <dx-date-box
              name="estDeadlineApprovalDate"
              stylingMode="outlined"
              :acceptCustomValue="false"
              :openOnFieldClick="true"
              type="date"
              validationMessageMode="auto"
              pickerType="calendar"
              :min.sync="reviewStartDate"
              v-model="estDeadlineApprovalDate"
              :use-mask-behavior="true"
              display-format="dd/MMM/yyyy"
              :placeholder="
                $t('shared.select', {
                  dataField: $t(
                    'orders.estimatedReviewTimeline.estDeadlineApprovalDate',
                  ),
                })
              "
              :disabled="
                !$can(
                  'update',
                  $subjects.schedule.subject,
                  'estDeadlineApprovalDate',
                ) ||
                orderStatus === 'Submitted' ||
                orderStatus === 'Completed' ||
                isNoReviewerCapacity
              "
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t(
                        'orders.estimatedReviewTimeline.estDeadlineApprovalDate',
                      ),
                    })
                  "
                />
              </dx-validator>
            </dx-date-box>
          </div>
        </div>
      </div>
    </div>
    <div class="content-block">
      <div class="row">
        <div class="col-lg-4">
          <div style="display: flex">
            <label class="bold-label">
              {{ $t("orders.reviewDetails.capStatus") }}
              <DxTooltip
                :visible.sync="capStatusTooltip"
                :close-on-outside-click="false"
                target="#capStatusStatus"
                position="top"
              >
                <div style="text-align: left; white-space: pre-line">
                  {{ $t("tooltips.schedule.capStatus") }}
                </div>
              </DxTooltip>
            </label>
            <div
              class="tooltip"
              id="capStatusStatus"
              @mouseenter="capStatusTooltip = !capStatusTooltip"
              @mouseleave="capStatusTooltip = !capStatusTooltip"
            ></div>
          </div>
          <dx-select-box
            :value.sync="selectedCAPStatus"
            :data-source="capStatusList"
            :wrapItemText="true"
            stylingMode="outlined"
            display-expr="name"
            value-expr="id"
            :placeholder="
              $t('shared.select', {
                dataField: $t('orders.reviewDetails.capStatus'),
              })
            "
            :disabled="
              !$can('update', $subjects.schedule.subject, 'capStatus') ||
              (orderStatus !== 'Submitted' && orderStatus !== 'Completed') ||
              isNoReviewerCapacity
            "
          />
        </div>

        <div class="col-lg-4">
          <div style="display: flex">
            <label class="bold-label">
              {{ $t("orders.reviewDetails.contractFollowupStatus") }}
              <DxTooltip
                :visible.sync="contractFollowupStatusTooltip"
                :close-on-outside-click="false"
                target="#contractFollowupStatus"
                position="top"
              >
                {{ $t("tooltips.schedule.contractFollowUpStatus") }}
              </DxTooltip>
            </label>
            <div
              class="tooltip"
              id="contractFollowupStatus"
              @mouseenter="
                contractFollowupStatusTooltip = !contractFollowupStatusTooltip
              "
              @mouseleave="
                contractFollowupStatusTooltip = !contractFollowupStatusTooltip
              "
            ></div>
          </div>
          <dx-select-box
            :value.sync="selectedFollowupStatus"
            :data-source="followupStatusList"
            stylingMode="outlined"
            display-expr="name"
            value-expr="id"
            :placeholder="
              $t('shared.select', {
                dataField: $t('orders.reviewDetails.contractFollowupStatus'),
              })
            "
            :disabled="
              !$can(
                'update',
                $subjects.schedule.subject,
                'contractFollowupStatus',
              ) ||
              ![
                orderStatuses.submitted,
                orderStatuses.completed,
                orderStatuses.cancelled,
              ].includes(orderStatus) ||
              isNoReviewerCapacity
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DxValidator, DxRequiredRule} from "devextreme-vue/validator";
import {DxSelectBox, DxDateBox, DxTagBox, DxTooltip} from "devextreme-vue";
import {mapActions, mapState} from "vuex";
import {mapFields} from "vuex-map-fields";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {reviewModes, yesNoOptions, capStatuses} from "../../common/constants";
import {
  addDaysToDate,
  addBusinessDaysToDate,
  todaysDate,
  toCamelCase,
} from "../../common/helperFunctions";

import {orderStatuses} from "../../custom/config/workflows";
import roleTypes from "../../config/roles";

export default {
  props: {
    isInReviewerCapacityVisible: {
      Type: Boolean,
      required: true,
    },
  },
  components: {
    DxSelectBox,
    DxValidator,
    DxRequiredRule,
    DxDateBox,
    DxTagBox,
    DxTooltip,
  },
  data() {
    return {
      leadReviewers: null,
      supportReviewersDataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.loadSupportReviewers,
          loadMode: "raw",
        }),
        sort: [{selector: "fullname", desc: false}],
      }),
      numberOfSupportReviewersSelected: 0,
      selectedSupportReviewers: null,
      componentContainerRef: "container",
      reviewersDropDownRef: "reviewers",
      checkBoxMode: "always",
      columns: [
        {
          dataField: "fullname",
          caption: "Support Reviewers",
          width: "auto",
        },
      ],
      isThereReviewerCapacityToolTip: false,
      startDateToolTip: false,
      reviewEndDateToolTip: false,
      estReportDueDateToolTip: false,
      estCAPDateToolTip: false,
      estDeadlineApprovalDateToolTip: false,
      complianceStatusToolTip: false,
      contractFollowupStatusTooltip: false,
      capStatusTooltip: false,
      leadReviewerDropdownRef: "leadReviewer",
      orderStatuses: orderStatuses,
    };
  },
  methods: {
    ...mapActions("orders", ["getUsers", "getReviewDetails"]),
    todaysDate: todaysDate,
    async loadSupportReviewers() {
      const response = await this.getUsers("Reviewer,Admin");

      return response.data.filter((x) => {
        return (
          x.id !== this.selectedLeadReviewer &&
          (x.activeState == 1 ||
            this.selectedMultipleSupportReviewers.includes(x.id))
        );
      });
    },
    onReviewersDropDownOpened() {
      let dropDownWidth = this.componentContainer.clientWidth - 46;
      this.reviewersDropDown.option("dropDownOptions.width", dropDownWidth);
    },
    async getScheduleDetails() {
      this.isLoading = true;
      if (
        this.$route.params.hasSchedulingDetails ||
        this.orderDetails.hasScheduleDetails
      ) {
        await this.getReviewDetails(this.$route.params.id);
        this.selectedIsThereReviewerCapacity = this.reviewDetails
          .isThereReviewerCapacity
          ? yesNoOptions.Yes
          : yesNoOptions.No;
        if (this.selectedIsThereReviewerCapacity) {
          let response = await this.getUsers("Reviewer,Admin");
          let reviewers = response.data;
          reviewers.sort(function (a, b) {
            return a.fullname.localeCompare(b.fullname);
          });
          this.selectedLeadReviewer = this.reviewDetails.leadReviewer;
          this.selectedMultipleSupportReviewers =
            this.reviewDetails.supportReviewers;

          this.leadReviewers = reviewers.filter((x) => {
            return this.selectedLeadReviewer == x.id || x.activeState == 1;
          });
        }

        if (this.reviewDetails.startDate !== null) {
          this.reviewStartDate = this.reviewDetails.startDate;
        }
        if (this.reviewDetails.endDate !== null)
          this.reviewEndDate = this.reviewDetails.endDate;
        this.selectedCAPStatus = this.reviewDetails.capStatus;
        this.selectedFollowupStatus = this.reviewDetails.followupStatus;
      } else {
        this.selectedIsThereReviewerCapacity = yesNoOptions.Yes;
        const response = await this.getUsers("Reviewer,Admin");

        let reviewers = response.data;
        let activeUsers = reviewers.filter((x) => {
          return x.activeState == 1;
        });

        this.leadReviewers = activeUsers.sort(function (a, b) {
          return a.fullname.localeCompare(b.fullname);
        });
      }
      this.isLoading = false;
    },
    isInRole(role) {
      return this.$store.state.auth.user.roles.includes(role);
    },
    formatStatus(status) {
      return this.$t(`orders.orderList.${toCamelCase(status)}`);
    },
    setStatusClass(status) {
      if (status == orderStatuses.cancelled) {
        return "red";
      } else if (status == orderStatuses.inProgress) {
        return "yellow";
      } else if (status == orderStatuses.submitted) {
        return "submitted";
      } else if (status == orderStatuses.completed) {
        return "green";
      } else if (status == orderStatuses.scheduled) {
        return "scheduled";
      } else if (status == orderStatuses.ordered) {
        return "ordered";
      } else {
        return "";
      }
    },
    onLeadReviewerChanged(e) {
      if (
        e.value === null &&
        e.previousValue !== null &&
        this.showClearButton
      ) {
        this.$refs[this.leadReviewerDropdownRef].instance.option(
          "isValid",
          true,
        );
      }
    },
  },
  computed: {
    ...mapState("orders", ["selectedReviewType", "reviewTypes", "levels"]),
    ...mapFields("orders", [
      "isLoading",
      "selectedMultipleSupportReviewers",
      "selectedLeadReviewer",
      "reviewEndDate",
      "reviewStartDate",
      "estReportDueDate",
      "estCAPDate",
      "estDeadlineApprovalDate",
      "selectedIsThereReviewerCapacity",
      "selectedCAPStatus",
      "selectedFollowupStatus",
      "orderStatus",
      "reviewDetails",
      "selectedReviewMode",
      "complianceStatus",
      "orderDetails",
    ]),
    ...mapState("auth", ["user"]),
    componentContainer() {
      return this.$refs[this.componentContainerRef];
    },
    reviewersDropDown() {
      return this.$refs[this.reviewersDropDownRef].instance;
    },
    reviewerCapacityOptions() {
      return [
        {val: 1, name: this.$t("shared.yes")},
        {val: 2, name: this.$t("shared.no")},
      ];
    },
    capStatusList() {
      let capStatusList = JSON.parse(JSON.stringify(capStatuses));
      capStatusList.forEach((element) => {
        element.name = this.$t(`orders.reviewDetails.${element.name}`);
      });
      return capStatusList;
    },
    followupStatusList() {
      return [
        {id: 1, name: this.$t("orders.reviewDetails.supplierPhasedOut")},
        {
          id: 2,
          name: this.$t("orders.reviewDetails.otherBizConsequenceApplied"),
        },
        {id: 3, name: this.$t("orders.reviewDetails.noBizConsequenceApplied")},
      ];
    },
    canReschedule() {
      return (
        this.isInRole(roleTypes.admin.role) ||
        this.isInRole(roleTypes.reviewer.role)
      );
      // TODO: revisit
      // return (
      //   isEmpty(this.selectedLeadReviewer) ||
      //   this.selectedLeadReviewer === this.user.id ||
      //   (this.selectedMultipleSupportReviewers &&
      //     this.selectedMultipleSupportReviewers.includes(this.user.id)) ||
      //   this.isInRole(roleTypes.admin.role)
      // );
    },
    isNoReviewerCapacity() {
      return (
        this.selectedReviewMode === reviewModes.ComplianceReview &&
        this.selectedIsThereReviewerCapacity === yesNoOptions.No
      );
    },
    showClearButton() {
      return (
        this.orderStatus === orderStatuses.ordered &&
        this.isInRole(roleTypes.admin.role)
      );
    },
  },
  created() {
    this.getScheduleDetails();
  },
  watch: {
    reviewEndDate() {
      if (
        (this.reviewDetails === null ||
          this.reviewDetails.endDate === null ||
          this.estCAPDate !== null) &&
        this.reviewEndDate
      ) {
        this.estCAPDate = addDaysToDate(this.reviewEndDate, 21);
        this.estReportDueDate = addBusinessDaysToDate(this.reviewEndDate, 5);
        if (
          this.reviewTypes[this.selectedReviewType - 1].name === "PPP Review"
        ) {
          this.estDeadlineApprovalDate = addDaysToDate(this.reviewEndDate, 35);
        } else {
          this.estDeadlineApprovalDate = addDaysToDate(this.reviewEndDate, 90);
        }
      } else {
        this.estCAPDate =
          this.reviewDetails.estCAPDate !== null
            ? this.reviewDetails.estCAPDate
            : null;
        this.estReportDueDate =
          this.reviewDetails.estReportDueDate !== null
            ? this.reviewDetails.estReportDueDate
            : null;
        this.estDeadlineApprovalDate =
          this.reviewDetails.estDeadlineApprovalDate !== null
            ? this.reviewDetails.estDeadlineApprovalDate
            : null;
      }
    },
    selectedLeadReviewer() {
      this.supportReviewersDataSource.reload();
    },
  },
};
</script>

<style></style>
