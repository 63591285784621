<template>
  <DxPopup
    :drag-enabled="false"
    :visible="visible"
    :close-on-outside-click="true"
    :show-title="true"
    class="popUpLargeTitle"
    :max-height="'100%'"
    :max-width="'100%'"
    :width="'700px'"
    :height="'fit-content'"
    :title="$t('findings.findingsResolution.reworkPopupTitle')"
    @hiding="onClose"
  >
  <template #content>
    <dx-scroll-view 
            :scroll-by-content="true"
            :scroll-by-thumb="true"
            show-scrollbar="true">
    <div class="container rework" style="padding: 0">
      <div class="row rework-content-section">
        <div class="col-12">
          <div class="row">
            <div class="col-md-12">
              <div class="content-block">
                <label>{{
                  $t("findings.findingsResolution.description")
                }}</label>
                <div class="content-text">{{ findingTitle }}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="content-block">
                <label>{{
                  $t("findings.findingsResolution.resolutionDate")
                }}</label>
                <div class="date">
                  {{ formatDate(resolutionDate) }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content-block">
                <label>{{ $t("findings.findingsResolution.owner") }}</label>
                <div
                  class="content-text"
                  style="position: relative; padding-left: 23px"
                >
                  <span
                    style="
                      position: absolute;
                      top: 2px;
                      left: 0;
                      width: 15px;
                      color: #7d7d7d;
                      line-height: 15px;
                      font-size: 9px;
                      text-align: center;
                      height: 15px;
                      border-radius: 50px;
                      display: block;
                      background: #d4d4d4;
                    "
                  >
                    <i class="fas fa-user"></i>
                  </span>
                  {{ findingOwner }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content-block mt-0">
                <label>{{
                  $t("findings.findingsResolution.resolutionComments")
                }}</label>
                <div class="auditor-name">{{ resolutionComment }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <dx-validation-group :ref="resetValidationRefKey">
      <div class="row">
        <div class="col-md-12">
          <div class="content-block mt-0">
            <label class="required">{{
              $t("findings.findingsResolution.reworkComments")
            }}</label>
            <dx-text-area
              :height="100"
              :value.sync="value"
              stylingMode="outlined"
            >
            <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('findings.findingsResolution.reworkComments'),
                    })
                  "
                />
              </dx-validator>
            </dx-text-area>
          </div>
        </div>
      </div>

      <div class="row" style="margin-bottom: 5px">
        <div class="col-12">
          <dx-button
            @click="onSendButtonClick"
            width="150px"
            style="margin-right: 15px"
            :text="$t('findings.findingsResolution.reworkSendButton')"
            type="success"
          ></dx-button
          >&nbsp;
          <dx-button @click="onClose" text="Cancel" width="150px"></dx-button>
        </div>
      </div>
      </dx-validation-group>
    </div>
    </dx-scroll-view>
  </template>
  </DxPopup>
</template>

<script>
import {DxTextArea, DxButton, DxPopup} from "devextreme-vue";
import {isNotEmpty, formatDate} from "../../common/helperFunctions";
import localeDatetimeFormat from "../../mixins/locale-datetime-format";
import { DxScrollView } from 'devextreme-vue/scroll-view';
import {DxValidator, DxRequiredRule} from "devextreme-vue/validator";
import DxValidationGroup from "devextreme-vue/validation-group";

export default {
  components: {
    DxTextArea,
    DxButton,
    DxPopup,
    DxScrollView,
    DxValidator,
    DxRequiredRule,
    DxValidationGroup
  },
  mixins: [localeDatetimeFormat],
  props: {
    findingTitle: {
      Type: String,
      required: true,
    },
    resolutionDate: {
      Type: Date,
      required: true,
    },
    findingOwner: {
      Type: String,
      required: true,
    },
    resolutionComment: {
      Type: String,
      required: true,
    },
    visible: {
      Type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: "",
      resetValidationRefKey: "resetValidationGroup",
      formatDate:formatDate
    };
  },
  methods: {
    onSendButtonClick() {
      this.validationGroup.validate();
      if (isNotEmpty(this.value)) {
        this.$emit("send-rework-click", this.value);
        this.value = "";
        this.$emit("close-click");
      }
    },
    onClose() {
      this.$emit("close-click");
    },
  },
  computed:{
    validationGroup() {
      return this.$refs[this.resetValidationRefKey].instance;
    },
  }
};
</script>

<style></style>
