import {assessmentStatuses} from "../../custom/config/workflows";

export default [
  {
    // Compulsory
    dataField: "id",
    visible: false,
    showInColumnChooser: false,
  },
  {
    // Compulsory
    // TODO: non-core field - does it need to be organisationId?
    dataField: "organisationId",
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: "retailer",
    caption: "assessments.assessmentList.retailer",
    cellTemplate: "retailer-template",
    visible: true,
    sortOrder: "asc",
    sortIndex: 1,
  },
  {
    dataField: "retailerAssessment",
    cellTemplate: "retailer-assessment-template",
    caption: "assessments.assessmentList.retailerAssessment",
    visible: true,
  },
  {
    dataField: "auditManager",
    caption: "assessments.assessmentList.auditManager",
    visible: true,
  },
  {
    dataField: "auditor",
    caption: "assessments.assessmentList.auditor",
    visible: true,
  },
  {
    dataField: "auditProgramme",
    caption: "assessments.assessmentList.auditProgrammes",
    visible: true,
  },
  {
    dataField: "startDate",
    caption: "assessments.assessmentList.startDate",
    dataType: "date",
    visible: true,
    sortOrder: "asc",
    sortIndex: 2,
  },
  {
    dataField: "endDate",
    caption: "assessments.assessmentList.endDate",
    dataType: "date",
    visible: false,
  },
  {
    dataField: "findings",
    caption: "assessments.assessmentList.findings",
    visible: false,
  },
  {
    dataField: "statusCode",
    caption: "assessments.assessmentList.status",
    lookup: {
      dataSource: {
        store: {
          type: "array",
          data: [
            {
              id: assessmentStatuses.notStarted,
              name: "assessments.assessmentList.notStarted",
            },
            {
              id: assessmentStatuses.inProgress,
              name: "assessments.assessmentList.inProgress",
            },
            {
              id: assessmentStatuses.submitted,
              name: "assessments.assessmentList.submitted",
            },
            {
              id: assessmentStatuses.complete,
              name: "assessments.assessmentList.complete",
            },
            {
              id: assessmentStatuses.cancelled,
              name: "assessments.assessmentList.cancelled",
            },
          ],
          key: "id",
        },
      },
      valueExpr: "id", // contains the same values as the "statusId" field provides
      displayExpr: "name", // provides display values
    },
    cellTemplate: "status-template",
    visible: true,
  },
  {
    dataField: "percentageComplete",
    caption: "assessments.assessmentList.percentageComplete",
    visible: true,
  },
  {
    dataField: "auditScore",
    caption: "assessments.assessmentList.auditScore",
    visible: true,
  },
  {
    cellTemplate: "assessment-button-cell-template",
    fixed: true,
    fixedPosition: "right",
    visible: true,
  },
];
