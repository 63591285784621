const getDefaultState = () => {
  return {
    imageStatus: null,
  };
};
export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    updateLoadingStatus({commit, state}, status) {
      commit("setLoadingStatus", status);
    },
  },
  mutations: {
    setLoadingStatus(state, imageStatus) {
      state.imageStatus = imageStatus;
    },
  },
  getters: {
    isLoading: (state) => {
      return state.imageStatus === "loading";
    },
  },
};
