<template>
  <div
    class="landing-card-container"
    :class="{'section-loader-on': loaderVisible}"
  >
    <div v-if="assessments && assessments.length">
      <h2>
        {{ $t("landingPage.assessmentOverview.assessmentsTitle") }}
        <router-link
          style="display: block; float: none"
          :to="{name: 'orders-list'}"
          >{{
            $t("landingPage.assessmentOverview.viewAllAssessments")
          }}</router-link
        >
      </h2>

      <div class="row assessment-cards-container" style="margin-bottom: -20px">
        <div
          class="col-sm-4"
          v-for="assessment in assessments"
          :key="assessment.id"
          @click="navigateToAssessment(assessment)"
          :style="[
            assessment.orderStatus === orderStatuses.ordered
              ? {'pointer-events': 'none'}
              : {},
          ]"
        >
          <div class="assessment-card">
            <div class="card-header">
              <div class="date">
                <p class="assessment-retailer">{{ assessment.retailerName }}</p>
                <i class="far fa-calendar"></i>
                <span v-if="assessment.startDate == null">TBC</span>

                <span v-else> {{ formatDate(assessment.startDate) }}</span>
              </div>
            </div>
            <div class="assessment-site">
              {{
                `${assessment.siteName} ${
                  assessment.supplierName ? assessment.supplierName : ""
                }`
              }}
            </div>
            <div class="assessment-type">
              {{ assessment.reviewType }}
            </div>
            <div class="score">
              {{ toPercentage(assessment.percentage)
              }}<span v-if="assessment.percentage">%</span>
              <!-- <i
                class="fas fa-exclamation-triangle"
                v-if="shouldShowExclamationMark(assessment)"
              ></i> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h2>
        {{ $t("landingPage.assessmentOverview.assessmentsTitle") }}
      </h2>
      <p style="margin-top: -5px" class="mb-0">
        {{ $t("landingPage.auditCoverage.noAssessmentsForCurrentPeriod") }}
      </p>
    </div>
  </div>
</template>

<script>
import {toCamelCase, formatDate} from "../../../common/helperFunctions";
import localeDatetimeFormat from "../../../mixins/locale-datetime-format";
import {orderStatuses} from "../../../custom/config/workflows";

export default {
  created() {
    this.$http
      .get(`dashboard/getUpcomingAssessments?top=5`)
      .then((response) => {
        this.assessments = response.data;
        this.loaderVisible = false;
      });
  },
  data() {
    return {
      assessments: null,
      loaderVisible: true,
      orderStatuses: orderStatuses,
    };
  },
  mixins: [localeDatetimeFormat],
  methods: {
    toCamelCase: toCamelCase,
    formatDate: formatDate,
    shouldShowExclamationMark(assessment) {
      return assessment.percentage > 0 && assessment.percentage !== 100;
    },
    navigateToAssessment(assessment) {
      this.$router.push({name: "assessment", params: {id: assessment.id}});
    },
    toPercentage(input) {
      return input === 0 ? "-" : `${input.toFixed(0)}`;
    },
  },
};
</script>

<style scoped>
</style>
