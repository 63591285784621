import axios from "axios";
import store from "../store";
import router from "../router";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {isEmpty} from "../common/helperFunctions";

const {VUE_APP_API_HOSTNAME, VUE_APP_CLIENT_HOSTNAME} = process.env;

const $axios = axios.create({
  headers: {
    "Access-Control-Allow-Origin": VUE_APP_CLIENT_HOSTNAME,
    "Content-Type": "application/json",
  },
  baseURL: `${VUE_APP_API_HOSTNAME}`,
});
// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) =>
  failedRequest &&
  failedRequest.response &&
  failedRequest.response.headers["token-expired"] == "true"
    ? $axios
        .post(
          "/security/refresh",
          {bearerToken: getAccessToken(), refreshToken: getRefreshToken()},
          {skipAuthRefresh: true},
        )
        .then((tokenRefreshResponse) => {
          if (tokenRefreshResponse && tokenRefreshResponse.data) {
            localStorage.setItem("token", tokenRefreshResponse.data.token);
            localStorage.setItem(
              "refreshToken",
              tokenRefreshResponse.data.refreshToken,
            );
            failedRequest.response.config.headers["Authorization"] =
              "Bearer " + tokenRefreshResponse.data.token;
            return Promise.resolve();
          } else {
            axiosLogout();
          }
        })
    : Promise.reject();

createAuthRefreshInterceptor($axios, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: false,
  statusCodes: [401],
});

// Obtain the fresh token each time the function is called
function getAccessToken() {
  return localStorage.getItem("token");
}

// Obtain the fresh token each time the function is called
function getRefreshToken() {
  return localStorage.getItem("refreshToken");
}

function axiosLogout() {
  let user = JSON.parse(localStorage.getItem("user"));
  store.dispatch("auth/logout");
  if (user && user.authMethod == "Federated") {
    window.location = `${VUE_APP_API_HOSTNAME}/security/wsfed-signout`;
  } else {
    router.push({name: "login-form"});
  }
}

// This is where we set the authorization config for our axios instance.
// This code will intercept every request made by the browser
// and check for an auth token stored in state
$axios.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("token");
    if (authToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${authToken}`,
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      };
    }

    return config;
  },
  (err) => {
    // TODO: more appropriate error logging
    // eslint-disable-next-line no-console
    console.log(err);
  },
);
$axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      isEmpty(error) ||
      isEmpty(error.response) ||
      isEmpty(router) ||
      isEmpty(router.currentRoute)
    ) {
      return Promise.reject(error);
    }

    if (
      (error.response.status === 401 &&
        router.currentRoute.name !== "login-form") ||
      error.response.status === 403
    ) {
      axiosLogout();
    } else {
      return Promise.reject(error);
    }
  },
);
export default $axios;
