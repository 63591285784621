<template>
  <div :class="{loading: isLoading}">
    <div class="row">
      <div class="col">
        <div class="with-side-indent">
          <page-heading
            :pageTitle="translatedPageTitle"
            :breadCrumbs="breadCrumbs"
          />
        </div>
        <div class="tabbed-details-menu" style="margin-top: -90px">
          <div
            class="assessment-subnav-tabs"
            style="margin-bottom: 5px; margin-left: 5px"
          >
            <div class="summary-tab" style="position: relative">
              <div @click="toggleDownloadOptions">
                <div v-show="!showDownloadOptions">
                  <i class="fas fa-download"></i>
                  {{ $t("assessments.summary.print") }}
                </div>
                <div v-show="showDownloadOptions">
                  <i class="fas fa-chevron-up"></i>
                  Close
                </div>
              </div>
              <div class="download-dropdown" v-show="this.showDownloadOptions">
                <label style="margin-bottom: 5px">Page Size: </label>
                <DxSelectBox
                  style="margin-bottom: 10px"
                  styling-mode="outlined"
                  :data-source="pageSizeDataSource"
                  :value.sync="pageSize"
                  display-expr="display"
                  value-expr="value"
                ></DxSelectBox>
                <label style="margin-bottom: 5px">Page Layout: </label>
                <DxSelectBox
                  style="margin-bottom: 10px"
                  styling-mode="outlined"
                  :data-source="pageOrientationDataSource"
                  :value.sync="orientation"
                  display-expr="display"
                  value-expr="value"
                ></DxSelectBox>
                <DxButton
                  type="normal"
                  class="btn btn-large"
                  icon="download"
                  @click="generateReport"
                >
                  <template #content>
                    <span>
                      <DxLoadIndicator
                        :visible="exportIndicatorVisible"
                        height="20"
                        width="20"
                        class="button-indicator"
                      />
                      <span class="dx-button-text">{{
                        exportIndicatorVisible
                          ? $t("assessments.summary.printing")
                          : $t("assessments.summary.print")
                      }}</span>
                    </span>
                  </template></DxButton
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="pdfContent">
      <div
        class="row"
        style="padding: 20px 0px; margin-left: 0px; margin-right: 0px"
      >
        <div
          v-if="siteInformation"
          class="col-lg-6"
          style="padding-left: 0px; padding-right: 0px"
        >
          <div
            ref="siteInformation"
            class="card-container xs-adjustment"
            style="border-radius: 5px 0px 0px 5px; padding-bottom: 10px"
          >
            <div
              class="d-flex flex-row justify-content-between"
              style="margin-bottom: 20px"
            >
              <p style="font-weight: bold">
                <i class="far fa-building x-large-fa"></i>
                {{ $t("assessments.summary.siteInformation") }}
              </p>
              <div
                v-if="siteInformation && isNotEmpty(siteInformation.riskRating)"
                class="d-flex justify-content-center align-items-center"
              >
                <label style="margin-right: 5px">Risk Rating:</label>
                <label class="risk-rating-label">
                  {{ siteInformation.riskRating }}
                </label>
              </div>
            </div>
            <div class="row">
              <div v-if="!isIngkaUnit" class="col-lg-6 site-information">
                <label>{{ $t("assessments.summary.supplierName") }}</label>
                <p class="details">
                  {{ siteInformation.supplierName }}
                </p>
              </div>
              <div class="col-lg-6 site-information">
                <label>{{ $t("assessments.summary.siteName") }}</label>
                <p class="details">
                  {{ siteInformation.siteName }}
                </p>
              </div>
              <div class="col-lg-6 site-information">
                <label>{{ $t("assessments.summary.reviewDates") }}</label>
                <p class="details">
                  {{
                    `${formatDate(siteInformation.startDate)} - ${formatDate(
                      siteInformation.endDate,
                    )}`
                  }}
                </p>
              </div>
              <div class="col-6 site-information">
                <label>{{ $t("assessments.summary.address") }}</label>
                <p class="details">{{ siteInformation.address }}</p>
              </div>
              <div v-if="!isIngkaUnit" class="col-lg-6 site-information">
                <label>{{ $t("assessments.summary.announcedReview") }}</label>
                <p class="details">
                  {{ `${siteInformation.isAnnouncedReview ? "Yes" : "No"}` }}
                </p>
              </div>
              <div v-if="!isIngkaUnit" class="col-lg-6 site-information">
                <label>{{ $t("assessments.summary.category") }}</label>
                <p class="details">
                  {{ siteInformation.categories.join(", ") }}
                </p>
              </div>
              <div class="col-lg-6 site-information">
                <label>{{ $t("assessments.summary.leadReviewer") }}</label>
                <p class="details">{{ siteInformation.leadReviewer }}</p>
              </div>
              <div class="col-lg-6 site-information">
                <label>{{ $t("assessments.summary.reviewType") }}</label>
                <p class="details">{{ siteInformation.reviewType }}</p>
              </div>
              <div v-if="!isIngkaUnit" class="col-lg-6 site-information">
                <label>{{ $t("assessments.summary.businessOwner") }}</label>
                <p class="details">
                  {{ siteInformation.businessOwners.join(", ") }}
                </p>
              </div>
              <div
                :class="isTextOverFlowing() ? 'col-lg-6' : 'col-lg-12'"
                class="site-information"
              >
                <label>{{ $t("assessments.summary.scopeOfReview") }}</label>
                <p :ref="scope" class="details scopeOfReviews">
                  {{ this.siteInformation.scopeOfReviews.join(", ") }}
                </p>
                <a
                  :ref="scopeOfReviewLink"
                  v-if="isTextOverFlowing()"
                  style="cursor: pointer"
                  @click="viewLessOrMore()"
                >
                  {{ toggleViewLessOrMoreText }}
                </a>
                <a
                  :ref="scopeOfReviewLink"
                  v-else
                  style="cursor: pointer"
                  @click="viewLessOrMore()"
                >
                  {{ toggleViewLessOrMoreText }}
                </a>
              </div>
            </div>
            <div class="html2pdf__page-break"></div>
          </div>
        </div>
        <div
          v-if="assessmentResponseDetails"
          class="col-lg-6"
          style="text-align: center; margin-left: 0px"
        >
          <div ref="assessmentResponseDetails">
            <div class="row">
              <div class="col-lg-6">
                <div style="border-radius: 25px; margin-bottom: 25px">
                  <div class="left-pill">
                    {{ $t("assessments.summary.numberOf") }} <br />
                    <b>{{ $t("assessments.summary.oks") }}</b>
                  </div>
                  <div
                    class="right-pill"
                    style="background-color: rgb(58, 96, 110)"
                  >
                    <span style="font-size: x-large">{{
                      assessmentResponseDetails.numberOfOks
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div style="border-radius: 25px; margin-bottom: 25px">
                  <div class="left-pill">
                    {{ $t("assessments.summary.numberOf") }} <br />
                    <b>{{ $t("assessments.summary.notOks") }}</b>
                  </div>
                  <div
                    class="right-pill"
                    style="background-color: rgb(96, 123, 125)"
                  >
                    <span style="font-size: x-large">{{
                      assessmentResponseDetails.numberOfNotOks
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div style="border-radius: 25px; margin-bottom: 25px">
                  <div class="left-pill">
                    {{ $t("assessments.summary.gradingStatus") }}
                  </div>
                  <div
                    class="right-pill"
                    style="background-color: rgb(170, 174, 142)"
                  >
                    <i
                      class="far fa-chart-bar xxx-large-fa"
                      style="margin-bottom: 5px"
                    ></i>
                    <b class="summary-report-pill-text">{{
                      `${
                        assessmentResponseDetails.complianceStatus
                          ? assessmentResponseDetails.complianceStatus
                          : $t("shared.na")
                      }`
                    }}</b>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div style="border-radius: 25px; margin-bottom: 25px">
                  <div class="left-pill">
                    {{ $t("assessments.summary.numberOf") }} <br />
                    <b>{{ $t("shared.na") }}</b>
                  </div>
                  <div
                    class="right-pill"
                    style="background-color: rgb(130, 142, 130)"
                  >
                    <span style="font-size: x-large">{{
                      assessmentResponseDetails.numberOfNAs
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div style="border-radius: 25px; margin-bottom: 25px">
                  <div class="left-pill">
                    {{ $t("assessments.summary.actionPlan") }}
                    <br />
                    {{ $t("assessments.summary.deadline") }}
                  </div>
                  <div
                    class="right-pill"
                    style="background-color: rgb(197, 199, 183)"
                  >
                    <i
                      class="far fa-calendar-alt xxx-large-fa"
                      style="margin-bottom: 5px"
                    ></i>
                    <b class="summary-report-pill-text">{{
                      assessmentResponseDetails.actionPlanDeadline
                    }}</b>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div style="border-radius: 25px; margin-bottom: 25px">
                  <div class="left-pill">
                    {{ $t("assessments.summary.finalApproval") }}
                    <br />{{ $t("assessments.summary.deadline") }}
                  </div>
                  <div
                    class="right-pill"
                    style="background-color: rgb(83, 120, 130)"
                  >
                    <i
                      class="far fa-calendar-alt xxx-large-fa"
                      style="margin-bottom: 5px"
                    ></i>
                    <b class="summary-report-pill-text">{{
                      assessmentResponseDetails.finalApprovalDeadline
                    }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-9">
          <div class="pills-header">
            {{ $t("assessments.summary.reviewInformation") }}
          </div>
        </div>
      </div>
      <div class="row" style="padding-bottom: 20px">
        <div class="col-sm-12">
          <div v-if="reviewInformationAndSummaryDetails" style="width: 100%">
            <DxDataGrid
              class="dx-card thin-column-chooser"
              :data-source="reviewInformationAndSummaryDetails"
              :show-borders="false"
              :focused-row-index="0"
              :focused-row-enabled="false"
              :column-hiding-enabled="false"
              :selection="{mode: 'single'}"
              :hover-state-enabled="true"
              :columns="colDefs(reviewInfoSummaryColDefs)"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :word-wrap-enabled="wordWrapEnabled"
              columnResizingMode="widget"
              :ref="reviewInfoDataGridRefName"
              @exporting="onExporting($event, 'ReviewInfoSummaryResults')"
            >
              <DxHeaderFilter :visible="true" :allowSearch="true" />
              <DxColumnChooser
                :enabled="true"
                :allowSearch="true"
                :height="500"
                mode="dragAndDrop"
              />
              <DxColumnFixing :enabled="true" />
              <DxRemoteOperations
                :filtering="false"
                :paging="false"
                :sorting="false"
                :summary="false"
                :grouping="false"
                :group-paging="false"
              />
              <DxGroupPanel :visible="true" />
              <DxGrouping :auto-expand-all="true" />
              <DxPaging :page-size="150" />
              <DxPager :show-page-size-selector="true" :show-info="true" />
              <DxFilterRow :visible="true" />
              <DxExport :enabled="true" />

              <template #response-cell-template="{data}">
                <p style="white-space: break-spaces">
                  {{ data.data.response }}
                </p>
              </template>

              <template #groupCellTemplate="{data}">
                <div>
                  {{ getGroupTitle(data) }}
                </div>
              </template>
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-9">
        <div class="pills-header">
          {{ $t("assessments.summary.assessmentResults") }}
        </div>
      </div>
    </div>
    <div class="row" style="padding-bottom: 20px">
      <div class="col-sm-12">
        <div v-if="assessmentResponseDetails" style="width: 100%">
          <DxDataGrid
            class="dx-card thin-column-chooser"
            :data-source="assessmentResults"
            :show-borders="false"
            :focused-row-index="0"
            :focused-row-enabled="false"
            :column-hiding-enabled="false"
            :selection="{mode: 'single'}"
            :hover-state-enabled="true"
            :columns="colDefs(assessmentSummarylistColDefs)"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :word-wrap-enabled="wordWrapEnabled"
            columnResizingMode="widget"
            :ref="assessmentSummaryDataGridRefName"
            @exporting="onExporting($event, 'ReviewResults')"
          >
            <DxHeaderFilter :visible="true" :allowSearch="true" />
            <DxColumnChooser
              :enabled="true"
              :allowSearch="true"
              :height="500"
              mode="dragAndDrop"
            />
            <DxColumnFixing :enabled="true" />
            <DxRemoteOperations
              :filtering="false"
              :paging="false"
              :sorting="false"
              :summary="false"
              :grouping="false"
              :group-paging="false"
            />
            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="true" />
            <DxPaging :page-size="150" />
            <DxPager :show-page-size-selector="true" :show-info="true" />
            <DxFilterRow :visible="true" />
            <DxExport :enabled="true" />

            <template #comments-findings-cell-template="{data}">
              <div
                v-if="
                  data && data.data && data.data.commentsOrDeviations.length > 0
                "
              >
                <p
                  style="white-space: break-spaces"
                  v-for="(comment, index) in data.data.commentsOrDeviations"
                  :key="index"
                >
                  {{ comment }}
                </p>
                <br />
              </div>
            </template>
            <template #root-cause-cell-template="{data}">
              <p style="white-space: break-spaces">
                {{ data.data.rootCause }}
              </p>
            </template>

            <template #corrective-action-plan-cell-template="{data}">
              <p style="white-space: break-spaces">
                {{ data.data.correctiveActionPlan }}
              </p>
            </template>

            <template #groupCellTemplate="{data}">
              <div>
                {{ getGroupTitle(data) }}
              </div>
            </template>
          </DxDataGrid>
        </div>
      </div>
    </div>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      :pdf-format="this.pageSize"
      :pdf-orientation="this.orientation"
      pdf-content-width="100%"
      ref="html2Pdf"
      @beforeDownload="beforeDownload($event)"
    >
      <section
        style="padding: 50px"
        ref="pdfOutput"
        slot="pdf-content"
      ></section>
    </VueHtml2pdf>
  </div>
</template>

<script>
import {jsPDF} from "jspdf";
import addFontToJsPdf from "../../assets/fonts/arial-unicode-ms-normal.js";
import "jspdf-autotable";
import {exportDataGrid as exportDataGridToPdf} from "devextreme/pdf_exporter";
import {PDFDocument} from "pdf-lib";
import {mapActions, mapState} from "vuex";
import {isNotEmpty} from "../../common/helperFunctions";
import ArrayStore from "devextreme/data/array_store";
import VueHtml2pdf from "vue-html2pdf";
import DxDataGrid, {
  DxFilterRow,
  DxPager,
  DxPaging,
  DxRemoteOperations,
  DxHeaderFilter,
  DxColumnChooser,
  DxColumnFixing,
  DxGrouping,
  DxGroupPanel,
  DxExport,
} from "devextreme-vue/data-grid";
import {DxButton, DxLoadIndicator} from "devextreme-vue";
import pageHeading from "../page-heading.vue";
import {exportDataGrid} from "devextreme/excel_exporter";
import {Workbook} from "exceljs";
import fileDownload from "js-file-download";
import assessmentSummarylistColDefs from "../../custom/assessment/assessmentSummaryColDefs";
import reviewInfoSummaryColDefs from "../../custom/assessment/reviewInformationAndSummaryDetailsColDefs";

import DxSelectBox from "devextreme-vue/select-box";
export default {
  components: {
    VueHtml2pdf,
    pageHeading,
    DxDataGrid,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxRemoteOperations,
    DxHeaderFilter,
    DxColumnChooser,
    DxColumnFixing,
    DxExport,
    DxGrouping,
    DxSelectBox,
    DxGroupPanel,
    DxButton,
    DxLoadIndicator,
  },
  data() {
    return {
      isContentAvailable: false,
      inputData: null,
      scopeOfReviewLink: "scopeOfReviewLinkRef",
      scope: "scopeRef",
      viewLessOrMoreText: "",
      savingTimeout: 0,
      summaryPopup: "summaryPopupRef",
      assessmentSummaryDataGridRefName: "assessmentSummaryDataGrid",
      reviewInfoDataGridRefName: "reviewInfoDataGrid",
      btnPaginationText: this.$t("assessments.summary.disablePagination"),
      paginationEnabled: true,
      isLoading: true,
      pageSize: "a2",
      orientation: "landscape",
      pageOrientationDataSource: new ArrayStore({
        data: [
          {display: "Portrait", value: "portrait"},
          {display: "Landscape", value: "landscape"},
        ],
        key: "display",
      }),
      showDownloadOptions: false,
      pageSizeDataSource: new ArrayStore({
        data: [
          {display: "A2", value: "a2"},
          {display: "A3", value: "a3"},
          {display: "A4", value: "a4"},
          {display: "A5", value: "a5"},
        ],
        key: "display",
      }),
      assessmentSummarylistColDefs: assessmentSummarylistColDefs,
      reviewInfoSummaryColDefs: reviewInfoSummaryColDefs,
      exportIndicatorVisible: false,
      wordWrapEnabled: true
    };
  },
  created() {
    this.loadSummaryReport();
  },
  computed: {
    ...mapState("assessments", [
      "siteInformation",
      "assessmentResults",
      "assessmentResponseDetails",
      "reviewInformationAndSummaryDetails",
    ]),
    translatedPageTitle() {
      return this.$t("assessments.summary.title");
    },
    breadCrumbs() {
      return [
        this.$t("assessments.summary.breadCrumbTitle"),
        this.translatedPageTitle,
      ];
    },
    toggleViewLessOrMoreText() {
      return this.viewLessOrMoreText;
    },
    isIngkaUnit() {
      return this.siteInformation.organisationType === "Ingka unit";
    },
  },
  methods: {
    gridDataSource(dataGridRefName) {
      if (dataGridRefName === this.assessmentSummaryDataGridRefName)
        return this.assessmentResults;
      else if (dataGridRefName === this.reviewInfoDataGridRefName)
        return this.reviewInformationAndSummaryDetails;
      else return [];
    },
    dataGrid(dataGridRefName) {
      return this.$refs[dataGridRefName].instance;
    },
    toggleDownloadOptions() {
      this.showDownloadOptions = !this.showDownloadOptions;
    },
    calcMinWidth(orientation, size) {
      if (size === 'a5' && orientation === "landscape")
        return 55
      else if (size === 'a5')
        return 45
      else if (orientation === "landscape")
        return 100
      return 65
    },

    async exportGrid(dataGridRefName) {
      const doc = new jsPDF(this.orientation, "pt", this.pageSize);
      this.exportIndicatorVisible = true;
      await addFontToJsPdf(doc);
      doc.setFont("arial-unicode-ms");
      this.exportIndicatorVisible = false;

      return exportDataGridToPdf({
        keepColumnWidths: false,
        jsPDFDocument: doc,
        component: this.dataGrid(dataGridRefName),
        autoTableOptions: {
          styles: {
            font: "arial-unicode-ms",
            minCellWidth: this.calcMinWidth(this.orientation, this.pageSize),
          },
        },
        customizeCell: ({gridCell, pdfCell}) => {
          if (
            gridCell.rowType === "group" &&
            gridCell.column.dataField === "sectionTitle"
          ) {
            const cellValue = this.gridDataSource(dataGridRefName).find(
              (x) => x.parentSectionLevel1Ref === gridCell.value,
            ).sectionTitle;
            pdfCell.content = `Section: ${cellValue}`;
          }
        },
      }).then(() => {
        let pdf = doc;
        return pdf.output("arraybuffer");
      });
    },
    async generateReport() {
      const ele = this.$refs[this.scope];
      const scopeOfReviewLinkElement = this.$refs[this.scopeOfReviewLink];
      ele.classList.remove("scopeOfReviews");
      scopeOfReviewLinkElement.hidden = true;
      let siteInformation = this.$refs.siteInformation.cloneNode(true);
      let assessmentResponseDetails =
        this.$refs.assessmentResponseDetails.cloneNode(true);
      ele.classList.add("scopeOfReviews");
      this.$refs.pdfOutput.replaceChildren(
        siteInformation,
        assessmentResponseDetails,
      );
      await this.$refs.html2Pdf.generatePdf();
      this.$refs.pdfOutput.replaceChildren();
      scopeOfReviewLinkElement.hidden = false;
    },
    async beforeDownload({html2pdf, options, pdfContent}) {
      options.margin = [0.5, 0, 0, 0];
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then(async (pdf) => {
          this.wordWrapEnabled = false;
          const reviewInfoSummaryTablePDF = await this.exportGrid(
            this.reviewInfoDataGridRefName,
          );
          const assessmentSummaryTablePDF = await this.exportGrid(
            this.assessmentSummaryDataGridRefName,
          );
          this.wordWrapEnabled = true;
          let contentPDF = pdf.output("arraybuffer");
          this.mergePdfs(
            contentPDF,
            reviewInfoSummaryTablePDF,
            assessmentSummaryTablePDF,
          );
        });
    },
    async mergePdfs(contentPdf, table1Pdf, table2Pdf) {
      const pdfDoc = await PDFDocument.create();
      const firstDoc = await PDFDocument.load(contentPdf);
      const firstPage = await pdfDoc.copyPages(
        firstDoc,
        firstDoc.getPageIndices(),
      );

      firstPage.forEach((page) => pdfDoc.addPage(page));
      const secondDoc = await PDFDocument.load(table1Pdf);
      const secondPage = await pdfDoc.copyPages(
        secondDoc,
        secondDoc.getPageIndices(),
      );

      secondPage.forEach((page) => pdfDoc.addPage(page));
      const thirdDoc = await PDFDocument.load(table2Pdf);
      const thirdPage = await pdfDoc.copyPages(
        thirdDoc,
        thirdDoc.getPageIndices(),
      );

      thirdPage.forEach((page) => pdfDoc.addPage(page));

      const pdfBytes = await pdfDoc.save();
      let pdfName = `${
        this.siteInformation.siteName
      } Summary Report ${this.formatDate(new Date())}.pdf`;
      let file = new Blob([pdfBytes], {type: "application/pdf"});

      var fileURL = URL.createObjectURL(file);
      let link = document.createElement("a");
      link.href = fileURL;
      link.download = pdfName;
      link.click();
    },
    isNotEmpty: isNotEmpty,
    ...mapActions("assessments", [
      "getSiteInformation",
      "getassessmentResults",
    ]),
    async loadSummaryReport() {
      await Promise.all([
        await this.getSiteInformation({assessmentId: this.$route.params.id}),
        await this.getassessmentResults({assessmentId: this.$route.params.id}),
      ]);

      this.isLoading = false;
    },
    formatDate(date) {
      return `${new Date(date).toLocaleDateString("en-gb", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })}`;
    },
    isTextOverFlowing() {
      var ele = this.$refs[this.scope];
      if (ele) {
        if (ele.clientWidth < ele.scrollWidth) {
          this.viewLessOrMoreText = this.$t("assessments.summary.viewMore");
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    viewLessOrMore() {
      const ele = this.$refs[this.scope];
      if (ele.clientWidth < ele.scrollWidth) {
        this.viewLessOrMoreText = this.$t("assessments.summary.viewLess");
        ele.classList.remove("scopeOfReviews");
      } else {
        ele.classList.add("scopeOfReviews");
        this.viewLessOrMoreText = this.$t("assessments.summary.viewMore");
      }
      this.isTextOverFlowing();
    },
    getGroupTitle(data) {
      if (data && data.data) {
        if (data.data.items && data.data.items.length > 0) {
          return data.column.caption + ": " + data.data.items[0].sectionTitle;
        } else if (
          data.data.collapsedItems &&
          data.data.collapsedItems.length > 0
        ) {
          return (
            data.column.caption +
            ": " +
            data.data.collapsedItems[0].sectionTitle
          );
        }
      }
    },
    onExporting(e, fileName) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(fileName);

      exportDataGrid({
        component: e.component,
        worksheet,
        customizeCell: ({gridCell, excelCell}) => {
          if (
            gridCell.rowType === "group" &&
            gridCell.column.dataField === "sectionTitle"
          ) {
            const cellValue = (fileName === "ReviewInfoSummaryResults" ? this.reviewInformationAndSummaryDetails : this.assessmentResults).find(
              (x) => x.parentSectionLevel1Ref === gridCell.value,
            ).sectionTitle;
            excelCell.value = `Section: ${cellValue}`;
          }

          if (
            gridCell.rowType === "data" &&
            gridCell.column.dataField === "commentsOrDeviations"
          ) {
            excelCell.value =
              gridCell.value.length > 0 ? gridCell.value.join(", ") : null;
          }
        },
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          fileDownload(
            new Blob([buffer], {type: "application/octet-stream"}),
            `${fileName}.xlsx`,
          );
        });
      });
      e.cancel = true;
    },
    colDefs(colDefsList) {
      let colDefs = JSON.parse(
        JSON.stringify(this.colDefsTranslatedLookups(colDefsList)),
      );
      return colDefs.map((x) =>
        x.caption
          ? {
              ...x,
              caption: this.$t(x.caption),
            }
          : x,
      );
    },
    colDefsTranslatedLookups(colDefsList) {
      let colDefs = JSON.parse(JSON.stringify(colDefsList));
      colDefs.forEach((element) => {
        if (element.lookup) {
          element.lookup.dataSource.store.data.forEach((x) => {
            if (x.name) {
              x.name = this.$t(x.name);
            }
          });
        }
      });
      return colDefs;
    },
  },
  beforeDestroy() {
    this.paginationEnabled = true;
  },
};
</script>

<style lang="scss" scoped>
.details {
  font-weight: bold;
}
.left-pill {
  float: left;
  width: 50%;
  background-color: white;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.right-pill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  height: 95px;
  padding: 10px 0;
  color: white;
}
.pills-header {
  margin-bottom: 20px;
  font-weight: bold;
  border-left: 10px solid black;
  padding-left: 5px;
  font-size: medium;
}
.scopeOfReviews {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.site-information {
  p {
    margin: 5px 0px;
  }
}
.review-information-details {
  background-color: white;
  margin-right: 30px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  height: fit-content;
  width: 31%;
  border: 1px solid #c4c4c4;
  word-break: break-word;
}
.review-information-details {
  @media screen and (max-width: 720px) {
    width: 100%;
  }
  @media screen and (min-width: 721px) and (max-width: 1300px) {
    width: 45%;
  }
}
.button-indicator {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
  border-radius: 5px;
}
</style>
