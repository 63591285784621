import $axios from "../../utils/axios-instance.js";
import {getField, updateField} from "vuex-map-fields";
import {getSecureImage} from "../../common/helperFunctions";

const getDefaultState = () => {
  return {
    isLoading: true,
    selectedVisitType: null,
    selectedOrganisation: [],
    selectedTertial: null,
    selectedReviewMode: null,
    selectedReviewType: null,
    selectedScopeOfReview: [],
    preselectedScopeOfReview: [],
    selectedLevelOfReviewMultiple: [],
    selectedOrderer: null,
    reviewScope: [],
    notificationDelayDate: null,
    delayNotification: false,
    selectedMultipleSupportReviewers: [],
    reviewModes: [
      {
        id: 1,
        name: "Compliance review",
      },
      {
        id: 2,
        name: "Self assessment",
      },
    ],
    reviewTypes: [],
    selectedLeadReviewer: null,
    supportReviewers: [],
    comments: [],
    comment: null,
    reviewEndDate: null,
    reviewStartDate: null,
    estReportDueDate: null,
    estCAPDate: null,
    estDeadlineApprovalDate: null,
    selectedSiteCategoryMultiple: [],
    selectedIsThereReviewerCapacity: null,
    notification: null,
    orderDetails: null,
    orderStatus: null,
    selectedCAPStatus: null,
    selectedFollowupStatus: null,
    reviewDetails: null,
    tertials: [
      {
        id: 1,
        name: "Tertial 1",
      },
      {
        id: 2,
        name: "Tertial 2",
      },
      {
        id: 3,
        name: "Tertial 3",
      },
    ],
    levels: [
      {
        id: 4,
        name: "Must",
      },
      {
        id: 1,
        name: "Basic",
      },
      {
        id: 2,
        name: "Advanced",
      },
      {
        id: 3,
        name: "Excellent",
      },
    ],
    complianceStatus: null,
    initialComments: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    setReviewScope(state, reviewScope) {
      state.reviewScope = reviewScope;
    },
    setReviewTypes(state, reviewTypes) {
      state.reviewTypes = reviewTypes;
    },
    setComments(state, comments) {
      state.comments = comments;
    },
    setCommentData(state, comment) {
      state.comment = comment;
    },
    setReviewEndDate(state, date) {
      state.reviewEndDate = date;
    },
    setOrder(state, orderDetails) {
      state.orderDetails = orderDetails;
    },
    setOrderStaus(state, status) {
      state.orderStatus = status;
    },
    setReviewDetails(state, reviewDetails) {
      state.reviewDetails = reviewDetails;
    },
    setPreselectedScopeOfReview(state, preselectedScopeOfReview) {
      state.preselectedScopeOfReview = preselectedScopeOfReview;
    },
    setComplianceStatus(state, complianceStatus) {
      state.complianceStatus = complianceStatus;
    },
  },
  actions: {
    resetOrderState({commit}) {
      commit("resetState");
    },

    async getUsers({commit}, roles) {
      try {
        let response = await $axios.get(`/users/GetUsersByRole/${roles}`);
        return response;
      } catch (err) {
        throw new Error(err);
      }
    },

    async getReviewTypes({commit}) {
      try {
        let response = await $axios.get(`orders/reviewtypes`);
        commit("setReviewTypes", response.data.sort((a,b) => a.name.localeCompare(b.name)));
      } catch (err) {
        throw new Error(err);
      }
    },
    async getScopeOfReview({commit}, reviewTypeId) {
      try {
        let response = await $axios.get(`/orders/reviewscope/${reviewTypeId}`);
        commit("setReviewScope", response.data);
        commit(
          "setPreselectedScopeOfReview",
          response.data.filter((x) => x.isSelected === true).map((x) => x.id),
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    async postComment({dispatch}, payload) {
      try {
        await $axios.post(`/orders/${payload.orderId}/comments`, {
          comment: payload.comment,
        });
      } catch (err) {
        throw new Error(err);
      }
    },
    async getCommentsList({commit}, payload) {
      try {
        let response = await $axios.get(`/orders/${payload.orderId}/comments`);

        let comments = [];
        for (let comment of response.data) {
          if (comment.pictureUrl) {
            comment = {
              ...comment,
              pictureUrl: getSecureImage(comment.pictureUrl),
            };
          }
          comments.push(comment);
        }
        commit("setComments", comments);
      } catch (err) {
        throw new Error(err);
      }
    },
    async updateComment({commit}, payload) {
      try {
        await $axios.put(`/orders/comments/${payload.commentId}`, payload);
      } catch (err) {
        throw new Error(err);
      }
    },
    async getComment({commit}, commentId) {
      try {
        let response = await $axios.get(`/orders/comments/${commentId}`);

        commit("setCommentData", response.data);
      } catch (err) {
        throw new Error(err);
      }
    },
    async deleteComment({commit}, commentId) {
      try {
        await $axios.delete(`/orders/comments/${commentId}`);
      } catch (err) {
        throw new Error(err);
      }
    },
    updateReviewEndDate({commit}, date) {
      commit("setReviewEndDate", date);
    },
    async createOrder({dispatch}, payload) {
      try {
        await $axios.post(`/orders`, payload.requestBody);
      } catch (err) {
        throw new Error(err);
      }
    },
    async getOrder({commit}, orderId) {
      try {
        let response = await $axios.get(`/orders/getorder/${orderId}`);
        commit("setOrder", response.data);
      } catch (err) {
        throw new Error(err);
      }
    },
    async updateOrder({dispatch}, payload) {
      try {
        await $axios.put(`/orders/${payload.orderId}`, payload.requestBody);
      } catch (err) {
        throw new Error(err);
      }
    },
    updateOrderStatus({commit}, orderStatus) {
      commit("setOrderStaus", orderStatus);
    },
    async scheduleReview({dispatch}, payload) {
      try {
        await $axios.post(`/visit/order`, payload.requestBody);
      } catch (err) {
        throw new Error(err);
      }
    },
    async updateReview({dispatch}, payload) {
      try {
        await $axios.put(
          `/visit/order/${payload.orderId}`,
          payload.requestBody,
        );
      } catch (err) {
        throw new Error(err);
      }
    },
    async getReviewDetails({commit}, orderId) {
      try {
        let response = await $axios.get(`/visit/order/${orderId}`);
        commit("setReviewDetails", response.data);
      } catch (err) {
        throw new Error(err);
      }
    },
    async getComplianceStatus({commit}, orderId) {
      try {
        let response = await $axios.get(`/visit/${orderId}/complianceStatus`);
        commit("setComplianceStatus", response.data.complianceByLevels);
      } catch (err) {
        throw new Error(err);
      }
    },
    async reopenAssessment({commit}, orderId) {
      try {
        await $axios.put(`/visit/${orderId}/reopenassessment`, {});
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
