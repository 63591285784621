import Vue from "vue";
import Vuex from "vuex";
import {createStore} from "vuex-extensions";
import organisationsModule from "./modules/organisations";
import schedulesModule from "./modules/schedules";
import assessmentsModule from "./modules/assessments";
import authModule from "./modules/auth";
import findingsResolutionModule from "./modules/findingsResolution";
import findingsModule from "./modules/findings";
import reportingFiltersModule from "./modules/reportingFilters";
import outstandingFindingsModule from "./modules/outstandingFindings";
import upcomingAssessmentModule from "./modules/upcomingAssessment";
import assessmentOverviewModule from "./modules/assessmentOverview";
import sharedComponentsModule from "./modules/sharedComponents";
import ordersModule from "./modules/orders";
import attachmentsModule from "@/store/modules/attachments";

Vue.use(Vuex);

export default createStore(Vuex.Store, {
  sub: {
    actions: {
      logout() {
        this.reset();
      },
    },
  },
  state: {},
  modules: {
    attachments: attachmentsModule,
    organisations: organisationsModule,
    schedules: schedulesModule,
    orders: ordersModule,
    assessments: assessmentsModule,
    findingsResolution: findingsResolutionModule,
    findings: findingsModule,
    auth: authModule,
    reportingFilters: reportingFiltersModule,
    sharedComponents: sharedComponentsModule,
    landingPage: {
      namespaced: true,
      modules: {
        outstandingFindings: outstandingFindingsModule,
        upcomingAssessment: upcomingAssessmentModule,
        assessmentOverview: assessmentOverviewModule,
      },
    },
  },
  getters: {},
});
