export const masterColDefs = Object.freeze([
  {
    dataField: "id",
    visible: false,
  },
  {
    dataField: "operationType",
    caption: "Activity Type",
  },
  {
    dataField: "description",
    caption: "Activity Description",
  },
  {
    dataField: "modifiedDate",
    caption: "Date",
    sortOrder: "desc",
    dataType: "datetime",
  },
  {
    dataField: "modifiedBy",
    caption: "Modified By",
  },
]);

export const detailsColDefs = Object.freeze([
  {
    dataField: "id",
    visible: false,
  },
  {
    dataField: "fieldName",
    caption: "Field",
  },
  {
    dataField: "oldValue",
    caption: "Old Value",
  },
  {
    dataField: "newValue",
    caption: "New Value",
  },
]);
