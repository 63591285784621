export const referenceLinkType = Object.freeze({
  None: 1,
  Document: 2,
  Url: 3,
  System: 4,
});

export const locationGroupBy = Object.freeze({
  Site: 1,
  Supplier: 2,
});

export const structureGroupBy = Object.freeze({
  ReviewType: 16,
  Country: 17,
  LeadReviewer: 18,
  Tertial: 19,
  IsThereReviewerCapacity: 20,
  Notification: 21,
  RiskAccordingToRatingTool: 22,
  FollowUpStatus: 23,
  CapStatus: 24,
  CategoryArea: 25,
  SiteCategory: 26,
});

export const reviewDetailsGroupBy = Object.freeze({
  Level: 11,
  Category: 14,
  Principle: 27,
});

export const locationAndStructureGroupBy = Object.freeze(
  Object.fromEntries(
    Object.entries(locationGroupBy).concat(Object.entries(structureGroupBy)),
  ),
);

export const locationStructureAndReviewDetailsGroupBy = Object.freeze(
  Object.fromEntries(
    Object.entries(locationAndStructureGroupBy).concat(
      Object.entries(reviewDetailsGroupBy),
    ),
  ),
);

export const assessmentStructureType = Object.freeze({
  Category: 1,
  Section: 2,
  Question: 3,
});

export const siteVisitTypes = Object.freeze({
  AuditVisit: "Audit visit",
  FieldVisit: "Field visit",
});

export const levels = Object.freeze({
  Basic: "Basic",
  Advanced: "Advanced",
  Excellent: "Excellent",
  Must: "Must",
  Core: "Core",
});

export const notificationStatus = Object.freeze({
  Announced: 1,
  Unannounced: 2,
});

export const abilityToComplyOptions = Object.freeze({
  NA: 1,
  Yes: 2,
  No: 3,
});

export const reviewModes = Object.freeze({
  ComplianceReview: 1,
  SelfAssessment: 2,
});

export const brandTypes = Object.freeze({
  Ikea: "IKEA",
});

export const maxCommentsLength = 200;

export const axisLabelPositions = Object.freeze({
  Rotate: "rotate",
  Standard: "standard",
  Stagger: "stagger",
});

export const pointLabelPositions = Object.freeze({
  Inside: "inside",
  Outside: "outside",
});

export const pointLabelOverlappingOptions = Object.freeze({
  Hide: "hide",
  None: "none",
  Stack: "stack",
});

export const userActiveState = Object.freeze({
  Active: 1,
  Disabled: 2,
});

export const authMethod = Object.freeze({
  Basic: 1,
  Federated: 2,
});

export const fileUploadValidation = Object.freeze({
  fileExtensionWhitelist: [
    ".jpg",
    ".jpeg",
    ".png",
    ".txt",
    ".csv",
    ".xls",
    ".doc",
    ".ppt",
    ".pdf",
    ".xlsx",
    ".xlsm",
    ".docx",
    ".pptx",
    ".mp4",
    ".3gp",
    ".3g2",
    ".flv",
    ".m4v",
    ".mov",
    ".mkv",
    ".webm",
    ".avi",
    ".flv",
    ".mpg",
    ".mpeg",
    ".wmv",
    ".ogv",
  ],
  maxFileUploadSize: 52428800,
  validCharacters: /^[\p{L}+()\d\s._-]+\.[\w]+/u,
});

export const thirdPartyActiveState = Object.freeze({
  Active: 1,
  Disabled: 2,
  Suspended: 3,
});

export const validPasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const loginFormState = Object.freeze({
  Login: 1,
  Mfa: 2,
  ResetPassword: 3,
});

export const auditLogOperationType = Object.freeze({
  AssessmentScheduled: 10,
  AssessmentStarted: 11,
  AssessmentSubmitted: 12,
  AssessmentApproved: 13,
  AssessmentCancelled: 14,
  AssessmentSentForRework: 15,
  AssessmentReopened: 19,
  FindingCreated: 20,
  FindingSubmitted: 21,
  FindingApproved: 22,
  FindingSentForRework: 23,
  AuditorModified: 30,
  PropertyModified: 40,
  Comment: 50,
});

export const requestType = Object.freeze({
  Exemption: 1,
});

export const exemptionRequestStatus = Object.freeze({
  Open: 1,
  Rejected: 2,
  Approved: 3,
});

export const questionType = Object.freeze({
  SingleSelect: "SingleSelect",
  SingleLineText: "SingleLineText",
  MultilineText: "MultilineText",
});

export const QuestionOptions = Object.freeze({
  Ok: "OK",
  NotOk: "Not OK",
  NA: "N/A",
  NotReviewed: "Not reviewed",
  Yes: "Yes",
  No: "No",
  NoGapIdentified: "No gap identified",
  GapIdentified: "Gap identified",
});

export const organisationTabs = Object.freeze({
  BasicInformation: 0,
  ContractingDetails: 1,
  DetailedInfo: 2,
  UserList: 3,
  Attachments: 4,
  Comments: 5,
});

export const organisationTypes = Object.freeze({
  IngkaUnit: 1,
  Supplier: 2,
});

export const ingkaUnits = Object.freeze({
  IKEARetail: "IKEA Retail",
  IngkaCentres: "Ingka Centres",
});

export const siteTypes = Object.freeze({
  RetailStore: "Retail - Store",
  CustomerFullfillmentCDC: "Customer fulfillment - CDC",
  CustomerFullfillmentDC: "Customer fulfillment - DC",
  IngkaCentres: "Ingka Centres - Meeting place",
});

export const riskTypes = Object.freeze({
  Low: "Low",
  Medium: "Medium",
  High: "High",
  Critical: "Critical",
  NoRiskRating: "No risk rating",
});

export const statusOptions = Object.freeze({
  Active: "Active",
  Archived: "Archived",
});

export const siteManagementTabs = [
  {
    id: 0,
    text: "basic information",
    icon: "info",
  },
  {
    id: 1,
    text: "contracting details",
    icon: "info",
  },
  {
    id: 2,
    text: "detailed info",
    icon: "info",
  },
  {
    id: 3,
    text: "users",
    icon: "fas fa-users",
  },
  {
    id: 4,
    text: "attachments",
    icon: "fas fa-paperclip",
  },
  {
    id: 5,
    text: "comments",
    icon: "comment",
  },
];

export const validIWAYVersionSignedOptions = ["5.2", "6.0", "N/A"];

export const riskRating = Object.freeze({
  NoRisk: "No risk",
  Low: "Low",
  Medium: "Medium",
  High: "High",
  Critical: "Critical",
});

export const yesNoOptions = Object.freeze({
  Yes: 1,
  No: 2,
});

export const questionOptionalOrMandatory = Object.freeze({
  Optional: "optional",
  Mandatory: "mandatory",
});

export const answersAllowedForComments = [
  "OK",
  "Yes",
  "N/A",
  "Not reviewed",
  "No gap identified",
  "Gap identified",
].map((x) => x.toLowerCase());

export const answersAllowedForFindings = ["Not OK", "No"].map((x) =>
  x.toLowerCase(),
);

export const capStatuses = [
  {
    id: 1,
    name: "capProcess",
  },
  {
    id: 3,
    name: "capProcessExtensionByReviewer",
  },
  {
    id: 4,
    name: "capProcessExtensionByICGorGIF",
  },
  {
    id: 5,
    name: "capProcessUnableToVerify",
  },
  {
    id: 6,
    name: "capProcessVerified",
  },
  {
    id: 7,
    name: "iaNoFollowUpNeeded",
  },
];
