<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="complianceStatusData"
      :placeholder="$t('reporting.filters.complianceStatusPlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      styling-mode="outlined"
      :value.sync="complianceStatuses"
      :wrapItemText="true"
      apply-value-mode="instantly"
      :max-displayed-tags="1"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapState} from "vuex";
import DxTagBox from "devextreme-vue/tag-box";

export default {
  components: {
    DxTagBox,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["complianceStatuses"],
      "getFilter",
      "setFilter",
    ),
    ...mapState("orders", ["levels"]),
  },
  data() {
    return {
      complianceStatusData: [
        "Compliant",
        "Non-compliant",
        "IWAY Must Non-compliant",
        "IWAY Must Compliant",
        "IWAY Basic Non-compliant",
        "IWAY Basic Compliant",
        "IWAY Advanced Compliant",
        "IWAY Excellent Compliant",
      ],
    };
  },
};
</script>

<style lang="sass" scoped></style>
