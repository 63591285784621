<template>
  <div>
    <page-heading :pageTitle="pageTitle" :breadCrumbs="breadCrumbs" />
    <div class="fixed-action-container" @click="newUserClick">
      <i class="fas fa-plus"></i>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <dx-button
          @click="newUserClick"
          type="normal"
          class="schedule-visit hidden-sm hidden-xs"
          stylingMode="contained"
          icon="plus"
          :text="this.$t('users.usersList.createNewUser')"
        />
      </div>
    </div>
    <div class="card-container" style="margin-bottom: 90px">
      <div style="margin-bottom: 20px">
        <h2>
          <i class="far fa-user"></i>
          {{ $t("users.usersList.title") }}
        </h2>
        <div v-if="isInRole(roleTypes.admin.role)">
          <dx-button
            @click="toggleUserActiveState"
            type="normal"
            class="toggle-user-state hidden-sm hidden-xs"
            stylingMode="outlined"
            icon="group"
            :text="formatText"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="content-block">
            <dx-data-grid
              class="dx-card thin-column-chooser"
              :ref="usersRefKey"
              :data-source="dataSource"
              :noDataText="noDataText"
              :columns="colDefs"
              :focused-row-index="0"
              :focused-row-enabled="false"
              :column-hiding-enabled="false"
              :selection="{mode: 'single'}"
              :hover-state-enabled="true"
              :show-borders="false"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :column-min-width="150"
              columnResizingMode="widget"
            >
              <DxExport :enabled="true" />
              <DxColumnChooser
                :enabled="true"
                :allowSearch="true"
                :height="500"
                mode="dragAndDrop"
              />
              <DxColumnFixing :enabled="true" />
              <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="storageUserList"
                :saving-timeout="savingTimeout"
              />
              <dx-filter-row :visible="true" />
              <dx-header-filter :visible="true" :allowSearch="true" />
              <dx-paging :page-size="10" />
              <dx-pager :show-page-size-selector="true" :show-info="true" />
              <dx-remote-operations
                :filtering="false"
                :paging="false"
                :sorting="false"
                :summary="false"
                :grouping="false"
                :group-paging="false"
              />

              <template #clear-filter-cell-template>
                <div>
                  <div class="cell-button-header" @click="switchToAllTab">
                    <p>
                      <i class="fas fa-sync"></i>
                      <span>{{ $t("shared.clearFilters") }}</span>
                    </p>
                  </div>
                </div>
              </template>

              <template #action-buttons-cell-template="{data}">
                <div style="text-align: center">
                  <span
                    class="table-button"
                    @click="
                      $router.push({
                        name: 'user-details',
                        params: {id: data.data.id},
                      })
                    "
                    :title="$t('users.usersList.editUser')"
                  >
                    <i class="far fa-edit"></i>
                  </span>
                </div>
              </template>
              <template #org-array-template="{data, column}">
                <div>
                  {{ getOrgArrayCellData(data, column) }}
                </div>
              </template>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxFilterRow,
  DxHeaderFilter,
  DxRemoteOperations,
  DxPaging,
  DxPager,
  DxColumnChooser,
  DxColumnFixing,
  DxStateStoring,
  DxExport,
} from "devextreme-vue/data-grid";
import {DxButton} from "devextreme-vue/button";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {serializeLoadOptions} from "../utils/load-options-serializer";
import columnDefinitions from "../custom/users/listColumnDefinitions";
import PageHeading from "../components/page-heading";
import {userActiveState} from "../common/constants";
import roleTypes from "../config/roles";

export default {
  components: {
    DxButton,
    DxDataGrid,
    DxFilterRow,
    DxHeaderFilter,
    DxRemoteOperations,
    PageHeading,
    DxPaging,
    DxPager,
    DxColumnChooser,
    DxColumnFixing,
    DxStateStoring,
    DxExport,
  },
  data() {
    return {
      popupVisible: false,
      dataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.load,
        }),
        pageSize: 25,
        sort: {mode: "multiple"},
      }),
      noDataText: this.$t("users.usersList.noData"),
      usersRefKey: "users-list",
      roleTypes: roleTypes,
      userState: userActiveState.Active,
      savingTimeout: 0,
    };
  },
  computed: {
    pageTitle() {
      return this.$t("users.usersList.title");
    },
    breadCrumbs() {
      return [this.$t("users.usersList.subTitle")];
    },
    users() {
      return this.$refs[this.usersRefKey].instance;
    },
    colDefs() {
      let colDefs = JSON.parse(JSON.stringify(this.colDefsTranslatedLookups));
      let chooserCols = colDefs
        .filter((x) => x.visible === false)
        .sort(function (a, b) {
          var nameA = a.dataField.toUpperCase();
          var nameB = b.dataField.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      let visibleCols = colDefs.filter(
        (x) => x.visible === true || x.visible == null,
      );

      let sortedColDefs = visibleCols.concat(chooserCols);

      return sortedColDefs.map((x) => ({
        ...x,
        caption: this.$t(x.caption),
      }));
    },
    colDefsTranslatedLookups() {
      let colDefs = [...columnDefinitions];

      colDefs.forEach((element) => {
        if (element.lookup) {
          element.lookup.dataSource.store.data.forEach((x) => {
            if (x.name) {
              x.name = this.$t(x.name);
            }
          });
        }
      });
      return colDefs;
    },
    formatText() {
      let buttonText =
        this.userState === userActiveState.Active
          ? this.$t("users.usersList.showDisabledUsers")
          : this.$t("users.usersList.showActiveUsers");

      return buttonText;
    },
  },
  methods: {
    async load(loadOptions) {
      let response = await this.$http.get(`users/usersList/${this.userState}`, {
        params: serializeLoadOptions(loadOptions),
      });

      return response.data;
    },
    getOrgArrayCellData(data) {
      return data.data[data.column.dataField].map((x) => x.name).join(", ");
    },
    newUserClick() {
      this.$router.push({
        name: "user-details",
      });
    },
    toggleUserActiveState() {
      this.userState =
        this.userState === userActiveState.Active
          ? userActiveState.Disabled
          : userActiveState.Active;

      this.dataSource.reload();
    },
    isInRole(role) {
      return this.$store.state.auth.user.roles.includes(role);
    },
    switchToAllTab() {
      this.users.clearFilter();
    },
  },
};
</script>

<style></style>
