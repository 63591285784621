<template>
  <div
    class="landing-card-container"
    :class="{'section-loader-on': loaderVisible}"
  >
    <h2>
      {{ $t("landingPage.findingsComponents.findingsProgress") }}
    </h2>

    <div v-if="dataSource">
      <div class="sectioned-part">
        <DxPieChart
          id="pie"
          :data-source="dataSource"
          palette="Bright"
          type="doughnut"
          resolveLabelOverlapping="shift"
          :customize-point="customizePoint"
        >
          <DxSeries argument-field="keyTranslated" value-field="value">
            <DxLabel :visible="true" position="outside">
              <DxConnector :visible="true" :width="1" />
            </DxLabel>
          </DxSeries>
          <DxLegend
            vertical-alignment="bottom"
            horizontal-alignment="center"
            item-text-position="right"
          >
            <dx-margin :top="30" />
          </DxLegend>
          <DxSize :height="300" />
          <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
        </DxPieChart>
      </div>

      <component-base
        :numberValue="number"
        :textAfterNumber="
          $t('landingPage.findingsComponents.findingsAwaitingApproval')
        "
        :textWhenZero="
          $t('landingPage.findingsComponents.findingsAwaitingApprovalNone')
        "
        :textWhenAny="
          $t('landingPage.findingsComponents.findingsAwaitingApprovalAny')
        "
        :textButton="
          $t('landingPage.findingsComponents.findingsAwaitingApprovalButton')
        "
        routeName="findings-list"
        status="Submitted"
      >
      </component-base>
    </div>
    <div v-else>
      <p style="margin-top: -5px" class="mb-0">
        {{ $t("landingPage.findingsComponents.findingsAwaitingApprovalNone") }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  DxSeries,
  DxSize,
  DxLegend,
  DxLabel,
  DxTooltip,
  DxConnector,
  DxMargin,
} from "devextreme-vue/chart";
import DxPieChart from "devextreme-vue/pie-chart";
import ComponentBase from "../base/number-of-outstanding-base-component";
import componentMixin from "../../../../mixins/number-of-elements-component-mixin";
import {DxButton} from "devextreme-vue";

export default {
  components: {
    ComponentBase,
    DxPieChart,
    DxSeries,
    DxLabel,
    DxLegend,
    DxTooltip,
    DxSize,
    DxConnector,
    DxMargin,
  },
  mixins: [componentMixin("findingsAwaitingApproval")],
  created() {
    this.$http.get("dashboard/getFindingsOverview").then((response) => {
      this.loaderVisible = false;
      let total =
        response.data.closed + response.data.open + response.data.submitted;
      if (total === 0) {
        this.dataSource = null;
      } else {
        this.dataSource = Object.entries(response.data)
          .map(([key, value]) => ({
            key,
            value,
            order:
              key === "open"
                ? 1
                : key === "submitted"
                ? 2
                : key === "closed"
                ? 3
                : 0,
            keyTranslated: this.$t(`landingPage.findingsComponents.${key}`),
          }))
          .sort((a, b) => a.order - b.order);
      }
    });
  },
  data() {
    return {
      dataSource: null,
      loaderVisible: true,
    };
  },
  methods: {
    customizeTooltip(pointInfo) {
      return {
        text: `${pointInfo.argumentText}: ${pointInfo.valueText}`,
      };
    },
    customizePoint(pointInfo) {
      switch (pointInfo.data.key) {
        case "open":
          return {color: "#c4c4c4"};
        case "closed":
          return {color: "#00a700"};
        case "submitted":
          return {color: "#f5a623"};
        default:
          return {};
      }
    },
  },
};
</script>

<style></style>
