import { render, staticRenderFns } from "./findings-awaiting-approval-component.vue?vue&type=template&id=587805ed&"
import script from "./findings-awaiting-approval-component.vue?vue&type=script&lang=js&"
export * from "./findings-awaiting-approval-component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports