<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="findingStatusesTypeDataSource"
      display-expr="name"
      :placeholder="$t('reporting.filters.findingStatusPlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      :search-enabled="true"
      :search-mode="`contains`"
      :search-expr="`name`"
      styling-mode="outlined"
      :value.sync="findingStatuses"
      value-expr="id"
      apply-value-mode="instantly"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import DxTagBox from "devextreme-vue/tag-box";
import {findingStatuses} from "../../custom/config/workflows";

export default {
  components: {
    DxTagBox,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["findingStatuses"],
      "getFilter",
      "setFilter",
    ),
  },
  data() {
    return {
      findingStatusesTypeDataSource: Object.entries(
        findingStatuses,
      ).map(([key, value]) => ({name: value, id: key})),
    };
  },
};
</script>

<style lang="sass" scoped></style>
