<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="auditPeriodDataSource"
      display-expr="name"
      :placeholder="$t('reporting.filters.auditPeriodPlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      styling-mode="outlined"
      :value.sync="auditPeriods"
      value-expr="id"
      :wrapItemText="true"
      apply-value-mode="instantly"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import DxTagBox from "devextreme-vue/tag-box";
import notify from "devextreme/ui/notify";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export default {
  components: {
    DxTagBox,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["auditPeriods"],
      "getFilter",
      "setFilter",
    ),
  },
  data() {
    return {
      auditPeriodDataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.loadAuditPeriodData,
          loadMode: "raw",
        }),
      }),
    };
  },
  methods: {
    async loadAuditPeriodData() {
      try {
        const response = await this.$http.get("Config/GetAuditYears");
        const json = JSON.parse(JSON.stringify(response));
        return json.data;
      } catch (err) {
        notify(this.$t("reporting.filters.auditPeriodLoadError"), "error");
      }

      return [];
    },
  },
};
</script>

<style lang="sass" scoped></style>
