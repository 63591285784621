<template>
  <div class="user-panel">
    <div class="user-info">
      <div class="image-container">
        <div
          v-if="isNotEmpty(avatarImage)"
          class="user-image"
          :style="{'background-image': 'url(' + avatarImage + ')'}"
        />
        <div v-else-if="user" class="user-image">{{ userInitials }}</div>
        <div v-else class="user-image"></div>
      </div>
      <div v-if="user" class="user-details">
        <div class="user-roles">
          <div class="user-role" v-for="role in user.roles" :key="role">
            {{ $t(`users.roles.${camelCase(role)}`) }}
          </div>
        </div>
        <div class="user-name">{{ `${user.firstName} ${user.surname}` }}</div>
      </div>
      <div v-else />
    </div>

    <dx-context-menu
      v-if="menuMode === 'context'"
      target=".user-button"
      :items="menuItems"
      :width="170"
      :position="menuPositionConfig"
      show-event="dxclick"
      css-class="user-menu"
    />

    <dx-list
      v-if="menuMode === 'list'"
      class="dx-toolbar-menu-action"
      :items="menuItems"
    />
  </div>
</template>

<script>
import DxContextMenu from "devextreme-vue/context-menu";
import DxList from "devextreme-vue/list";
import {
  isNotEmpty,
  loadLanguage,
  toCamelCase,
  getSecureImage,
} from "../common/helperFunctions";
import {locale, loadMessages} from "devextreme/localization";
import {mapActions, mapState} from "vuex";
const {VUE_APP_API_HOSTNAME} = process.env;

export default {
  props: {
    menuMode: String,
    menuItems: Array,
  },
  data() {
    return {
      menuPositionConfig: {
        my: "top center",
        at: "bottom center",
      },
      avatarImage: null,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    userInitials() {
      if (this.user) {
        return `${this.getCapitalizedInitial(
          this.user.firstName,
        )}${this.getCapitalizedInitial(this.user.surname)}`;
      }

      return null;
    },
  },
  methods: {
    isNotEmpty: isNotEmpty,
    ...mapActions("auth", ["getUser"]),
    camelCase: toCamelCase,
    getCapitalizedInitial(text) {
      if (text && text.length > 0) {
        return text[0].toUpperCase();
      }

      return null;
    },
    async getAvatarImage() {
      if (this.user.pictureUrl) {
        this.avatarImage = await getSecureImage(
          `${VUE_APP_API_HOSTNAME}${this.user.pictureUrl}`,
        );
      }
    },
  },
  created() {
    this.getUser().then((response) => {
      if (isNotEmpty(response.preferredCultureCode)) {
        this.$i18n.locale = response.preferredCultureCode;
      }
      loadMessages(
        loadLanguage(this.$i18n.locale.split("-").shift().toLowerCase(), this),
      );
      locale(this.$i18n.locale);
      if (this.user.pictureUrl) {
        this.getAvatarImage();
      }
    });
  },
  watch: {
    user() {
      this.getAvatarImage();
    },
  },
  components: {
    DxContextMenu,
    DxList,
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.user-info {
  display: flex;
  align-items: center;
  text-align: start;
  line-height: normal;

  .dx-toolbar-menu-section & {
    padding: 10px 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .image-container {
    overflow: hidden;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

    .user-image {
      text-align: center;
      font-size: 12px;
      color: #1e1e1e;
      width: 100%;
      height: 100%;
      line-height: 28px;
      background-color: #cccccc;
      background-size: cover;
    }
  }

  .user-details {
    .user-roles {
      display: inline-flex;
      margin-top: 0px;
      color: #1e1e1e;
      font-size: 10px;

      .user-role {
        margin: 1px 9px;
      }
    }

    .user-name {
      font-size: 14px;
      color: #1e1e1e;
      margin: 2px 9px;
    }
  }
}

.user-panel {
  .dx-list-item .dx-icon {
    vertical-align: middle;
    color: #1e1e1e;
    margin-right: 16px;
  }
  .dx-rtl .dx-list-item .dx-icon {
    margin-right: 0;
    margin-left: 16px;
  }
}

.dx-context-menu.user-menu.dx-menu-base {
  &.dx-rtl {
    .dx-submenu .dx-menu-items-container .dx-icon {
      margin-left: 16px;
    }
  }
  .dx-submenu .dx-menu-items-container .dx-icon {
    margin-right: 16px;
  }
  .dx-menu-item .dx-menu-item-content {
    padding: 3px 15px 4px;
  }
}

.dx-theme-generic .user-menu .dx-menu-item-content .dx-menu-item-text {
  padding-left: 4px;
  padding-right: 4px;
}
</style>
