<template>
  <div v-show="isVisible" id="attachmentWrapper" ref="attachmentImage">
    <img id="big-attachment" :src="this.backgroundImage" alt="" />
    <div style="width: 100%">
      <DxButton
        name="addAttachment"
        type="normal"
        class="btn btn-medium"
        stylingMode="container"
        id="download-btn"
        @click="downloadAttachment()"
      >
        Download
      </DxButton>
    </div>
  </div>
</template>
<script>
import DxButton from "devextreme-vue/button";
import {mapActions, mapState} from "vuex";

export default {
  props: {
    modalData: {
      Type: Object,
    },
    url: {
      Type: String,
    },
  },
  created() {
    this.getBackgroundImage();
  },
  components: {
    DxButton,
  },
  data() {
    return {
      backgroundImage: null,
      isVisible: false,
    };
  },
  methods: {
    getBackgroundImage() {
      this.$store.dispatch("attachments/updateLoadingStatus", "loading");
      this.$http.get(this.url, {responseType: "blob"}).then((response) => {
        this.backgroundImage = URL.createObjectURL(response.data);
        this.$store.dispatch("attachments/updateLoadingStatus", null);
        this.isVisible = true;
      });
    },
    downloadAttachment() {
      this.$emit("downloadAttachment", this.modalData);
    },
  },
};
</script>
<style>
#big-attachment {
  width: 100%;
}

#attachmentWrapper {
  padding: 10px;
  align-items: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#download-btn {
  float: right;
  margin-top: 5px;
}
</style>
