<template>
  <div>
    <DxRadioGroup
      class="radio-with-icon"
      :dataSource="answerOptions"
      layout="horizontal"
      @value-changed="setSelectedAnswer($event)"
      displayExpr="name"
      valueExpr="val"
      :disabled="disabled"
      :value.sync="selected"
    ></DxRadioGroup>
  </div>
</template>

<script>
import {DxRadioGroup} from "devextreme-vue";
import {isNotEmpty} from "../../common/helperFunctions";
export default {
  components: {
    DxRadioGroup,
  },
  props: {
    answerOptions: {
      Type: Array,
    },
    questionId: {
      Type: Number,
    },
    preselectedValue: {
      Type: String,
      default: undefined,
    },
    question: {
      Type: Object,
    },
    disabled: {
      Type: Boolean,
    },
  },
  created() {
    if (this.preselectedValue !== undefined) {
      this.selected = parseInt(this.preselectedValue);
    }
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    setSelectedAnswer(e) {
      if (isNotEmpty(e.value)) {
        e.value = e.value.toString();
        this.$emit("set-answer", e);
      }
    },
  },
  watch: {
    // whenever question changes, this function will run
    question: function (newValue) {
      if (newValue.answer) {
        this.selected = newValue.answer.value;
      } else {
        this.selected = null;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
