import roles from "../../config/roles";

const dashboards = [
  {
    title: "reporting.performanceManagementDashboard.thumbnailTitle",
    summary: "reporting.performanceManagementDashboard.thumbnailSummary",
    route: "performance-management-dashboard",
    roles: [
      roles.admin.role,
      roles.orderer.role,
      roles.reviewer.role,
      roles.reviewParticipant.role,
      roles.businessOwner.role,
    ],
  },
  {
    title: "reporting.findingsDashboard.thumbnailTitle",
    summary: "reporting.findingsDashboard.thumbnailSummary",
    route: "findings-dashboard",
    roles: [
      roles.admin.role,
      roles.orderer.role,
      roles.reviewer.role,
      roles.reviewParticipant.role,
      roles.businessOwner.role,
    ],
  },
  // {
  //   title: "reporting.retailerDashboard.thumbnailTitle",
  //   summary: "reporting.retailerDashboard.thumbnailSummary",
  //   route: "retailer-dashboard",
  //   roles: [roles.reviewParticipant.role, roles.businessOwner.role],
  // },
];

export default Object.freeze(dashboards);
