var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-block"},[_c('h2',{staticStyle:{"margin-bottom":"30px"}},[_c('i',{staticClass:"fas fa-bell"}),_vm._v(" "+_vm._s(_vm.$t("orders.orderDetails.notification"))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.notification.announcedOrUnannounced"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.announcedOrUnannouncedToolTip,"close-on-outside-click":false,"target":"#divAnnouncedOrUnannounced","position":"top"},on:{"update:visible":function($event){_vm.announcedOrUnannouncedToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.order.announcedOrUnannounced"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divAnnouncedOrUnannounced"},on:{"mouseenter":function($event){_vm.announcedOrUnannouncedToolTip = !_vm.announcedOrUnannouncedToolTip},"mouseleave":function($event){_vm.announcedOrUnannouncedToolTip = !_vm.announcedOrUnannouncedToolTip}}})]),_c('dx-select-box',{attrs:{"value":_vm.notification,"data-source":_vm.notifications,"stylingMode":"outlined","display-expr":"name","value-expr":"id","placeholder":_vm.$t('shared.select', {
            dataField: _vm.$t('orders.notification.announcedOrUnannounced'),
          }),"disabled":!_vm.$can('update', _vm.$subjects.order.subject) ||
          _vm.orderStatus === 'Submitted' ||
          _vm.isDisabled},on:{"update:value":function($event){_vm.notification=$event},"value-changed":function($event){return _vm.onSelectedNotificationChanged($event)}}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                dataField: _vm.$t('orders.notification.announcedOrUnannounced'),
              })}})],1)],1)],1)]),_c('div',{staticClass:"row"},[(_vm.showDelayNotification)?_c('div',{staticClass:"col-xl-12"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.notification.delayNotification"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.delayNotificationToolTip,"close-on-outside-click":false,"target":"#divDelayNotification","position":"top"},on:{"update:visible":function($event){_vm.delayNotificationToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.order.delayNotification"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divDelayNotification"},on:{"mouseenter":function($event){_vm.delayNotificationToolTip = !_vm.delayNotificationToolTip},"mouseleave":function($event){_vm.delayNotificationToolTip = !_vm.delayNotificationToolTip}}})]),_c('div',{staticClass:"checkbox-container"},[_c('dx-check-box',{attrs:{"text":_vm.$t('orders.notification.delay'),"disabled":!_vm.$can('update', _vm.$subjects.order.subject) ||
            _vm.orderStatus === 'Submitted' ||
            !_vm.$can('update', _vm.$subjects.order.subject, 'delayNotification')},on:{"value-changed":_vm.onDelayNotificationChange},model:{value:(_vm.delayNotification),callback:function ($$v) {_vm.delayNotification=$$v},expression:"delayNotification"}})],1)]):_vm._e()]),_c('div',{ref:_vm.delayNotificationDateContainerRef,staticClass:"row"},[(_vm.delayNotification)?_c('div',{staticClass:"col-xl-12"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{staticClass:"level-down-container"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.notification.delayDate"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.delayDateToolTip,"close-on-outside-click":false,"target":"#divDelayDate","position":"top"},on:{"update:visible":function($event){_vm.delayDateToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.order.delayDate"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divDelayDate"},on:{"mouseenter":function($event){_vm.delayDateToolTip = !_vm.delayDateToolTip},"mouseleave":function($event){_vm.delayDateToolTip = !_vm.delayDateToolTip}}})]),_c('dx-date-box',{ref:_vm.delayNotificationDateDropDownRef,attrs:{"name":"delayNotificationDate","stylingMode":"outlined","acceptCustomValue":false,"openOnFieldClick":true,"type":"date","validationMessageMode":"auto","pickerType":"calendar","min":_vm.todaysDate,"placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.notification.delayDate'),
              }),"disabled":!_vm.$can('update', _vm.$subjects.order.subject) ||
              _vm.orderStatus === 'Submitted' ||
              !_vm.$can('update', _vm.$subjects.order.subject, 'delayNotification'),"use-mask-behavior":true,"display-format":"dd/MMM/yyyy"},on:{"update:min":function($event){_vm.todaysDate=$event},"opened":_vm.onDelayNotificationDateDropDownOpened},model:{value:(_vm.notificationDelayDate),callback:function ($$v) {_vm.notificationDelayDate=$$v},expression:"notificationDelayDate"}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.notification.delayDate'),
                  })}}),_c('dx-range-rule',{attrs:{"max":_vm.maxDate}})],1)],1)],1)])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }