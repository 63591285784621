export default {
  admin: {role: "Admin", display: "Admin"},
  orderer: {
    role: "Orderer",
    display: "Orderer",
  },
  reviewer: {role: "Reviewer", display: "Reviewer"},
  reviewParticipant: {role: "ReviewParticipant", display: "Review Participant"},
  businessOwner: {role: "BusinessOwner", display: "Business Owner"},
};
