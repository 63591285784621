<template>
  <div>
    <page-heading :pageTitle="pageTitle" :breadCrumbs="breadCrumbs" />
    <div class="row">
      <div class="col-xl-4">
        <user-profile />
      </div>
      <div class="col-xl-4">
        <div class="card-container">
          <h2><i class="far fa-user"></i> {{ pageTitle }}</h2>
          <locale-select />
        </div>
      </div>
      <div class="col-xl-4" v-if="user && user.authMethod == 'Basic'">
        <div class="card-container">
          <h2>
            <i class="fas fa-user-shield"></i>
            {{ $t("userSettings.changePassword.submit") }}
          </h2>
          <p class="mb-4">
            {{ $t("userSettings.changePassword.infoParagraph") }}
          </p>
          <change-password />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocaleSelect from "../components/shared/locale-select";
import ChangePassword from "../components/shared/change-password";
import pageHeading from "../components/page-heading";
import UserProfile from "../components/shared/user-profile.vue";

export default {
  components: {
    LocaleSelect,
    ChangePassword,
    pageHeading,
    UserProfile,
  },
  computed: {
    pageTitle() {
      return this.$t("userSettings.title");
    },
    breadCrumbs() {
      return [this.$t("userSettings.subTitle"), this.$t("userSettings.title")];
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
