import $axios from "../utils/axios-instance";

export default function (url) {
    return {
        created: function () {
            let that = this;
            $axios.get(`dashboard/${url}`)
                .then(x => that.number = x.data)
                .catch(x => console.error(`Error while getting number of elements from ${url}`, x));
        },
        data: function () {
            return {
                number: null
            }
        }
    }
}

