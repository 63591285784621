<template>
  <div :class="{loading: isLoadingVisible}" id="root">
    <lost-connection-warning :class="{visible: isConnectionLost}" />

    <div :class="[cssClasses, (globalWarning) ? globalWarningPaddingClass : '']">
      <global-warning v-if="globalWarning" :message="globalWarning"/>
      <router-view
        name="layout"
        :title="title"
        :is-x-small="screen.isXSmall"
        :is-small="screen.Small"
        :is-x-large="screen.isXLarge"
      >
        <div class="content">
          <transition name="fade" mode="out-in">
            <div>
              <router-view name="content" />
            </div>
          </transition>
          <support-banner />
        </div>
        <!--<template #footer>
          <the-footer />
        </template>-->
      </router-view>
    </div>
  </div>
</template>

<script>
//import TheFooter from "./components/the-footer";
import {sizes, subscribe, unsubscribe} from "./utils/media-query";
import LostConnectionWarning from "./components/shared/lost-connection-warning";
import SupportBanner from "./components/support-banner";
import GlobalWarning from "./components/global-warning";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isSmall: screenSizes["screen-small"],
    isXLarge: screenSizes["screen-x-large"],
    cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
  };
}

export default {
  name: "app",
  data() {
    return {
      title: this.$appInfo.title,
      screen: getScreenSizeInfo(),
      isConnectionLost: false,
      globalWarning: "",
      globalWarningPaddingClass: "with-warning",
      globalWarningStart: "",
      globalWarningEnd: "",
      todayDateTime: ""
    };
  },

  computed: {
    cssClasses() {
      return ["app"].concat(this.screen.cssClasses);
    },
    isLoadingVisible() {
      return (
        this.$store.getters["assessments/isLoading"] ||
        this.$store.getters["findings/isLoading"] ||
        this.$store.getters["findingsResolution/isLoading"] ||
        this.$store.getters["schedules/isLoading"] ||
        this.$store.getters["attachments/isLoading"]
      );
    },
  },
  methods: {
    screenSizeChanged() {
      this.screen = getScreenSizeInfo();
    },
    displayConnectionError() {
      this.isConnectionLost = !navigator.onLine;
      setTimeout(this.displayConnectionError, 5000);
    },
    updateNotification() {
      this.$http.get(`/Notifications`).then((resp) => {        
        this.globalWarningStart = Date.parse(resp.data.startDate);
        this.globalWarningEnd = Date.parse(resp.data.endDate);
        this.getTodayDateTime();   

        if(this.globalWarningStart < this.todayDateTime && this.globalWarningEnd > this.todayDateTime){
          this.globalWarning = resp.data.notificationMessage;
        } else{
          this.globalWarning = "";
        }
        
        setTimeout(this.updateNotification, 60000);
      });    
    },
    getTodayDateTime() {
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        this.todayDateTime = Date.parse(date+' '+time);  
    }
  },

  mounted() {
    subscribe(this.screenSizeChanged);
    this.displayConnectionError();
        this.updateNotification();

  },

  beforeDestroy() {
    unsubscribe(this.screenSizeChanged);
  },

  components: {
    //TheFooter
    LostConnectionWarning,
    SupportBanner,
    GlobalWarning
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
