<template>
  <div class="row">
    <div class="col-lg-8">
      <dx-select-box
        :data-source="answerOptions"
        styling-mode="outlined"
        display-expr="name"
        value-expr="val"
        :value="value"
        placeholder="Select..."
        v-model="selected"
        :disabled="disabled"
        @value-changed="setSelectedAnswer($event)"
      />
    </div>
  </div>
</template>

<script>
import DxSelectBox from "devextreme-vue/select-box";
import {isNotEmpty} from "../../common/helperFunctions";
export default {
  components: {
    DxSelectBox,
  },
  props: {
    answerOptions: {
      Type: Array,
    },
    questionId: {
      Type: Number,
    },
    preselectedValue: {
      Type: String,
      default: undefined,
    },
    question: {
      Type: Object,
    },
    disabled: {
      Type: Boolean,
    },
  },
  created() {
    if (this.preselectedValue !== undefined || this.preselectedValue === null) {
      this.selected = this.preselectedValue;
    }
  },
  data() {
    return {
      selected: null,
      textBoxContents: undefined,
      value: undefined,
    };
  },
  methods: {
    setSelectedAnswer(e) {
      if (isNotEmpty(e.value)) this.$emit("set-answer", e);
    },
  },
  watch: {
    // whenever question changes, this function will run
    question: function (newValue) {
      if (newValue.answer && newValue.answer.value) {
        this.selected = parseInt(newValue.answer.value);
      } else {
        this.selected = null;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
