<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="reviewTypeDataSource"
      display-expr="name"
      :placeholder="$t('reporting.filters.reviewTypePlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :search-enabled="true"
      :search-mode="`contains`"
      :search-expr="`name`"
      :show-selection-controls="true"
      styling-mode="outlined"
      :value.sync="reviewTypes"
      value-expr="name"
      apply-value-mode="instantly"
    />
  </div>
</template>

<script>
import DxTagBox from "devextreme-vue/tag-box";
import {mapState} from "vuex";
import {mapFields} from "vuex-map-fields";
import notify from "devextreme/ui/notify";

export default {
  components: {
    DxTagBox,
  },
  created() {
    this.loadReviewTypes();
  },
  computed: {
    ...mapFields("reportingFilters", ["reviewTypes"], "getFilter", "setFilter"),
  },
  data() {
    return {
      reviewTypeDataSource: null,
    };
  },
  methods: {
    async loadReviewTypes() {
      try {
        const response = await this.$http.get("/orders/reviewtypes");
        const json = JSON.parse(JSON.stringify(response));
        this.reviewTypeDataSource = json.data.map((x) => ({
          id: x.id,
          name: x.name,
        }));
      } catch (err) {
        notify(this.$t("reporting.filters.reviewTypesLoadError"), "error");
      }

      return [];
    },
  },
};
</script>

<style lang="sass" scoped></style>
