<template>
  <div :class="{'section-loader-on': loaderVisible}">
    <div :class="{'sectioned-part': any()}">
      <h2 id="findings-header">
        <span id="findings-number">
          {{ numberValue }}
        </span>
        {{ textAfterNumber }}
        <!-- <i
          class="fas"
          :class="{
            'fa-exclamation-triangle warning': any(),
            'fa-check success': !any(),
          }"
        ></i> -->
      </h2>

      <p class="mb-0" v-if="!any()">
        {{ textWhenZero }}
      </p>
      <p class="mb-0" v-else>
        {{ textWhenAny }}
      </p>
    </div>

    <div v-if="any()">
      <DxButton
        :text="`${textButton}`"
        type="normal"
        icon="chevronright"
        class="btn btn-large"
        @click="linkToList"
      />
    </div>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";

export default {
  components: {
    DxButton,
  },
  props: {
    numberValue: null,
    textAfterNumber: null,
    textWhenZero: null,
    textWhenAny: null,
    textButton: null,
    routeName: null,
    status: null,
  },
  methods: {
    any() {
      return this.numberValue > 0;
    },
    linkToList() {
      if (this.routeName == "assessment-list") {
        localStorage.removeItem("storageAssessmentList");
      } else if (this.routeName == "findings-list") {
        localStorage.removeItem("storageFindingList");
      } else if (this.routeName == "exemptions") {
        localStorage.removeItem("storageExemptionsList");
      }
      this.$router.push({name: this.routeName, query: {status: this.status}});
    },
  },
  updated() {
    this.loaderVisible = false;
  },
  data() {
    return {
      loaderVisible: false,
    };
  },
};
</script>

<style></style>
