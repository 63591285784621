import {assessmentStatuses} from "../../custom/config/workflows";

export default Object.freeze([
  {
    dataField: "supplierName",
  },
  {
    dataField: "siteName",
    visible: true,
  },
  {
    dataField: "siteCategories",
    visible: true,
  },
  {
    dataField: "siteCountry",
  },
  {
    dataField: "reviewMode",
  },
  {
    dataField: "reviewType",
  },
  {
    dataField: "scheduledStartDate",
    visible: true,
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "scheduledEndDate",
    visible: true,
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "assessmentScope",
    visible: true,
  },
  {
    dataField: "leadReviewer",
  },
  {
    dataField: "supportReviewers",
    visible: true,
  },
  {
    dataField: "estReportDueDate",
    dataType: "date",
    visible: true,
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "estCapDate",
    dataType: "date",
    visible: true,
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "estDeadlineApprovalDate",
    dataType: "date",
    visible: true,
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "assessmentSubmittedBy",
    visible: true,
  },
  {
    dataField: "assessmentPeriodName",
    visible: true,
  },
  {
    dataField: "orderStatus",
    visible: true,
  },
  {
    dataField: "assessmentScore",
    cellTemplate: "complianceRate-template",
    visible: true,
    alignment: "left",
  },
  {
    dataField: "assessmentRiskScore",
    visible: true,
  },
  {
    dataField: "assessmentPercentageComplete",
    alignment: "left",
  },
  {
    dataField: "assessmentSubmittedOn",
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "numberOfFindingsIdentified",
    alignment: "left",
  },
  {
    dataField: "numberOfFindingsResolved",
    alignment: "left",
  },
  {
    dataField: "assessmentDaysToComplete",
  },
  {
    dataField: "complianceStatus",
  },
  {
    dataField: "siteCategoriesArea",
  },
  {
    dataField: "siteSubCategories",
  },
  {
    dataField: "isThereReviewerCapacity",
    cellTemplate: "isThereReviewerCapacity-template",
  },
  {
    dataField: "tertial",
  },
  {
    dataField: "riskAccordingToRatingTool",
  },
  {
    dataField: "notification",
  },
  {
    dataField: "followupStatus",
    cellTemplate: "followupStatus-template",
  },
  {
    dataField: "finalComplianceRate",
  },
]);
