<template>
  <div class="support-banner" v-if="currentRouteName != 'login-form'">
    <div class="support-icon-container">
      <div class="support-icon" @click="togglePopup">
        <i class="fas fa-headset"></i>
      </div>
      <div class="card-container" v-if="isActive">
        <div class="close-button-container" @click="togglePopup">
          <i class="fas fa-times"></i>
        </div>
        <h2>Technical support</h2>
        <p>
          Before raising a query with us please consider reviewing the provided
          system user guides and FAQ's located under the "Training materials"
          widget on the home page.
        </p>
        <hr />
        <p>
          If you have questions please contact iReview Support at
          <a href="mailto:iReview.Support.gl@ingka.ikea.com" target="_blank"
            >iReview.Support.gl@ingka.ikea.com</a
          >.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
</style>

<script>
export default {
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    togglePopup() {
      this.isActive = this.isActive ? false : true;
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
