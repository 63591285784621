export const fromResponse = (error) => {
  return error
    ? error.response && error.response.data
      ? error.response.data.message ||
        error.response.data.title ||
        error.message ||
        ""
      : error.message || ""
    : "";
};
