<template>
  <div class="report-filter-pane-item">
    <div class="report-filter-pane-item-header">
      <i class="fas fa-globe-americas"></i>
      <h1>{{ $t("reporting.filters.locationTitle") }}</h1>
    </div>
    <div class="report-filter-pane-item-filter" :ref="siteOrStoreContainerRef">
      <DxDropDownBox
        :value.sync="locations"
        :defer-rendering="false"
        :show-clear-button="true"
        resize-enabled="false"
        :data-source="siteOrStoreDataSource"
        display-expr="siteName"
        stylingMode="outlined"
        @opened="onsiteOrStoreDropDownOpened"
        :ref="siteOrStoreDropDownRef"
        :placeholder="$t('reporting.filters.locationPlaceholder')"
      >
        <!-- TODO: decide width value/strategy  -->
        <template #content>
          <DxDataGrid
            :data-source="siteOrStoreDataSource"
            :columns="siteOrStoreColumns"
            :hover-state-enabled="true"
            :column-auto-width="true"
            :selected-row-keys.sync="locations"
            height="100%"
          >
            <DxSelection mode="multiple" />
            <DxPaging :enabled="true" :page-size="10" />
            <DxFilterRow :visible="true" />
            <DxScrolling mode="infinite" />

            <template #country-template="{data}">
              <span>
                {{ getCountryName(data.data.country) }}
              </span>
            </template>

          </DxDataGrid>
        </template>
      </DxDropDownBox>
    </div>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {DxDropDownBox, DxDropDownOptions} from "devextreme-vue/drop-down-box";
import DxTreeView from "devextreme-vue/tree-view";
import notify from "devextreme/ui/notify";
import orgCountries from "../../config/organisationCountries";
import DxTagBox from "devextreme-vue/tag-box";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import $axios from "../../utils/axios-instance";
import {
  DxDataGrid,
  DxSelection,
  DxPaging,
  DxFilterRow,
  DxScrolling,
} from "devextreme-vue/data-grid";
export default {
  components: {
    DxDropDownBox,
    DxDataGrid,
    DxPaging,
    DxSelection,
    DxFilterRow,
    DxScrolling,
  },
  computed: {
    ...mapFields("reportingFilters", ["locations"], "getFilter", "setFilter"),
    locationTreeView() {
      return this.$refs[this.locationTreeViewRef].instance;
    },
  },
  data() {
    return {
      locationTreeViewRef: "locationTreeView",
      rootParentId: -1,
      initialLoad: true,
      siteOrStoreContainerRef: "container",
      siteOrStoreDataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.loadSitesOrStores,
          loadMode: "raw",
        }),
      }),

      siteOrStoreColumns: [
        {
          dataField: "supplierName",
          caption: "Supplier name",
          width: "auto",
          sortOrder: "asc",
        },
        {
          dataField: "siteName",
          caption: "Site/Store",
          width: "auto",
        },
        {
          dataField: "country",
          caption: "Country",
          width: "auto",
          cellTemplate: "country-template",
          calculateFilterExpression: function (
            filterValue,
            selectedFilterOperation,
            target,
          ) {
            if (target === "filterRow") {
              return function (data) {
                const country = orgCountries.find((x) => x.value === data.country)?.name;                  
                return country && country.toLowerCase().includes(filterValue.toLowerCase())
              };
            }
          }
        },
      ],

      siteOrStoreDropDownRef: "site/store",
    };
  },
  methods: {
    getSelectedLocationsDisplayValue() {
      return this.locations
        ? this.locations.map((x) => x.name).join(", ")
        : null;
    },
    async loadSitesOrStores(loadOptions) {
      let params = "?";
      [
        "skip",
        "take",
        "sort",
        "requireTotalCount",
        "requireGroupCount",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });
      params = params.slice(0, -1);
      const response = await $axios.get(
        `/Organisations/GetOrganisationsByType/site?${params}`,
      );
      const json = await JSON.parse(JSON.stringify(response));
      return json.data.data;
    },
    onsiteOrStoreDropDownOpened() {
      let screenWidth = window.innerWidth;
      let dropDownWidth =
        screenWidth < 576
          ? this.componentContainer(this.siteOrStoreContainerRef).clientWidth
          : 700;
      this.dropdown(this.siteOrStoreDropDownRef).option(
        "dropDownOptions.width",
        dropDownWidth,
      );
    },
    dropdown(dropdownRef) {
      return this.$refs[dropdownRef].instance;
    },
    getCountryName(data) {
      return orgCountries.find((x) => x.value === data)?.name;
    },
  },
};
</script>

<style lang="sass" scoped></style>
