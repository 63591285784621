<template>
  <div class="card-container">
    <component :is="component"></component>
  </div>
</template>

<script>
export default {
  props: {
    component: null,
  },
};
</script>

<style></style>
