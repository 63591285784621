<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <!-- eslint-disable vue/no-unused-vars -->
        <dx-button
          icon="menu"
          styling-mode="text"
          @click="toggleMenuFunc"
          slot-scope="_"
          css-class="menu-button-icon"
        />
        <!-- eslint-enable -->
      </dx-item>

      <dx-item location="before" css-class="toolbar-logo">
        <div slot-scope="_">
          <img src="../assets/logos/ikea-logo.svg" />
        </div>
      </dx-item>

      <dx-item
        v-if="title"
        location="before"
        css-class="header-title dx-toolbar-label"
      >
        <!-- eslint-disable vue/no-unused-vars -->
        <div slot-scope="_">{{ title }}</div>
        <!-- eslint-enable -->
      </dx-item>

      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
        <div slot-scope="_">
          <dx-button
            class="user-button authorization"
            :width="210"
            height="100%"
            styling-mode="text"
          >
            <user-panel :menu-items="userMenuItems" menu-mode="context" />
          </dx-button>
        </div>
      </dx-item>

      <!-- eslint-disable vue/no-unused-vars -->
      <user-panel
        :menu-items="userMenuItems"
        menu-mode="list"
        slot-scope="_"
        slot="menuUserItem"
      />
      <!-- eslint-enable -->
    </dx-toolbar>
  </header>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
import UserPanel from "./user-panel";

export default {
  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function,
  },
  computed: {
    userMenuItems() {
      return [
        {
          text: this.$t("userToolbar.settings"),
          icon: "preferences",
          onClick: this.onSettingsClick,
        },
        {
          text: this.$t("userToolbar.logout"),
          icon: "runner",
          onClick: this.onLogoutClick,
        },
      ];
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    onLogoutClick() {
      this.$store.dispatch("auth/logout");
      this.$router.push({
        path: "/login-form",
        query: {redirect: this.$route.path},
      });
    },
    onSettingsClick() {
      this.$router.push({name: "profile-settings"});
    },
  },
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanel,
  },
};
</script>

<style lang="scss">
@import "../themes/custom-variables.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $black;
  }
}
.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 25px;

  .dx-button {
    height: 36px;
  }

  .screen-x-small & {
    padding: 0 15px;
  }
}

.dx-toolbar.header-toolbar
  .dx-toolbar-items-container
  .dx-toolbar-item.toolbar-logo {
  padding-right: 15px;
}

.dx-toolbar.header-toolbar
  .dx-toolbar-items-container
  .dx-toolbar-item.toolbar-logo
  img {
  max-height: 62px;
}

.dx-toolbar .dx-toolbar-label {
  font-size: $base-font-size !important;
  font-weight: normal;
}

.dx-toolbar {
  background-color: $brand1;
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
  position: relative;
  top: 6px;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
  text-overflow: unset;
  font-size: 15px;
  font-weight: bold;

  .screen-small &,
  .screen-x-small & {
    display: none;
  }
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}
</style>
