<!--TODO: Remove the v-if-else and use properties against value.sync, placeholder and
dxselection to determine the correct syncing.
The current implementation is a "temporary hack" (20200314) to get things rolling 💨🏀-->

<template>
  <div class="content-block level-down-container" :ref="componentContainerRef">
    <label>
      {{ $t("schedules.scheduleAssessment.pickRetailers") }}
    </label>

    <div v-if="selfAssessmentSelected">
      <DxDropDownBox
        :value.sync="selectedRetailerMultiple"
        :defer-rendering="false"
        :show-clear-button="true"
        resize-enabled="false"
        :data-source="dataSource"
        display-expr="name"
        stylingMode="outlined"
        value-expr="id"
        :placeholder="$t('schedules.scheduleAssessment.pickRetailers')"
        @value-changed="onRetailerValueChanged($event, 'multiple')"
        :ref="retailersDropDownRef"
        @opened="onRetailersDropDownOpened"
      >
        <template #content>
          <DxDataGrid
            :data-source="dataSource"
            :columns="retailerColDefs"
            :hover-state-enabled="true"
            :column-auto-width="true"
            :selected-row-keys.sync="selectedRetailerMultiple"
            height="100%"
          >
            <DxSelection mode="multiple" />
            <DxPaging :enabled="true" :page-size="10" />
            <DxFilterRow :visible="true" />
            <DxScrolling mode="infinite" />
          </DxDataGrid>
        </template>
        <dx-validator>
          <dx-required-rule
            :message="
              $t('schedules.scheduleAssessment.multipleRetailersAreRequired')
            "
          />
        </dx-validator>
      </DxDropDownBox>
      <div class="retailer-count">{{ retailersSelected }}</div>
    </div>
    <div v-else>
      <DxDropDownBox
        :value.sync="selectedRetailerSingle"
        :defer-rendering="false"
        :show-clear-button="true"
        :data-source="dataSource"
        resize-enabled="false"
        display-expr="name"
        stylingMode="outlined"
        :drop-down-options="dropDownOptions"
        value-expr="id"
        :placeholder="$t('schedules.scheduleAssessment.pickRetailer')"
        @value-changed="onRetailerValueChanged($event, 'single')"
        :ref="retailersDropDownRef"
        @opened="onRetailersDropDownOpened"
      >
        <template #content>
          <DxDataGrid
            :data-source="dataSource"
            :columns="retailerColDefs"
            :hover-state-enabled="true"
            :column-auto-width="true"
            :selected-row-keys.sync="selectedRetailerSingle"
            height="100%"
          >
            <DxSelection mode="single" />
            <DxPaging :enabled="true" :page-size="10" />
            <DxFilterRow :visible="true" />
            <DxScrolling mode="infinite" />
          </DxDataGrid>
        </template>
        <dx-validator>
          <dx-required-rule
            :message="
              $t('schedules.scheduleAssessment.singleRetailerIsRequired')
            "
          />
        </dx-validator>
      </DxDropDownBox>
    </div>
  </div>
</template>

<script>
import {DxValidator, DxRequiredRule} from "devextreme-vue/validator";
import {DxDropDownBox} from "devextreme-vue";
import {
  DxDataGrid,
  DxSelection,
  DxPaging,
  DxFilterRow,
  DxScrolling,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {retailerColDefs} from "../../custom/schedule/retailerSelectColDefs";
import $axios from "../../utils/axios-instance";
import {isNotEmpty, toCamelCase} from "../../common/helperFunctions";
import {createHelpers} from "vuex-map-fields";
import {mapState} from "vuex";

const {mapFields} = createHelpers({
  getterType: "schedules/getField",
  mutationType: "schedules/updateField",
});

export default {
  props: {
    selectedAuditType: {
      type: Object,
    },
  },
  watch: {
    auditManagers() {
      if (this.auditManagers) {
        const auditManager = this.$store.state.schedules.auditManagers.find(
          (x) => x.username === this.$store.state.auth.user.username,
        );
        this.$store.dispatch("schedules/setDefaultAuditManager", auditManager);
      }
    },
    selectedThirdParty() {
      if (isNotEmpty(this.selectedThirdParty)) {
        this.dataSource.reload();
      }
    },
  },
  components: {
    DxDropDownBox,
    DxDataGrid,
    DxSelection,
    DxPaging,
    DxFilterRow,
    DxScrolling,
    DxValidator,
    DxRequiredRule,
  },
  data() {
    return {
      dataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.loadRetailers,
          loadMode: "raw",
        }),
      }),
      numberOfRetailersSelected: 0,
      componentContainerRef: "container",
      retailersDropDownRef: "retailers",
    };
  },
  methods: {
    onRetailersDropDownOpened() {
      let screenWidth = window.innerWidth;
      let dropDownWidth =
        screenWidth < 992 ? this.componentContainer.clientWidth : 900;
      this.retailersDropDown.option("dropDownOptions.width", dropDownWidth);
    },
    onRetailerValueChanged(e, type) {
      this.numberOfRetailersSelected = e.value ? e.value.length : 0;

      if (this.numberOfRetailersSelected > 0) {
        if (type === "single") {
          this.$emit("onRetailerValueChanged", e.value);
          this.selectedRetailerSingle = e.value;
          this.selectedRetailerMultiple = [];
        } else {
          this.selectedRetailerMultiple = e.value;
          this.$store.dispatch(
            "schedules/updateSelectedRetailerMultiple",
            e.value,
          );
          this.selectedRetailerSingle = [];
        }
      }
    },
    async loadRetailers(loadOptions) {
      if (!isNotEmpty(this.selectedThirdParty)) return [];
      let params = "?";
      [
        "skip",
        "take",
        "sort",
        "requireTotalCount",
        "requireGroupCount",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });
      params = params.slice(0, -1);
      const response = await $axios.get(
        `/Organisations?auditPeriodId=${this.selectedAuditYear}&type=Retailer&thirdPartyId=${this.selectedThirdParty}${params}`,

        {
          headers: {},
        },
      );
      const json = await JSON.parse(JSON.stringify(response));
      return json.data.data;
    },
  },
  computed: {
    ...mapFields([
      "selectedRetailerSingle",
      "selectedRetailerMultiple",
      "selectedThirdParty",
    ]),
    ...mapState("organisations", ["selectedAuditYear"]),
    componentContainer() {
      return this.$refs[this.componentContainerRef];
    },
    retailersDropDown() {
      return this.$refs[this.retailersDropDownRef].instance;
    },
    selfAssessmentSelected() {
      if (this.selectedAuditType !== null) {
        return this.selectedAuditType.val === "SelfAssessment";
      }
      return false;
    },
    retailerColDefs() {
      let colDefs = JSON.parse(JSON.stringify(retailerColDefs));
      colDefs.forEach((element) => {
        if (element.dataField) {
          element.caption = this.$t(
            `schedules.scheduleAssessment.retailerList.${toCamelCase(
              element.dataField,
            )}`,
          );
        }
      });

      return colDefs;
    },
    selectedSingleRetailerId() {
      let retailer = this.$store.state.schedules.selectedRetailerSingle;
      if (Array.isArray(retailer) && retailer.length) {
        return retailer[0];
      }
      return 0;
    },
    retailersSelected() {
      return `${this.numberOfRetailersSelected} ${this.$t(
        "schedules.scheduleAssessment.retailersSelected",
      )}`;
    },
    auditManagers() {
      return this.$store.state.schedules.auditManagers;
    },
  },
};
</script>

<style></style>
