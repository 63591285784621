var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-heading',{attrs:{"pageTitle":_vm.pageTitle,"breadCrumbs":_vm.breadCrumbs}}),_c('div',{staticClass:"fixed-action-container",on:{"click":_vm.newUserClick}},[_c('i',{staticClass:"fas fa-plus"})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('dx-button',{staticClass:"schedule-visit hidden-sm hidden-xs",attrs:{"type":"normal","stylingMode":"contained","icon":"plus","text":this.$t('users.usersList.createNewUser')},on:{"click":_vm.newUserClick}})],1)]),_c('div',{staticClass:"card-container",staticStyle:{"margin-bottom":"90px"}},[_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('h2',[_c('i',{staticClass:"far fa-user"}),_vm._v(" "+_vm._s(_vm.$t("users.usersList.title"))+" ")]),(_vm.isInRole(_vm.roleTypes.admin.role))?_c('div',[_c('dx-button',{staticClass:"toggle-user-state hidden-sm hidden-xs",attrs:{"type":"normal","stylingMode":"outlined","icon":"group","text":_vm.formatText},on:{"click":_vm.toggleUserActiveState}})],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"content-block"},[_c('dx-data-grid',{ref:_vm.usersRefKey,staticClass:"dx-card thin-column-chooser",attrs:{"data-source":_vm.dataSource,"noDataText":_vm.noDataText,"columns":_vm.colDefs,"focused-row-index":0,"focused-row-enabled":false,"column-hiding-enabled":false,"selection":{mode: 'single'},"hover-state-enabled":true,"show-borders":false,"allow-column-reordering":true,"allow-column-resizing":true,"column-min-width":150,"columnResizingMode":"widget"},scopedSlots:_vm._u([{key:"clear-filter-cell-template",fn:function(){return [_c('div',[_c('div',{staticClass:"cell-button-header",on:{"click":_vm.switchToAllTab}},[_c('p',[_c('i',{staticClass:"fas fa-sync"}),_c('span',[_vm._v(_vm._s(_vm.$t("shared.clearFilters")))])])])])]},proxy:true},{key:"action-buttons-cell-template",fn:function(ref){
var data = ref.data;
return [_c('div',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"table-button",attrs:{"title":_vm.$t('users.usersList.editUser')},on:{"click":function($event){return _vm.$router.push({
                      name: 'user-details',
                      params: {id: data.data.id},
                    })}}},[_c('i',{staticClass:"far fa-edit"})])])]}},{key:"org-array-template",fn:function(ref){
                    var data = ref.data;
                    var column = ref.column;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getOrgArrayCellData(data, column))+" ")])]}}])},[_c('DxExport',{attrs:{"enabled":true}}),_c('DxColumnChooser',{attrs:{"enabled":true,"allowSearch":true,"height":500,"mode":"dragAndDrop"}}),_c('DxColumnFixing',{attrs:{"enabled":true}}),_c('DxStateStoring',{attrs:{"enabled":true,"type":"localStorage","storage-key":"storageUserList","saving-timeout":_vm.savingTimeout}}),_c('dx-filter-row',{attrs:{"visible":true}}),_c('dx-header-filter',{attrs:{"visible":true,"allowSearch":true}}),_c('dx-paging',{attrs:{"page-size":10}}),_c('dx-pager',{attrs:{"show-page-size-selector":true,"show-info":true}}),_c('dx-remote-operations',{attrs:{"filtering":false,"paging":false,"sorting":false,"summary":false,"grouping":false,"group-paging":false}})],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }