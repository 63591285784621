<template>
  <div>
    <div class="row">
      <div class="col-xl-3 action-left-container individual-box">
        <h2>
          <i class="far fa-flag"></i>
          {{ this.$t("findings.findingList.findings") }}
          <span
            v-if="findings && findings.length > 0"
            class="warning-indication"
            >{{ findings.length }}</span
          >
        </h2>
        <p class="mb-4">
          {{ this.$t("findings.findingList.addFindingParagraph") }}.
        </p>

        <template v-if="showButton">
          <dx-button
            @click="onAddFindingClick"
            type="normal"
            name="addFinding"
            stylingMode="contained"
            class="btn btn-medium"
            icon="plus"
            :text="this.$t('findings.findingList.addFinding')"
          >
          </dx-button>
        </template>
      </div>

      <div class="col-xl-9">
        <div class="findings-list" v-if="findings && findings.length > 0">
          <!-- TODO: list goes here -->
          <dx-data-grid
            :data-source="dataSource"
            :show-borders="false"
            :show-column-headers="true"
            key-expr="id"
            :focused-row-index="0"
            :focused-row-enabled="false"
            :column-auto-width="true"
            :column-hiding-enabled="true"
            :selection="{mode: 'single'}"
            :hover-state-enabled="true"
            :columns="findingsColDefs"
          >
            <template #description-cell-template="cell">
              <div
                class="table-link warning"
                id="finding-description"
                @click="onEditFindingClick(cell)"
              >
                <span>
                  <i class="fas fa-exclamation-circle"></i>
                  {{ cell.data.data.description.trim() }}
                </span>
              </div>
            </template>

            <!-- <template #root-cause-cell-template="cell">
              {{ cell.data.data.rootCause }}
            </template> -->

            <template #options-cell-template="cell">
              <data-grid-options
                v-if="!readOnly"
                @delete-click="onDeleteFindingClick(cell)"
                @edit-click="onEditFindingClick(cell)"
              />
            </template>
          </dx-data-grid>
        </div>

        <div v-else style="text-align: center">
          <div class="no-records-fallback">
            <i class="far fa-flag"></i>
            <span class="darker"
              >{{ this.$t("findings.findingList.noFindings") }}.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DxButton, DxDataGrid} from "devextreme-vue";
import findingsColDefs from "../../custom/assessment/findingsColDefs";
import DataGridOptions from "../../components/shared/datagrid-options";
import {confirm} from "devextreme/ui/dialog";

export default {
  props: {
    findings: {
      Type: Array,
    },
    readOnly: {
      Type: Boolean,
    },
    showButton: {
      Type: Boolean,
      default: true,
      required: false,
    },
  },
  components: {
    DxButton,
    DxDataGrid,
    DataGridOptions,
  },
  data() {
    return {
      dataSource: this.findings,
    };
  },
  computed: {
    findingsColDefs() {
      let colDefs = JSON.parse(JSON.stringify(findingsColDefs));
      colDefs.forEach((element) => {
        if (element.caption) {
          element.caption = this.$t(
            `findings.findingList.${element.caption
              .charAt(0)
              .toLowerCase()}${element.caption.slice(1).replace(/\s+/g, "")}`,
          );
        }
      });

      return colDefs;
    },
  },
  methods: {
    onAddFindingClick() {
      this.$emit("add-finding-click");
    },
    onDeleteFindingClick(cell) {
      let result = confirm(
        `<i>${this.$t("findings.addFindings.sureDeleteFinding")}</i>`,
        this.$t("findings.addFindings.confirmDeletion"),
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          this.$emit("delete-finding-click", cell.data);
        }
      });
    },
    onEditFindingClick(cell) {
      this.$emit("edit-finding-click", cell.data);
    },
  },
  watch: {
    findings() {
      this.dataSource = this.findings;
    },
  },
};
</script>

<style lang="scss">
@import "../../themes/generated/variables.base.scss";
.darker {
  color: #1e1e1e !important;
}
/* fixes description overflow */
.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-field-item-content {
  line-height: 25px !important;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

/* makes root cause and description into columns */
.dx-item-content.dx-box-item-content.dx-box-flex.dx-box.dx-widget.dx-visibility-change-handler.dx-collection {
  flex-direction: column !important;
}

/* fixes description layout in column*/
.dx-layout-manager .dx-field-item:not(.dx-first-col) {
  padding: 0px 0px 0px 0px !important;
}

#finding-description {
  max-width: 250px;
}

@media screen and (max-width: 480px) {
  #finding-description {
    max-width: 150px;
  }
}
</style>
