<template>
  <div>
    <img src="../assets/logos/ikea-logo.svg" />
    <div class="login-container">
      <h1>Reset your password</h1>
      <p>Please fill the form below to reset your password.</p>

      <dx-validation-group :ref="resetValidationRefKey">
        <div class="dx-field" style="z-index: 3 !important">
          <DxTooltip
            :visible.sync="passwordTooltip"
            :close-on-outside-click="false"
            target="#divPassword"
            position="top"
          >
            <div style="text-align: left">
              <p :class="numberOfChars">
                &nbsp;Must have at least 8 characters
              </p>
              <p :class="lowerCaseChar">
                &nbsp;Must have at least 1 lower case character
              </p>
              <p :class="upperCaseChar">
                &nbsp;Must have at least 1 upper case character
              </p>
              <p :class="number">&nbsp;Must have at least 1 number</p>
              <p :class="specialChar">
                &nbsp;Must have at least 1 special character (!, @, #, $, %, ^,
                &, *)
              </p>
            </div>
          </DxTooltip>
          <div id="divPassword" style="width: 100%">
            <dx-text-box
              placeholder="New password"
              stylingMode="outlined"
              width="100%"
              mode="password"
              :value.sync="password"
              validationMessageMode="always"
              ref="txtPassword"
              @input="onKeyUp"
              @focus-in="passwordTooltip = true"
              @focus-out="passwordTooltip = false"
            >
              <dx-validator>
                <dx-required-rule message="Password is required" />
                <dx-pattern-rule
                  :pattern="passwordPattern"
                  message="Password must contain at least 8 characters and include an upper case, lower case, number and special character (!, @, #, $, %, ^, &, *)"
                />
              </dx-validator>
            </dx-text-box>
          </div>
        </div>

        <div class="dx-field" style="z-index: 2 !important">
          <dx-text-box
            placeholder="Repeat password"
            stylingMode="outlined"
            width="100%"
            mode="password"
            :value.sync="repeatPassword"
            validationMessageMode="always"
            @enter-key="onRepeatPasswordEnterKeyPress()"
          >
            <dx-validator>
              <dx-required-rule message="Repeat password is required" />
              <dx-compare-rule
                :comparison-target="newPasswordComparison"
                message="Passwords do not match"
              />
            </dx-validator>
          </dx-text-box>
        </div>

        <div style="margin: 30px 0">
          <dx-button
            type="default"
            text="Reset password"
            ref="submitBtn"
            width="100%"
            @click="onSubmitClick"
          />
        </div>
      </dx-validation-group>
    </div>
    <div :style="loaderDisplay" class="login-loader"></div>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxTextBox from "devextreme-vue/text-box";
import DxValidationGroup from "devextreme-vue/validation-group";
import DxValidator, {
  DxRequiredRule,
  DxCompareRule,
  DxPatternRule,
} from "devextreme-vue/validator";
import {mapActions} from "vuex";
import notify from "devextreme/ui/notify";
import {isNotEmpty} from "../common/helperFunctions";
import {validPasswordRegex} from "../common/constants";
import {DxTooltip} from "devextreme-vue";

export default {
  data() {
    return {
      password: "",
      repeatPassword: "",
      passwordPattern: validPasswordRegex,
      resetValidationRefKey: "resetValidationGroup",
      submitted: false,
      loaderDisplay: {},
      verificationToken: "",
      userName: "",
      passwordTooltip: false,
      numberOfChars: "password-alert",
      lowerCaseChar: "password-alert",
      upperCaseChar: "password-alert",
      number: "password-alert",
      specialChar: "password-alert",
    };
  },
  created() {
    if (
      !isNotEmpty(this.$route.query.token) ||
      !isNotEmpty(this.$route.query.username)
    ) {
      this.$router.push({name: "login-form"});
    }

    this.verificationToken = decodeURIComponent(this.$route.query.token);
    this.userName = this.$route.query.username;
  },
  methods: {
    ...mapActions("auth", ["resetPassword"]),
    newPasswordComparison() {
      return this.password;
    },
    onSubmitClick(e) {
      if (!e.validationGroup.validate().isValid || this.submitted) return;
      Vue.set(this.loaderDisplay, "display", "block");
      let password = this.password;
      let token = this.verificationToken;
      let username = this.userName;
      this.submitted = true;

      this.resetPassword({password, token, username})
        .then(() => {
          this.submitted = false;
          Vue.set(this.loaderDisplay, "display", "none");
          notify("Your password was successfully changed", "success");
          this.$router.push({name: "login-form"});
        })
        .catch(() => {
          this.submitted = false;
          Vue.set(this.loaderDisplay, "display", "none");
          notify("Unable to reset password", "error");
        });
    },
    onRepeatPasswordEnterKeyPress() {
      this.$refs.submitBtn.$el.click();
    },
    checkNewPassword(password) {
      this.numberOfChars = new RegExp("(?=.{8,})").test(password)
        ? "password-success"
        : "password-alert";

      this.lowerCaseChar = new RegExp("(?=.*[a-z])").test(password)
        ? "password-success"
        : "password-alert";

      this.upperCaseChar = new RegExp("(?=.*[A-Z])").test(password)
        ? "password-success"
        : "password-alert";

      this.number = new RegExp("(?=.*[0-9])").test(password)
        ? "password-success"
        : "password-alert";

      this.specialChar = new RegExp("(?=.*[!@#$%^&*])").test(password)
        ? "password-success"
        : "password-alert";
    },
    onKeyUp(e) {
      this.checkNewPassword(this.$refs.txtPassword.$_innerChanges.text);
    },
  },
  computed: {
    loginValidationGroup: function () {
      return this.$refs[this.resetValidationRefKey].instance;
    },
  },
  components: {
    DxButton,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationGroup,
    DxCompareRule,
    DxPatternRule,
    DxTooltip,
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.login-header {
  text-align: center;
  margin-bottom: 40px;

  .title {
    color: $base-accent;
    font-weight: bold;
    font-size: 30px;
    margin: 0;
  }
}
</style>
