<template>
  <div>
    <div class="content-block">
      <h2 class="content-title">
        <i class="far fa-comment"></i>
        {{ $t("assessments.assessment.comments") }}
      </h2>
    </div>
    <div class="element-margin">
      <DxTextArea
        :height="150"
        :value.sync="activeComment"
        stylingMode="outlined"
        value-change-event="change"
      ></DxTextArea>
    </div>

    <dx-button
      :text="$t('assessments.assessment.editComment')"
      name="editComment"
      type="default"
      class="btn btn-large"
      icon="check"
      @click="onSubmitButtonClick($event)"
    />
  </div>
</template>

<script>
import {DxTextArea} from "devextreme-vue";
import DxButton from "devextreme-vue/button";
import {isNotEmpty} from "../../common/helperFunctions";

export default {
  components: {
    DxTextArea,
    DxButton,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeComment: this.comment.comment,
    };
  },
  methods: {
    onSubmitButtonClick() {
      if (isNotEmpty(this.activeComment)) {
        this.$emit("save-comment-click", this.activeComment);
      }
    },
  },
};
</script>

<style></style>
