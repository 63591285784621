<template>
  <div
    class="chart-full-screen-container"
    :class="{open: isChartFullScreen}"
    ref="chartContainer"
  >
    <div class="filter-panel">
      <DxScrollView
        class="filters-scrollview"
        show-scrollbar="always"
        :use-native="false"
      >
        <div class="sectioned-part">
          <h2>
            <i class="fas fa-filter"></i>
            {{ $t("reporting.filters.sizeSettingsTitle") }}
          </h2>
          <div class="content-block">
            <label>{{ $t("reporting.filters.sizeSettingsWidth") }}</label>
            <DxNumberBox
              :value.sync="chartWidth"
              :show-spin-buttons="true"
              stylingMode="outlined"
            />
          </div>

          <div class="content-block">
            <label>{{ $t("reporting.filters.sizeSettingsHeight") }}</label>
            <DxNumberBox
              :value.sync="chartHeight"
              :show-spin-buttons="true"
              stylingMode="outlined"
            />
          </div>
        </div>

        <div class="sectioned-part">
          <h2>
            <i class="fas fa-filter"></i>
            {{ $t("reporting.filters.legendSettingsTitle") }}
          </h2>
          <div class="content-block">
            <label>{{ $t("reporting.filters.legendSettingsVisiblity") }}</label>
            <div class="checkbox-container">
              <dx-check-box
                name="showLegend"
                :text="showLegendText"
                :value.sync="legendOn"
              />
            </div>
          </div>
        </div>

        <div class="sectioned-part">
          <h2>
            <i class="fas fa-chart-line"></i>
            {{ $t("reporting.filters.axisSettingsTitle") }}
          </h2>
          <div class="content-block">
            <label>{{ $t("reporting.filters.axisSettingsVisibility") }}</label>
            <div class="checkbox-container">
              <dx-check-box
                name="showAxis"
                :text="showAxisText"
                :value.sync="axisOn"
              />
            </div>
          </div>
          <div class="content-block mb-4">
            <label>{{
              $t("reporting.filters.axisSettingsLabelPosition")
            }}</label>
            <DxSelectBox
              :data-source="axisLabelPositionsDataSource"
              :value.sync="axisLabelPosition"
              display-expr="name"
              value-expr="value"
              stylingMode="outlined"
            >
            </DxSelectBox>
          </div>

          <template v-if="axisLabelPosition === 'rotate'">
            <div class="content-block mb-4">
              <label>{{
                $t("reporting.filters.axisSettingsLabelRotationAngle")
              }}</label>
              <DxNumberBox
                :value.sync="axisRotationAngle"
                :show-spin-buttons="true"
                :min="-720"
                :max="720"
                stylingMode="outlined"
              />
            </div>
          </template>
        </div>

        <h2>
          <i class="fas fa-chart-line"></i>
          {{ $t("reporting.filters.pointSettingsTitle") }}
        </h2>
        <div class="content-block">
          <label>{{
            $t("reporting.filters.pointSettingsLabelVisibility")
          }}</label>
          <div class="checkbox-container">
            <dx-check-box
              name="showPoint"
              :text="showPointLabelText"
              :value.sync="pointOn"
            />
          </div>
        </div>

        <div class="content-block">
          <label>{{
            $t("reporting.filters.pointSettingsConnectorVisibility")
          }}</label>
          <div class="checkbox-container">
            <dx-check-box
              name="showPointConnector"
              :text="showPointConnectorText"
              :value.sync="connectorOn"
            />
          </div>
        </div>

        <div class="content-block">
          <label>{{
            $t("reporting.filters.pointSettingsLabelTextVisibility")
          }}</label>
          <div class="checkbox-container">
            <dx-check-box
              name="showPointLabel"
              :text="showPointLabelInnerText"
              :value.sync="pointLabelTextOn"
            />
          </div>
        </div>

        <div class="content-block">
          <label>{{
            $t("reporting.filters.pointSettingsLabelPosition")
          }}</label>
          <DxSelectBox
            :data-source="pointLabelPositionsDataSource"
            :value.sync="pointLabelPosition"
            display-expr="name"
            value-expr="value"
            stylingMode="outlined"
          >
          </DxSelectBox>
        </div>

        <div class="content-block">
          <label>{{
            $t("reporting.filters.pointSettingsLabelOverlapping")
          }}</label>
          <DxSelectBox
            :data-source="pointLabelOverlappingOptionsDataSource"
            :value.sync="pointOverlappingOptions"
            display-expr="name"
            value-expr="value"
            stylingMode="outlined"
          >
          </DxSelectBox>
        </div>
      </DxScrollView>
    </div>

    <div
      class="expand-icon-container hidden-md hidden-sm hidden-xs"
      @click="toggleChartFullScreen"
    >
      <i class="fas fa-expand-arrows-alt expand-icon"></i>
      <i class="fas fa-times close-icon"></i>
    </div>
    <div class="overlay-container">
      <slot
        name="item"
        :chartHeight="chartHeight"
        :chartWidth="chartWidth"
        :isChartFullScreen="isChartFullScreen"
        :legendOn="legendOn"
        :axisOn="axisOn"
        :axisLabelPosition="axisLabelPosition"
        :axisRotationAngle="axisRotationAngle"
        :pointOn="pointOn"
        :connectorOn="connectorOn"
        :pointLabelTextOn="pointLabelTextOn"
        :pointLabelPosition="pointLabelPosition"
        :pointOverlappingOptions="pointOverlappingOptions"
        :dataLoaded="resizeHandler"
      ></slot>
    </div>
  </div>
</template>

<script>
import {DxCheckBox, DxSelectBox, DxNumberBox} from "devextreme-vue";
import {DxScrollView} from "devextreme-vue/scroll-view";
import {
  axisLabelPositions,
  pointLabelPositions,
  pointLabelOverlappingOptions,
} from "../../common/constants";
export default {
  components: {
    DxCheckBox,
    DxSelectBox,
    DxNumberBox,
    DxScrollView,
  },
  props: {
    pageTitle: {
      type: String,
    },
  },
  data() {
    return {
      chartHeight: 400,
      chartWidth: undefined,
      isChartFullScreen: false,
      legendOn: true,
      axisOn: true,
      axisLabelPosition: "standard",
      axisRotationAngle: 90,
      pointOn: true,
      connectorOn: false,
      pointLabelTextOn: false,
      pointLabelPosition: "inside",
      pointOverlappingOptions: "stack",
      axisLabelPositionsDataSource: Object.entries(axisLabelPositions).map(
        ([key, value]) => ({
          name: this.$t(`reporting.filters.axisLabelPosition${key}`),
          value: value,
        }),
      ),
      pointLabelPositionsDataSource: Object.entries(pointLabelPositions).map(
        ([key, value]) => ({
          name: this.$t(`reporting.filters.pointLabelPosition${key}`),
          value: value,
        }),
      ),
      pointLabelOverlappingOptionsDataSource: Object.entries(
        pointLabelOverlappingOptions,
      ).map(([key, value]) => ({
        name: this.$t(`reporting.filters.pointLabelOverlappingOption${key}`),
        value: value,
      })),
    };
  },
  computed: {
    showLegendText() {
      return this.$t("reporting.filters.showLegend");
    },
    showAxisText() {
      return this.$t("reporting.filters.showAxis");
    },
    showPointLabelText() {
      return this.$t("reporting.filters.showPointLabel");
    },
    showPointConnectorText() {
      return this.$t("reporting.filters.showPointConnector");
    },
    showPointLabelInnerText() {
      return this.$t("reporting.filters.showPointLabelText");
    },
  },
  methods: {
    toggleChartFullScreen: function () {
      this.isChartFullScreen = !this.isChartFullScreen;
      this.legendOn = true;
      this.axisOn = true;
      this.axisLabelPosition = "standard";
      this.axisRotationAngle = 90;
      this.pointOn = true;
      this.connectorOn = false;
      this.pointLabelTextOn = false;
      this.pointLabelPosition = "inside";
      this.pointOverlappingOptions = "stack";

      if (this.isChartFullScreen) {
        this.chartHeight = window.innerHeight - 150;
        this.chartWidth = window.innerWidth - 450;
      } else {
        this.chartHeight = 400;
      }
    },
    resizeHandler() {
      if (!this.isChartFullScreen) {
        const chartWidth = this.$refs.chartContainer.clientWidth - 50;
        if (chartWidth > 0) {
          this.chartWidth = chartWidth;
        }
      }
    },
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  updated() {
    this.resizeHandler();
  },
};
</script>

<style lang="scss"></style>
