import fileDownload from "js-file-download";
import {isNotEmpty} from "../common/helperFunctions";
import ContentDisposition from "content-disposition";

export default {
  methods: {
    downloadAuthAttachment(link) {
      if (isNotEmpty(link)) {
        this.$http.get(link, {responseType: "blob"}).then((response) => {
          return fileDownload(
            response.data,
            ContentDisposition.parse(response.headers["content-disposition"])
              .parameters.filename,
          );
        });
      }
    },
  },
};
