<template>
  <div class="login-container">
    <dx-validation-group
      :ref="passwordValidationRefKey"
      name="passwordValidationGroup"
    >
      <div class="mb-4">
        <dx-text-box
          :placeholder="$t('userSettings.changePassword.password')"
          stylingMode="outlined"
          width="100%"
          mode="password"
          :value.sync="password"
        >
          <dx-validator>
            <dx-required-rule
              :message="
                $t('userSettings.changePassword.requiredError', {
                  dataField: $t('userSettings.changePassword.password'),
                })
              "
            />
          </dx-validator>
        </dx-text-box>
      </div>
      <div class="mb-4">
        <DxTooltip
          :visible.sync="passwordTooltip"
          :close-on-outside-click="false"
          target="#divPassword"
          position="top"
        >
          <div style="text-align: left">
            <p :class="numberOfChars">
              {{ $t("tooltips.passwordRequirements.numberOfCharacters") }}
            </p>
            <p :class="lowerCaseChar">
              {{ $t("tooltips.passwordRequirements.lowerCaseChar") }}
            </p>
            <p :class="upperCaseChar">
              {{ $t("tooltips.passwordRequirements.upperCaseChar") }}
            </p>
            <p :class="number">
              {{ $t("tooltips.passwordRequirements.number") }}
            </p>
            <p :class="specialChar">
              {{ $t("tooltips.passwordRequirements.specialChar") }}
            </p>
          </div>
        </DxTooltip>
        <div id="divPassword">
          <dx-text-box
            :placeholder="$t('userSettings.changePassword.newPassword')"
            stylingMode="outlined"
            width="100%"
            mode="password"
            :value.sync="newPassword"
            ref="txtPassword"
            @input="onKeyUp"
            @focus-in="passwordTooltip = true"
            @focus-out="passwordTooltip = false"
          >
            <dx-validator>
              <dx-required-rule
                :message="
                  $t('userSettings.changePassword.requiredError', {
                    dataField: $t('userSettings.changePassword.newPassword'),
                  })
                "
              />
              <dx-pattern-rule
                :pattern="passwordPattern"
                :message="
                  $t('userSettings.changePassword.passwordPatternError')
                "
              />
            </dx-validator>
          </dx-text-box>
        </div>
      </div>
      <div class="mb-4">
        <dx-text-box
          :placeholder="$t('userSettings.changePassword.repeatPassword')"
          stylingMode="outlined"
          width="100%"
          mode="password"
          @enter-key="onRepeatPasswordEnterKeyPress()"
          :value.sync="repeatPassword"
        >
          <dx-validator>
            <dx-required-rule
              :message="
                $t('userSettings.changePassword.requiredError', {
                  dataField: $t('userSettings.changePassword.repeatPassword'),
                })
              "
            />
            <dx-compare-rule
              :comparison-target="newPasswordComparison"
              :message="$t('userSettings.changePassword.passwordMatchError')"
            />
          </dx-validator>
        </dx-text-box>
      </div>
      <div>
        <dx-button
          type="normal"
          :text="$t('userSettings.changePassword.submit')"
          ref="submitBtn"
          @click="onSubmitClick"
          class="btn btn-large"
          icon="check"
        />
      </div>
    </dx-validation-group>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import notify from "devextreme/ui/notify";
import {DxButton, DxTextBox, DxTooltip} from "devextreme-vue";
import DxValidationGroup from "devextreme-vue/validation-group";
import DxValidator, {
  DxRequiredRule,
  DxCompareRule,
  DxPatternRule,
} from "devextreme-vue/validator";
import {validPasswordRegex} from "../../common/constants";

export default {
  data() {
    return {
      password: "",
      newPassword: "",
      repeatPassword: "",
      passwordPattern: validPasswordRegex,
      passwordValidationRefKey: "passwordValidationGroup",
      submitted: false,
      passwordTooltip: false,
      numberOfChars: "password-alert",
      lowerCaseChar: "password-alert",
      upperCaseChar: "password-alert",
      number: "password-alert",
      specialChar: "password-alert",
    };
  },
  components: {
    DxValidator,
    DxRequiredRule,
    DxButton,
    DxTextBox,
    DxCompareRule,
    DxPatternRule,
    DxValidationGroup,
    DxTooltip,
  },
  computed: {
    passwordValidationGroup: function () {
      return this.$refs[this.passwordValidationRefKey].instance;
    },
  },
  methods: {
    ...mapActions("auth", ["changePassword"]),
    newPasswordComparison() {
      return this.newPassword;
    },
    onSubmitClick(e) {
      if (!e.validationGroup.validate().isValid || this.submitted) return;
      let password = this.password;
      let newPassword = this.newPassword;
      this.submitted = true;

      this.changePassword({password, newPassword})
        .then(() => {
          this.submitted = false;
          notify(this.$t("userSettings.changePassword.success"), "success");
          // TODO:
          // Decide whether or not to keep this emit.
          this.$emit("on-password-change-success");
        })
        .catch(() => {
          this.submitted = false;
          notify(this.$t("userSettings.changePassword.failure"), "error");
        });
    },
    onRepeatPasswordEnterKeyPress() {
      this.$refs.submitBtn.$el.click();
    },
    checkNewPassword(password) {
      this.numberOfChars = new RegExp("(?=.{8,})").test(password)
        ? "password-success"
        : "password-alert";

      this.lowerCaseChar = new RegExp("(?=.*[a-z])").test(password)
        ? "password-success"
        : "password-alert";

      this.upperCaseChar = new RegExp("(?=.*[A-Z])").test(password)
        ? "password-success"
        : "password-alert";

      this.number = new RegExp("(?=.*[0-9])").test(password)
        ? "password-success"
        : "password-alert";

      this.specialChar = new RegExp("(?=.*[!@#$%^&*])").test(password)
        ? "password-success"
        : "password-alert";
    },
    onKeyUp(e) {
      this.checkNewPassword(this.$refs.txtPassword.$_innerChanges.text);
    },
  },
};
</script>

<style></style>
