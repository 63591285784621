<template>
  <div class="chart-container card-container">
    <div class="chart-container-header">
      <h1>
        {{ chartTitle }}
      </h1>
    </div>
    <dx-chart
      :ref="chartRef"
      :data-source="dataSource"
      :resolve-label-overlapping="pointOverlappingOptions"
      @exporting="onExporting"
      @exported="onExported"
      :animation="{
        enabled: false,
      }"
    >
      <DxSize :height="chartHeight" :width="chartWidth" />
      <dx-loading-indicator :enabled="true" />
      <dx-common-axis-settings :color="color.axis">
        <dx-tick :color="color.axis" />
        <dx-label
          :visible="axisOn"
          :displayMode="axisLabelPosition"
          :rotationAngle="axisRotationAngle"
        >
          <dx-font :color="color.text" />
        </dx-label>
      </dx-common-axis-settings>
      <dx-argument-axis
        position="bottom"
        argument-type="datetime"
        aggregation-interval="month"
        tick-interval="month"
        :value-margins-enabled="false"
        discrete-axis-division-mode="crossLabels"
      >
        <dx-label
          overlappingBehavior="stagger"
          :format="{year: 'numeric', month: 'short'}"
        />
        <dx-grid :visible="true" :color="color.grid" />
      </dx-argument-axis>
      <dx-value-axis position="left" :allow-decimals="false">
        <dx-grid :color="color.grid" />
      </dx-value-axis>
      <dx-common-series-settings
        argument-field="date"
        value-field="value"
        type="line"
        hover-mode="allArgumentPoints"
        selection-mode="allArgumentPoints"
      >
        <dx-label
          :visible="pointOn"
          :position="pointLabelPosition"
          :customize-text="customizeText"
        >
          <dx-connector :visible="connectorOn" />
        </dx-label>
      </dx-common-series-settings>
      <dx-series-template
        :customize-series="customizeSeries"
        name-field="series"
      />
      <dx-tooltip :enabled="true" content-template="tooltipTemplate" />
      <dx-legend
        orientation="horizontal"
        horizontal-alignment="center"
        vertical-alignment="bottom"
        item-text-position="bottom"
        :visible="legendOn"
      >
        <dx-font :color="color.text" />
      </dx-legend>
      <dx-export :enabled="true" background-color="#ffffff" />
      <template #tooltipTemplate="{data}">
        <span>{{ data.point.data.value }}</span>
      </template>
    </dx-chart>
  </div>
</template>

<script>
import {
  DxArgumentAxis,
  DxChart,
  DxCommonAxisSettings,
  DxCommonSeriesSettings,
  DxExport,
  DxFont,
  DxGrid,
  DxLabel,
  DxLegend,
  DxLoadingIndicator,
  DxSeriesTemplate,
  DxTick,
  DxTooltip,
  DxValueAxis,
  DxSize,
  DxConnector,
} from "devextreme-vue/chart";
import {CancelToken} from "axios";
import {mapState} from "vuex";
import findingActivityStatusChartSeriesDefinitions from "../../custom/reporting/findingActivityStatusChartSeriesDefinitions";
import chartParameters from "../../mixins/chart-parameters";

export default {
  mixins: [chartParameters],
  props: {...chartParameters.props},
  components: {
    DxArgumentAxis,
    DxChart,
    DxCommonAxisSettings,
    DxCommonSeriesSettings,
    DxExport,
    DxFont,
    DxGrid,
    DxLabel,
    DxLegend,
    DxLoadingIndicator,
    DxSeriesTemplate,
    DxTick,
    DxTooltip,
    DxValueAxis,
    DxSize,
    DxConnector,
  },
  computed: {
    ...mapState("reportingFilters", ["filters"]),
    chart() {
      return this.$refs[this.chartRef].instance;
    },
    chartTitle() {
      return this.$t("reporting.charts.findingActivityOverTimeChartTitle");
    },
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      dataSource: [],
      loadCancelToken: null,
      chartRef: "findingActivityChart",
      seriesDefinitions: findingActivityStatusChartSeriesDefinitions.map(
        (x) => ({...x, caption: this.$t(x.caption)}),
        this,
      ),
      color: {
        text: "black",
        axis: "#C4C4C4",
        grid: "#e8e8e8",
      },
    };
  },
  methods: {
    customizeSeries(seriesName) {
      const series = this.seriesDefinitions.find(
        (x) => x.caption === seriesName,
      );
      return {
        color: series.color,
      };
    },
    onExporting(e) {
      this.chart.option("title.text", this.chartTitle);
      this.chart.option("title.font.color", "black");
    },
    onExported(e) {
      this.chart.option("title.text", "");
    },
    customizeText(pointInfo) {
      if (this.pointLabelTextOn) {
        return `${pointInfo.seriesName}: ${pointInfo.value}`;
      } else {
        return pointInfo.value;
      }
    },
    async load() {
      this.chart.showLoadingIndicator();
      try {
        if (this.loadCancelToken) {
          this.loadCancelToken.cancel();
        }

        this.loadCancelToken = CancelToken.source();
        let response = await this.$http.post(
          "/reporting/findingActivityOverTime",
          this.filters,
          {
            cancelToken: this.loadCancelToken.token,
          },
        );
        this.loadCancelToken = null;

        this.dataSource = Object.entries(response.data)
          .reduce((acc, [key, value]) => {
            let series = this.seriesDefinitions;
            return value.reduce((entries, entry) => {
              entries.push({
                series: series[0].caption,
                date: entry.date,
                value: entry.findingsOpenedCount,
              });
              entries.push({
                series: series[1].caption,
                date: entry.date,
                value: entry.findingsClosedCount,
              });
              return entries;
            }, acc);
          }, [])
          .sort((a, b) => a.date.localeCompare(b.date));

        this.chart.hideLoadingIndicator();
        this.dataLoaded();
      } catch (err) {
        this.dataSource = [];
      }
    },
  },
  watch: {
    filters() {
      this.load();
    },
  },
};
</script>

<style lang="sass" scoped></style>
