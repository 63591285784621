import {orderStatuses} from "../../custom/config/workflows";

export default Object.freeze([
  {
    dataField: "inProgressCount",
    caption: "reporting.charts.assessmentStatusInProgressSeriesTitle",
    color: "#f5a623",
    statusCode: orderStatuses.inProgress,
    order: 3,
  },
  {
    dataField: "scheduledCount",
    caption: "reporting.charts.assessmentStatusNotStartedSeriesTitle",
    color: "#c4c4c4",
    statusCode: orderStatuses.scheduled,
    order: 2,
  },
  {
    dataField: "submittedCount",
    caption: "reporting.charts.assessmentStatusSubmittedSeriesTitle",
    color: "#ff7310",
    statusCode: orderStatuses.submitted,
    order: 4,
  },
  {
    dataField: "orderedCount",
    caption: "reporting.charts.assessmentStatusOrderedSeriesTitle",
    color: "#5b778f",
    statusCode: orderStatuses.ordered,
    order: 1,
  },
  {
    dataField: "completedCount",
    caption: "reporting.charts.assessmentStatusCompletedSeriesTitle",
    color: "#00a700",
    statusCode: orderStatuses.completed,
    order: 5,
  },
  {
    dataField: "cancelledCount",
    caption: "reporting.charts.assessmentStatusCancelledSeriesTitle",
    color: "#ff0000",
    statusCode: orderStatuses.cancelled,
    order: 6,
  },
]);
