import $axios from "../../utils/axios-instance.js";
import {getSecureImage} from "../../common/helperFunctions";

export default {
  namespaced: true,
  state: {
    finding: null,
    findingStatus: "",
    attachments: null,
    attachmentsStatus: "",
    resolutionAttachments: null,
    resolutionAttachmentsStatus: "",
    comments: [],
    comment: "",
    readOnly: true,
  },
  actions: {
    getFinding({commit}, findingId) {
      return new Promise((resolve, reject) => {
        commit("setFindingStatus", "loading");
        $axios
          .get(`/assessments/findings/${findingId}`)
          .then((response) => {
            commit("setFindingStatus", "success");
            commit("setFinding", response.data);
            if (response.data.resolution) {
              commit("setComment", response.data.resolution.comment);
            }
            resolve(response.data);
          })
          .catch((err) => {
            commit("setFindingStatus", "error");
          });
      });
    },
    getFindingAttachments({commit}, findingId) {
      return new Promise((resolve, reject) => {
        commit("setAttachmentsStatus", "loading");
        $axios
          .get(`/assessments/findings/${findingId}/evidence`)
          .then((response) => {
            commit("setAttachmentsStatus", "success");
            commit("setAttachments", response.data);
            resolve(response);
          })
          .catch((err) => {
            commit("setAttachmentsStatus", "error");
            reject(err);
          });
      });
    },
    getFindingResolutionAttachments({commit}, findingId) {
      return new Promise((resolve, reject) => {
        commit("setResolutionAttachmentsStatus", "loading");
        $axios
          .get(`/assessments/findings/${findingId}/remediation`)
          .then((response) => {
            commit("setResolutionAttachmentsStatus", "success");
            commit("setResolutionAttachments", response.data);
            resolve(response);
          })
          .catch((err) => {
            commit("setResolutionAttachmentsStatus", "error");
            reject(err);
          });
      });
    },
    sendFindingForRework({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("setFindingStatus", "loading");
        $axios
          .post(`assessments/findings/${payload.assessmentId}/reject`, {
            reasonComment: payload.comment,
          })
          .then((response) => {
            commit("setFindingStatus", "success");
            resolve(response);
          })
          .catch((err) => {
            commit("setFindingStatus", "error");
            reject(err);
          });
      });
    },
    approveFinding({commit}, findingId) {
      return new Promise((resolve, reject) => {
        commit("setFindingStatus", "loading");
        $axios
          .post(`assessments/findings/${findingId}/approve`)
          .then((response) => {
            commit("setFindingStatus", "success");
            resolve(response);
          })
          .catch((err) => {
            commit("setFindingStatus", "error");
            reject(err);
          });
      });
    },
    sendForApproval({dispatch, commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("setFindingStatus", "loading");
        let formData = new FormData();
        formData.append("FindingId", payload.findingId);
        formData.append("ActionComment", payload.actionComment);
        formData.append("ActionStatusCode", payload.actionStatusCode);
        formData.append("RootCause", payload.rootCause);
        if (payload.reviewParticipantTargetDate) {
          formData.append(
            "ReviewParticipantTargetDate",
            payload.reviewParticipantTargetDate,
          );
        }
        if (payload.responsibleUserId) {
          formData.append("ResponsibleUserId", payload.responsibleUserId);
        }
        if (payload.attachments) {
          payload.attachments.forEach((file) =>
            formData.append("Files", file, file.name),
          );
        }
        $axios
          .post(
            `/assessments/findings/${payload.findingId}/resolution`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((response) => {
            commit("setFindingStatus", "success");
            resolve(response);
          })
          .catch((err) => {
            commit("setFindingStatus", "error");
            reject(err);
          });
      });
    },
    updateFinding({commit}, finding) {
      commit("setFinding", finding);
    },
    updateResolutionAttachmentsStatus({commit}, resolutionAttachmentsStatus) {
      commit("setResolutionAttachmentsStatus", resolutionAttachmentsStatus);
    },
    deleteAttachment({commit}, payload) {
      return new Promise((resolve, reject) => {
        $axios
          .delete(`/assessments/evidence/${payload.id}`)
          .then((response) => {
            if (payload.type === "resolution") {
              commit("removeResolutionEvidenceFromList", payload.id);
            }
            if (payload.type === "finding") {
              commit("removeFindingEvidenceFromList", payload.id);
            }
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getUsers({commit}, roles) {
      try {
        let response = await $axios.get(`/users/GetUsersByRole/${roles}`);
        return response;
      } catch (err) {
        throw new Error(err);
      }
    },
    async postComment({dispatch}, payload) {
      try {
        await $axios.post(
          `/assessments/findings/${payload.responseFindingId}/comments`,
          {
            comment: payload.comment,
          },
        );
      } catch (err) {
        throw new Error(err);
      }
    },
    async getCommentsList({commit}, payload) {
      try {
        let response = await $axios.get(
          `/assessments/findings/${payload.responseFindingId}/comments`,
        );

        let comments = [];
        for (let comment of response.data) {
          if (comment.pictureUrl) {
            comment = {
              ...comment,
              pictureUrl: getSecureImage(comment.pictureUrl),
            };
          }
          comments.push(comment);
        }
        commit("setComments", comments);
      } catch (err) {
        throw new Error(err);
      }
    },
    async updateComment({commit}, payload) {
      try {
        await $axios.put(
          `/assessments/findings/comments/${payload.commentId}`,
          payload,
        );
      } catch (err) {
        throw new Error(err);
      }
    },
    async deleteComment({commit}, commentId) {
      try {
        await $axios.delete(`/assessments/findings/comments/${commentId}`);
      } catch (err) {
        throw new Error(err);
      }
    },
    reOpenFinding({commit}, findingId) {
      return new Promise((resolve, reject) => {
        commit("setFindingStatus", "loading");
        $axios
          .post(`assessments/findings/${findingId}/reopen`)
          .then((response) => {
            commit("setFindingStatus", "success");
            resolve(response);
          })
          .catch((err) => {
            commit("setFindingStatus", "error");
            reject(err);
          });
      });
    },
  },
  mutations: {
    setFinding(state, finding) {
      state.finding = finding;
    },
    setComment(state, comment) {
      state.comment = comment;
    },
    setFindingStatus(state, findingStatus) {
      state.findingStatus = findingStatus;
    },
    setAttachmentsStatus(state, attachmentsStatus) {
      state.attachmentsStatus = attachmentsStatus;
    },
    setAttachments(state, attachments) {
      state.attachments = attachments;
    },
    setResolutionAttachmentsStatus(state, attachmentsResolutionStatus) {
      state.attachmentsResolutionStatus = attachmentsResolutionStatus;
    },
    setResolutionAttachments(state, resolutionAttachments) {
      state.resolutionAttachments = resolutionAttachments;
    },
    removeResolutionEvidenceFromList(state, id) {
      state.resolutionAttachments = state.resolutionAttachments.filter(
        (x) => x.id !== id,
      );
    },
    removeFindingEvidenceFromList(state, id) {
      state.attachments = state.attachments.filter((x) => x.id !== id);
    },
    setComments(state, comments) {
      state.comments = comments;
    },
  },
  getters: {
    isLoading: (state) => {
      return (
        state.findingStatus === "loading" ||
        state.attachmentsStatus === "loading" ||
        state.resolutionAttachmentsStatus === "loading"
      );
    },
  },
};
