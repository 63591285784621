<template>
  <div>
    <DxDataGrid
      :data-source="dataSource"
      :show-borders="true"
      :columns="auditLogColDefs"
    >
    </DxDataGrid>
  </div>
</template>
<script>
import {DxDataGrid} from "devextreme-vue/data-grid";
import {detailsColDefs} from "../../custom/audit/auditColumnDefinitions";
import {toCamelCase} from "../../common/helperFunctions";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

export default {
  components: {DxDataGrid},
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
    details: null,
  },
  computed: {
    auditLogColDefs() {
      // TODO:
      // this should be a mixin
      let colDefs = JSON.parse(JSON.stringify(detailsColDefs));
      colDefs.forEach((element) => {
        if (element.dataField) {
          element.caption = this.$t(
            `auditLogs.auditLogsList.${toCamelCase(element.dataField)}`,
          );
        }
      });

      return colDefs;
    },
  },
  data() {
    return {
      dataSource: new DataSource({
        store: new ArrayStore({
          data: this.details,
          key: "id",
        }),
        filter: ["logEntryId", "=", this.templateData.key],
      }),
    };
  },
};
</script>
<style></style>
