<template>
  <div class="chart-container card-container">
    <div class="chart-container-header">
      <h1>
        {{ chartTitle }}
      </h1>
      <div class="chart-group-by-container">
        <dx-select-box
          display-expr="name"
          :items="groupByDataSource"
          styling-mode="filled"
          :value.sync="groupByValue"
          value-expr="id"
        />
      </div>
    </div>
    <dx-chart
      :data-source="dataSource"
      :resolve-label-overlapping="pointOverlappingOptions"
      :rotated="true"
      :customize-point="customizePoint"
      :ref="chartRef"
      @point-click="onPointClick($event)"
      @exporting="onExporting"
      @exported="onExported"
      @done="chartZoomIn"
      :animation="{
        enabled: false,
      }"
    >
      <dx-size :height="chartHeight" :width="chartWidth" />
      <dx-loading-indicator :enabled="true" />
      <dx-scroll-bar :visible="true" />
      <dx-zoom-and-pan argument-axis="both" />
      <dx-common-axis-settings :color="color.axis">
        <dx-tick :color="color.axis" />
        <dx-label
          :visible="axisOn"
          :displayMode="axisLabelPosition"
          :rotationAngle="axisRotationAngle"
        >
          <dx-font :color="color.text" />
        </dx-label>
      </dx-common-axis-settings>
      <dx-argument-axis :visual-range="initialRange" position="bottom">
        <dx-label overlappingBehavior="stagger" />
      </dx-argument-axis>
      <dx-value-axis position="left" :allow-decimals="false">
        <dx-grid :color="color.grid" />
        <dx-title
          :text="$t('reporting.charts.retailerAverageScoresValueAxisTitle')"
        >
          <dx-font :color="color.text" />
        </dx-title>
      </dx-value-axis>
      <dx-common-series-settings
        argument-field="group"
        value-field="value"
        type="stackedbar"
        hover-mode="allArgumentPoints"
        selection-mode="allArgumentPoints"
      >
        <dx-label
          :visible="pointOn"
          :position="pointLabelPosition"
          :customize-text="customizeText"
        >
          <dx-connector :visible="connectorOn" />
        </dx-label>
      </dx-common-series-settings>
      <dx-series-template
        :customize-series="customizeSeries"
        name-field="series"
      />
      <dx-tooltip :enabled="true" content-template="tooltipTemplate" />
      <dx-legend
        orientation="horizontal"
        horizontal-alignment="center"
        vertical-alignment="bottom"
        item-text-position="bottom"
        :visible="legendOn"
      >
        <dx-font :color="color.text" />
      </dx-legend>
      <dx-export :enabled="true" background-color="#ffffff" />
      <template #tooltipTemplate="{data}">
        <span
          >{{ data.point.data.series }}:
          {{ data.point.data.value.toFixed(2) }}</span
        >
      </template>
    </dx-chart>
  </div>
</template>

<script>
import {
  DxArgumentAxis,
  DxChart,
  DxCommonAxisSettings,
  DxCommonSeriesSettings,
  DxExport,
  DxFont,
  DxGrid,
  DxLabel,
  DxLegend,
  DxLoadingIndicator,
  DxSeriesTemplate,
  DxTick,
  DxTitle,
  DxTooltip,
  DxValueAxis,
  DxSize,
  DxConnector,
  DxScrollBar,
  DxZoomAndPan,
} from "devextreme-vue/chart";
import DxSelectBox from "devextreme-vue/select-box";
import {CancelToken} from "axios";
import {mapFields} from "vuex-map-fields";
import {mapMutations} from "vuex";
import {generateColors, registerPalette} from "devextreme/viz/palette";
import {cool} from "../../../common/palettes";
import {structureGroupBy} from "../../../common/constants";
import chartParameters from "../../../mixins/chart-parameters";
import retailerAverageScoresChartDefinitions from "../../../custom/reporting/retailerAverageScoresChartDefinitions";

export default {
  mixins: [chartParameters],
  props: {...chartParameters.props},
  components: {
    DxArgumentAxis,
    DxChart,
    DxCommonAxisSettings,
    DxCommonSeriesSettings,
    DxExport,
    DxFont,
    DxGrid,
    DxLabel,
    DxLegend,
    DxLoadingIndicator,
    DxSelectBox,
    DxSeriesTemplate,
    DxTick,
    DxTitle,
    DxTooltip,
    DxValueAxis,
    DxSize,
    DxConnector,
    DxScrollBar,
    DxZoomAndPan,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["programmeTypes", "filters"],
      "getFilter",
      "setFilter",
    ),
    chart() {
      return this.$refs[this.chartRef].instance;
    },
    chartTitle() {
      return this.$t("reporting.charts.retailerAverageScoresChartTitle", {
        groupByText: this.groupByText,
      });
    },
    colors() {
      const items = this.dataSource;
      // Ensure distinct values
      const series = [...new Set(items.map((x) => x.series))];
      const palette = generateColors(this.palette.name, series.length);

      return Object.fromEntries(
        series.map((s, i) => [s, palette[i % palette.length]]),
      );
    },
    groupByText() {
      return this.groupByDataSource.find(
        (x) => x.id === this.groupByValue,
        this,
      ).name;
    },
  },
  created() {
    registerPalette(this.palette.name, this.palette.palette);
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      dataSource: [],
      loadCancelToken: null,
      initialRange: {startValue: 0, length: 30},
      chartRef: "averageScoresChart",
      groupByDataSource: Object.entries(structureGroupBy).map(
        ([key, value]) => ({
          name: this.$t(`reporting.charts.groupBy${key}Title`),
          id: value,
        }),
        this,
      ),
      groupByValue: structureGroupBy.ProgrammeType,
      palette: cool,
      stackTranslations: {
        auditScore: this.$t("reporting.charts.auditScoreStackTitle"),
        averageScore: this.$t("reporting.charts.complianceScoreStackTitle"),
        finalScore: this.$t("reporting.charts.finalScoreStackTitle"),
      },
      color: {
        text: "black",
        axis: "#C4C4C4",
        grid: "#e8e8e8",
      },
    };
  },
  methods: {
    ...mapMutations("reportingFilters", ["applyFilters"]),
    onPointClick(e) {
      if (this.groupByValue === structureGroupBy.ProgrammeType) {
        if (!this.programmeTypes.includes(e.target.tag.structure)) {
          this.programmeTypes = [e.target.tag.structure];
          this.applyFilters();
        }
      } else {
        // TODO:
        // Map location/structure
        // if (!this.assessmentStructures.includes(e.target.data.structure)) {
        //   this.assessmentStructures.push(e.target.data.structure);
        // }
      }
    },
    onExporting(e) {
      this.chart.option("title.text", this.chartTitle);
      this.chart.option("title.font.color", "black");
    },
    onExported(e) {
      this.chart.option("title.text", "");
    },
    customizePoint(e) {
      return {
        color: this.colors[e.data.series],
      };
    },
    customizeSeries(seriesName) {
      return {
        color: this.colors[seriesName],
        valueField: "value",
      };
    },
    chartZoomIn() {
      this.chart.option("argumentAxis.visualRange", this.initialRange);
    },
    customizeText(pointInfo) {
      if (this.pointLabelTextOn) {
        return `${pointInfo.seriesName}: ` + pointInfo.value.toFixed(2);
      } else {
        return pointInfo.value.toFixed(2);
      }
    },
    async load() {
      this.chart.showLoadingIndicator();
      try {
        if (this.loadCancelToken) {
          this.loadCancelToken.cancel();
        }

        this.loadCancelToken = CancelToken.source();
        let response = await this.$http.post(
          "/reporting/averageComplianceScores",
          {
            grouping: this.groupByValue,
            filter: this.filters,
          },
          {
            cancelToken: this.loadCancelToken.token,
          },
        );
        this.loadCancelToken = null;
        this.dataSource = response.data.columns
          .reduce((acc, column) => {
            column.finalScore = column.finalScore - column.averageScore;
            column.averageScore = column.averageScore - column.auditScore;
            Object.entries(column).forEach(([key, value]) => {
              let series = this.stackTranslations[key];
              if (series) {
                acc.push({
                  key: key,
                  series: series,
                  group: column.label,
                  value: value,
                  tag: {
                    // TODO: change structure when returned from endpoint
                    structure: column.label,
                  },
                });
              }
            }, this);

            return acc;
          }, [])
          .sort(
            (a, b) =>
              retailerAverageScoresChartDefinitions.find(
                (x) => x.dataField == a.key,
              ).order -
              retailerAverageScoresChartDefinitions.find(
                (x) => x.dataField == b.key,
              ).order,
          );

        this.chart.hideLoadingIndicator();
        this.dataLoaded();
      } catch (err) {
        this.dataSource = [];
      }
    },
  },
  watch: {
    filters() {
      this.load();
    },
    groupByValue() {
      this.load();
    },
  },
};
</script>

<style lang="sass" scoped></style>
