export default Object.freeze([
  {
    dataField: "id",
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: "requirementName",
  },
  {
    dataField: "level",
  },
  {
    dataField: "answer",
    caption: "Result",
  },
  {
    dataField: "commentsOrDeviations",
    caption: "Comments / Deviations",
    cellTemplate: "comments-findings-cell-template",
    allowHeaderFiltering: false,
    dataType: "string",
    calculateFilterExpression: function (
      filterValue,
      selectedFilterOperation,
      target,
    ) {
      if (target === "filterRow") {
        return function (data) {
          for (let i = 0; i < data.commentsOrDeviations.length; i++) {
            const commentsOrDeviation = data.commentsOrDeviations[i].name;
            if (
              commentsOrDeviation
                .toLowerCase()
                .includes(filterValue.toLowerCase())
            )
              return true;
          }
          return false;
        };
      }
    },
  },
  {
    dataField: "rootCause",
    cellTemplate: "root-cause-cell-template",
  },
  {
    dataField: "correctiveActionPlan",
    cellTemplate: "corrective-action-plan-cell-template",
  },
  {
    dataField: "verificationResult",
  },
  {
    dataField: "sectionTitle",
    caption: "Section",
    groupIndex: 0,
    calculateGroupValue: "parentSectionLevel1Ref",
    groupCellTemplate: "groupCellTemplate",
  },
]);
