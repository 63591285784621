<template>
  <div class="chart-container card-container">
    <div class="chart-container-header">
      <h1>
        {{ chartTitle }}
      </h1>
      <div class="chart-group-by-container">
        <dx-select-box
          display-expr="name"
          :items="groupByDataSource"
          styling-mode="filled"
          :value.sync="groupByValue"
          value-expr="id"
        />
      </div>
    </div>
    <dx-chart
      :data-source="dataSource"
      :ref="chartRef"
      @point-click="onPointClick($event)"
      @legend-click="onLegendClick($event)"
      @exporting="onExporting"
      @exported="onExported"
      @done="chartZoomIn"
      :resolve-label-overlapping="pointOverlappingOptions"
      :animation="{
        enabled: false,
      }"
    >
      <dx-size :height="chartHeight" :width="chartWidth" />
      <dx-loading-indicator :enabled="true" />
      <dx-scroll-bar :visible="true" />
      <dx-zoom-and-pan argument-axis="both" />
      <dx-common-axis-settings :color="color.axis">
        <dx-tick :color="color.axis" />
        <dx-label
          :visible="axisOn"
          :displayMode="axisLabelPosition"
          :rotationAngle="axisRotationAngle"
        >
          <dx-font :color="color.text" />
        </dx-label>
      </dx-common-axis-settings>
      <dx-argument-axis :visual-range="initialRange" position="bottom">
        <dx-label overlappingBehavior="stagger" />
      </dx-argument-axis>
      <dx-value-axis position="left" :allow-decimals="false">
        <dx-grid :color="color.grid" />
      </dx-value-axis>
      <dx-common-series-settings
        argument-field="group"
        value-field="value"
        type="stackedbar"
        hover-mode="allArgumentPoints"
        selection-mode="allArgumentPoints"
      >
        <dx-label
          :visible="pointOn"
          :position="pointLabelPosition"
          :customize-text="customizeText"
        >
          <dx-connector :visible="connectorOn" />
        </dx-label>
      </dx-common-series-settings>
      <dx-series-template
        :customize-series="customizeSeries"
        name-field="series"
      />
      <dx-tooltip :enabled="true" content-template="tooltipTemplate" />
      <dx-legend
        orientation="horizontal"
        horizontal-alignment="center"
        vertical-alignment="bottom"
        item-text-position="bottom"
        :visible="legendOn"
      >
        <dx-font :color="color.text" />
      </dx-legend>
      <dx-export :enabled="true" background-color="#ffffff" />
      <template #tooltipTemplate="{data}">
        <span>{{ data.point.data.series }}: {{ data.point.data.value }}</span>
      </template>
    </dx-chart>
  </div>
</template>

<script>
import {
  DxArgumentAxis,
  DxChart,
  DxCommonAxisSettings,
  DxCommonSeriesSettings,
  DxExport,
  DxFont,
  DxGrid,
  DxLabel,
  DxLegend,
  DxLoadingIndicator,
  DxSeriesTemplate,
  DxTick,
  DxTooltip,
  DxValueAxis,
  DxSize,
  DxConnector,
  DxScrollBar,
  DxZoomAndPan,
} from "devextreme-vue/chart";
import DxSelectBox from "devextreme-vue/select-box";
import {CancelToken} from "axios";
import {mapFields} from "vuex-map-fields";
import {mapMutations} from "vuex";
import {locationAndStructureGroupBy} from "../../common/constants";
import assessmentStatusChartSeriesDefinitions from "../../custom/reporting/assessmentStatusChartSeriesDefinitions";
import chartParameters from "../../mixins/chart-parameters";

export default {
  mixins: [chartParameters],
  props: {...chartParameters.props},
  components: {
    DxArgumentAxis,
    DxChart,
    DxCommonAxisSettings,
    DxCommonSeriesSettings,
    DxExport,
    DxFont,
    DxGrid,
    DxLabel,
    DxLegend,
    DxLoadingIndicator,
    DxSelectBox,
    DxSeriesTemplate,
    DxTick,
    DxTooltip,
    DxValueAxis,
    DxSize,
    DxConnector,
    DxScrollBar,
    DxZoomAndPan,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["visitStatuses", "filters"],
      "getFilter",
      "setFilter",
    ),
    chart() {
      return this.$refs[this.chartRef].instance;
    },
    chartTitle() {
      return this.$t("reporting.charts.assessmentStatusChartTitle", {
        groupByText: this.groupByText,
      });
    },
    groupByText() {
      return this.groupByDataSource.find(
        (x) => x.id === this.groupByValue,
        this,
      ).name;
    },
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      dataSource: [],
      loadCancelToken: null,
      initialRange: {startValue: 0, length: 30},
      chartRef: "assessmentStatusChart",
      groupByDataSource: Object.entries(locationAndStructureGroupBy).map(
        ([key, value]) => ({
          name: this.$t(`reporting.charts.groupBy${key}Title`),
          id: value,
        }),
        this,
      ),
      groupByValue: locationAndStructureGroupBy.Site,
      seriesDefinitions: assessmentStatusChartSeriesDefinitions.map(
        (x) => ({...x, caption: this.$t(x.caption)}),
        this,
      ),
      color: {
        text: "black",
        axis: "#C4C4C4",
        grid: "#e8e8e8",
      },
    };
  },
  methods: {
    ...mapMutations("reportingFilters", ["applyFilters"]),
    onLegendClick(e) {
      if (!this.visitStatuses.includes(e.target._data[0].tag.statusCode)) {
        this.visitStatuses = [e.target._data[0].tag.statusCode];
        this.applyFilters();
      }
    },
    onExporting(e) {
      this.chart.option("title.text", this.chartTitle);
      this.chart.option("title.font.color", "black");
    },
    chartZoomIn() {
      this.chart.option("argumentAxis.visualRange", this.initialRange);
    },
    onExported(e) {
      this.chart.option("title.text", "");
    },
    onPointClick(e) {
      if (!this.visitStatuses.includes(e.target.tag.statusCode)) {
        this.visitStatuses = [e.target.tag.statusCode];
        this.applyFilters();
      }
      // TODO:
      // Map location
      // if (!this.locations.includes(e.target.data.location)) {
      //   this.locations.push(e.target.data.location);
      // }
    },
    customizeSeries(seriesName) {
      const series = this.seriesDefinitions.find(
        (x) => x.caption === seriesName,
      );
      return {
        color: series.color,
      };
    },
    customizeText(pointInfo) {
      if (this.pointLabelTextOn) {
        return `${pointInfo.seriesName}: ${pointInfo.value}`;
      } else {
        return pointInfo.value;
      }
    },
    countAssessments(accumulator, group) {
      return Object.entries(group || {}).reduce(
        (groupAssessmentsCount, [statusName, statusAssessmentsCount]) => {
          return this.seriesDefinitions.find((x) => x.dataField === statusName)
            ? groupAssessmentsCount + statusAssessmentsCount
            : groupAssessmentsCount;
        },
        accumulator,
      );
    },
    async load() {
      this.chart.showLoadingIndicator();
      try {
        if (this.loadCancelToken) {
          this.loadCancelToken.cancel();
        }

        this.loadCancelToken = CancelToken.source();
        let response = await this.$http.post(
          "/reporting/assessmentStatusByMarket",
          {
            grouping: this.groupByValue,
            filter: this.filters,
          },
          {
            cancelToken: this.loadCancelToken.token,
          },
        );
        this.loadCancelToken = null;

        if (this.groupByValue == 22 && response.data.columns) {
          response.data.columns.map((x) => {
            if (x.label == null) {
              x.label = '""';
            }
          });
        }

        if (this.groupByValue == 23 && response.data.columns) {
          response.data.columns.map((x) => {
            if (x.label === "1") {
              x.label = this.$t("orders.reviewDetails.supplierPhasedOut");
            } else if (x.label === "2") {
              x.label = this.$t(
                "orders.reviewDetails.otherBizConsequenceApplied",
              );
            } else if (x.label === "3") {
              x.label = this.$t("orders.reviewDetails.noBizConsequenceApplied");
            } else {
              x.label = '""';
            }
          });
        }

        if (this.groupByValue == 24 && response.data.columns) {
          response.data.columns.map((x) => {
            if (x.label === "1") {
              x.label = this.$t("orders.reviewDetails.capProcess");
            } else if (x.label === "3") {
              x.label = this.$t("orders.reviewDetails.capProcessExtensionByReviewer");
            } else if (x.label === "4") {
              x.label = this.$t("orders.reviewDetails.capProcessExtensionByICGorGIF");
            } else if (x.label === "5") {
              x.label = this.$t("orders.reviewDetails.capProcessUnableToVerify");
            } else if (x.label === "6") {
              x.label = this.$t("orders.reviewDetails.capProcessVerified");
            } else if (x.label === "7") {
              x.label = this.$t("orders.reviewDetails.iaNoFollowUpNeeded");
            } else {
              x.label = '""';
            }
          });
        }

        this.dataSource = response.data.columns
          .sort(
            (a, b) => this.countAssessments(0, b) - this.countAssessments(0, a),
          )
          .reduce((acc, column) => {
            Object.entries(column)
              .map(([key, value]) => {
                let series = this.seriesDefinitions.find(
                  (x) => x.dataField === key,
                );
                return {
                  series: series,
                  value: value,
                };
              })
              .filter((x) => x.series)
              .sort((a, b) => a.series.order - b.series.order)
              .forEach((x) => {
                acc.push({
                  series: x.series.caption,
                  group: column.label,
                  value: x.value,
                  tag: {
                    // TODO: label needs to return ID for the location filtering
                    location: column.label,
                    statusCode: x.series.statusCode,
                  },
                });
              });

            return acc;
          }, []);

        this.chart.hideLoadingIndicator();
        this.dataLoaded();
      } catch (err) {
        this.dataSource = [];
      }
    },
  },
  watch: {
    filters() {
      this.load();
    },
    groupByValue() {
      this.load();
    },
  },
};
</script>

<style lang="sass" scoped></style>
