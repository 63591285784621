const findingStatuses = {
  open: "Open",
  submitted: "Submitted",
  closed: "Closed",
};

const reportingFindingStatuses = {
  ...findingStatuses,
  closedLate: "Closed (late)",
  overdue: "Overdue",
};

const assessmentStatuses = {
  notCreated: "NotCreated",
  notStarted: "NotStarted",
  inProgress: "InProgress",
  submitted: "Submitted",
  complete: "Complete",
  cancelled: "Cancelled",
};

const orderStatuses = {
  ordered: "Ordered",
  scheduled: "Scheduled",
  inProgress: "InProgress",
  submitted: "Submitted",
  completed: "Completed",
  cancelled: "Cancelled",
};

export {
  findingStatuses,
  reportingFindingStatuses,
  assessmentStatuses,
  orderStatuses,
};
