<template>
  <div>
    <div v-if="role == 'Admin' || role == 'Reviewer'">
      <div class="row">
        <div class="col-xl-6">
          <component-placeholder
            :component="componentSet[0]"
          ></component-placeholder>
        </div>
        <div class="col-xl-6">
          <div class="row">
            <div class="col-xl-12">
              <component-placeholder
                :component="componentSet[1]"
              ></component-placeholder>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <component-placeholder
                :component="componentSet[2]"
              ></component-placeholder>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="role == 'ReviewParticipant' || role == 'BusinessOwner'">
      <div class="row">
        <div class="col-xl-6">
          <component-placeholder
            :component="componentSet[0]"
          ></component-placeholder>
        </div>
        <div class="col-xl-6">
          <div class="row">
            <div class="col-xl-12">
              <component-placeholder
                :component="componentSet[1]"
              ></component-placeholder>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <component-placeholder
                :component="componentSet[2]"
              ></component-placeholder>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="role == 'Orderer'">
      <div class="row">
        <div class="col-xl-6">
          <component-placeholder
            :component="componentSet[0]"
          ></component-placeholder>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ComponentPlaceholder from "./components/component-placeholder";
import roles from "../../config/roles";
import SelfAssessmentComponent from "./components/retailer/self-assessment-component";
import UpcomingAssessmentComponent from "./components/retailer/upcoming-assessment-component";
import AssessmentOverviewComponent from "./components/retailer/assessment-overview-component";
import FindingsAwaitingApprovalComponent from "./components/auditManager/findings-awaiting-approval-component";
import AssessmentsAwaitingApprovalComponent from "./components/auditManager/assessments-awaiting-approval-component";
import UpcomingAssessmentsListComponent from "./components/upcoming-assessments-list-component";
import AuditCoverageComponent from "./components/audit-coverage-component";

const componentSets = [
  {
    keys: [roles.reviewer.role, roles.admin.role],
    set: [
      AuditCoverageComponent,
      UpcomingAssessmentsListComponent,
      FindingsAwaitingApprovalComponent,
    ],
  },
  {
    keys: [roles.reviewParticipant.role, roles.businessOwner.role],
    set: [
      AssessmentOverviewComponent,
      UpcomingAssessmentComponent,
      SelfAssessmentComponent,
    ],
  },
  {
    keys: ["AuditManager", "RegionalNsc"],
    set: [
      AuditCoverageComponent,
      FindingsAwaitingApprovalComponent,
      AssessmentsAwaitingApprovalComponent,
      UpcomingAssessmentsListComponent,
    ],
  },
  {
    keys: [roles.orderer.role],
    set: [AuditCoverageComponent],
  },
  // {
  //   keys: [roles.admin.role],
  //   set: [AuditCoverageComponent],
  // },
];
export default {
  components: {
    ComponentPlaceholder,
  },
  created() {
    this.componentSet = componentSets.find((x) =>
      x.keys.includes(this.role),
    ).set;
  },
  props: {
    role: null,
  },
  data() {
    return {
      componentSet: [],
      allSets: componentSets,
    };
  },
};
</script>
<style></style>
