import { render, staticRenderFns } from "./assessment-scores-chart.vue?vue&type=template&id=1a0d4d5a&scoped=true&"
import script from "./assessment-scores-chart.vue?vue&type=script&lang=js&"
export * from "./assessment-scores-chart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a0d4d5a",
  null
  
)

export default component.exports