const Breakpoints = {
  XSmall: "(max-width: 575.99px)",
  Small: "(min-width: 576px) and (max-width: 767.99px)",
  Medium: "(min-width: 768px) and (max-width: 991.99px)",
  Large: "(min-width: 992px) and (max-width: 1199.99px)",
  XLarge: "(min-width: 1200px)"
};

let handlers = [];
const xSmallMedia = window.matchMedia(Breakpoints.XSmall);
const smallMedia = window.matchMedia(Breakpoints.Small);
const mediumMedia = window.matchMedia(Breakpoints.Medium);
const largeMedia = window.matchMedia(Breakpoints.Large);
const xLargeMedia = window.matchMedia(Breakpoints.XLarge);

[xSmallMedia, smallMedia, mediumMedia, largeMedia, xLargeMedia].forEach(media => {
  media.addListener(() => {
    handlers.forEach(handler => handler());
  });
});

export const sizes = () => {
  return {
    "screen-x-small": xSmallMedia.matches,
    "screen-small": smallMedia.matches,
    "screen-medium": mediumMedia.matches,
    "screen-large": largeMedia.matches,
    "screen-x-large": xLargeMedia.matches
  };
};

export const subscribe = handler => handlers.push(handler);

export const unsubscribe = handler => {
  handlers = handlers.filter(item => item !== handler);
};
