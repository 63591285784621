<template>
  <div>
    <!--
      <DxList :items="question.answerOptions"
                :show-selection-controls="true"
                :value="checked"
                styling-mode="outlined"
                v-model="selectedItems"
                selection-mode="all"
                @value-changed="$emit('answerGiven', selectedItems)"/>
    -->
    <DxTagBox
      :data-source="answerOptions"
      display-expr="name"
      value-expr="val"
      styling-mode="outlined"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      v-model="selected"
      placeholder="Select..."
      :readOnly="disabled"
      @value-changed="setSelectedAnswer($event)"
    />
  </div>
</template>

<script>
//import DxList from "devextreme-vue/list";
import DxTagBox from "devextreme-vue/tag-box";
import {isNotEmpty} from "../../common/helperFunctions";
export default {
  components: {
    //DxList,
    DxTagBox,
  },
  props: {
    answerOptions: {
      Type: Array,
    },
    questionId: {
      Type: Number,
    },
    preselectedValue: {
      Type: String,
      default: undefined,
    },
    question: {
      Type: Object,
    },
    disabled: {
      Type: Boolean,
    },
  },
  created() {
    if (this.preselectedValue !== undefined) {
      this.selected = this.preselectedValue.split(",").map(Number);
    }
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    setSelectedAnswer(e) {
      if (isNotEmpty(e.value)) {
        e.value = e.value.toString();
        this.$emit("set-answer", e);
      }
    },
  },
  watch: {
    // whenever question changes, this function will run
    question: function (newValue) {
      if (newValue.answer) {
        this.selected = newValue.answer.value;
        this.selected = this.selected.split(",");
      } else {
        this.selected = null;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
