<template>
  <div class="report-filter-pane-item-filter" :ref="scopeOfReviewContainerRef">
    <DxDropDownBox
      :value.sync="assessmentStructures"
      :defer-rendering="false"
      :show-clear-button="true"
      resize-enabled="false"
      :data-source="reviewStructureDataSource"
      display-expr="name"
      value-expr="id"
      stylingMode="outlined"
      @opened="onScopeOfReviewDropDownOpened"
      :ref="scopeOfReviewDropDownRef"
      :placeholder="$t('reporting.filters.assessmentStructurePlaceholder')"
    >
      <!-- TODO: decide width value/strategy  -->
      <template #content>
        <DxDataGrid
          :data-source="reviewStructureDataSource"
          :columns="scopeOfReviewColumns"
          :hover-state-enabled="true"
          :column-auto-width="true"
          :selected-row-keys.sync="assessmentStructures"
          height="100%"
        >
          <DxSelection mode="multiple" />
          <DxPaging :enabled="true" :page-size="10" />
          <DxFilterRow :visible="true" />
          <DxScrolling mode="infinite" />
        </DxDataGrid>
      </template>
    </DxDropDownBox>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {DxDropDownBox} from "devextreme-vue/drop-down-box";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import $axios from "../../utils/axios-instance";
import {assessmentStructureType} from "../../common/constants";

import {
  DxDataGrid,
  DxSelection,
  DxPaging,
  DxFilterRow,
  DxScrolling,
} from "devextreme-vue/data-grid";
export default {
  components: {
    DxDropDownBox,
    DxDataGrid,
    DxPaging,
    DxSelection,
    DxFilterRow,
    DxScrolling,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["assessmentStructures"],
      "getFilter",
      "setFilter",
    ),
  },
  data() {
    return {
      scopeOfReviewContainerRef: "container",
      reviewStructureDataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.loadReviewStructure,
          loadMode: "raw",
        }),
      }),

      scopeOfReviewColumns: [
        {
          dataField: "reviewType",
          caption: "Review Type",
          width: "auto",
        },
        {
          dataField: "name",
          caption: "Scope of review",
          width: "auto",
        },
      ],

      scopeOfReviewDropDownRef: "scopeOfReview",
    };
  },
  methods: {
    async loadReviewStructure() {
      const response = await $axios.get(
        `/assessments/structure?type=${assessmentStructureType.Category}`,
      );
      const json = await JSON.parse(JSON.stringify(response));
      return json.data;
    },
    onScopeOfReviewDropDownOpened() {
      let screenWidth = window.innerWidth;
      let dropDownWidth =
        screenWidth < 576
          ? this.componentContainer(this.scopeOfReviewContainerRef).clientWidth
          : 700;
      this.dropdown(this.scopeOfReviewDropDownRef).option(
        "dropDownOptions.width",
        dropDownWidth,
      );
    },
    dropdown(dropdownRef) {
      return this.$refs[dropdownRef].instance;
    },
  },
};
</script>

<style lang="sass" scoped></style>
