<template>
  <div>
    <page-heading :pageTitle="pageTitle" :breadCrumbs="breadCrumbs" />

    <div class="card-container" style="margin-bottom: 90px">
      <div class="row">
        <div class="col-xl-12">
          <h2>
            <i class="fas fa-cookie"></i>
            Cookie Policy
          </h2>
          <h3 class="mt-5">Introduction</h3>
          <p>
            We've designed this information page to provide our application
            visitors with accessible, transparent information about the cookies
            we use.
          </p>
          <p>
            This information is relevant for visitors to the
            <b>IKEA iReview</b>. For more information about how we process your
            personal data collected through the app, please refer to our privacy
            statement.
          </p>

          <h3 class="mt-5">Changes to this Cookie Policy</h3>
          <p>
            We recognise that transparency is an ongoing responsibility so we
            will keep this Cookie Policy under regular review.
          </p>
          <p>
            This cookies policy was last updated on [<span>{{
              new Date().toJSON().slice(0, 10).split("-").reverse().join("/")
            }}</span
            >].
          </p>

          <h3 class="mt-5">What are cookies?</h3>

          <p>
            Cookies are small text files that are placed on your computer by the
            applications that you visit. They are used in order to make
            applications work, or work more efficiently, as well as to provide
            statistical information to site owners. Some cookies also enable the
            display of relevant advertising when you move from site to site.
          </p>

          <p>
            <b>Necessary Cookies</b> <br />
            We only use strictly necessary cookies on our application. These
            cookies are necessary for our application to operate. Our
            application cannot function without these cookies so they are always
            set on.
          </p>

          <p>
            You can manage and control cookies through your browser (a good
            search term is ‘managing cookies’). Your choices will include
            removing cookies by deleting them from your ‘browser history’ when
            you leave our site. If you do switch cookies off for our site it may
            not work as well as you would expect it to.
          </p>

          <p>
            The tables below provide details about the actual cookies we use of
            each type. We’ve included information on the duration of each cookie
            and their purpose.
          </p>

          <h3 class="mt-5">Section A: Strictly necessary cookies</h3>

          <div style="width: 100%; overflow: auto">
            <table class="table">
              <tr>
                <th>Cookie Name</th>
                <th>First or third party Cookie?</th>
                <th>Cookie Duration</th>
                <th>Cookie Description</th>
                <th>Information shared with</th>
              </tr>
              <tr>
                <td>ARRAffinity</td>
                <td>First party, Session</td>
                <td>Until end of the session</td>
                <td>
                  Affinity Cookies are used to keep users connected to web apps
                  hosted in Azure.
                </td>
                <td>PwC</td>
              </tr>
              <tr>
                <td>ARRAffinitySameSite</td>
                <td>First party, Persistent</td>
                <td>Until end of the session</td>
                <td>
                  Affinity Cookies are used to keep users connected to web apps
                  hosted in Azure.
                </td>
                <td>PwC</td>
              </tr>
              <tr>
                <td>visid_incap_*</td>
                <td>First party</td>
                <td>1 year</td>
                <td>
                  This cookie is from the incapsula CDN and helps us with
                  reliability, security and the performance of our site.
                </td>
                <td>PwC</td>
              </tr>
              <tr>
                <td>incap_ses_*</td>
                <td>First Party</td>
                <td>Until end of the session</td>
                <td>
                  This cookie is set to allow a visitor to receive site content
                  from one out of multiple servers as the visitor browses the
                  site. This allows the visitor’s session to be maintained.
                </td>
                <td>PwC</td>
              </tr>
              <tr>
                <td>nlbi_*</td>
                <td>First Party</td>
                <td>Until end of the session</td>
                <td>
                  Incapsula DDoS Protection and Web Application Firewall: Load
                  balancing cookie. To ensure requests by a client are sent to
                  the same origin server.
                </td>
                <td>PwC</td>
              </tr>
            </table>
          </div>

          <h3 class="mt-5">Managing cookies on your device</h3>
          <p>
            We use cookies to personalize content and to provide you with an
            improved user experience. You can control and manage cookies using
            your browser (see below). Please note that removing or blocking
            cookies can impact your user experience and some functionality may
            no longer be available.
          </p>

          <h3 class="mt-5">Using your browser to control cookies</h3>
          <p>
            Most browsers allow you to view, manage, delete and block cookies
            for a website. Be aware that if you delete all cookies then any
            preferences you have set will be lost, including the ability to
            opt-out from cookies as this function itself requires placement of
            an opt out cookie on your device. Guidance on how to control cookies
            for common browsers is linked below.
          </p>

          <p>
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
              >Google Chrome</a
            ><br />
            <a
              href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
              >Mozilla Firefox</a
            ><br />
            <a href="https://support.apple.com/en-us/HT201265">MacOS Safari</a
            ><br />
            <a
              href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              >Microsoft Internet Explorer</a
            >
          </p>

          <p>
            For information on additional browsers and device types please see
            <a href="http://www.aboutcookies.org/"
              >http://www.aboutcookies.org/</a
            >
            or
            <a href="http://www.cookiecentral.com/faq/"
              >http://www.cookiecentral.com/faq/</a
            >.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";
import $axios from "../../utils/axios-instance";
import pageHeading from "../../components/page-heading";
import {isNotEmpty, toCamelCase} from "../../common/helperFunctions";

export default {
  components: {
    pageHeading,
  },

  computed: {
    pageTitle() {
      return this.$t("legalNotices.cookies");
    },
    breadCrumbs() {
      return [this.$t("legalNotices.title"), this.pageTitle];
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  max-width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  overflow: auto;
  tr {
  }

  th,
  td {
    padding: 10px 20px;
    text-align: left;
    border: 0;
    min-width: 210px;
  }
}
</style>
