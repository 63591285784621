export default Object.freeze([
  {
    dataField: "closed",
    caption: "reporting.charts.findingStatusClosedStackTitle",
    color: "#00a700",
    order: 3,
  },
  // {
  //   dataField: "closedLate",
  //   caption: "reporting.charts.findingStatusClosedLateStackTitle",
  //   color: "#f5a623",
  //   order: 5,
  // },
  {
    dataField: "open",
    caption: "reporting.charts.findingStatusOpenStackTitle",
    color: "#c4c4c4",
    order: 1,
  },
  // {
  //   dataField: "overdue",
  //   caption: "reporting.charts.findingStatusOverdueStackTitle",
  //   color: "#ca1a1a",
  //   order: 2,
  // },
  {
    dataField: "submitted",
    caption: "reporting.charts.findingStatusSubmittedStackTitle",
    color: "#ff7310",
    order: 2,
  },
]);
