<template>
  <div>
    <page-heading :pageTitle="pageTitle" :breadCrumbs="breadCrumbs" />

    <div class="card-container" style="margin-bottom: 90px">
      <div class="row">
        <div class="col-xl-12">
          <h2>
            <i class="far fa-copyright"></i>
            Copyrights
          </h2>
          <p>
            <span>{{ new Date().getFullYear() }}</span> PricewaterhouseCoopers
            LLP. All rights reserved. In this document, "PwC" refers to
            PricewaterhouseCoopers LLP (a limited liability partnership in the
            United Kingdom), which is a member firm of PricewaterhouseCoopers
            International Limited, each member firm of which is a separate legal
            entity.
          </p>
          <hr />
        </div>
        <div class="col-xl-12">
          <h2>
            <i class="fas fa-user-shield"></i>
            Privacy statement
          </h2>
          <p>
            PwC UK is providing IKEA with iReview. For more information
            regarding the use of personal data please use the following link:
            <a
              style="cursor: pointer"
              href="https://www.ikea.com/gb/en/customer-service/privacy-policy/"
              target="_blank"
              >IKEA Privacy Statement</a
            >.
          </p>
          <hr />
        </div>
        <div class="col-xl-12">
          <h2>
            <i class="fas fa-balance-scale-left"></i>
            Legal disclaimer
          </h2>
          <p>
            The information contained in this Site is intended solely to provide
            general guidance on matters of interest for the personal use of the
            reader, who accepts full responsibility for its use. The application
            and impact of laws can vary widely based on the specific facts
            involved. Given the changing nature of laws, rules and regulations,
            and the inherent hazards of electronic communication, there may be
            delays, omissions or inaccuracies in information contained in this
            Site. Accordingly, the information on this Site is provided with the
            understanding that the authors and publishers are not herein engaged
            in rendering legal, accounting, tax, or other professional advice or
            services. As such, it should not be used as a substitute for
            consultation with professional accounting, tax, legal or other
            competent advisers. Before making any decision or taking any action,
            you should consult a PricewaterhouseCoopers professional. While we
            have made every attempt to ensure that the information contained in
            this Site has been obtained from reliable sources,
            PricewaterhouseCoopers LLP is not responsible for any errors or
            omissions, or for the results obtained from the use of this
            information. All information in this Site is provided "as is", with
            no guarantee of completeness, accuracy, timeliness or of the results
            obtained from the use of this information, and without warranty of
            any kind, express or implied, including, but not limited to
            warranties of performance, merchantability and fitness for a
            particular purpose. Nothing herein shall to any extent substitute
            for the independent investigations and the sound technical and
            business judgment of the reader. In no event will
            PricewaterhouseCoopers LLP, or its partners, employees or agents, be
            liable to you or anyone else for any decision made or action taken
            in reliance on the information in this Site or for any
            consequential, special or similar damages, even if advised of the
            possibility of such damages. Certain links in this Site connect to
            other Web Sites maintained by third parties over whom
            PricewaterhouseCoopers LLP has no control. PricewaterhouseCoopers
            LLP makes no representations as to the accuracy or any other aspect
            of information contained in other Web Sites.
          </p>
          <p>
            PricewaterhouseCoopers LLP is a limited liability partnership
            registered in England with registered number OC303525. The
            registered office of PricewaterhouseCoopers LLP is 1 Embankment
            Place, London WC2N 6RH, United Kingdom. Unless otherwise indicated
            either expressly or by the context, we use the word "partner" to
            describe a member of PricewaterhouseCoopers LLP in their capacity as
            such.
          </p>
          <hr />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <h2>
            <i class="far fa-file-alt mr-w"></i>
            Legal documents
          </h2>
          <div class="legal-notice mb-2">
            <a @click="openCookies"
              ><i class="far fa-file-alt mr-2"></i> Cookie Policy</a
            >
          </div>
          <div class="legal-notice mb-2">
            <a
              href="https://www.ikea.com/gb/en/customer-service/privacy-policy/"
              target="_blank"
              ><i class="far fa-file-alt mr-2"></i> IKEA Privacy Policy</a
            >
          </div>
          <div class="legal-notice mb-2">
            <a @click="openTermsOfUse"
              ><i class="far fa-file-alt mr-2"></i> Terms of Use</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";
import $axios from "../../utils/axios-instance";
import pageHeading from "../../components/page-heading";
import {isNotEmpty, toCamelCase} from "../../common/helperFunctions";

export default {
  components: {
    pageHeading,
  },
  computed: {
    pageTitle() {
      return this.$t("legalNotices.title");
    },
    breadCrumbs() {
      return [this.$t("legalNotices.subTitle")];
    },
  },
  methods: {
    openCookies() {
      this.$router.push({name: "cookies"});
    },
    openTermsOfUse() {
      this.$router.push({name: "terms-of-use", query: {review: "true"}});
    },
  },
};
</script>

<style lang="scss" scoped></style>
