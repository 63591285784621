<template>
  <div style="margin-bottom: 70px">
    <page-heading :pageTitle="translatedPageTitle" :breadCrumbs="breadCrumbs" />
    <div class="row">
      <div class="col-xl-6">
        <div class="card-container">
          <dx-scroll-view
            :scroll-by-content="true"
            :scroll-by-thumb="true"
            show-scrollbar="true"
          >
            <h2>
              <i class="far fa-user"></i>
              {{ $t("users.userDetails.formTitle") }}
              <span class="sso-indication">{{
                isBasicUser ? "(NON-SSO)" : "(SSO)"
              }}</span>
            </h2>

            <dx-form
              :form-data="user"
              :ref="formRefKey"
              :show-validation-summary="true"
              :validation-group="formValidationGroup"
              :scrolling-enabled="true"
              :col-count="1"
              @field-data-changed="fieldDataChangedHandler"
            >
              <dx-item item-type="group" :col-count="1" :col-span="1">
                <dx-simple-item
                  data-field="email"
                  :editorOptions="{
                    stylingMode: 'outlined',
                    disabled: !this.isBasicUser,
                  }"
                >
                  <dx-label
                    class="bold-label"
                    :text="$t('users.userDetails.emailLabel')"
                  />
                  <dx-required-rule
                    :message="$t('users.userDetails.emailRequired')"
                  />
                  <dx-email-rule
                    :message="$t('users.userDetails.emailInvalid')"
                  />
                  <dx-string-length-rule
                    :max="maxLength.email"
                    :message="
                      $t('users.userDetails.maxLengthError', {
                        dataField: $t('users.userDetails.emailLabel'),
                        maxLength: maxLength.email,
                      })
                    "
                  />
                </dx-simple-item>
                <dx-simple-item
                  data-field="firstName"
                  :editorOptions="nameOptions"
                >
                  <dx-label :text="$t('users.userDetails.firstNameLabel')" />
                  <dx-required-rule
                    :message="
                      $t('users.userDetails.isRequired', {
                        dataField: $t('users.userDetails.firstNameLabel'),
                      })
                    "
                  />
                  <dx-string-length-rule
                    :max="maxLength.name"
                    :message="
                      $t('users.userDetails.maxLengthError', {
                        dataField: $t('users.userDetails.firstNameLabel'),
                        maxLength: maxLength.name,
                      })
                    "
                  />
                </dx-simple-item>
                <dx-simple-item
                  data-field="lastName"
                  :editorOptions="nameOptions"
                >
                  <dx-label :text="$t('users.userDetails.lastNameLabel')" />
                  <dx-required-rule
                    :message="$t('users.userDetails.lastNameRequired')"
                  />
                  <dx-string-length-rule
                    :max="maxLength.name"
                    :message="
                      $t('users.userDetails.maxLengthError', {
                        dataField: $t('users.userDetails.lastNameLabel'),
                        maxLength: maxLength.name,
                      })
                    "
                  />
                </dx-simple-item>

                <dx-simple-item
                  :editor-options="{
                    dataSource: userRoles,
                    displayExpr: 'name',
                    valueExpr: 'value',
                    stylingMode: 'outlined',
                    applyValueMode: 'instantly',
                    placeholder: '',
                  }"
                  data-field="role"
                  editor-type="dxSelectBox"
                >
                  <dx-label :text="$t('users.userDetails.roleLabel')" />
                  <dx-required-rule
                    :message="
                      $t('users.userDetails.isRequired', {
                        dataField: $t('users.userDetails.roleLabel'),
                      })
                    "
                  />
                </dx-simple-item>

                <dx-simple-item
                  :editor-options="{
                    dataSource: jobTitlesAvailableOptions,
                    displayExpr: 'name',
                    valueExpr: 'id',
                    stylingMode: 'outlined',
                    applyValueMode: 'instantly',
                    disabled: jobTitleDisabled,
                    placeholder: '',
                  }"
                  ref="jobTitleRef"
                  :validation-group="formValidationGroup"
                  data-field="jobTitleId"
                  editor-type="dxSelectBox"
                >
                  <dx-label :text="$t('users.userDetails.jobTitleLabel')" />

                  <dx-custom-rule
                    :validation-callback="validateJobTitle"
                    :message="
                      $t('users.userDetails.isRequired', {
                        dataField: $t('users.userDetails.jobTitleLabel'),
                      })
                    "
                    :reevaluate="true"
                  />
                </dx-simple-item>

                <dx-simple-item>
                  <dx-label :text="$t('users.userDetails.locationLabel')" />
                  <template #default :ref="siteContainerRef">
                    <dx-drop-down-box
                      :value.sync="locations"
                      :defer-rendering="false"
                      :show-clear-button="true"
                      resize-enabled="false"
                      :data-source="siteDataSource"
                      display-expr="siteName"
                      stylingMode="outlined"
                      value-expr="id"
                      :placeholder="locationPlaceholder"
                      :ref="siteDropDownRef"
                      @opened="onSiteDropDownOpened"
                      :disabled="locationDisabled"
                    >
                      <template #content>
                        <dx-data-grid
                          :data-source="siteDataSource"
                          :columns="siteColumns"
                          :hover-state-enabled="true"
                          :column-auto-width="true"
                          :selected-row-keys.sync="locations"
                          height="100%"
                        >
                          <dx-selection
                            mode="multiple"
                            :show-check-boxes-mode="checkBoxMode"
                          />
                          <dx-paging :enabled="true" :page-size="10" />
                          <dx-filter-row :visible="true" />
                          <dx-scrolling mode="infinite" />

                          <template #country-template="{data}">
                            <span>
                              {{ getCountryName(data.data.country) }}
                            </span>
                          </template>
                        </dx-data-grid>
                      </template>
                      <dx-validator :validation-group="formValidationGroup">
                        <dx-custom-rule
                          :validation-callback="validateLocations"
                          :message="
                            $t('users.userDetails.isRequired', {
                              dataField: $t('users.userDetails.locationLabel'),
                            })
                          "
                          :reevaluate="true"
                        />
                      </dx-validator>
                    </dx-drop-down-box>
                  </template>
                </dx-simple-item>

                <dx-simple-item
                  data-field="validTo"
                  editor-type="dxDateBox"
                  :editorOptions="{
                    dateSerializationFormat: 'yyyy-MM-dd',
                    min: Date.now(),
                    dateOutOfRangeMessage: $t(
                      'users.userDetails.cannotBeInThePastError',
                      {dataField: $t('users.userDetails.validToDateLabel')},
                    ),
                    stylingMode: 'outlined',
                  }"
                >
                  <dx-label :text="$t('users.userDetails.validToDateLabel')" />
                  <dx-required-rule
                    :message="
                      $t('users.userDetails.isRequired', {
                        dataField: $t('users.userDetails.validToDateLabel'),
                      })
                    "
                  />
                </dx-simple-item>

                <dx-simple-item
                  :editor-options="{
                    dataSource: activeStatuses,
                    displayExpr: 'name',
                    valueExpr: 'id',
                    stylingMode: 'outlined',
                    applyValueMode: 'instantly',
                    value: selectedActiveState,
                    disabled: !isEditPage(),
                  }"
                  data-field="activeStatus"
                  editor-type="dxSelectBox"
                >
                  <dx-label :text="$t('users.userDetails.activeStatus')" />

                  <dx-required-rule
                    :message="
                      $t('users.userDetails.isRequired', {
                        dataField: $t('users.userDetails.activeStatus'),
                      })
                    "
                  />
                </dx-simple-item>

                <dx-simple-item :visible="true" template="formbuttons" />
              </dx-item>
              <template #formbuttons>
                <div class="mt-3">
                  <dx-button
                    :text="$t('users.userDetails.back')"
                    type="normal"
                    styling-mode="contained"
                    :on-click="exit"
                    class="btn btn-large mr-3"
                    icon="undo"
                  ></dx-button>
                  <dx-button
                    :text="$t('users.userDetails.save')"
                    type="default"
                    styling-mode="contained"
                    :on-click="saveUser"
                    validation-group="userDetails"
                    class="btn btn-large"
                    icon="check"
                  ></dx-button>
                </div>
              </template>
            </dx-form>
            <dx-tooltip
              showEvent="mouseenter"
              hideEvent="mouseleave"
              position="right"
              target="label[class*='roleTooltip']"
            >
              <div style="white-space: break-spaces; text-align: left">
                {{ $t("users.userDetails.roleToolTip") }}
              </div>
            </dx-tooltip>
            <dx-tooltip
              showEvent="mouseenter"
              hideEvent="mouseleave"
              position="right"
              target="label[class*='jobTitleTooltip']"
            >
              <div style="white-space: break-spaces; text-align: left">
                {{ $t("users.userDetails.jobTitleToolTip") }}
              </div>
            </dx-tooltip>
          </dx-scroll-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import DxForm, {
  DxItem,
  DxSimpleItem,
  DxLabel,
  DxRequiredRule,
  DxStringLengthRule,
  DxEmailRule,
  DxCustomRule,
} from "devextreme-vue/form";
import {DxScrollView} from "devextreme-vue/scroll-view";
import {DxButton} from "devextreme-vue/button";
import {DxDropDownBox, DxValidator, DxTooltip} from "devextreme-vue";

import {isNotEmpty} from "../common/helperFunctions";
import navigator from "../mixins/navigator";
import {userActiveState} from "../common/constants";
import notify from "devextreme/ui/notify";
import {fromResponse} from "../utils/error-message-extractor";
import PageHeading from "../components/page-heading";
import roles from "../config/roles";
import {toCamelCase} from "../common/helperFunctions";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {
  DxDataGrid,
  DxSelection,
  DxPaging,
  DxFilterRow,
  DxScrolling,
} from "devextreme-vue/data-grid";
import $axios from "../utils/axios-instance";
import {confirm} from "devextreme/ui/dialog";
import orgCountries from "../config/organisationCountries";

export default {
  components: {
    DxSimpleItem,
    DxItem,
    DxLabel,
    DxRequiredRule,
    DxEmailRule,
    DxStringLengthRule,
    DxForm,
    DxButton,
    DxScrollView,
    PageHeading,
    DxCustomRule,
    DxDropDownBox,
    DxValidator,
    DxDataGrid,
    DxSelection,
    DxPaging,
    DxFilterRow,
    DxScrolling,
    DxTooltip,
  },
  async beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (
      !(this.$router.history.pending.name === "login-form") &&
      (await this.confirmStayInDirtyForm())
    ) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  created() {
    this.loadJobTitlesData();
  },
  mixins: [navigator],

  data() {
    return {
      locations: null,
      formValidationGroup: "userDetails",
      locationDisabled: true,
      user: {
        thirdParties: [],
        jobTitleId: 0,
      },
      siteColumns: [
        {
          dataField: "supplierName",
          caption: "Supplier name",
          width: "33%",
          sortOrder: "asc",
        },
        {
          dataField: "siteName",
          caption: "Site/Store",
          width: "33%",
        },
        {
          dataField: "country",
          caption: "Country",
          width: "33%",
          cellTemplate: "country-template",
          calculateFilterExpression: function (
            filterValue,
            selectedFilterOperation,
            target,
          ) {
            if (target === "filterRow") {
              return function (data) {
                const country = orgCountries.find(
                  (x) => x.value === data.country,
                )?.name;
                return (
                  country &&
                  country.toLowerCase().includes(filterValue.toLowerCase())
                );
              };
            }
          },
        },
      ],
      siteDataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.loadSites,
          loadMode: "raw",
        }),
      }),
      locationBorderStyle: {},
      roles: roles,
      userActiveState: userActiveState,
      selectedActiveState: userActiveState.Active,
      formRefKey: "formUserDetails",
      maxLength: {
        name: 50,
        email: 254,
      },
      activeStatuses: [
        {
          id: userActiveState.Active,
          name: this.$t("users.usersList.active"),
        },
        {
          id: userActiveState.Disabled,
          name: this.$t("users.usersList.disabled"),
        },
      ],
      startDate: new Date(),
      isUserLoaded: false,
      isLocationDropdownOpen: false,
      jobTitleDisabled: true,
      sites: [],
      locationPlaceholder: "Select a role",
      initialValues: null,
      isRedirectingAfterUpdateSuccess: false,
      jobTitlesData: [],
      jobTitlesAvailableOptions: [],
      checkBoxMode: "always",
      siteDropDownRef: "siteDropDown",
    };
  },
  mounted() {
    this.loadUser();
    document
      .querySelector("label[for*='role']")
      .classList.add("tooltip", "roleTooltip");
    document
      .querySelector("label[for*='jobTitleId']")
      .classList.add("tooltip", "jobTitleTooltip");
  },
  computed: {
    ...mapState("auth", {currentUser: "user"}),
    nameOptions: function () {
      return {
        disabled: this.isAppUserReadOnly || !this.isBasicUser,
        stylingMode: "outlined",
      };
    },
    translatedPageTitle() {
      if (this.isEditPage()) {
        return this.$t("users.userDetails.titleEdit");
      } else {
        return this.$t("users.userDetails.titleCreate");
      }
    },
    breadCrumbs() {
      return [this.$t("users.usersList.title"), this.translatedPageTitle];
    },
    userRoles() {
      let availableRoles = [];
      if (!this.currentUser) return availableRoles;
      switch (this.currentUser.roles[0]) {
        case roles.admin.role:
          availableRoles = [
            roles.admin.role,
            roles.orderer.role,
            roles.reviewer.role,
            roles.reviewParticipant.role,
            roles.businessOwner.role,
          ];
          break;
        case roles.orderer.role:
        case roles.reviewer.role:
        case roles.businessOwner.role:
          availableRoles = [
            roles.reviewParticipant.role,
            roles.businessOwner.role,
          ];
          break;
        case roles.reviewParticipant.role:
          availableRoles = [roles.reviewParticipant.role];
          break;
        default:
          break;
      }
      return availableRoles.map((x) => ({
        name: this.$t(`users.roles.${toCamelCase(x)}`),
        value: x,
      }));
    },
    getinitialState() {
      return {
        email: this.initialValues.email,
        firstName: this.initialValues.firstName,
        lastName: this.initialValues.lastName,
        role: this.initialValues.role,
        validTo: this.initialValues.validTo.slice(0, 10),
        activeStatus: this.initialValues.activeStatus,
        jobTitleId: this.initialValues.jobTitleId,
      };
    },
    getCurrentState() {
      return {
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        role: this.user.role,
        validTo: this.user.validTo.slice(0, 10),
        activeStatus: this.user.activeStatus,
        jobTitleId: this.user.jobTitleId,
      };
    },
  },
  beforeDestroy() {
    this.locations = [];
  },
  methods: {
    onSiteDropDownOpened() {
      let screenWidth = window.innerWidth;
      let dropDownWidth =
        screenWidth < 576
          ? this.componentContainer(this.siteContainerRef).clientWidth
          : 700;
      this.dropdown(this.siteDropDownRef).option(
        "dropDownOptions.width",
        dropDownWidth,
      );
    },
    dropdown(dropdownRef) {
      return this.$refs[dropdownRef].instance;
    },
    async loadSites(loadOptions) {
      let params = "?";
      [
        "skip",
        "take",
        "sort",
        "requireTotalCount",
        "requireGroupCount",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });
      params = params.slice(0, -1);
      const response = await $axios.get(
        `/Organisations/GetOrganisationsByType/site?${params}`,
      );
      const json = await JSON.parse(JSON.stringify(response));
      return json.data.data;
    },
    fieldDataChangedHandler(e) {
      if (e.dataField === "startDate" && e.value) {
        this.startDate = e.value;
      }
      if (e.dataField === "role" && isNotEmpty(e.value)) {
        let isGlobal = [
          roles.admin.role,
          roles.orderer.role,
          roles.reviewer.role,
        ].includes(e.value);
        if (isGlobal) {
          this.locations = [];
        }
        this.locationDisabled = isGlobal;
        this.locationPlaceholder = isGlobal ? "Global" : "Select sites";

        if (
          e.value === roles.reviewParticipant.role ||
          e.value === roles.businessOwner.role
        ) {
          this.jobTitleDisabled = false;
          this.jobTitlesAvailableOptions = this.getAvailableJobTitles(e.value);
        } else {
          this.user.jobTitleId = 0;
          this.$refs[this.formRefKey].instance.updateData("jobTitleId", 0); // Updates the select box as doesn't sync in edit mode.
          this.jobTitleDisabled = true;
        }
        this.$refs[this.formRefKey].instance.validate();
      }
      if (e.dataField === "accessLocations" && isNotEmpty(e.value)) {
        this.locations = e.value;
      }
    },
    async getLocations() {
      var resp = await this.$http.get("Organisations/GetOrganisationsFlatTree");
      this.sites = resp.data;
    },
    validateJobTitle(e) {
      if (
        (e.value === 0 || e.value === null) &&
        (this.user.role === roles.reviewParticipant.role ||
          this.user.role === roles.businessOwner.role)
      )
        return false;
      return true;
    },
    validateLocations(e) {
      if (
        (e.value === null || e.value.length === 0) &&
        (this.user.role === roles.reviewParticipant.role ||
          this.user.role === roles.businessOwner.role)
      )
        return false;
      return true;
    },
    exit() {
      this.backOrPush("users-list");
    },
    isBasicUser() {
      return this.user.loginType == 1 ? true : false;
    },
    isEditPage() {
      return isNotEmpty(this.$route.params.id);
    },
    async loadUser() {
      await this.getLocations();
      if (this.isEditPage() && !this.isUserLoaded) {
        const response = await this.$http.get(
          `/users/${this.$route.params.id}`,
        );

        this.selectedActiveState = response.data.activeStatus;
        let accessMap = [
          roles.admin.role,
          roles.orderer.role,
          roles.reviewer.role,
        ].includes(response.data.role)
          ? []
          : response.data.accessLocations.map((x) => x.id);
        this.user = {
          ...response.data,
          accessLocations: accessMap,
        };
        this.initialValues = Object.assign({}, this.user);
        this.isUserLoaded = true;
      } else {
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var c = new Date(year + 5, month, day);
        this.$refs[this.formRefKey].instance.updateData("validTo", c);
      }
    },
    // TODO: Upgrade devextreme so we don't have to provide our own implementation
    // (getSelectedNodes introduced in v20.2, current version 19.2)
    getSelectedNodes(accumulator, node) {
      if (node.selected) {
        accumulator.push(node.itemData);
      }
      if (node.children) {
        node.children.reduce(this.getSelectedNodes, accumulator);
      }

      return accumulator;
    },
    onLocationClosed() {
      this.isLocationDropdownOpen = false;
    },
    onLocationOpened() {
      this.isLocationDropdownOpen = true;
    },
    async saveUser(e) {
      let result = e.validationGroup.validate();
      if (result.isValid) {
        // todo: to be fixed with Location dropdown
        this.user.accessLocations = this.locations;
        if (!this.isEditPage()) {
          this.$http
            .post(`users`, this.user)
            .then(() => {
              notify(this.$t("users.userDetails.saveSuccessful"), "success");
              this.exit();
            })
            .catch((err) => {
              notify(
                `${this.$t(
                  "users.userDetails.saveUnsuccessful",
                )}. ${fromResponse(err)}`,
                "error",
                15000,
              );
            });
        } else {
          this.user.thirdParties = [];

          this.$http
            .put(`users/${this.user.id}`, this.user)
            .then(() => {
              notify(this.$t("users.userDetails.saveSuccessful"), "success");
              this.isRedirectingAfterUpdateSuccess = true;
              this.exit();
            })
            .catch((err) => {
              notify(
                `${this.$t(
                  "users.userDetails.saveUnsuccessful",
                )}. ${fromResponse(err)}`,
                "error",
                15000,
              );
            });
        }
      }
    },
    async loadJobTitlesData() {
      try {
        const response = await this.$http.get("/Users/getJobTitles");
        const json = JSON.parse(JSON.stringify(response));
        this.jobTitlesData = json.data;
        this.jobTitlesAvailableOptions = this.getAvailableJobTitles(
          this.user.role,
        );
      } catch (err) {
        notify(this.$t("users.userDetails.jobTitlesLoadError"), "error");
      }
    },
    areArraysIdentical(a, b) {
      if (a.length != b.length) {
        return false;
      }
      if (a.length === 0 && b.length === 0) {
        return true;
      }
      a.sort();
      b.sort();
      let isEqual = true;
      for (let i = 0; i < a.length; i++) {
        if (a[i] != b[i]) {
          isEqual = false;
          break;
        }
      }
      return isEqual;
    },
    hasValueChanged() {
      let hasValueChanged = false;
      const initialState = this.getinitialState;
      const currentState = this.getCurrentState;
      hasValueChanged =
        !(
          JSON.stringify(initialState, (k, v) =>
            v === undefined ? null : v,
          ) ===
          JSON.stringify(currentState, (k, v) => (v === undefined ? null : v))
        ) ||
        !this.areArraysIdentical(
          this.initialValues.accessLocations,
          this.user.accessLocations,
        );
      return hasValueChanged;
    },
    unsavedChangesAlert(message, title) {
      return confirm(`<i>${message}</i>`, title);
    },

    async confirmStayInDirtyForm() {
      return (
        this.isEditPage() &&
        !this.isRedirectingAfterUpdateSuccess &&
        this.hasValueChanged() &&
        !(await this.unsavedChangesAlert(
          this.$t("shared.unsavedChangesConfirmation"),
          this.$t("shared.unsavedChangesTitle"),
        ))
      );
    },
    getAvailableJobTitles(role) {
      let jobTitles = [];
      switch (role) {
        case roles.reviewParticipant.role:
          jobTitles = this.jobTitlesData.filter(
            (x) =>
              x.name === "Ingka Representative" ||
              x.name === "Supplier Representative",
          );
          break;
        case roles.businessOwner.role:
          jobTitles = this.jobTitlesData.filter(
            (x) =>
              x.name === "Country Function" ||
              x.name === "Purchaser" ||
              x.name === "Business Owner",
          );
          break;
        default:
          break;
      }
      return jobTitles.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
      );
    },
    getCountryName(data) {
      return orgCountries.find((x) => x.value === data)?.name;
    },
  },
};
</script>

<style lang="scss">
@import "../themes/custom-variables.scss";
span.dx-field-item-label-text {
  font-size: $base-font-size;
  font-family: $base-font-family-bold;
  color: $black;
}
.jobTitleTooltip,
.roleTooltip {
  display: inline-block !important;
}
</style>
