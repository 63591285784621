<template>
  <div class="heading-section">
    <div class="row">
      <div class="col-sm-12">
        <div
          class="page-heading"
          :class="{'with-backlink': isBacklinkAvailable}"
        >
          <div class="heading-backlink" @click="back()">
            <i class="fas fa-chevron-left"></i>
          </div>
          <div class="breadcrumb">
            <li v-for="item in breadCrumbsList" :key="item">{{item}}</li>
          </div>
          <h1>{{ pageTitle }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: {
      type: String,
    },
    breadCrumbs: {
      type: Array
    }
  },
  data() {
    return {
      isBacklinkAvailable: true,
      breadCrumbsList: this.breadCrumbs
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss"></style>
