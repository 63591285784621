export default Object.freeze([
  {
    dataField: "title",
    caption: "Title",
    cellTemplate: "training-material",
  },
  {
    dataField: "href",
    visible: false,
  },
]);
