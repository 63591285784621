<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="notificationsData"
      display-expr="name"
      value-expr="name"
      :placeholder="$t('reporting.filters.notificationPlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      styling-mode="outlined"
      :value.sync="notifications"
      :wrapItemText="true"
      apply-value-mode="instantly"
      :max-displayed-tags="1"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import DxTagBox from "devextreme-vue/tag-box";

export default {
  components: {
    DxTagBox,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["notifications"],
      "getFilter",
      "setFilter",
    ),
  },
  data() {
    return {
      notificationsData: [
        {
          id: 1,
          name: "Announced",
        },
        {
          id: 2,
          name: "Unannounced",
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped></style>
