export default Object.freeze([
  {
    dataField: "supplierName",
    visible: true,
  },
  {
    dataField: "siteName",
    visible: true,
  },
  {
    dataField: "siteCountry",
  },
  {
    dataField: "reviewMode",
  },
  {
    dataField: "reviewType",
  },
  {
    dataField: "auditPeriod",
    visible: true,
  },
  {
    dataField: "scheduledStartDate",
    visible: true,
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "scheduledEndDate",
    visible: true,
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "leadReviewer",
  },
  {
    dataField: "supportReviewers",
    visible: true,
  },
  {
    dataField: "findingStatus",
    visible: true,
    cellTemplate: "finding-status-template",
  },
  {
    dataField: "capDate",
    visible: true,
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "targetDate",
    visible: true,
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "findingDescription",
    visible: true,
  },
  {
    dataField: "findingRootCause",
    visible: true,
  },
  {
    dataField: "findingSubmittedOn",
    dataType: "date",
    visible: true,
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "findingSubmittedBy",
    visible: true,
  },
  {
    dataField: "findingApprovedOn",
    dataType: "date",
    visible: true,
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "findingApprovedBy",
    visible: true,
  },
  {
    dataField: "daysToResolveFinding",
    visible: true,
    dataType: "number",
  },
  {
    dataField: "daysToReviewFinding",
    dataType: "number",
    visible: true,
  },
  {
    dataField: "siteCategories",
    visible: true,
  },
  {
    dataField: "siteCategoriesArea",
  },
  {
    dataField: "siteSubCategories",
  },
  {
    dataField: "requirementLevel",
  },
  {
    dataField: "assessmentQuestionNumber",
  },
  {
    dataField: "requirementText",
  },
  {
    dataField: "requirementPrinciple",
  },
  {
    dataField: "riskAccordingToRatingTool",
  },
  {
    dataField: "assessmentAnswerOption",
  },
]);
