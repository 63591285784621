<template>
  <div class="side-nav-outer-toolbar">
    <session-expiry />
    <header-toolbar
      class="layout-header"
      :menu-toggle-enabled="headerMenuTogglerEnabled"
      :toggle-menu-func="toggleMenu"
      :title="title"
    />
    <dx-drawer
      class="layout-body"
      position="before"
      template="menu"
      :opened.sync="menuOpened"
      :opened-state-mode="drawerOptions.menuMode"
      :reveal-mode="drawerOptions.menuRevealMode"
      :min-size="drawerOptions.minMenuSize"
      :shading="drawerOptions.shaderEnabled"
      :close-on-outside-click="drawerOptions.closeOnOutsideClick"
    >
      <dx-scroll-view class="with-footer">
        <div class="container-fluid">
          <slot
            v-if="
              ($can('read', this.$router.currentRoute.meta.subject) ||
                this.$router.currentRoute.meta.requiresAuth === false) &&
              (userAgreedTerms ||
                this.$router.currentRoute.name === 'terms-of-use')
            "
          ></slot>
          <slot name="footer"></slot>
        </div>
      </dx-scroll-view>
      <!-- eslint-disable vue/no-unused-vars -->
      <side-nav-menu
        slot="menu"
        slot-scope="_"
        class="dx-swatch-additional"
        :compact-mode="!menuOpened"
        :selected-item="$route.path"
        :items="menuItems"
        @click="handleSideBarClick"
      />
      <!-- eslint-enable -->
    </dx-drawer>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DxDrawer from "devextreme-vue/drawer";
import DxScrollView from "devextreme-vue/scroll-view";
import menuItems from "../app-navigation";
import HeaderToolbar from "../components/header-toolbar";
import SideNavMenu from "../components/side-nav-menu";
import SessionExpiry from "../components/shared/session-expiry";
import {isNotEmpty, loadLanguage} from "../common/helperFunctions";
import {locale, loadMessages} from "devextreme/localization";
import defineAbilitiesFor from "../config/ability";

export default {
  props: {
    title: String,
    isXSmall: Boolean,
    isSmall: Boolean,
    isXLarge: Boolean,
  },
  methods: {
    ...mapActions("auth", ["getUser"]),

    toggleMenu(e) {
      const pointerEvent = e.event;
      pointerEvent.stopPropagation();
      if (this.menuOpened) {
        this.menuTemporaryOpened = false;
      }
      this.menuOpened = !this.menuOpened;
    },
    handleSideBarClick() {
      if (this.menuOpened === false) this.menuTemporaryOpened = true;
      this.menuOpened = true;
    },
  },
  created() {
    this.getUser().then((response) => {
      this.$ability.update(defineAbilitiesFor(response));

      //check for if user can read the page
      const routeMeta = this.$router.currentRoute.meta;
      if (routeMeta.requiresAuth === true) {
        if (!this.$can("read", routeMeta.subject)) {
          // User cannot access the route thus
          // should receive 403 Forbidden error code
          this.$router.push({name: "login-form", params: {errorCode: 403}});
        }
      }
      if (isNotEmpty(response.preferredCultureCode)) {
        this.$i18n.locale = response.preferredCultureCode;
      }
      loadMessages(
        loadLanguage(this.$i18n.locale.split("-").shift().toLowerCase(), this),
      );
      locale(this.$i18n.locale);
    });
  },
  data() {
    return {
      menuOpened: false,
      menuTemporaryOpened: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["userAgreedTerms"]),
    menuItems() {
      let items = menuItems.filter((item) => this.$can("read", item.subject));
      return items.map((x) => {
        return {...x, text: this.$t(x.translation)};
      });
    },
    drawerOptions() {
      //const shaderEnabled = !this.isXLarge;
      const shaderEnabled = true;
      return {
        //menuMode: this.isXLarge ? "shrink" : "overlap",
        menuMode: "overlap",
        menuRevealMode: this.isXSmall ? "slide" : "expand",
        minMenuSize: this.isXSmall ? 0 : 60,
        menuOpened: false,
        closeOnOutsideClick: shaderEnabled,
        shaderEnabled,
      };
    },
    headerMenuTogglerEnabled() {
      return true;
    },
  },
  watch: {
    //isXLarge() {
    //  if (!this.menuTemporaryOpened) {
    //    this.menuOpened = this.isXLarge;
    //  }
    //},
    $route() {
      if (this.menuTemporaryOpened || !this.isXLarge) {
        this.menuOpened = false;
        this.menuTemporaryOpened = false;
      }
    },
  },
  components: {
    DxDrawer,
    DxScrollView,
    HeaderToolbar,
    SideNavMenu,
    SessionExpiry,
  },
};
</script>

<style lang="scss">
.side-nav-outer-toolbar {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
}

.layout-header {
  z-index: 1501;
}

.layout-body {
  flex: 1;
  min-height: 0;
}
</style>
