<template>
  <div class="landing-card-container table">
    <h2>
      <i class="far fa-bookmark"></i>
      {{ $t("landingPage.trainingMaterials.header") }}
    </h2>

    <!-- todo: need to check if that is the correct link to a document -->
    <DxDataGrid
      :show-borders="false"
      :data-source="dataSource"
      :columns="colDefs"
      :show-column-headers="false"
    >
      <template #training-material="{data}">
        <div class="additional-info-item-container">
          <a
            v-if="data.data.trainingMaterialType === 'File'"
            @click="
              downloadAuthAttachment(
                `/dashboard/getTrainingMaterial/${data.data.trainingMaterialId}`,
              )
            "
            :title="data.data.title"
          >
            <i class="far fa-file-alt mr-3"></i>{{ data.data.title }}
          </a>
          <a
            v-else-if="data.data.trainingMaterialType === 'Link'"
            :href="data.data.trainingMaterialUrl"
            target="_blank"
            :title="data.data.title"
          >
            <i class="far fa-file-alt mr-3"></i>{{ data.data.title }}
          </a>
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {DxDataGrid} from "devextreme-vue/data-grid";
import colDefs from "../../../custom/landingPage/trainingMaterialColDefs";
import downloadAuthAttachment from "../../../mixins/download-auth-attachment";

export default {
  components: {
    DxDataGrid,
  },
  created() {
    this.$http.get(`dashboard/getTrainingMaterials`).then((response) => {
      this.dataSource = response.data;
    });
  },
  mixins: [downloadAuthAttachment],
  data() {
    return {
      dataSource: null,
      colDefs,
      apiUrl: process.env.VUE_APP_API_HOSTNAME,
    };
  },
};
</script>

<style scoped></style>
