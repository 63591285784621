<template>
  <div class="content-block">
    <label>
      {{ $t("schedules.scheduleAssessment.thirdParty") }}
    </label>

    <template v-if="!readOnly">
      <dx-select-box
        :value.sync="selectedThirdParty"
        :data-source="thirdParties"
        stylingMode="outlined"
        display-expr="name"
        value-expr="id"
        :placeholder="$t('schedules.scheduleAssessment.selectThirdParty')"
        @value-changed="onSelectedThirdPartyChanged($event)"
      >
        <dx-validator>
          <dx-required-rule
            :message="$t('schedules.scheduleAssessment.thirdPartyIsRequired')"
          />
        </dx-validator>
      </dx-select-box>
    </template>
    <template v-else>
      <div class="indented-label">
        {{
          scheduledVisit && scheduledVisit.thirdParty
            ? scheduledVisit.thirdParty.name
            : ""
        }}
      </div>
    </template>
  </div>
</template>

<script>
import {DxValidator, DxRequiredRule} from "devextreme-vue/validator";
import {DxSelectBox} from "devextreme-vue";
import {mapActions} from "vuex";
import {isNotEmpty, toCamelCase} from "../../common/helperFunctions";
import {mapFields} from "vuex-map-fields";

export default {
  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DxValidator,
    DxRequiredRule,
    DxSelectBox,
  },

  methods: {
    ...mapActions("schedules", ["getThirdParties", "getAuditManagers"]),
    onSelectedThirdPartyChanged(e) {
      this.$emit("selected-third-party-changed", e);
      if (!this.readOnly) {
        this.getAuditManagers(e.value);
      }
    },
  },
  computed: {
    ...mapFields("schedules", [
      "selectedThirdParty",
      "thirdParties",
      "scheduledVisit",
    ]),
  },
  mounted() {
    this.getThirdParties().then((x) => {
      if (!this.readOnly) {
        this.selectedThirdParty = x.length > 0 ? x[0].id : null;
      }
    });
  },
};
</script>

<style></style>
