export default Object.freeze([
  {
    dataField: "ordered",
    caption: "reporting.charts.orderedActivityStatusSeriesTitle",
    color: "#5b778f",
  },
  {
    dataField: "scheduled",
    caption: "reporting.charts.scheduledActivityStatusSeriesTitle",
    color: "#c4c4c4",
  },
  {
    dataField: "inProgress",
    caption: "reporting.charts.inProgressActivityStatusSeriesTitle",
    color: "#f5a623",
  },
  {
    dataField: "submitted",
    caption: "reporting.charts.submittedActivityStatusSeriesTitle",
    color: "#ff7310",
  },
  {
    dataField: "completed",
    caption: "reporting.charts.completedActivityStatusSeriesTitle",
    color: "#00a700",
  },
  {
    dataField: "cancelled",
    caption: "reporting.charts.cancelledActivityStatusSeriesTitle",
    color: "#ff0000",
  },
]);
