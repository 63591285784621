var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"70px"}},[_c('page-heading',{attrs:{"pageTitle":_vm.translatedPageTitle,"breadCrumbs":_vm.breadCrumbs}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card-container"},[_c('dx-scroll-view',{attrs:{"scroll-by-content":true,"scroll-by-thumb":true,"show-scrollbar":"true"}},[_c('h2',[_c('i',{staticClass:"far fa-user"}),_vm._v(" "+_vm._s(_vm.$t("users.userDetails.formTitle"))+" "),_c('span',{staticClass:"sso-indication"},[_vm._v(_vm._s(_vm.isBasicUser ? "(NON-SSO)" : "(SSO)"))])]),_c('dx-form',{ref:_vm.formRefKey,attrs:{"form-data":_vm.user,"show-validation-summary":true,"validation-group":_vm.formValidationGroup,"scrolling-enabled":true,"col-count":1},on:{"field-data-changed":_vm.fieldDataChangedHandler},scopedSlots:_vm._u([{key:"formbuttons",fn:function(){return [_c('div',{staticClass:"mt-3"},[_c('dx-button',{staticClass:"btn btn-large mr-3",attrs:{"text":_vm.$t('users.userDetails.back'),"type":"normal","styling-mode":"contained","on-click":_vm.exit,"icon":"undo"}}),_c('dx-button',{staticClass:"btn btn-large",attrs:{"text":_vm.$t('users.userDetails.save'),"type":"default","styling-mode":"contained","on-click":_vm.saveUser,"validation-group":"userDetails","icon":"check"}})],1)]},proxy:true}])},[_c('dx-item',{attrs:{"item-type":"group","col-count":1,"col-span":1}},[_c('dx-simple-item',{attrs:{"data-field":"email","editorOptions":{
                  stylingMode: 'outlined',
                  disabled: !this.isBasicUser,
                }}},[_c('dx-label',{staticClass:"bold-label",attrs:{"text":_vm.$t('users.userDetails.emailLabel')}}),_c('dx-required-rule',{attrs:{"message":_vm.$t('users.userDetails.emailRequired')}}),_c('dx-email-rule',{attrs:{"message":_vm.$t('users.userDetails.emailInvalid')}}),_c('dx-string-length-rule',{attrs:{"max":_vm.maxLength.email,"message":_vm.$t('users.userDetails.maxLengthError', {
                      dataField: _vm.$t('users.userDetails.emailLabel'),
                      maxLength: _vm.maxLength.email,
                    })}})],1),_c('dx-simple-item',{attrs:{"data-field":"firstName","editorOptions":_vm.nameOptions}},[_c('dx-label',{attrs:{"text":_vm.$t('users.userDetails.firstNameLabel')}}),_c('dx-required-rule',{attrs:{"message":_vm.$t('users.userDetails.isRequired', {
                      dataField: _vm.$t('users.userDetails.firstNameLabel'),
                    })}}),_c('dx-string-length-rule',{attrs:{"max":_vm.maxLength.name,"message":_vm.$t('users.userDetails.maxLengthError', {
                      dataField: _vm.$t('users.userDetails.firstNameLabel'),
                      maxLength: _vm.maxLength.name,
                    })}})],1),_c('dx-simple-item',{attrs:{"data-field":"lastName","editorOptions":_vm.nameOptions}},[_c('dx-label',{attrs:{"text":_vm.$t('users.userDetails.lastNameLabel')}}),_c('dx-required-rule',{attrs:{"message":_vm.$t('users.userDetails.lastNameRequired')}}),_c('dx-string-length-rule',{attrs:{"max":_vm.maxLength.name,"message":_vm.$t('users.userDetails.maxLengthError', {
                      dataField: _vm.$t('users.userDetails.lastNameLabel'),
                      maxLength: _vm.maxLength.name,
                    })}})],1),_c('dx-simple-item',{attrs:{"editor-options":{
                  dataSource: _vm.userRoles,
                  displayExpr: 'name',
                  valueExpr: 'value',
                  stylingMode: 'outlined',
                  applyValueMode: 'instantly',
                  placeholder: '',
                },"data-field":"role","editor-type":"dxSelectBox"}},[_c('dx-label',{attrs:{"text":_vm.$t('users.userDetails.roleLabel')}}),_c('dx-required-rule',{attrs:{"message":_vm.$t('users.userDetails.isRequired', {
                      dataField: _vm.$t('users.userDetails.roleLabel'),
                    })}})],1),_c('dx-simple-item',{ref:"jobTitleRef",attrs:{"editor-options":{
                  dataSource: _vm.jobTitlesAvailableOptions,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  stylingMode: 'outlined',
                  applyValueMode: 'instantly',
                  disabled: _vm.jobTitleDisabled,
                  placeholder: '',
                },"validation-group":_vm.formValidationGroup,"data-field":"jobTitleId","editor-type":"dxSelectBox"}},[_c('dx-label',{attrs:{"text":_vm.$t('users.userDetails.jobTitleLabel')}}),_c('dx-custom-rule',{attrs:{"validation-callback":_vm.validateJobTitle,"message":_vm.$t('users.userDetails.isRequired', {
                      dataField: _vm.$t('users.userDetails.jobTitleLabel'),
                    }),"reevaluate":true}})],1),_c('dx-simple-item',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('dx-drop-down-box',{ref:_vm.siteDropDownRef,attrs:{"value":_vm.locations,"defer-rendering":false,"show-clear-button":true,"resize-enabled":"false","data-source":_vm.siteDataSource,"display-expr":"siteName","stylingMode":"outlined","value-expr":"id","placeholder":_vm.locationPlaceholder,"disabled":_vm.locationDisabled},on:{"update:value":function($event){_vm.locations=$event},"opened":_vm.onSiteDropDownOpened},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('dx-data-grid',{attrs:{"data-source":_vm.siteDataSource,"columns":_vm.siteColumns,"hover-state-enabled":true,"column-auto-width":true,"selected-row-keys":_vm.locations,"height":"100%"},on:{"update:selectedRowKeys":function($event){_vm.locations=$event},"update:selected-row-keys":function($event){_vm.locations=$event}},scopedSlots:_vm._u([{key:"country-template",fn:function(ref){
                    var data = ref.data;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getCountryName(data.data.country))+" ")])]}}])},[_c('dx-selection',{attrs:{"mode":"multiple","show-check-boxes-mode":_vm.checkBoxMode}}),_c('dx-paging',{attrs:{"enabled":true,"page-size":10}}),_c('dx-filter-row',{attrs:{"visible":true}}),_c('dx-scrolling',{attrs:{"mode":"infinite"}})],1)]},proxy:true}])},[_c('dx-validator',{attrs:{"validation-group":_vm.formValidationGroup}},[_c('dx-custom-rule',{attrs:{"validation-callback":_vm.validateLocations,"message":_vm.$t('users.userDetails.isRequired', {
                            dataField: _vm.$t('users.userDetails.locationLabel'),
                          }),"reevaluate":true}})],1)],1)]},proxy:true}])},[_c('dx-label',{attrs:{"text":_vm.$t('users.userDetails.locationLabel')}})],1),_c('dx-simple-item',{attrs:{"data-field":"validTo","editor-type":"dxDateBox","editorOptions":{
                  dateSerializationFormat: 'yyyy-MM-dd',
                  min: Date.now(),
                  dateOutOfRangeMessage: _vm.$t(
                    'users.userDetails.cannotBeInThePastError',
                    {dataField: _vm.$t('users.userDetails.validToDateLabel')}
                  ),
                  stylingMode: 'outlined',
                }}},[_c('dx-label',{attrs:{"text":_vm.$t('users.userDetails.validToDateLabel')}}),_c('dx-required-rule',{attrs:{"message":_vm.$t('users.userDetails.isRequired', {
                      dataField: _vm.$t('users.userDetails.validToDateLabel'),
                    })}})],1),_c('dx-simple-item',{attrs:{"editor-options":{
                  dataSource: _vm.activeStatuses,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  stylingMode: 'outlined',
                  applyValueMode: 'instantly',
                  value: _vm.selectedActiveState,
                  disabled: !_vm.isEditPage(),
                },"data-field":"activeStatus","editor-type":"dxSelectBox"}},[_c('dx-label',{attrs:{"text":_vm.$t('users.userDetails.activeStatus')}}),_c('dx-required-rule',{attrs:{"message":_vm.$t('users.userDetails.isRequired', {
                      dataField: _vm.$t('users.userDetails.activeStatus'),
                    })}})],1),_c('dx-simple-item',{attrs:{"visible":true,"template":"formbuttons"}})],1)],1),_c('dx-tooltip',{attrs:{"showEvent":"mouseenter","hideEvent":"mouseleave","position":"right","target":"label[class*='roleTooltip']"}},[_c('div',{staticStyle:{"white-space":"break-spaces","text-align":"left"}},[_vm._v(" "+_vm._s(_vm.$t("users.userDetails.roleToolTip"))+" ")])]),_c('dx-tooltip',{attrs:{"showEvent":"mouseenter","hideEvent":"mouseleave","position":"right","target":"label[class*='jobTitleTooltip']"}},[_c('div',{staticStyle:{"white-space":"break-spaces","text-align":"left"}},[_vm._v(" "+_vm._s(_vm.$t("users.userDetails.jobTitleToolTip"))+" ")])])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }