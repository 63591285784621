<template>
  <div
    class="landing-card-container"
    :class="{'section-loader-on': loaderVisible}"
  >
    <div v-if="!upcomingAssessment" ref="no-upcoming-assessment">
      <h2>
        {{ $t("landingPage.upcomingAssessment.noUpcomingAssessment") }}
        <!-- <i class="fas fa-check success"></i> -->
      </h2>
      <p style="margin-bottom: 0">
        {{
          $t("landingPage.upcomingAssessment.noUpcomingAssessmentParagraph")
        }}.
      </p>
    </div>
    <div v-else>
      <div class="sectioned-part upcoming-assessment">
        <h2>
          {{ $t("landingPage.upcomingAssessment.nextAssessmentIn") }}
          <span ref="days-to-assessment">{{
            upcomingAssessment.daysToAssessment
          }}</span>
          {{ $t("landingPage.upcomingAssessment.days") }}
        </h2>

        <div class="row mb-4">
          <div class="col-6">
            <label>{{
              $t("landingPage.upcomingAssessment.assessmentType")
            }}</label>
            <div class="assessment-start-date">
              {{
                $t(
                  `landingPage.upcomingAssessment.visitType${upcomingAssessment.visitType}`,
                )
              }}
            </div>
          </div>
          <div class="col-6">
            <label>{{ $t("landingPage.upcomingAssessment.carriedBy") }}</label>
            <div class="assessment-start-date">
              {{ upcomingAssessment.leadReviewer }}
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-6">
            <label>{{ $t("schedules.scheduleAssessment.startDate") }}</label>
            <div class="assessment-start-date" ref="assessment-start-date">
              <i class="far fa-calendar-alt"></i>
              {{ formatDate(upcomingAssessment.startDate) }}
            </div>
          </div>
          <div class="col-6">
            <label>{{ $t("schedules.scheduleAssessment.endDate") }}</label>
            <div class="assessment-start-date" ref="assessment-end-date">
              <i class="far fa-calendar-alt"></i>
              {{ formatDate(upcomingAssessment.endDate) }}
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-6">
            <label>{{ $t("orders.orderDetails.reviewType") }}</label>
            <div class="assessment-start-date">
              <i class="far fa-calendar-alt"></i>
              {{ upcomingAssessment.reviewType }}
            </div>
          </div>
          <div class="col-6">
            <label>{{ $t("orders.orderDetails.siteName") }}</label>
            <div class="assessment-start-date">
              <i class="far fa-calendar-alt"></i>
              {{ upcomingAssessment.siteName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import DxButton from "devextreme-vue/button";
import localeDatetimeFormat from "../../../../mixins/locale-datetime-format";
import {formatDate} from "../../../../common/helperFunctions";

export default {
  components: {},
  created() {
    this.getUpcomingAssessment();
  },
  mixins: [localeDatetimeFormat],
  computed: {
    ...mapState("landingPage/upcomingAssessment", ["upcomingAssessment"]),
  },
  methods: {
    ...mapActions("landingPage/upcomingAssessment", ["getUpcomingAssessment"]),
    formatDate:formatDate
  },
  updated() {
    this.loaderVisible = false;
  },
  mounted() {
    if (this.upcomingAssessment != null) {
      this.loaderVisible = false;
    }
  },
  data() {
    return {
      loaderVisible: true,
    };
  },
};
</script>

<style lang="scss">
.upcoming-assessment {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  border-bottom: 0px !important;
}
</style>
