import Vue from "vue";
import VueI18n from "vue-i18n";
import getBrowserLocale from "@/utils/i18n/get-browser-locale";
import getMatchingLanguageLocale from "@/utils/i18n/get-matching-language-locale";
import {supportedLocalesInclude} from "./utils/i18n/supported-locales";

Vue.use(VueI18n);

// load Locale messages
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

// Get Starting Locale
// This method calls the get browser locale method to determine which language
// to show before sign-in completes.
function getStartingLocale() {
  const browserLocale = getBrowserLocale();
  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  } else {
    // look for the first locale with the same language
    const matchingLanguageLocale = getMatchingLanguageLocale(browserLocale);
    return matchingLanguageLocale
      ? matchingLanguageLocale
      : process.env.VUE_APP_I18N_LOCALE ||
          process.env.VUE_APP_I18N_FALLBACK_LOCALE;
  }
}

export default new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages(),
  objectNotation: true,
});
