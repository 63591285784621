<template>
  <div
    class="landing-card-container"
    :class="{'section-loader-on': loaderVisible}"
  >
    <h2>
      {{ $t("landingPage.auditCoverage.header") }}
    </h2>

    <div v-if="dataSource">
      <div class="sectioned-part">
        <p>
          {{ $t("landingPage.auditCoverage.description") }}
        </p>

        <DxPieChart
          id="pie"
          :data-source="dataSource"
          palette="Bright"
          type="doughnut"
          resolveLabelOverlapping="shift"
          :customize-point="customizePoint"
        >
          <DxSeries argument-field="keyTranslated" value-field="value">
            <DxLabel :visible="true" position="outside">
              <DxConnector :visible="true" :width="1" />
            </DxLabel>
          </DxSeries>
          <DxLegend
            vertical-alignment="bottom"
            horizontal-alignment="center"
            item-text-position="right"
          >
            <dx-margin :top="30" />
          </DxLegend>
          <DxSize :height="300" />
          <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
        </DxPieChart>
      </div>
      <DxButton
        :text="$t('landingPage.auditCoverage.programmeManagementDashboard')"
        type="normal"
        class="btn btn-large"
        icon="chevronright"
        @click="$router.push({name: 'reporting'})"
      />
    </div>
    <div v-else>
      <p style="margin-top: -5px" class="mb-0">
        {{ $t("landingPage.auditCoverage.noAssessmentsForCurrentPeriod") }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  DxSeries,
  DxSize,
  DxLegend,
  DxLabel,
  DxTooltip,
  DxConnector,
  DxMargin,
} from "devextreme-vue/chart";
import DxPieChart from "devextreme-vue/pie-chart";

import {DxButton} from "devextreme-vue";
export default {
  components: {
    DxPieChart,
    DxSeries,
    DxLabel,
    DxLegend,
    DxTooltip,
    DxSize,
    DxConnector,
    DxButton,
    DxMargin,
  },
  created() {
    this.$http.get("dashboard/getAuditCoverage").then((response) => {
      let total =
        response.data.ordered +
        response.data.scheduled +
        response.data.inProgress +
        response.data.submitted +
        response.data.completed;
      if (total === 0) {
        this.dataSource = null;
      } else {
        this.dataSource = Object.entries(response.data)
          .map(([key, value]) => ({
            key,
            value,
            order:
              key === "ordered"
                ? 1
                : key === "scheduled"
                ? 2
                : key === "inProgress"
                ? 3
                : key === "submitted"
                ? 4
                : key === "completed"
                ? 5
                : 0,
            keyTranslated: this.$t(`landingPage.auditCoverage.${key}`),
          }))
          .filter((el) => el.key != "cancelled")
          .sort((a, b) => a.order - b.order);
      }
      this.loaderVisible = false;
    });
  },
  data() {
    return {
      dataSource: null,
      loaderVisible: true,
    };
  },
  methods: {
    customizeTooltip(pointInfo) {
      return {
        text: `${pointInfo.argumentText}: ${pointInfo.valueText}`,
      };
    },
    customizePoint(pointInfo) {
      switch (pointInfo.data.key) {
        case "ordered":
          return {color: "#5b778f"};
        case "scheduled":
          return {color: "#c4c4c4"};
        case "inProgress":
          return {color: "#f5a623"};
        case "submitted":
          return {color: "#ff7310"};
        case "completed":
          return {color: "#00a700"};
        default:
          return {};
      }
    },
  },
};
</script>

<style></style>
