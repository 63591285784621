import $axios from "../utils/axios-instance";

export function getFieldValue(value) {
  const fieldType = value.fieldType;
  let fieldValue = null;
  switch (fieldType) {
    case "Boolean":
      fieldValue = value.fieldValueBoolean;
      break;
    case "DateTime":
      fieldValue = value.fieldValueDateTime;
      break;
    case "Int":
      fieldValue = value.fieldValueInt;
      break;
    case "Decimal":
      fieldValue = value.fieldValueDecimal;
      break;
    default:
      fieldValue = value.fieldValueString;
  }
  return fieldValue;
}
export function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

export function isEmpty(value) {
  return value === undefined || value === null || value === "";
}

export function loadLanguage(language, sender) {
  switch (language) {
    case "cs":
      return sender.$csMessagesComp;
    case "de":
      return sender.$deMessagesComp;
    case "el":
      return sender.$elMessagesComp;
    case "en":
      return sender.$enMessagesComp;
    case "es":
      return sender.$esMessagesComp;
    case "fi":
      return sender.$fiMessagesComp;
    case "fr":
      return sender.$frMessagesComp;
    case "hu":
      return sender.$huMessagesComp;
    case "it":
      return sender.$itMessagesComp;
    case "ja":
      return sender.$jaMessagesComp;
    case "nl":
      return sender.$nlMessagesComp;
    case "pt":
      return sender.$ptMessagesComp;
    case "ru":
      return sender.$ruMessagesComp;
    case "sl":
      return sender.$slMessagesComp;
    case "sv":
      return sender.$svMessagesComp;
    case "tr":
      return sender.$trMessagesComp;
    case "vi":
      return sender.$viMessagesComp;
    case "zh":
      return sender.$zhMessagesComp;
    case "ko":
      return sender.$koMessagesComp;
  }
}

export function resetLocalStorage() {
  localStorage.removeItem("storageUserList");
  localStorage.removeItem("storageThirdPartyList");
  localStorage.removeItem("storageAssessmentList");
  localStorage.removeItem("storageFindingList");
  localStorage.removeItem("storageExemptionsList");
}

export function toCamelCase(str) {
  return str
    .replace(/\s(.)/g, function (a) {
      return a.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function (b) {
      return b.toLowerCase();
    });
}

export const firstLetterToUppercase = (input) =>
  input[0].toUpperCase() + input.slice(1);

export function getUserInitials(text) {
  if (text && text.length > 0) {
    return text
      .split(/\s/)
      .map((x) => x.charAt(0))
      .join("")
      .substring(0, 2);
  }
  return null;
}

export async function getSecureImage(url) {
  let response = await $axios.get(url, {responseType: "arraybuffer"});
  let image = btoa(
    new Uint8Array(response.data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      "",
    ),
  );
  return `data:${response.headers[
    "content-type"
  ].toLowerCase()};base64,${image}`;
}

export function addDaysToDate(date, days) {
  const dt = new Date(date);
  return new Date(dt.setDate(dt.getDate() + days));
}

export function addBusinessDaysToDate(date, days) {
  let dt = new Date(date);
  let currentDay = dt.getDay();
  if (currentDay === 0) days++;
  if (currentDay + days >= 6) {
    let remainingDays = days - (5 - currentDay);
    days += 2;
    if (remainingDays > 5) {
      days += 2 * Math.floor(remainingDays / 5);
      if (remainingDays % 5 == 0) days -= 2;
    }
  }
  return new Date(dt.setDate(dt.getDate() + days));
}

export function todaysDate() {
  return new Date().setHours(0, 0, 0, 0);
}

export function isIdenticalArray(a, b) {
  if (a && b) {
    a.sort();
    b.sort();
    return a.length === b.length && a.every((val, index) => val === b[index]);
  } else return false;
}

export function isUnsavedComment() {
  let commentTextAreaElement = document.getElementById("activeCommentTextArea");
  if (commentTextAreaElement != null) {
    let inputTextElement =
      commentTextAreaElement.getElementsByTagName("textarea");
    if (inputTextElement && inputTextElement.length) {
      let commentValue = inputTextElement[0].value;
      if (commentValue) return true;
      return false;
    }
  }
}

export function formatDate(date, format) {
  if (!isNotEmpty(date)) return "";
  if (typeof date === "string") date = new Date(date);

  return isNotEmpty(format)
    ? this.$moment.utc(date).local().format(format)
    : this.$moment.utc(date).local().format("DD/MMM/YYYY");
}
