export default Object.freeze([
  {
    dataField: "id",
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: "question",
    caption: "Requirement Name",
  },
  {
    dataField: "response",
    cellTemplate: "response-cell-template",
  },
  {
    dataField: "sectionTitle",
    caption: "Section",
    groupIndex: 0,
    calculateGroupValue: "parentSectionLevel1Ref",
    groupCellTemplate: "groupCellTemplate",
  },
]);
