<template>
  <div v-if="uploadUrl && tokenRefreshed">
    <div class="upload-container" :class="buttonMode">
      <i class="fas fa-upload"></i>
      <dx-file-uploader
        :accept="accept"
        :multiple="true"
        name="file"
        :upload-mode="mode"
        :upload-url="uploadUrl"
        @value-changed="onAttachmentValueChanged($event)"
        @uploaded="onUploadSuccessful()"
        @upload-started="onUploadStarted()"
        @upload-error="onUploadError()"
        :allowed-file-extensions="fileExtensionWhitelist"
        :max-file-size="maxFileUploadSize"
        :invalid-file-extension-message="
          $t('attachments.errors.invalidFileExtensionMessage')
        "
        :invalid-max-file-size-message="
          $t('attachments.errors.invalidMaxFileSizeMessage')
        "
        :upload-headers="{Authorization: `Bearer ${authToken}`}"
      >
      </dx-file-uploader>
    </div>
    <div class="attachment-rules-description">
      <p class="attachment-restrictions-title">
        <i class="fas fa-exclamation-circle mr-2"></i>
        {{ $t("attachments.information.restrictionsTitle") }}
      </p>
      <ul>
        <li>
          {{ $t("attachments.information.maxFileSize") }}
        </li>
        <li>
          {{ $t("attachments.information.fileExtensionWhitelist") }}
        </li>
        <li>
          {{ $t("attachments.information.acceptedCharacters") }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {DxFileUploader} from "devextreme-vue/file-uploader";
import {isNotEmpty} from "../../common/helperFunctions";
import {fileUploadValidation} from "../../common/constants";
import {mapActions} from "vuex";

export default {
  components: {
    DxFileUploader,
  },
  props: {
    uploadUrl: {
      Type: String,
    },
    uploadMode: {
      Type: String,
    },
  },
  data() {
    return {
      accept: "*",
      files: [],
      fileExtensionWhitelist: fileUploadValidation.fileExtensionWhitelist,
      maxFileUploadSize: fileUploadValidation.maxFileUploadSize,
      currentUploadUrl: this.uploadUrl,
      mode: this.uploadMode,
      tokenRefreshed: false,
    };
  },
  created() {
    if (this.uploadMode === "instantly") {
      this.callTokenRefresh();
    }
  },
  methods: {
    onUploadSuccessful() {
      this.$emit("upload-successful");
    },
    ...mapActions("auth", ["tokenRefresh"]),
    async callTokenRefresh() {
      await this.tokenRefresh();
      this.tokenRefreshed = true;
    },
    onUploadStarted() {
      this.$emit("upload-started");
    },
    onUploadError() {
      this.$emit("upload-error");
    },
    onAttachmentValueChanged(e) {
      this.files = e.value;

      for (let index = 0; index < e.value.length; index++) {
        let fileName = e.value[index].name;

        if (fileUploadValidation.validCharacters.test(fileName)) continue;
        if (!e.component._files[index].isValid()) {
          e.component._files[index].$statusMessage.append(
            this.$t("attachments.errors.invalidFileNameMessage"),
          );
        } else {
          e.component._files[index].$statusMessage.text(
            this.$t("attachments.errors.invalidFileNameMessage"),
          );
          e.component._files[index].$statusMessage.addClass("error-text");
          e.component._files[index].isValid = () => false;
        }
      }
    },
  },
  computed: {
    authToken() {
      return localStorage.getItem("token");
    },
    buttonMode() {
      return {
        buttonMode: this.mode === "useButtons",
      };
    },
  },
  watch: {
    uploadUrl() {
      if (isNotEmpty(this.uploadUrl)) {
        this.currentUploadUrl = this.uploadUrl;
      } else {
        this.currentUploadUrl = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fa-upload {
  position: absolute;
  top: 45px;
  left: calc(50% - 17px);
  font-size: 34px;
  color: #5b778f;
}
.upload-container::v-deep .dx-fileuploader-input-wrapper {
  background-color: rgba(183, 183, 183, 0.1);
}
.attachment-rules-description ul {
  padding-left: 1em;
}
</style>
