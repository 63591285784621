export default Object.freeze([
  {
    dataField: "auditScorePercentage",
    caption: "reporting.charts.auditScoreStackTitle",
    order: 1,
  },
  {
    dataField: "finalScorePercentage",
    caption: "reporting.charts.finalScoreStackTitle",
    order: 2,
  },
]);
