<template>
  <div class="content-block">
    <label>
      {{ $t("schedules.scheduleAssessment.auditType") }}
    </label>
    <DxRadioGroup
      class="radio-with-icon"
      :dataSource="auditTypes"
      layout="horizontal"
      v-model="selectedAuditType"
      displayExpr="name"
      itemTemplate="item"
      @value-changed="onChange"
    >
      <template #item="{ data }">
        <div>
          <i :class="data.fa"></i>
          <span>{{ data.name }}</span>
        </div>
      </template>
      <dx-validator>
        <dx-required-rule message="Audit type is required" />
      </dx-validator>
    </DxRadioGroup>
  </div>
</template>

<script>
import {DxRadioGroup} from "devextreme-vue";
import {DxValidator, DxRequiredRule} from "devextreme-vue/validator";
import {mapActions, mapState} from "vuex";
import {createHelpers} from "vuex-map-fields";

const {mapFields} = createHelpers({
  getterType: "schedules/getField",
  mutationType: "schedules/updateField",
});

export default {
  components: {
    DxRadioGroup,
    DxValidator,
    DxRequiredRule,
  },
  methods: {
    ...mapActions("schedules", [
      "getAssessmentProgrammes",
      "updateSelectedAuditType",
    ]),
    setSelectedAuditType(e) {
      this.updateSelectedAuditType(e.value);
      if (this.$store.state.schedules.selectedVisitType === "FieldVisit") {
        this.getAssessmentProgrammes("FieldVisit");
      } else {
        this.getAssessmentProgrammes("AuditVisit");
      }
    },
    onChange(e) {
      if (e.value.val === "SelfAssessment") {
        this.getAssessmentProgrammes("AuditVisit");
      } else if (
        this.selectedVisitType.value === "FieldVisit" &&
        e.value.val === "AuditorVisit"
      ) {
        this.getAssessmentProgrammes("FieldVisit");
      }
    },
  },
  computed: {
    ...mapState("schedules", ["auditTypes", "selectedVisitType"]),
    ...mapFields(["selectedAuditType"]),
    auditTypes() {
      const types = this.$store.state.schedules.auditTypes;
      return types.map(function (elem) {
        return {
          fa: elem.fa,
          name: this.$i18n.t(
            `schedules.scheduleAssessment.${elem.name
              .charAt(0)
              .toLowerCase()}${elem.name.slice(1).replace(/\s+/g, "")}`,
          ),
          val: elem.val,
        };
      }, this);
    },
  },
  beforeDestroy() {
    // clear the selected value in the store
    this.$store.dispatch("schedules/updateSelectedAuditType", null);
  },
};
</script>
