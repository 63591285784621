<template>
  <div :class="{loading: isLoading}">
    <page-heading :pageTitle="translatedPageTitle" :breadCrumbs="breadCrumbs" />

    <dx-validation-group :ref="orderValidationRefKey" name="orderGroup">
      <div class="row">
        <div class="col-xl-7 scheduling-section">
          <div class="card-container xs-adjustment">
            <div class="row">
              <div class="col-lg-8">
                <order-details
                  :isDisabled="isDisabled"
                  @show-in-reviewer-capacity="showInReviewerCapacity($event)"
                />
              </div>
              <div class="col-lg-4">
                <div class="grey-side-section" style="height: 100%">
                  <order-notification :isDisabled="isDisabled" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-xl-5 scheduling-section"
          v-if="
            $can('read', $subjects.schedule.subject) && orderStatus !== null
          "
        >
          <div class="card-container xs-adjustment">
            <div class="row">
              <div class="col-lg-12">
                <review-details
                  :isInReviewerCapacityVisible="isInReviewerCapacityVisible"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        style="margin-bottom: 65px"
        v-if="$route.name !== 'order'"
      >
        <div class="col-xl-12">
          <comments
            :comments="comments"
            @edit-comment-click="editCommentClick($event)"
            @delete-comment-click="deleteCommentClick($event)"
            @submit-comment-click="submitComment($event)"
            @save-comment-click="editComment($event)"
            :isSelfContained="true"
            :showEditButtons="true"
            :headerDescription="
              this.$t('orders.orderDetails.commentsDescription')
            "
            :toolTipDescription="this.$t('tooltips.schedule.comments')"
            :hasToolTip="true"
          />
        </div>
      </div>
      <div class="fixed-bottom-container">
        <div class="row">
          <div class="col">
            <div v-if="canCancel && isOwner">
              <dx-button
                @click="onCancelButtonClick"
                class="btn btn-large mr-4"
                :text="setButtonText"
                type="normal"
                icon="undo"
              />
            </div>
          </div>
          <div
            class="col-auto"
            v-if="
              ($can('update', $subjects.schedule.subject, 'updateReview') ||
                [
                  orderStatuses.submitted,
                  orderStatuses.completed,
                  orderStatuses.cancelled,
                ].includes(orderStatus)) &&
              isOwner
            "
          >
            <dx-button
              class="btn btn-large"
              type="default"
              icon="check"
              :text="$t('orders.scheduleReview.updateReview')"
              @click="onUpdateReviewButtonClick"
              :visible="
                $can('update', $subjects.schedule.subject) &&
                orderStatus !== null
              "
            />
          </div>
          <div
            class="col-auto"
            v-if="
              $can('create', $subjects.schedule.subject) &&
              orderStatus === 'Ordered'
            "
          >
            <dx-button
              class="btn btn-large"
              type="default"
              icon="check"
              name="scheduleReview"
              :text="$t('orders.scheduleReview.scheduleReview')"
              @click="onScheduleButtonClick"
              :visible="
                $can('create', $subjects.schedule.subject) &&
                orderStatus === 'Ordered' &&
                isReviewerCapacity
              "
            />
          </div>
          <div
            class="col-auto"
            v-if="
              isInRole(roleTypes.admin.role) &&
              (orderStatus === 'Submitted' || orderStatus === 'Completed')
            "
          >
            <dx-button
              class="btn btn-large"
              type="default"
              icon="check"
              name="Reopen assessment"
              :text="$t('orders.scheduleReview.reopenAssessment')"
              @click="onReopenAssessmentButtonClick"
              :visible="
                isInRole(roleTypes.admin.role) &&
                (orderStatus === 'Submitted' || orderStatus === 'Completed')
              "
            />
          </div>
          <div
            class="col-auto"
            v-if="
              $can('update', $subjects.order.subject, 'updateOrder') &&
              orderStatus &&
              orderStatus === 'Ordered' &&
              isOwner
            "
          >
            <dx-button
              class="btn btn-large"
              type="default"
              icon="check"
              :text="$t('orders.scheduleReview.updateOrder')"
              @click="onOrderCreateUpdateClick"
            />
          </div>
          <div
            class="col-auto"
            v-if="
              $can('create', $subjects.order.subject) &&
              $route.name !== 'edit-order'
            "
          >
            <dx-button
              class="btn btn-large"
              type="default"
              icon="check"
              :text="$t('orders.scheduleReview.orderAReview')"
              @click="onOrderCreateUpdateClick"
            />
          </div>
        </div>
      </div>
    </dx-validation-group>
    <DxPopup
      :visible.sync="schedulePopupVisible"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="true"
      class="popUpLargeTitle"
      height="auto"
      width="auto"
      min-width="550px"
      :title="$t('orders.reviewDetails.sureScheduleReview')"
    >
      <div class="container" style="padding: 0">
        <div class="row">
          <div class="col-md-12">
            <div class="content-block mt-0">
              <p>
                {{ $t("orders.reviewDetails.scheduleReviewInfo") }}
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <dx-button
              style="margin-right: 15px"
              width="150px"
              :text="$t('shared.yes')"
              @click="onScheduleReviewButtonClick()"
              type="success"
            />

            <dx-button
              width="150px"
              :text="$t('shared.no')"
              type="primary"
              @click="closeSchedulePopUp()"
            />
          </div>
        </div>
      </div>
    </DxPopup>
    <DxPopup
      :visible.sync="cancelPopupVisible"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="true"
      class="popUpLargeTitle"
      height="auto"
      width="auto"
      min-width="550px"
      :title="$t('orders.orderDetails.sureCancelOrder')"
    >
      <div class="container" style="padding: 0">
        <dx-validation-group :ref="validationRefKey" name="validationGroup">
          <div class="row">
            <div class="col-md-12">
              <div class="content-block mt-0">
                <label class="required">
                  {{ $t("shared.cancellationReason") }}
                </label>
                <dx-select-box
                  :value.sync="selectedCancellationReason"
                  :data-source="cancellationReasonsList"
                  stylingMode="outlined"
                  display-expr="name"
                  value-expr="id"
                  :is-valid="isCancellationReasonValid"
                  @value-changed="revalidateCancellationReason()"
                  :placeholder="
                    $t('shared.select', {
                      dataField: $t('shared.cancellationReason'),
                    })
                  "
                >
                  <dx-validator :adapter="cancellationReasonAdapterConfig">
                    <dx-required-rule
                      :message="
                        $t('shared.isRequired', {
                          dataField: $t('shared.cancellationReason'),
                        })
                      "
                    />
                  </dx-validator>
                </dx-select-box>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <dx-button
                style="margin-right: 15px"
                width="150px"
                :text="$t('shared.yes')"
                @click="cancelOrderOrReview"
                type="success"
              />

              <dx-button
                width="150px"
                :text="$t('shared.no')"
                type="primary"
                @click="closeConfirmationPopUp()"
              />
            </div>
          </div>
        </dx-validation-group>
      </div>
    </DxPopup>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapActions, mapState} from "vuex";
import pageHeading from "../page-heading.vue";
import {DxButton, DxPopup, DxSelectBox} from "devextreme-vue";
import notify from "devextreme/ui/notify";
import Comments from "../../components/shared/comments";
import DxValidationGroup from "devextreme-vue/validation-group";
import OrderNotification from "../scheduling/order-notification";
import ReviewDetails from "../scheduling/review-details";
import OrderDetails from "../scheduling/order-details.vue";
import {orderStatuses} from "../../custom/config/workflows";
import subjects from "../../config/subjects";
import {
  isNotEmpty,
  isEmpty,
  isUnsavedComment,
} from "../../common/helperFunctions";
import {yesNoOptions, reviewModes} from "../../common/constants";
import $axios from "../../utils/axios-instance";
import {confirm} from "devextreme/ui/dialog";
import roleTypes from "../../config/roles";
import {DxValidator, DxRequiredRule} from "devextreme-vue/validator";

export default {
  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    pageHeading,
    DxButton,
    DxPopup,
    DxSelectBox,
    OrderNotification,
    ReviewDetails,
    OrderDetails,
    Comments,
    DxValidationGroup,
    DxValidator,
    DxRequiredRule,
  },
  async beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (
      !(this.$router.history.pending.name === "login-form") &&
      (await this.confirmStayInDirtyForm())
    ) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  created() {
    if (this.$route.name === "edit-order") {
      this.getCommentsList({orderId: this.$route.params.id});
    }
  },
  data() {
    return {
      orderId: this.$route.params.id,
      isInReviewerCapacityVisible: false,
      orderValidationRefKey: "orderValidationGroup",
      cancelPopupVisible: false,
      schedulePopupVisible: false,
      cancellationComment: null,
      selectedCancellationReason: null,
      cancellationReasonsList: [
        {
          id: 1,
          name: "Supplier phase out - due to IWAY",
        },
        {
          id: 2,
          name: "Supplier phase out - due to other biz. reason",
        },
        {
          id: 3,
          name: "Unclear Ingka participant",
        },
        {
          id: 4,
          name: "ICC not signed",
        },
        {
          id: 5,
          name: "Lack Reviewer capacity",
        },
        {
          id: 6,
          name: "Request by mistake",
        },
        {
          id: 7,
          name: "Risk mitigated by other means",
        },
        {
          id: 9,
          name: "Lack of IWAY implementation",
        },
        {
          id: 10,
          name: "Order no longer needed",
        },
        {
          id: 8,
          name: "Other reason",
        },
      ],
      validationRefKey: "cancellationReasonValidationGroup",
      isCancellationReasonValid: true,
      cancellationReasonAdapterConfig: {
        getValue: () => {
          return this.selectedCancellationReason;
        },
        bypass: () => {
          return false;
        },
        applyValidationResults: (e) => {
          this.isCancellationReasonValid = e.isValid;
        },
        validationRequestsCallbacks: [],
      },
      isRedirectingAfterUpdateSuccess: false,
      roleTypes: roleTypes,
      orderStatuses: orderStatuses
    };
  },
  methods: {
    ...mapActions("orders", [
      "postComment",
      "getCommentsList",
      "getComment",
      "updateComment",
      "deleteComment",
      "createOrder",
      "updateOrder",
      "getOrder",
      "scheduleReview",
      "updateReview",
      "reopenAssessment",
    ]),
    async onOrderCreateUpdateClick() {
      if (!this.orderValidationGroup.validate().isValid) return;
      this.isLoading = true;
      try {
        let requestBody = {
          ...this.getOrderDetails,
        };

        if (!this.orderDetails) {
          const updatedRequestBody = {
            ...requestBody,
            initialComments: this.initialComments,
          };
          await this.createOrder({requestBody: updatedRequestBody});
          this.isLoading = false;
          notify(
            this.$t("orders.scheduleReview.successfullySubmittedOrder"),
            "success",
          );
        } else {
          if (isUnsavedComment()) {
            let result = await this.unsavedChangesAlert(
              this.$t("shared.unsavedComment"),
              this.$t("shared.unsavedCommentsTitle"),
            );
            if (result) return;
          }
          await this.updateOrder({
            orderId: this.$route.params.id,
            requestBody: requestBody,
          });
          this.isLoading = false;
          notify(
            this.$t("orders.scheduleReview.successfullyUpdatedOrder"),
            "success",
          );
        }
        this.isRedirectingAfterUpdateSuccess = true;
        this.$router.push({name: "orders-list"});
      } catch (err) {
        this.isLoading = false;
        notify(err, "error");
      }
    },
    async onScheduleReviewButtonClick() {
      if (!this.orderValidationGroup.validate().isValid) {
        this.schedulePopupVisible = false;

        return;
      }
      this.schedulePopupVisible = false;
      this.isLoading = true;
      try {
        const requestBody = {
          orderId: parseInt(this.$route.params.id),
          ...this.getOrderDetails,
          ...this.getSchedulingDetails,
        };
        await this.scheduleReview({requestBody});
        this.isLoading = false;
        notify(
          this.$t("orders.scheduleReview.successfullySubmittedReview"),
          "success",
        );
        this.isRedirectingAfterUpdateSuccess = true;
        this.$router.push({name: "orders-list"});
      } catch (err) {
        this.isLoading = false;
        notify(err, "error");
      }
    },
    showInReviewerCapacity(e) {
      this.isInReviewerCapacityVisible = e;
    },
    async submitComment(comment) {
      try {
        await this.postComment({
          comment: comment,
          orderId: this.orderId,
        });

        notify(
          this.$t("orders.orderDetails.successfullyAddedComment"),
          "success",
        );
        this.getCommentsList({orderId: this.orderId});
      } catch (err) {
        notify(err, "error");
      }
    },
    async editCommentClick(e) {
      await this.getComment(e.commentsId);
    },
    async editComment(e) {
      try {
        let payload = {
          commentId: e.commentsId,
          comment: e.comments,
        };
        await this.updateComment(payload);

        notify(
          this.$t("orders.orderDetails.successfullyEditedComment"),
          "success",
        );
      } catch (error) {
        notify(error.response.data.message || error, "error");
      }
    },
    async deleteCommentClick(e) {
      let result = confirm(
        `<i>${this.$t("shared.sureDeleteComment")}</i>`,
        this.$t("shared.confirmDeletion"),
      );
      result.then(async (dialogResult) => {
        if (dialogResult) {
          try {
            await this.deleteComment(e.commentsId);

            notify(
              this.$t("orders.orderDetails.successfullyDeletedComment"),
              "success",
            );
            this.getCommentsList({orderId: this.orderId});
          } catch (error) {
            notify(error.response.data.message || error, "error");
          }
        }
      });
    },
    async onCancelButtonClick() {
      if (isUnsavedComment()) {
        let result = await this.unsavedChangesAlert(
          this.$t("shared.unsavedComment"),
          this.$t("shared.unsavedCommentsTitle"),
        );
        if (result) return;
      }
      this.cancelPopupVisible = !this.cancelPopupVisible;
    },
    async onUpdateReviewButtonClick() {
      if (isUnsavedComment()) {
        let result = await this.unsavedChangesAlert(
          this.$t("shared.unsavedComment"),
          this.$t("shared.unsavedCommentsTitle"),
        );
        if (result) return;
      }
      this.isLoading = true;
      try {
        const requestBody = {
          ...this.getOrderDetails,
          ...this.getSchedulingDetails,
        };
        await this.updateReview({
          orderId: this.$route.params.id,
          requestBody: requestBody,
        });
        this.isLoading = false;
        notify(
          this.$t("orders.scheduleReview.successfullySavedReview"),
          "success",
        );
        this.isRedirectingAfterUpdateSuccess = true;
        this.$router.push({name: "orders-list"});
      } catch (err) {
        this.isLoading = false;
        notify(err, "error");
      }
    },
    async onScheduleButtonClick() {
      if (isUnsavedComment()) {
        let result = await this.unsavedChangesAlert(
          this.$t("shared.unsavedComment"),
          this.$t("shared.unsavedCommentsTitle"),
        );
        if (result) return;
      }
      this.schedulePopupVisible = !this.schedulePopupVisible;
    },
    closeConfirmationPopUp() {
      return (this.cancelPopupVisible = false);
    },
    closeSchedulePopUp() {
      return (this.schedulePopupVisible = false);
    },
    async cancelOrderOrReview() {
      if (this.cancellationReasonValidationGroup.validate().isValid) {
        this.cancelPopupVisible = false;
        this.isLoading = true;
        try {
          await $axios.post(`/visit/${this.$route.params.id}/cancel`, {
            cancellationReason: this.selectedCancellationReason,
          });
          this.isLoading = false;
          notify(
            this.$t("orders.scheduleReview.successfullyCancelledReview"),
            "success",
          );
        } catch (err) {
          this.isLoading = false;
          notify(err, "error");
        }
        this.isRedirectingAfterUpdateSuccess = true;
        this.$router.replace("/orders-list");
      }
    },
    isInRole(role) {
      return this.$store.state.auth.user.roles.includes(role);
    },
    revalidateCancellationReason() {
      this.cancellationReasonAdapterConfig.validationRequestsCallbacks.forEach(
        (func) => {
          func();
        },
      );
    },
    hasValueChanged() {
      let hasValueChanged = false;
      const initialOrderState = this.getInitialOrderState;
      const currentOrderState = this.getCurrentOrderState;
      hasValueChanged = !(
        JSON.stringify(initialOrderState, (k, v) =>
          v === undefined ? null : v,
        ) ===
        JSON.stringify(currentOrderState, (k, v) =>
          v === undefined ? null : v,
        )
      );

      //schedule details
      if (this.orderDetails.hasScheduleDetails) {
        const initialReviewState = this.getInitialReviewState;
        const currentReviewState = this.getCurrentReviewState;
        hasValueChanged =
          hasValueChanged ||
          !(
            JSON.stringify(initialReviewState, (k, v) =>
              v === undefined ? null : v,
            ) ===
            JSON.stringify(currentReviewState, (k, v) =>
              v === undefined ? null : v,
            )
          );
      }

      return hasValueChanged;
    },
    unsavedChangesAlert(message, title) {
      return confirm(`<i>${message}</i>`, title);
    },

    async confirmStayInDirtyForm() {
      return (
        this.$route.name === "edit-order" &&
        !this.isRedirectingAfterUpdateSuccess &&
        (isUnsavedComment() || this.hasValueChanged()) &&
        !(await this.unsavedChangesAlert(
          this.$t("shared.unsavedChangesConfirmation"),
          this.$t("shared.unsavedChangesTitle"),
        ))
      );
    },
    async onReopenAssessmentButtonClick() {
      if (isUnsavedComment()) {
        let result = await this.unsavedChangesAlert(
          this.$t("shared.unsavedComment"),
          this.$t("shared.unsavedCommentsTitle"),
        );
        if (result) return;
      }
      if (
        this.orderStatus !== orderStatuses.submitted &&
        this.orderStatus !== orderStatuses.completed
      )
        return;
      this.isLoading = true;
      try {
        await this.reopenAssessment(this.$route.params.id);
        this.isLoading = false;
        notify(
          this.$t("orders.scheduleReview.successfullyReopenedReview"),
          "success",
        );
        this.isRedirectingAfterUpdateSuccess = true;
        this.$router.push({name: "orders-list"});
      } catch (err) {
        this.isLoading = false;
        notify(err, "error");
      }
    },
  },
  computed: {
    ...mapState("orders", ["comments"]),
    ...mapState("organisations", ["selectedAuditYear"]),
    ...mapState("auth", ["user"]),
    ...mapFields("orders", [
      "isLoading",
      "orderDetails",
      "reviewDetails",
      "orderStatus",
      "selectedOrganisation",
      "selectedTertial",
      "selectedReviewMode",
      "selectedReviewType",
      "selectedLevels",
      "selectedOrderer",
      "selectedLevelOfReviewMultiple",
      "selectedSiteCategoryMultiple",
      "notification",
      "delayNotification",
      "notificationDelayDate",
      "selectedLeadReviewer",
      "selectedMultipleSupportReviewers",
      "reviewEndDate",
      "reviewStartDate",
      "estReportDueDate",
      "estCAPDate",
      "estDeadlineApprovalDate",
      "selectedIsThereReviewerCapacity",
      "selectedCAPStatus",
      "selectedFollowupStatus",
      "selectedScopeOfReview",
      "initialComments",
    ]),

    translatedPageTitle() {
      return this.$t("orders.scheduleReview.title");
    },
    breadCrumbs() {
      return [
        this.$t("orders.scheduleReview.breadCrumbTitle"),
        this.translatedPageTitle,
      ];
    },
    orderValidationGroup() {
      return this.$refs[this.orderValidationRefKey].instance;
    },
    cancellationReasonValidationGroup() {
      return this.$refs[this.validationRefKey].instance;
    },
    getOrderDetails() {
      let orderDetails = {
        ordererId: this.selectedOrderer,
        fiscalYear: this.selectedAuditYear,
        tertial: this.selectedTertial,
        reviewMode: this.selectedReviewMode,
        organisationId: this.selectedOrganisation[0],
        reviewType: this.selectedReviewType,
        levelsOfReview: this.selectedLevelOfReviewMultiple,
        siteCategories: this.selectedSiteCategoryMultiple,
        scopeOfReview: this.selectedScopeOfReview,
        notification: this.notification,
        notificationDate:
          this.notificationDelayDate == null
            ? null
            : this.$moment(this.notificationDelayDate)
                .format("YYYY-MM-DDTHH:mm:ss"),
      };
      return orderDetails;
    },
    getSchedulingDetails() {
      let schedulingDetails = {
        isThereReviewerCapacity:
          this.selectedIsThereReviewerCapacity === yesNoOptions.Yes,
        leadReviewer: this.selectedLeadReviewer,
        supportReviewers: this.selectedMultipleSupportReviewers,
        startDate:
          this.reviewStartDate == null
            ? null
            : this.$moment(this.reviewStartDate)
                .format("YYYY-MM-DDTHH:mm:ss"),
        endDate:
          this.reviewEndDate == null
            ? null
            : this.$moment(this.reviewEndDate)
                .format("YYYY-MM-DDTHH:mm:ss"),
        estReportDueDate:
          this.estReportDueDate == null
            ? null
            : this.$moment(this.estReportDueDate)
                .format("YYYY-MM-DDTHH:mm:ss"),
        estCAPDate:
          this.estCAPDate == null
            ? null
            : this.$moment(this.estCAPDate)
                .format("YYYY-MM-DDTHH:mm:ss"),
        estDeadlineApprovalDate:
          this.estDeadlineApprovalDate == null
            ? null
            : this.$moment(this.estDeadlineApprovalDate)
                .format("YYYY-MM-DDTHH:mm:ss"),
        capStatus: this.selectedCAPStatus,
        followupStatus: this.selectedFollowupStatus,
      };
      return schedulingDetails;
    },
    setButtonText() {
      switch (this.orderStatus) {
        case orderStatuses.ordered:
        case orderStatuses.scheduled:
          return this.$can("update", subjects.order.subject, "cancelOrder")
            ? this.$t("orders.orderList.cancelOrder")
            : null;
        case orderStatuses.inProgress:
        case orderStatuses.submitted:
        case orderStatuses.completed:
          return this.$can("update", subjects.schedule.subject, "cancelReview")
            ? this.$t("orders.orderList.cancelReview")
            : null;
        default:
          return this.$t("orders.orderList.cancelOrder");
      }
    },
    canCancel() {
      switch (this.orderStatus) {
        case orderStatuses.ordered:
        case orderStatuses.scheduled:
          return this.$can("update", subjects.order.subject, "cancelOrder");
        case orderStatuses.inProgress:
        case orderStatuses.submitted:
        case orderStatuses.completed:
          return this.$can("update", subjects.schedule.subject, "cancelReview");
        default:
          return false;
      }
    },
    isOwner() {
      // TODO: revisit
      return true;
      // return (
      //   this.selectedOrderer === this.user.id ||
      //   (isEmpty(this.selectedLeadReviewer) &&
      //     (!this.selectedMultipleSupportReviewers ||
      //       this.selectedMultipleSupportReviewers.length === 0)) ||
      //   this.selectedLeadReviewer === this.user.id ||
      //   (this.selectedMultipleSupportReviewers &&
      //     this.selectedMultipleSupportReviewers.includes(this.user.id)) ||
      //   this.isInRole(roleTypes.admin.role)
      // );
    },
    isDisabled() {
      return (
        this.orderStatus &&
        this.orderStatus === "Ordered" &&
        this.isInRole(roleTypes.orderer.role) &&
        !(this.selectedOrderer === this.user.id)
      );
    },
    isReviewerCapacity() {
      return (
        this.selectedReviewMode === reviewModes.SelfAssessment ||
        (this.selectedIsThereReviewerCapacity === yesNoOptions.Yes &&
          this.selectedReviewMode === reviewModes.ComplianceReview)
      );
    },
    getInitialOrderState() {
      return {
        ordererId: this.orderDetails.ordererId,
        fiscalYear: this.orderDetails.fiscalYear,
        tertial: this.orderDetails.tertial,
        reviewMode: this.orderDetails.reviewMode,
        organisationId: this.orderDetails.organisationId,
        reviewType: this.orderDetails.reviewType,
        levelsOfReview:
          this.orderDetails.levelsOfReview &&
          this.orderDetails.levelsOfReview.length > 0
            ? [...this.orderDetails.levelsOfReview].sort()
            : null,
        siteCategories:
          this.orderDetails.siteCategories &&
          this.orderDetails.siteCategories.length > 0
            ? [...this.orderDetails.siteCategories].sort()
            : null,
        scopeOfReview:
          this.orderDetails.scopeOfReview &&
          this.orderDetails.scopeOfReview.length > 0
            ? [...this.orderDetails.scopeOfReview].sort()
            : null,
        notification: this.orderDetails.notification,
        notificationDate: this.orderDetails.notificationDate
          ? this.orderDetails.notificationDate.slice(0, 10)
          : null,
      };
    },
    getCurrentOrderState() {
      return {
        ordererId: this.selectedOrderer,
        fiscalYear: this.selectedAuditYear,
        tertial: this.selectedTertial,
        reviewMode: this.selectedReviewMode,
        organisationId: this.selectedOrganisation[0],
        reviewType: this.selectedReviewType,
        levelsOfReview:
          this.selectedLevelOfReviewMultiple &&
          this.selectedLevelOfReviewMultiple.length > 0
            ? [...this.selectedLevelOfReviewMultiple].sort()
            : null,
        siteCategories:
          this.selectedSiteCategoryMultiple &&
          this.selectedSiteCategoryMultiple.length > 0
            ? [...this.selectedSiteCategoryMultiple].sort()
            : null,
        scopeOfReview:
          this.selectedScopeOfReview && this.selectedScopeOfReview.length > 0
            ? [...this.selectedScopeOfReview].sort()
            : null,
        notification: this.notification,
        notificationDate:
          this.notificationDelayDate == null
            ? null
            : this.$moment
                .utc(this.notificationDelayDate)
                .local()
                .format("YYYY-MM-DDTHH:mm:ss")
                .slice(0, 10),
      };
    },
    getInitialReviewState() {
      return {
        isThereReviewerCapacity: this.reviewDetails.isThereReviewerCapacity,
        leadReviewer: this.reviewDetails.leadReviewer,
        supportReviewers:
          this.reviewDetails.supportReviewers &&
          this.reviewDetails.supportReviewers.length > 0
            ? [...this.reviewDetails.supportReviewers].sort()
            : null,
        startDate: this.reviewDetails.startDate
          ? this.reviewDetails.startDate.slice(0, 10)
          : null,
        endDate: this.reviewDetails.endDate
          ? this.reviewDetails.endDate.slice(0, 10)
          : null,
        estReportDueDate: this.reviewDetails.estReportDueDate
          ? this.reviewDetails.estReportDueDate.slice(0, 10)
          : null,
        estCAPDate: this.reviewDetails.estCAPDate
          ? this.reviewDetails.estCAPDate.slice(0, 10)
          : null,
        estDeadlineApprovalDate: this.reviewDetails.estDeadlineApprovalDate
          ? this.reviewDetails.estDeadlineApprovalDate.slice(0, 10)
          : null,
        capStatus: this.reviewDetails.capStatus,
        followupStatus: this.reviewDetails.followupStatus,
      };
    },
    getCurrentReviewState() {
      return {
        isThereReviewerCapacity:
          this.selectedIsThereReviewerCapacity === yesNoOptions.Yes,
        leadReviewer: this.selectedLeadReviewer,
        supportReviewers:
          this.selectedMultipleSupportReviewers &&
          this.selectedMultipleSupportReviewers.length > 0
            ? [...this.selectedMultipleSupportReviewers].sort()
            : null,
        startDate:
          this.reviewStartDate == null
            ? null
            : this.$moment
                .utc(this.reviewStartDate)
                .local()
                .format("YYYY-MM-DDTHH:mm:ss")
                .slice(0, 10),
        endDate:
          this.reviewEndDate == null
            ? null
            : this.$moment
                .utc(this.reviewEndDate)
                .local()
                .format("YYYY-MM-DDTHH:mm:ss")
                .slice(0, 10),
        estReportDueDate:
          this.estReportDueDate == null
            ? null
            : this.$moment
                .utc(this.estReportDueDate)
                .local()
                .format("YYYY-MM-DDTHH:mm:ss")
                .slice(0, 10),
        estCAPDate:
          this.estCAPDate == null
            ? null
            : this.$moment
                .utc(this.estCAPDate)
                .local()
                .format("YYYY-MM-DDTHH:mm:ss")
                .slice(0, 10),
        estDeadlineApprovalDate:
          this.estDeadlineApprovalDate == null
            ? null
            : this.$moment
                .utc(this.estDeadlineApprovalDate)
                .local()
                .format("YYYY-MM-DDTHH:mm:ss")
                .slice(0, 10),
        capStatus: this.selectedCAPStatus,
        followupStatus: this.selectedFollowupStatus,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.loading::before {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff52;
  background-image: url(../../assets/loader.gif);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 600px;
  content: "_";
  color: transparent;
  z-index: 99999;
}
</style>
