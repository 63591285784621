export default Object.freeze([
  {
    dataField: "dates",
    caption: "reporting.charts.openedFindingsSeriesTitle",
    color: "#f5a623",
  },
  {
    dataField: "dates",
    caption: "reporting.charts.closedFindingsSeriesTitle",
    color: "#00a700",
  },
]);
