import $axios from "../../utils/axios-instance.js";

export default {
    namespaced: true,
    state: {
        numberOfOutstandingFindings: null,
    },
    mutations: {
        setNumberOfOutstandingFindings: (state, n) => state.numberOfOutstandingFindings = n.numberOfOutstandingFindings,
    },

    actions: {
        getNumberOfOutstandingFindings: ({commit}) => $axios.get(`dashboard/outstandingFindings`)
                     .then(x => {
                         commit("setNumberOfOutstandingFindings", x.data);
                        })
                     .catch(x => console.error('Error while getting number of outstanding findings', x)),
    },
}