<template>
  <div v-if="warningZone" class="logout-container">
    <div class="aligner">
      <div class="aligner-item">
        <div style="margin-top: -136px">
          <img style="width: 150px" src="../../assets/logos/ikea-logo.svg" />
        </div>
        <h1 class="mb-5">
          <i class="far fa-clock mr-3"></i> {{ $t("sessionExpiry.title") }}
        </h1>
        <p class="mb-5">
          {{ $t("sessionExpiry.logoutMessage") }} <br />{{
            $t("sessionExpiry.confirmActivity")
          }}
        </p>

        <dx-button
          type="default"
          :text="$t('sessionExpiry.extendButton')"
          class="btn btn-large mb-3"
          icon="check"
        />
      </div>
    </div>
    <!-- <img class="session-logout-top-bg" src="../../assets/timeout-bg2.png" /> -->
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";

export default {
  components: {
    DxButton,
  },
  data: function () {
    return {
      events: ["click", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      warningZone: false,
      interval: null,
      warningLength: 780000,
      logoutLength: 900000,
      timerLength: 10000,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer);
    }, this);

    this.interval = window.setInterval(() => {
      this.checkTimer();
    }, this.timerLength);

    this.setTimers();
  },
  destroyed() {
    this.events.forEach(function (event) {
      window.removeEventListener(event, this.resetTimer);
    }, this);
    window.clearInterval(this.interval);
    this.resetTimer();
  },
  methods: {
    setTimers: function () {
      this.warningTimer = setTimeout(this.warningMessage, this.warningLength);
      this.logoutTimer = setTimeout(this.logoutUser, this.logoutLength);
      localStorage.setItem("userSessionStartTime", new Date().getTime());
      localStorage.setItem("userCurrentTime", new Date().getTime());
      this.warningZone = false;
    },
    warningMessage: function () {
      if (
        localStorage.getItem("userCurrentTime") -
          localStorage.getItem("userSessionStartTime") <
          this.logoutLength &&
        localStorage.getItem("userCurrentTime") -
          localStorage.getItem("userSessionStartTime") >=
          this.warningLength
      ) {
        this.warningZone = true;
      }
    },
    logoutUser: function () {
      if (
        localStorage.getItem("userCurrentTime") -
          localStorage.getItem("userSessionStartTime") >=
        this.logoutLength
      ) {
        let authMethod = this.user.authMethod;
        this.$store.dispatch("logout");
        this.$store.dispatch("auth/logout");
        if (authMethod == "Federated") {
          window.location(
            window.location.hostname + "/api/security/wsfed-signout",
          );
        } else {
          this.$router.push({
            path: "/login-form",
            query: {redirect: this.$route.path},
          });
        }
      }
    },
    checkTimer: function () {
      localStorage.setItem("userCurrentTime", new Date().getTime());
      if (
        localStorage.getItem("userCurrentTime") -
          localStorage.getItem("userSessionStartTime") >=
        this.logoutLength
      ) {
        this.logoutUser();
      } else if (
        localStorage.getItem("userCurrentTime") -
          localStorage.getItem("userSessionStartTime") <
          this.logoutLength &&
        localStorage.getItem("userCurrentTime") -
          localStorage.getItem("userSessionStartTime") >=
          this.warningLength
      ) {
        this.warningMessage();
      }
    },
    resetTimer: function () {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
  },
};
</script>

<style scoped></style>
