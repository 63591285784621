<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="riskTypes"
      :placeholder="$t('reporting.filters.riskRatingPlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      styling-mode="outlined"
      :value.sync="riskRatings"
      :wrapItemText="true"
      apply-value-mode="instantly"
      :max-displayed-tags="1"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import DxTagBox from "devextreme-vue/tag-box";
import {riskTypes} from "../../common/constants";

export default {
  components: {
    DxTagBox,
  },
  computed: {
    ...mapFields("reportingFilters", ["riskRatings"], "getFilter", "setFilter"),
  },
  data() {
    return {
      riskTypes: Object.values(riskTypes),
    };
  },
};
</script>

<style lang="sass" scoped></style>
