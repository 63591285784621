<template>
  <span class="table-button" @click="onButtonClick()" :title="text">
    <i class="fas fa-play-circle"></i>
  </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
  methods: {
    onButtonClick() {
      // passing to parent gives us access to the row data 🚣
      this.$emit("assessmentButtonClicked");
    },
  },
};
</script>

<style></style>
