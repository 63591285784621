<template>
  <div v-if="filtersList.length">
    <dx-select-box
      :value="filter"
      :data-source="filtersList"
      stylingMode="outlined"
      display-expr="name"
      value-expr="id"
      @value-changed="selectedFilterChanged"
    ></dx-select-box>
  </div>
</template>

<script>
import {DxSelectBox} from "devextreme-vue";
import {mapState} from "vuex";

export default {
  components: {
    DxSelectBox,
  },
  props: {
    filtersList: null,
  },
  created() {},
  methods: {
    selectedFilterChanged(e) {
      this.$store.dispatch("sharedComponents/updateFilter", e.value);
    },
  },
  computed: {
    ...mapState("sharedComponents", ["filter"]),
  },
};
</script>

<style></style>
