<template>
  <div class="radial-progress" :data-progress="progressValue">
    <div class="circle">
      <div class="mask full">
        <div class="fill"></div>
      </div>
      <div class="mask half">
        <div class="fill"></div>
        <div class="fill fix"></div>
      </div>
      <div class="shadow"></div>
    </div>
    <div class="inset">
      <div class="percentage">
        <div class="numbers">
          <span>-</span>
          <span style="color: #1e1e1e" v-for="(n, i) in 101" :key="i"
            >{{ i }}%</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progressValue: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped lang="scss">
.radial-progress {
  background-color: white;
}
</style>
