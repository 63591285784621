import {orderStatuses} from "../../custom/config/workflows";

export default [
  {
    // Compulsory
    dataField: "id",
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: "supplier",
    caption: "orders.orderList.supplierName",
    visible: true,
  },
  {
    dataField: "site",
    caption: "orders.orderList.siteName",
    visible: true,
  },
  {
    dataField: "country",
    caption: "orders.orderList.country",
    visible: true,
  },
  {
    dataField: "reviewType",
    caption: "orders.orderList.reviewType",
    visible: true,
  },
  {
    dataField: "status",
    caption: "orders.orderList.status",
    lookup: {
      dataSource: {
        store: {
          type: "array",
          data: [
            {
              id: orderStatuses.ordered,
              name: "orders.orderList.ordered",
            },
            {
              id: orderStatuses.inProgress,
              name: "orders.orderList.inProgress",
            },
            {
              id: orderStatuses.submitted,
              name: "orders.orderList.submitted",
            },
            {
              id: orderStatuses.scheduled,
              name: "orders.orderList.scheduled",
            },
            {
              id: orderStatuses.cancelled,
              name: "orders.orderList.cancelled",
            },
            {
              id: orderStatuses.completed,
              name: "orders.orderList.completed",
            },
          ],
          key: "id",
        },
      },
      valueExpr: "id", // contains the same values as the "statusId" field provides
      displayExpr: "name", // provides display values
    },
    cellTemplate: "status-template",
    visible: true,
  },
  {
    dataField: "openCorrectiveActions",
    caption: "orders.orderList.openCorrectiveActions",
    visible: true,
    alignment: "left",
  },
  {
    dataField: "leadReviewer",
    caption: "orders.orderList.leadReviewer",
    visible: true,
  },
  {
    dataField: "reviewStartDate",
    caption: "orders.orderList.reviewStartDate",
    dataType: "date",
    format: "dd/MMM/yyyy",
    visible: true,
  },
  {
    dataField: "tertial",
    caption: "orders.orderList.tertial",
    cellTemplate: "tertial-template",
    visible: true,
  },
  {
    dataField: "reviewMode",
    caption: "orders.orderList.reviewMode",
    visible: false,
  },
  {
    dataField: "orderer",
    caption: "orders.orderList.orderer",
    visible: false,
  },
  {
    dataField: "fiscalYear",
    caption: "orders.orderList.fiscalYear",
    visible: false,
  },

  {
    dataField: "scopeOfReview",
    caption: "orders.orderList.scopeOfReview",
    visible: false,
    cellTemplate: "scopeOfReview-template",
    allowHeaderFiltering: false,
    dataType: "string",
    width: 250,
    calculateFilterExpression: function (
      filterValue,
      selectedFilterOperation,
      target,
    ) {
      if (target === "filterRow") {
        return function (data) {
          for (let i = 0; i < data.scopeOfReview.length; i++) {
            const scopeOfReview = data.scopeOfReview[i].name;
            if (scopeOfReview.toLowerCase().includes(filterValue.toLowerCase()))
              return true;
          }
          return false;
        };
      }
    },
  },
  {
    dataField: "levelsOfReview",
    caption: "orders.orderList.levelsOfReview",
    visible: false,
    cellTemplate: "levelsOfReview-template",
    allowHeaderFiltering: false,
    dataType: "string",
    width: 250,
    calculateFilterExpression: function (
      filterValue,
      selectedFilterOperation,
      target,
    ) {
      if (target === "filterRow") {
        return function (data) {
          for (let i = 0; i < data.levelsOfReview.length; i++) {
            const levelOfReview = data.levelsOfReview[i].name;
            if (levelOfReview.toLowerCase().includes(filterValue.toLowerCase()))
              return true;
          }
          return false;
        };
      }
    },
  },
  {
    dataField: "siteCategories",
    caption: "orders.orderList.siteCategories",
    visible: false,
    cellTemplate: "siteCategories-template",
    allowHeaderFiltering: false,
    dataType: "string",
    width: 250,
    calculateFilterExpression: function (
      filterValue,
      selectedFilterOperation,
      target,
    ) {
      if (target === "filterRow") {
        return function (data) {
          for (let i = 0; i < data.siteCategories.length; i++) {
            const siteCategory = data.siteCategories[i].name;
            if (siteCategory.toLowerCase().includes(filterValue.toLowerCase()))
              return true;
          }
          return false;
        };
      }
    },
  },
  {
    dataField: "notification",
    caption: "orders.orderList.announcedOrUnannounced",
    visible: false,
  },
  {
    dataField: "notificationDate",
    caption: "orders.orderList.notificationDate",
    visible: false,
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "delayNotification",
    caption: "orders.orderList.delayNotification",
    visible: false,
  },
  {
    dataField: "supportReviewers",
    caption: "orders.orderList.supportReviewers",
    visible: false,
    cellTemplate: "supportReviewers-template",
    allowHeaderFiltering: false,
    dataType: "string",
    width: 250,
    calculateFilterExpression: function (
      filterValue,
      selectedFilterOperation,
      target,
    ) {
      if (target === "filterRow") {
        return function (data) {
          for (let i = 0; i < data.supportReviewers.length; i++) {
            const supportReviewers = data.supportReviewers[i].name;
            if (
              supportReviewers.toLowerCase().includes(filterValue.toLowerCase())
            )
              return true;
          }
          return false;
        };
      }
    },
  },
  {
    dataField: "businessOwners",
    caption: "orders.orderList.businessOwners",
    visible: false,
    cellTemplate: "businessOwners-template",
    allowHeaderFiltering: false,
    dataType: "string",
    width: 250,
    calculateFilterExpression: function (
      filterValue,
      selectedFilterOperation,
      target,
    ) {
      if (target === "filterRow") {
        return function (data) {
          for (let i = 0; i < data.businessOwners.length; i++) {
            const businessOwners = data.businessOwners[i].name;
            if (
              businessOwners.toLowerCase().includes(filterValue.toLowerCase())
            )
              return true;
          }
          return false;
        };
      }
    },
  },
  {
    dataField: "reviewParticipants",
    caption: "orders.orderList.reviewParticipants",
    visible: false,
    cellTemplate: "reviewParticipants-template",
    allowHeaderFiltering: false,
    dataType: "string",
    width: 250,
    calculateFilterExpression: function (
      filterValue,
      selectedFilterOperation,
      target,
    ) {
      if (target === "filterRow") {
        return function (data) {
          for (let i = 0; i < data.reviewParticipants.length; i++) {
            const reviewParticipants = data.reviewParticipants[i].name;
            if (
              reviewParticipants
                .toLowerCase()
                .includes(filterValue.toLowerCase())
            )
              return true;
          }
          return false;
        };
      }
    },
  },
  {
    dataField: "complianceStatus",
    caption: "orders.orderList.complianceStatus",
    visible: false,
  },
  {
    dataField: "complianceRate",
    cellTemplate: "complianceRate-template",
    caption: "orders.orderList.complianceRate",
    visible: false,
    alignment: "left",
  },
  {
    dataField: "riskScore",
    caption: "orders.orderList.riskRating",
    visible: false,
    alignment: "left",
  },
  {
    dataField: "reviewEndDate",
    caption: "orders.orderList.reviewEndDate",
    visible: false,
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "estCapDate",
    caption: "orders.orderList.estCapDate",
    visible: false,
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "estReportDueDate",
    caption: "orders.orderList.estReportDueDate",
    visible: false,
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "estDeadlineApprovalDate",
    caption: "orders.orderList.estDeadlineApprovalDate",
    visible: false,
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "capStatus",
    caption: "orders.orderList.capStatus",
    cellTemplate: "capStatus-cell-template",
    visible: false,
    alignment: "left",
    lookup: {
      dataSource: {
        store: {
          type: "array",
          data: [
            {
              id: 1,
              name: "orders.orderList.capProcess",
            },
            {
              id: 3,
              name: "orders.orderList.capProcessExtensionByReviewer",
            },
            {
              id: 4,
              name: "orders.orderList.capProcessExtensionByICGorGIF",
            },
            {
              id: 5,
              name: "orders.orderList.capProcessUnableToVerify",
            },
            {
              id: 6,
              name: "orders.orderList.capProcessVerified",
            },
            {
              id: 7,
              name: "orders.orderList.iaNoFollowUpNeeded",
            },
          ],
          key: "id",
        },
      },
      valueExpr: "id", // contains the same values as the "statusId" field provides
      displayExpr: "name", // provides display values
    },
  },

  {
    dataField: "followupStatus",
    caption: "orders.orderList.followupStatus",
    visible: false,
  },
  {
    dataField: "isThereReviewerCapacity",
    caption: "orders.orderList.isThereReviewerCapacity",
    visible: false,
  },
  {
    dataField: "totalCorrectiveActions",
    caption: "orders.orderList.totalCorrectiveActions",
    visible: false,
    alignment: "left",
  },
  {
    cellTemplate: "order-button-cell-template",
    headerCellTemplate: "clear-filter-cell-template",
    fixed: true,
    fixedPosition: "right",
    visible: true,
    width: "5px",
  },
  {
    dataField: "cancellationReason",
    caption: "orders.orderList.cancellationReason",
    visible: false,
  },
];
