import {getFieldValue, getSecureImage} from "../../common/helperFunctions";
import $axios from "../../utils/axios-instance";

const getDefaultState = () => {
  return {
    orgDetails: {},
    formData: {},
    auditYears: [],
    selectedAuditYear: null,
    comments: [],
    comment: null,
    selectedIngkaSiteType: null,
    selectedOrgType: null,
    generalAttachments: [],
    ignkaInternalAttachments: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),

  actions: {
    resetOrganisationState({commit}) {
      commit("resetState");
    },
    getOrgDetails({commit}, id) {
      $axios
        .get(`/Organisations/${id}`)
        .then((response) => commit("setDetails", response.data));
    },
    getAuditYears({commit}, selectCurrentPeriod) {
      return new Promise((resolve, reject) => {
        $axios
          .get(`/Config/GetAuditYears`)
          .then((response) => {
            commit("setAuditYears", response.data);
            if (selectCurrentPeriod) {
              let currentDate = new Date();

              const currentObject = response.data.filter((e) => {
                let ps = new Date(e.periodStart);
                let pe = new Date(e.periodEnd);

                return ps < currentDate && pe > currentDate;
              })[0];

              commit("setSelectedAuditYear", currentObject.id);
            }
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateSelectedAuditYear({commit}, id) {
      commit("setSelectedAuditYear", id);
    },
    async postComment({dispatch}, payload) {
      try {
        await $axios.post(`/organisations/${payload.organisationId}/comment`, {
          comment: payload.comment,
        });
      } catch (err) {
        throw new Error(err);
      }
    },
    async getCommentsList({commit}, payload) {
      try {
        let response = await $axios.get(
          `/organisations/${payload.organisationId}/comments`,
        );

        let comments = [];
        for (let comment of response.data) {
          if (comment.pictureUrl) {
            comment = {
              ...comment,
              pictureUrl: getSecureImage(comment.pictureUrl),
            };
          }
          comments.push(comment);
        }
        commit("setComments", comments);
      } catch (err) {
        throw new Error(err);
      }
    },
    async updateComment({commit}, payload) {
      try {
        await $axios.put(
          `/organisations/${payload.commentId}/comment`,
          payload,
        );
      } catch (err) {
        throw new Error(err);
      }
    },
    async getComment({commit}, commentId) {
      try {
        let response = await $axios.get(`/organisations/${commentId}/comment`);

        commit("setComment", response.data);
      } catch (err) {
        throw new Error(err);
      }
    },
    async deleteComment({commit}, commentId) {
      try {
        await $axios.delete(`/organisations/${commentId}/comment`);
      } catch (err) {
        throw new Error(err);
      }
    },
    async getAttachmentsList({commit}, payload) {
      try {
        let response = await $axios.get(
          `/organisations/${payload.organisationId}/attachments`,
        );
        if (response && response.data) commit("setAttachments", response.data);
      } catch (err) {
        throw new Error(err);
      }
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    setDetails(state, response) {
      state.formData = response;
      const details = response.organisationDetails;
      details.forEach(function (value) {
        state.orgDetails[value.fieldCode] = getFieldValue(value);
      }, this);
    },
    setSelectedAuditYear(state, selectedAuditYear) {
      state.selectedAuditYear = selectedAuditYear;
    },
    setAuditYears(state, auditYears) {
      state.auditYears = auditYears;
    },
    setComments(state, comments) {
      state.comments = comments;
    },
    setComment(state, comment) {
      state.comment = comment;
    },
    setAttachments(state, attachments) {
      state.ignkaInternalAttachments = attachments.filter(
        (x) => x.isInternalAttachment,
      );
      state.generalAttachments = attachments.filter(
        (x) => !x.isInternalAttachment,
      );
    },
  },
  getters: {},
};
