<template>
  <div>
    <div class="text-area-container">
      <dx-text-area
        styling-mode="outlined"
        v-model="textBoxContents"
        :height="100"
        :read-only="disabled"
        @value-changed="setSelectedAnswer($event)"
      />
    </div>
    <br />
  </div>
</template>

<script>
import DxTextArea from "devextreme-vue/text-area";
import {isNotEmpty} from "../../common/helperFunctions";
export default {
  components: {
    DxTextArea,
  },
  props: {
    answerOptions: {
      Type: Array,
    },
    questionId: {
      Type: Number,
    },
    preselectedValue: {
      Type: String,
      default: undefined,
    },
    question: {
      Type: Object,
    },
    disabled: {
      Type: Boolean,
    },
  },
  created() {
    if (this.preselectedValue !== undefined) {
      this.textBoxContents = this.preselectedValue;
    }
  },
  data() {
    return {
      textBoxContents: "",
    };
  },
  methods: {
    setSelectedAnswer(e) {
      if (isNotEmpty(e.value)) this.$emit("set-answer", e);
      else {
        if (e.value === "") this.$emit("set-answer", e);
      }
    },
  },
  watch: {
    // whenever question changes, this function will run
    question: function (newValue) {
      if (newValue.answer) {
        this.textBoxContents = newValue.answer.value;
      } else {
        this.textBoxContents = null;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
