import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import App from "./App";
import router from "./router";
import appInfo from "./app-info";
import $axios from "./utils/axios-instance.js";
import store from "./store";
import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/custom.scss";
import i18n from "./i18n";
import enMessagesComp from "devextreme/localization/messages/en.json";
import {abilitiesPlugin} from "@casl/vue";
import ability from "./config/defineAbility";
import subjects from "./config/subjects";
import VueMeta from "vue-meta";

Vue.use(VueMeta);
Vue.use(abilitiesPlugin, ability);
Vue.prototype.$subjects = subjects;

Vue.config.productionTip = false;
Vue.prototype.$appInfo = appInfo;
Vue.prototype.$enMessagesComp = enMessagesComp;
window.Vue = Vue;

const axiosStatic = $axios;
let vuexStore = store;

Vue.prototype.$http = axiosStatic;
vuexStore.$http = axiosStatic;

Vue.use(require("vue-moment"));

new Vue({
  router,
  i18n,
  render: (h) => h(App),
  store,
}).$mount("#app");
