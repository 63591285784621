export default {
  computed: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
  methods: {
    backOrPush(destination) {
      if (this.hasHistory) {
        this.$router.go(-1);
      } else {
        this.$router.push({name: destination});
      }
    },
  },
};
