<template>
  <div>
    <DxDataGrid
      :onRowPrepared="hideExpandForEmptyDetails"
      id="grid-container"
      :show-borders="true"
      :data-source="dataSource"
      :column-auto-width="true"
      key-expr="id"
      width="100%"
      :columns="auditLogColDefs"
      word-wrap-enabled="true"
    >
      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{data: logEntry}">
        <DetailTemplate :details="detailsData" :template-data="logEntry" />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {DxDataGrid, DxMasterDetail} from "devextreme-vue/data-grid";
import {masterColDefs} from "../../custom/audit/auditColumnDefinitions";
import {auditLogOperationType} from "../../common/constants";
import DetailTemplate from "./audit-log-details.vue";
import {toCamelCase} from "../../common/helperFunctions";
import $axios from "../../utils/axios-instance";
import {mapActions} from "vuex";

export default {
  components: {
    DxDataGrid,
    DxMasterDetail,
    DetailTemplate,
  },
  created() {
    let that = this;
    $axios
      .get(`${this.dataPath}${this.elementId}`)
      .then((data) => {
        const entriesResponse = JSON.parse(JSON.stringify(data)).data.entries;
        that.dataSource = entriesResponse
          .map((entry) => {
            const description = this.getDescription(entry);
            return {
              ...entry,
              description: description,
            };
          }, this)
          .map((entry, entryIndex) =>
            entry.details
              ? {
                  ...entry,
                  id: entryIndex,
                  details: entry.details.map((details, detailsIndex) => {
                    return {
                      ...details,
                      id: detailsIndex,
                      logEntryId: entryIndex,
                    };
                  }),
                }
              : {...entry, id: entryIndex},
          );

        that.detailsData = that.dataSource.reduce(
          (acc, entry) => (entry.details ? acc.concat(entry.details) : acc),
          [],
        );
      })
      .catch();
  },
  props: {
    elementId: null,
    dataPath: null,
  },
  methods: {
    ...mapActions("auditLogs", ["getAuditLogs"]),
    hideExpandForEmptyDetails(e) {
      if (e.rowType !== "data") {
        return;
      }
      if (
        !this.detailsData.some((x) => x.logEntryId == e.data.id) ||
        this.detailsData[0].fieldName == "Comment"
      ) {
        let element = e.rowElement.querySelector(".dx-command-expand");

        element.addEventListener("dxclick", function (event) {
          event.stopPropagation();
        });
        element.addEventListener("click", function (event) {
          event.stopPropagation();
        });
        element.addEventListener("keydown", function (event) {
          if (event.keyCode === 13) {
            event.stopPropagation();
          }
        });

        element.firstChild.classList.remove("dx-datagrid-group-closed");
      }
    },
    getDescription(entry) {
      const descriptionTranslation = this.activityDescriptionTranslationMapping.find(
        (x) => x.id === entry.operationType,
      ).name;
      switch (entry.operationType) {
        case auditLogOperationType.PropertyModified:
          return this.$t(descriptionTranslation, {
            noOfChanges: entry.details ? entry.details.length : 0,
          });
        case auditLogOperationType.Comment:
          return entry.details[0].newValue;
        default:
          return this.$t(descriptionTranslation);
      }
    },
  },
  data() {
    return {
      dataSource: null,
      detailsData: null,
    };
  },
  computed: {
    auditLogColDefs() {
      // TODO:
      // this should be a mixin
      let colDefs = JSON.parse(JSON.stringify(masterColDefs));
      colDefs.forEach((element) => {
        if (element.dataField) {
          element.caption = this.$t(
            `auditLogs.auditLogsList.${toCamelCase(element.dataField)}`,
          );
        }
        if (element.dataField === "operationType") {
          element.lookup = {
            dataSource: this.translatedActivityTypes,
            valueExpr: "id",
            displayExpr: "name",
          };
        }
      });

      return colDefs;
    },
    translatedActivityTypes() {
      return Object.entries(auditLogOperationType).map(
        ([key, value]) => ({
          id: value,
          name: this.$t(`auditLogs.activityType.${toCamelCase(key)}`),
        }),
        this,
      );
    },
    activityDescriptionTranslationMapping() {
      return Object.entries(auditLogOperationType).map(
        ([key, value]) => ({
          id: value,
          name: `auditLogs.activityDescription.${toCamelCase(key)}`,
        }),
        this,
      );
    },
  },
};
</script>

<style></style>
