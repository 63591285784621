<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="categoryDataSource"
      :placeholder="$t('reporting.filters.categoryPlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      styling-mode="outlined"
      :value.sync="siteCategories"
      :wrapItemText="true"
      apply-value-mode="instantly"
      :max-displayed-tags="1"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import DxTagBox from "devextreme-vue/tag-box";
import $axios from "../../utils/axios-instance";

export default {
  components: {
    DxTagBox,
  },
  created() {
    this.loadCategories();
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["siteCategories"],
      "getFilter",
      "setFilter",
    ),
  },
  data() {
    return {
      categoryDataSource: [],
    };
  },
  methods: {
    async loadCategories() {
      const response = await $axios.get(`/orders/sitecategories`);
      this.categoryDataSource = [
        ...new Set(response.data.map((item) => item.category)),
      ];
    },
  },
};
</script>

<style lang="sass" scoped></style>
