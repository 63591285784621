import $axios from "../../utils/axios-instance.js";
import {getField, updateField} from "vuex-map-fields";

const getDefaultState = () => {
  return {
    assessmentProgrammesStatus: "",
    scheduledVisitStatus: "",
    auditorsStatus: "",
    scheduledVisit: null,
    selectedVisitType: null,
    availableAuditors: [],
    auditManagers: [],
    thirdParties: [],
    selectedThirdParty: null,
    assessmentProgrammes: [],
    selectedAssessmentProgrammes: [],
    selectedRetailerSingle: [],
    selectedRetailerMultiple: [],
    selectedAuditor: [],
    selectedAuditType: null,
    retailers: {},
    schedulingDetails: {},
    startDate: null,
    endDate: null,
    notificationDate: null,
    selectedAuditManager: null,
    auditTypes: [
      {val: "AuditorVisit", name: "Site Visit", fa: "fas fa-user-tie"},
      {
        val: "SelfAssessment",
        name: "Self Assessment",
        fa: "far fa-building",
      },
    ],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    getAvailableAuditors(
      {commit, state},
      {
        retailerId,
        selectedAuditorId,
        selectedAuditManagerId,
        startDate,
        endDate,
        thirdPartyId,
      },
    ) {
      return new Promise((resolve, reject) => {
        commit("setAuditorsStatus", "loading");
        $axios
          .get(`/Users/GetAuditors/${retailerId}`, {
            params: {
              RetailerId: retailerId,
              SelectedAuditorId: selectedAuditorId,
              SelectedAuditManagerId: selectedAuditManagerId,
              StartDate: startDate,
              EndDate: endDate,
              ThirdPartyId: thirdPartyId,
            },
          })
          .then((response) => {
            commit("setAuditorsStatus", "success");
            let auditors = response.data;

            if (state.selectedAuditManager) {
              auditors = auditors.filter(
                (x) => x.id !== state.selectedAuditManager.id,
              );
            }
            commit("setAvailableAuditors", auditors);
            resolve(auditors);
          })
          .catch((err) => {
            commit("setAuditorsStatus", "error");
            reject(err);
          });
      });
    },
    getScheduledVisit({commit}, visitId) {
      return new Promise((resolve, reject) => {
        commit("setScheduledVisitStatus", "loading");
        $axios
          .get(`/ScheduledVisit/${visitId}`)
          .then((response) => {
            commit("setScheduledVisitStatus", "success");
            commit("setScheduledVisit", response.data);
            resolve(response.data);
          })
          .catch((err) => {
            commit("setScheduledVisitStatus", "error");
            reject(err);
          });
      });
    },
    getThirdParties({commit}) {
      return new Promise((resolve, reject) => {
        $axios
          .get(`/organisations/thirdparties`)
          .then((response) => {
            commit("setThirdParties", response.data);
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    resetSchedulesState({commit}) {
      commit("resetState");
    },
    updateVisit({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("setScheduledVisitStatus", "loading");
        $axios
          .put(`ScheduledVisit/${payload.visitId}/Edit`, payload.requestBody)
          .then((response) => {
            commit("setScheduledVisitStatus", "success");
            resolve(response);
          })
          .catch((err) => {
            commit("setScheduledVisitStatus", "error");
            reject(err);
          });
      });
    },
    getAuditManagers({commit}, thirdPartyId) {
      return new Promise((resolve, reject) => {
        $axios
          .get(`/Users/GetAuditManagers/${thirdPartyId}`)
          .then((response) => {
            commit("setAuditManagers", response.data);
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateSelectedAuditor({commit}, auditor) {
      commit("setSelectedAuditor", auditor);
    },
    setDefaultAuditManager({commit}, user) {
      commit("setDefaultAuditManager", user);
    },
    updateSelectedAuditType({commit}, auditType) {
      commit("setSelectedAuditType", auditType);
    },
    getAssessmentProgrammes({commit}, visitType) {
      return new Promise((resolve, reject) => {
        commit("setAssessmentProgrammesStatus", "loading");
        $axios
          .get(`scheduledVisit/GetAssessmentProgrammes/${visitType}`)
          .then((response) => {
            commit("setAssessmentProgrammesStatus", "success");
            commit("setAssessmentProgrammes", response.data);
            resolve(response);
          })
          .catch((err) => {
            commit("setAssessmentProgrammesStatus", "error");
            reject(err);
          });
      });
    },
    updateRetailers({commit}, data) {
      commit("setRetailers", data);
    },
    updateSelectedRetailerSingle({commit}, data) {
      commit("setSelectedRetailerSingle", data);
    },
    updateSelectedRetailerMultiple({commit}, data) {
      commit("setSelectedRetailerMultiple", data);
    },
  },
  mutations: {
    updateField,
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    getDetails(state, response) {
      state.scheduledVisits = response;
    },
    setRetailers(state, retailers) {
      state.retailers = retailers;
    },
    setAvailableAuditors(state, auditors) {
      state.availableAuditors = auditors;
    },
    setAssessmentProgrammes(state, assessmentProgrammes) {
      state.assessmentProgrammes = assessmentProgrammes;
    },
    setAssessmentProgrammesStatus(state, assessmentProgrammesStatus) {
      state.assessmentProgrammesStatus = assessmentProgrammesStatus;
    },
    setAuditManagers(state, auditManagers) {
      state.auditManagers = auditManagers;
    },
    setSelectedAuditor(state, selectedAuditor) {
      state.selectedAuditor = selectedAuditor;
    },
    setDefaultAuditManager(state, defaultAuditManager) {
      state.selectedAuditManager = defaultAuditManager;
    },
    setSelectedAuditType(state, selectedAuditType) {
      state.selectedAuditType = selectedAuditType;
    },
    setScheduledVisit(state, scheduledVisit) {
      state.scheduledVisit = scheduledVisit;
    },
    setSelectedVisitType(state, selectedVisitType) {
      state.selectedVisitType = selectedVisitType;
    },
    setScheduledVisitStatus(state, scheduledVisitStatus) {
      state.scheduledVisitStatus = scheduledVisitStatus;
    },
    setAuditorsStatus(state, auditorsStatus) {
      state.auditorsStatus = auditorsStatus;
    },
    setSelectedRetailerSingle(state, selectedRetailerSingle) {
      state.selectedRetailerSingle = selectedRetailerSingle;
    },
    setSelectedRetailerMultiple(state, selectedRetailerMultiple) {
      state.selectedRetailerMultiple = selectedRetailerMultiple;
    },
    setSelectedThirdParty(state, selectedThirdParty) {
      state.selectedThirdParty = selectedThirdParty;
    },
    setThirdParties(state, thirdParties) {
      state.thirdParties = thirdParties;
    },
  },
  getters: {
    getField,
    retailerFromStateById: (state) => (id) => {
      return state.retailers.find((retailer) => retailer.id === id);
    },
    isLoading: (state) => {
      return (
        state.assessmentProgrammesStatus === "loading" ||
        state.scheduledVisitStatus === "loading" ||
        state.auditorsStatus === "loading"
      );
    },
  },
};
