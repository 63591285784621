var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-order-details"},[_c('h2',{staticStyle:{"margin-bottom":"30px"}},[_c('i',{staticClass:"far fa-clipboard"}),_vm._v(" "+_vm._s(_vm.$t("orders.orderDetails.title"))+" ")]),_c('div',{staticClass:"container-breaker"},[_c('div',{staticClass:"content-block"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.orderDetails.orderer"))+" ")]),_c('dx-select-box',{attrs:{"value":_vm.selectedOrderer,"data-source":_vm.orderers,"stylingMode":"outlined","display-expr":"fullname","value-expr":"id","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.orderDetails.orderer'),
              }),"disabled":!_vm.$can('update', _vm.$subjects.order.subject, 'orderer') ||
              _vm.isDisabled},on:{"update:value":function($event){_vm.selectedOrderer=$event}}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.orderDetails.orderer'),
                  })}})],1)],1)],1),_c('div',{ref:_vm.siteOrStoreContainerRef,staticClass:"col-lg-6"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.orderDetails.siteName"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.siteNameToolTip,"close-on-outside-click":false,"target":"#divSiteName","position":"top"},on:{"update:visible":function($event){_vm.siteNameToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.order.general"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divSiteName"},on:{"mouseenter":function($event){_vm.siteNameToolTip = !_vm.siteNameToolTip},"mouseleave":function($event){_vm.siteNameToolTip = !_vm.siteNameToolTip}}})]),_c('DxDropDownBox',{ref:_vm.siteOrStoreDropDownRef,attrs:{"value":_vm.selectedOrganisation,"defer-rendering":false,"show-clear-button":true,"resize-enabled":"false","data-source":_vm.siteOrStoreDataSource,"display-expr":"siteName","stylingMode":"outlined","value-expr":"id","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.orderDetails.siteName'),
              }),"disabled":!_vm.$can('update', _vm.$subjects.order.subject, 'site') ||
              (_vm.orderStatus && _vm.orderStatus !== 'Ordered') ||
              _vm.isDisabled},on:{"update:value":function($event){_vm.selectedOrganisation=$event},"value-changed":function($event){return _vm.onSelectedOrganisationChanged($event)},"opened":_vm.onsiteOrStoreDropDownOpened},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DxDataGrid',{attrs:{"data-source":_vm.siteOrStoreDataSource,"columns":_vm.siteOrStoreColumns,"hover-state-enabled":true,"column-auto-width":true,"selected-row-keys":_vm.selectedOrganisation,"height":"100%"},on:{"update:selectedRowKeys":function($event){_vm.selectedOrganisation=$event},"update:selected-row-keys":function($event){_vm.selectedOrganisation=$event}},scopedSlots:_vm._u([{key:"country-template",fn:function(ref){
              var data = ref.data;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getCountryName(data.data.country))+" ")])]}}])},[_c('DxSelection',{attrs:{"mode":"single"}}),_c('DxPaging',{attrs:{"enabled":true,"page-size":10}}),_c('DxFilterRow',{attrs:{"visible":true}}),_c('DxScrolling',{attrs:{"mode":"infinite"}})],1)]},proxy:true}])},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.orderDetails.siteName'),
                  })}})],1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.orderDetails.fiscalYear"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.fiscalYearToolTip,"close-on-outside-click":false,"target":"#divFiscalYear","position":"top"},on:{"update:visible":function($event){_vm.fiscalYearToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.order.general"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divFiscalYear"},on:{"mouseenter":function($event){_vm.fiscalYearToolTip = !_vm.fiscalYearToolTip},"mouseleave":function($event){_vm.fiscalYearToolTip = !_vm.fiscalYearToolTip}}})]),_c('dx-select-box',{attrs:{"value":_vm.selectedAuditYear,"data-source":_vm.auditYears,"stylingMode":"outlined","display-expr":"name","value-expr":"id","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.orderDetails.fiscalYear'),
              }),"disabled":!_vm.$can('update', _vm.$subjects.order.subject, 'fiscalYear') ||
              (_vm.orderStatus && _vm.orderStatus !== 'Ordered') ||
              _vm.isDisabled},on:{"value-changed":function($event){return _vm.onSelectedAuditYearChanged($event)}}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.orderDetails.fiscalYear'),
                  })}})],1)],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.orderDetails.tertial"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.tertialToolTip,"close-on-outside-click":false,"target":"#divTertial","position":"top"},on:{"update:visible":function($event){_vm.tertialToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.order.general"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divTertial"},on:{"mouseenter":function($event){_vm.tertialToolTip = !_vm.tertialToolTip},"mouseleave":function($event){_vm.tertialToolTip = !_vm.tertialToolTip}}})]),_c('dx-select-box',{attrs:{"value":_vm.selectedTertial,"data-source":_vm.tertials,"stylingMode":"outlined","display-expr":"name","value-expr":"id","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.orderDetails.tertial'),
              }),"disabled":!_vm.$can('update', _vm.$subjects.order.subject, 'tertial') ||
              _vm.isDisabled},on:{"update:value":function($event){_vm.selectedTertial=$event}}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.orderDetails.tertial'),
                  })}})],1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.orderDetails.reviewMode"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.reviewModeToolTip,"close-on-outside-click":false,"target":"#divReviewMode","position":"top"},on:{"update:visible":function($event){_vm.reviewModeToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.order.general"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divReviewMode"},on:{"mouseenter":function($event){_vm.reviewModeToolTip = !_vm.reviewModeToolTip},"mouseleave":function($event){_vm.reviewModeToolTip = !_vm.reviewModeToolTip}}})]),_c('dx-select-box',{attrs:{"value":_vm.selectedReviewMode,"data-source":_vm.reviewModes,"stylingMode":"outlined","display-expr":"name","value-expr":"id","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.orderDetails.reviewMode'),
              }),"disabled":!_vm.$can('update', _vm.$subjects.order.subject, 'reviewMode') ||
              (_vm.orderStatus && _vm.orderStatus !== 'Ordered') ||
              _vm.isDisabled},on:{"update:value":function($event){_vm.selectedReviewMode=$event},"value-changed":function($event){return _vm.onSelectedReviewModeChanged($event)}}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.orderDetails.reviewMode'),
                  })}})],1)],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.orderDetails.reviewType"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.reviewTypeToolTip,"close-on-outside-click":false,"target":"#divReviewType","position":"top"},on:{"update:visible":function($event){_vm.reviewTypeToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.order.general"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divReviewType"},on:{"mouseenter":function($event){_vm.reviewTypeToolTip = !_vm.reviewTypeToolTip},"mouseleave":function($event){_vm.reviewTypeToolTip = !_vm.reviewTypeToolTip}}})]),_c('dx-select-box',{attrs:{"value":_vm.selectedReviewType,"data-source":_vm.reviewTypes,"stylingMode":"outlined","display-expr":"name","value-expr":"id","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.orderDetails.reviewType'),
              }),"disabled":!_vm.$can('update', _vm.$subjects.order.subject, 'reviewType') ||
              (_vm.orderStatus && _vm.orderStatus !== 'Ordered') ||
              _vm.isDisabled},on:{"update:value":function($event){_vm.selectedReviewType=$event},"value-changed":function($event){return _vm.onSelectedReviewTypeChanged($event)}}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.orderDetails.reviewType'),
                  })}})],1)],1)],1)]),_c('div',{staticClass:"row"},[(_vm.selectedReviewType)?_c('div',{staticClass:"col-lg-6",on:{"click":function($event){return _vm.tagBoxClick(_vm.scopeOfReviewRefKey)}}},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.orderDetails.scopeOfReview"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.scopeOfReviewToolTip,"close-on-outside-click":false,"target":"#divScopeOfReview","position":"top"},on:{"update:visible":function($event){_vm.scopeOfReviewToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.order.general"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divScopeOfReview"},on:{"mouseenter":function($event){_vm.scopeOfReviewToolTip = !_vm.scopeOfReviewToolTip},"mouseleave":function($event){_vm.scopeOfReviewToolTip = !_vm.scopeOfReviewToolTip}}})]),_c('dx-tag-box',{ref:_vm.scopeOfReviewRefKey,attrs:{"defer-rendering":false,"data-source":_vm.reviewScope,"display-expr":"name","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.orderDetails.scopeOfReview'),
              }),"show-clear-button":true,"show-drop-down-button":true,"show-selection-controls":true,"styling-mode":"outlined","value":_vm.selectedScopeOfReview,"value-expr":"id","wrapItemText":true,"max-displayed-tags":1,"apply-value-mode":"instantly","readOnly":_vm.isScopeOfReviewDisabled,"dropDownOptions":_vm.scopeOfReviewDropdownOptions},on:{"update:value":function($event){_vm.selectedScopeOfReview=$event},"value-changed":function($event){return _vm.onSelectedScopeOfReviewChanged($event)}}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.orderDetails.scopeOfReview'),
                  })}})],1)],1)],1):_vm._e(),(_vm.levelsOfReviewData.length > 0)?_c('div',{ref:_vm.levelOfReviewContainerRef,staticClass:"col-lg-6",on:{"click":function($event){return _vm.tagBoxClick(_vm.levelsOfReviewRefKey)}}},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.orderDetails.levelOfReview"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.levelOfReviewToolTip,"close-on-outside-click":false,"target":"#divLevelOfReview","position":"top"},on:{"update:visible":function($event){_vm.levelOfReviewToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.order.general"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divLevelOfReview"},on:{"mouseenter":function($event){_vm.levelOfReviewToolTip = !_vm.levelOfReviewToolTip},"mouseleave":function($event){_vm.levelOfReviewToolTip = !_vm.levelOfReviewToolTip}}})]),_c('dx-tag-box',{ref:_vm.levelsOfReviewRefKey,attrs:{"defer-rendering":false,"data-source":_vm.levelsOfReviewData,"display-expr":"name","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.orderDetails.levelOfReview'),
              }),"show-clear-button":true,"show-drop-down-button":true,"show-selection-controls":true,"styling-mode":"outlined","value":_vm.selectedLevelOfReviewMultiple,"value-expr":"id","wrapItemText":true,"max-displayed-tags":2,"apply-value-mode":"instantly","disabled":_vm.isLevelsOfReviewDisabled,"dropDownOptions":_vm.levelsOfReviewDropdownOptions},on:{"update:value":function($event){_vm.selectedLevelOfReviewMultiple=$event}}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.orderDetails.levelOfReview'),
                  })}})],1)],1)],1):_vm._e(),(!_vm.selectedReviewType)?_c('div',{ref:_vm.siteCategoryContainerRef,staticClass:"col-lg-6"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.orderDetails.siteCategory"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.siteCategoryToolTip,"close-on-outside-click":false,"target":"#divSiteCategory","position":"top"},on:{"update:visible":function($event){_vm.siteCategoryToolTip=$event}}},[_vm._v(" "+_vm._s(("" + (_vm.$t("tooltips.order.general"))))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divSiteCategory"},on:{"mouseenter":function($event){_vm.siteCategoryToolTip = !_vm.siteCategoryToolTip},"mouseleave":function($event){_vm.siteCategoryToolTip = !_vm.siteCategoryToolTip}}})]),_c('DxDropDownBox',{ref:_vm.siteCategoryDropDownRef,attrs:{"value":_vm.selectedSiteCategoryMultiple,"defer-rendering":false,"show-clear-button":true,"resize-enabled":"false","data-source":_vm.siteCategoryDataSource,"stylingMode":"outlined","display-expr":"subCategory","value-expr":"id","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.orderDetails.siteCategory'),
              }),"disabled":!_vm.$can('update', _vm.$subjects.order.subject, 'siteCategory') ||
              _vm.isDisabled},on:{"update:value":function($event){_vm.selectedSiteCategoryMultiple=$event},"opened":_vm.onsiteCategoryDropDownOpened},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DxDataGrid',{attrs:{"data-source":_vm.siteCategoryDataSource,"columns":_vm.siteCategoryColumns,"hover-state-enabled":true,"word-wrap-enabled":true,"column-auto-width":true,"selected-row-keys":_vm.selectedSiteCategoryMultiple,"height":"100%"},on:{"update:selectedRowKeys":function($event){_vm.selectedSiteCategoryMultiple=$event},"update:selected-row-keys":function($event){_vm.selectedSiteCategoryMultiple=$event}}},[_c('DxSelection',{attrs:{"mode":"multiple","show-check-boxes-mode":_vm.checkBoxMode}}),_c('DxPaging',{attrs:{"enabled":true,"page-size":10}}),_c('DxFilterRow',{attrs:{"visible":true}}),_c('DxScrolling',{attrs:{"mode":"infinite"}})],1)]},proxy:true}],null,false,2107337608)},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.orderDetails.siteCategory'),
                  })}})],1)],1)],1):_vm._e()]),_c('div',{staticClass:"row"},[(_vm.selectedReviewType)?_c('div',{ref:_vm.siteCategoryContainerRef,staticClass:"col-lg-12"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.orderDetails.siteCategory"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.siteCategoryToolTip,"close-on-outside-click":false,"target":"#divSiteCategory","position":"top"},on:{"update:visible":function($event){_vm.siteCategoryToolTip=$event}}},[_vm._v(" "+_vm._s(((_vm.$t("tooltips.order.general")) + ". " + (_vm.$t( "tooltips.order.siteCategory" ))))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divSiteCategory"},on:{"mouseenter":function($event){_vm.siteCategoryToolTip = !_vm.siteCategoryToolTip},"mouseleave":function($event){_vm.siteCategoryToolTip = !_vm.siteCategoryToolTip}}})]),_c('DxDropDownBox',{ref:_vm.siteCategoryDropDownRef,attrs:{"value":_vm.selectedSiteCategoryMultiple,"defer-rendering":false,"show-clear-button":true,"resize-enabled":"false","data-source":_vm.siteCategoryDataSource,"stylingMode":"outlined","display-expr":"subCategory","value-expr":"id","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.orderDetails.siteCategory'),
              }),"disabled":!_vm.$can('update', _vm.$subjects.order.subject, 'siteCategory') ||
              _vm.isDisabled},on:{"update:value":function($event){_vm.selectedSiteCategoryMultiple=$event},"opened":_vm.onsiteCategoryDropDownOpened},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('DxDataGrid',{attrs:{"data-source":_vm.siteCategoryDataSource,"columns":_vm.siteCategoryColumns,"hover-state-enabled":true,"word-wrap-enabled":true,"column-auto-width":true,"selected-row-keys":_vm.selectedSiteCategoryMultiple,"height":"100%"},on:{"update:selectedRowKeys":function($event){_vm.selectedSiteCategoryMultiple=$event},"update:selected-row-keys":function($event){_vm.selectedSiteCategoryMultiple=$event}}},[_c('DxSelection',{attrs:{"mode":"multiple","show-check-boxes-mode":_vm.checkBoxMode}}),_c('DxPaging',{attrs:{"enabled":true,"page-size":10}}),_c('DxFilterRow',{attrs:{"visible":true}}),_c('DxScrolling',{attrs:{"mode":"infinite"}})],1)]},proxy:true}],null,false,2107337608)},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.orderDetails.siteCategory'),
                  })}})],1)],1)],1):_vm._e()]),(_vm.$route.name === 'order')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{staticClass:"bold-label"},[_vm._v(_vm._s(_vm.$t("orders.orderDetails.initialComments")))]),_c('dx-text-area',{attrs:{"styling-mode":"outlined","height":150},model:{value:(_vm.initialComments),callback:function ($$v) {_vm.initialComments=$$v},expression:"initialComments"}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[(_vm.selectedOrganisation && _vm.selectedOrganisation.length)?_c('div',{staticClass:"col-lg-12"},[_c('label',{staticClass:"bold-label"},[_vm._v(_vm._s(_vm.$t("orders.orderDetails.usersAssigned")))]),_c('DxDataGrid',{attrs:{"data-source":_vm.reviewParticipantsAndBusinessOwnersData,"columns":_vm.siteUserscolDefs,"hover-state-enabled":true,"word-wrap-enabled":true,"column-auto-width":true,"noDataText":this.$t('orders.orderDetails.noUsersData'),"height":"100%","width":"100%"}})],1):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }