<template>
  <div class="chart-container card-container">
    <div class="chart-container-header">
      <h1>
        {{ $t("reporting.charts.schedulingActivityOverTimeChartTitle") }}
      </h1>
    </div>
    <dx-chart
      :ref="chartRef"
      :data-source="dataSource"
      :resolve-label-overlapping="pointOverlappingOptions"
      @exporting="onExporting"
      @exported="onExported"
      :animation="{
        enabled: false,
      }"
    >
      <DxSize :height="chartHeight" :width="chartWidth" />
      <dx-loading-indicator :enabled="true" />
      <dx-common-axis-settings :color="color.axis">
        <dx-tick :color="color.axis" />
        <dx-label
          :visible="axisOn"
          :displayMode="axisLabelPosition"
          :rotationAngle="axisRotationAngle"
        >
          <dx-font :color="color.text" />
        </dx-label>
      </dx-common-axis-settings>
      <dx-argument-axis
        position="bottom"
        argument-type="datetime"
        aggregation-interval="month"
        tick-interval="month"
        :value-margins-enabled="false"
        discrete-axis-division-mode="crossLabels"
      >
        <dx-label
          overlappingBehavior="stagger"
          :format="{year: 'numeric', month: 'short'}"
        />
        <dx-grid :visible="true" :color="color.grid" />
      </dx-argument-axis>
      <dx-value-axis position="left" :allow-decimals="false">
        <dx-grid :color="color.grid" />
        <dx-label>
          <dx-format type="percent" />
        </dx-label>
      </dx-value-axis>
      <dx-common-series-settings
        argument-field="date"
        value-field="value"
        type="line"
        hover-mode="allArgumentPoints"
        selection-mode="allArgumentPoints"
      >
        <dx-label
          :visible="pointOn"
          :position="pointLabelPosition"
          :customize-text="customizeText"
        >
          <dx-connector :visible="connectorOn" />
        </dx-label>
      </dx-common-series-settings>
      <dx-series-template
        :customize-series="customizeSeries"
        name-field="series"
      />
      <dx-tooltip :enabled="true" content-template="tooltipTemplate" />
      <dx-legend
        orientation="horizontal"
        horizontal-alignment="center"
        vertical-alignment="bottom"
        item-text-position="bottom"
        :visible="legendOn"
      >
        <dx-font :color="color.text" />
      </dx-legend>
      <dx-export :enabled="true" background-color="#ffffff" />
      <template #tooltipTemplate="{data}">
        <span>
          {{ `${$t("reporting.charts.countLabel")}: ${data.point.data.count}`
          }}<br />
          {{
            `${$t("reporting.charts.percentageLabel")}: ${(
              data.point.data.value * 100
            ).toFixed(2)}%`
          }}
        </span>
      </template>
    </dx-chart>
  </div>
</template>

<script>
import {
  DxArgumentAxis,
  DxChart,
  DxCommonAxisSettings,
  DxCommonSeriesSettings,
  DxExport,
  DxFont,
  DxGrid,
  DxLabel,
  DxFormat,
  DxLegend,
  DxLoadingIndicator,
  DxSeriesTemplate,
  DxTick,
  DxTooltip,
  DxValueAxis,
  DxSize,
  DxConnector,
} from "devextreme-vue/chart";
import {CancelToken} from "axios";
import {mapState} from "vuex";
import schedulingActivityStatusChartSeriesDefinitions from "../../custom/reporting/schedulingActivityStatusChartSeriesDefinitions";
import chartParameters from "../../mixins/chart-parameters";

export default {
  mixins: [chartParameters],
  props: {...chartParameters.props},
  components: {
    DxArgumentAxis,
    DxChart,
    DxCommonAxisSettings,
    DxCommonSeriesSettings,
    DxExport,
    DxFont,
    DxGrid,
    DxLabel,
    DxLegend,
    DxLoadingIndicator,
    DxSeriesTemplate,
    DxTick,
    DxTooltip,
    DxValueAxis,
    DxSize,
    DxConnector,
    DxFormat,
  },
  computed: {
    ...mapState("reportingFilters", ["filters"]),
    chart() {
      return this.$refs[this.chartRef].instance;
    },
    chartTitle() {
      return this.$t("reporting.charts.schedulingActivityOverTimeChartTitle");
    },
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      dataSource: [],
      retailerCount: 0,
      loadCancelToken: null,
      chartRef: "schedulingActivityChart",
      seriesDefinitions: schedulingActivityStatusChartSeriesDefinitions.map(
        (x) => ({...x, caption: this.$t(x.caption)}),
        this,
      ),
      color: {
        text: "black",
        axis: "#C4C4C4",
        grid: "#e8e8e8",
      },
    };
  },
  methods: {
    getTotalReviewInAMonth(
      date,
      schedResult,
      orderedResult,
      submittedResult,
      inProgressResult,
      completedResult,
      cancelledResult,
    ) {
      let sum = 0;
      if (schedResult.length > 0) {
        sum += schedResult[0].activityResult
          .filter((x) => x.date == date)
          .reduce((a, b) => a + (b["count"] || 0), 0);
      }
      if (orderedResult.length > 0) {
        sum += orderedResult[0].activityResult
          .filter((x) => x.date == date)
          .reduce((a, b) => a + (b["count"] || 0), 0);
      }
      if (submittedResult.length > 0) {
        sum += submittedResult[0].activityResult
          .filter((x) => x.date == date)
          .reduce((a, b) => a + (b["count"] || 0), 0);
      }
      if (inProgressResult.length > 0) {
        sum += inProgressResult[0].activityResult
          .filter((x) => x.date == date)
          .reduce((a, b) => a + (b["count"] || 0), 0);
      }
      if (completedResult.length > 0) {
        sum += completedResult[0].activityResult
          .filter((x) => x.date == date)
          .reduce((a, b) => a + (b["count"] || 0), 0);
      }
      if (cancelledResult.length > 0) {
        sum += cancelledResult[0].activityResult
          .filter((x) => x.date == date)
          .reduce((a, b) => a + (b["count"] || 0), 0);
      }
      return sum;
    },
    customizeSeries(seriesName) {
      const series = this.seriesDefinitions.find(
        (x) => x.caption === seriesName,
      );
      return {
        color: series.color,
      };
    },
    onExporting(e) {
      this.chart.option("title.text", this.chartTitle);
      this.chart.option("title.font.color", "black");
    },
    onExported(e) {
      this.chart.option("title.text", "");
    },
    customizeText(pointInfo) {
      return `${this.$t("reporting.charts.countLabel")}: ${
        pointInfo.point.data.count
      } \n ${this.$t("reporting.charts.percentageLabel")}: ${(
        pointInfo.point.data.value * 100
      ).toFixed(2)}%`;
    },
    async load() {
      this.chart.showLoadingIndicator();
      try {
        if (this.loadCancelToken) {
          this.loadCancelToken.cancel();
        }

        this.loadCancelToken = CancelToken.source();
        let response = await this.$http.post(
          "/reporting/schedulingActivityOverTime",
          this.filters,
          {
            cancelToken: this.loadCancelToken.token,
          },
        );
        this.loadCancelToken = null;
        this.retailerCount = response.data.retailerCount;

        let data = response.data;
        let schedResult = data.filter((x) => x.status === "Scheduled");
        let orderedResult = data.filter((x) => x.status === "Ordered");
        let submittedResult = data.filter((x) => x.status === "Submitted");
        let inProgressResult = data.filter((x) => x.status === "InProgress");
        let completedResult = data.filter((x) => x.status === "Completed");
        let cancelledResult = data.filter((x) => x.status === "Cancelled");
        let dataMod = {
          ordered:
            orderedResult.length > 0 ? orderedResult[0].activityResult : [],
          scheduled:
            schedResult.length > 0 ? schedResult[0].activityResult : [],
          inProgress:
            inProgressResult.length > 0
              ? inProgressResult[0].activityResult
              : [],
          submitted:
            submittedResult.length > 0 ? submittedResult[0].activityResult : [],
          completed:
            completedResult.length > 0 ? completedResult[0].activityResult : [],
          cancelled:
            cancelledResult.length > 0 ? cancelledResult[0].activityResult : [],
        };

        delete data.retailerCount;
        this.dataSource = Object.entries(dataMod).reduce(
          (acc, [key, value]) => {
            let series = this.seriesDefinitions.find(
              (x) => x.dataField === key,
            );
            return value.reduce((entries, entry) => {
              let totalCount = this.getTotalReviewInAMonth(
                entry.date,
                schedResult,
                orderedResult,
                submittedResult,
                inProgressResult,
                completedResult,
                cancelledResult,
              );
              entries.push({
                series: series.caption,
                date: entry.date,
                count: entry.count,
                value: entry.count / totalCount,
              });
              return entries;
            }, acc);
          },
          [],
        );

        this.chart.hideLoadingIndicator();
        this.dataLoaded();
      } catch (err) {
        this.dataSource = [];
      }
    },
  },
  watch: {
    filters() {
      this.load();
    },
  },
};
</script>

<style lang="sass" scoped></style>
