import {isNotEmpty} from "../common/helperFunctions";

export default {
  methods: {
    localeDatetimeFormat: function (date, options) {

      if(!this.$i18n) return date;

      if (!isNotEmpty(date)) return "";
      // TODO:
      // this error needs logging somewhere
      if (typeof date === "string") date = new Date(date);
      return isNotEmpty(options)
        ? Intl.DateTimeFormat(this.$i18n.locale, options).format(date)
        : Intl.DateTimeFormat(this.$i18n.locale).format(date);
    },
  },
};
