<template>
  <div class="header-container">
    <div class="row">
      <div class="col">
        <h2 class="question-header">
          <span>{{ question.category }}</span>
          <!-- <i class="fas fa-chevron-right secondary"></i>
          <span class="secondary">{{ question.section }}</span> -->
        </h2>
        <div v-for="item in question.guidanceFlags" :key="item">
          <div class="guidance-flag"></div>
        </div>
      </div>

      <div
        class="col-md-auto col-12 order-md-last order-first"
        style="margin-bottom: 5px"
      >
        <div v-if="question.number" class="question-marker header-marker">
          <i class="fas fa-file-medical-alt"></i> {{ `${question.number}` }}
        </div>

        <!-- Leaving this for future additional guidance flag wire-up
        <div class="audit-programme type">
          <img src="../../assets/check-indicators/6.png" />
        </div>  
        -->
        <div
          v-if="question && question.level && question.level !== 'Core'"
          class="audit-programme"
        >
          {{ question.level }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {levels, questionOptionalOrMandatory} from "../../common/constants";
export default {
  data() {
    return {
      guidanceFlags: [],
      levels: levels,
      questionOptionalOrMandatory: questionOptionalOrMandatory,
    };
  },
  props: {
    question: {
      Type: Object,
    },
    questionCount: {
      Type: Number,
    },
  },
  methods: {},
};
</script>

<style></style>
