export default [
  {name: "Cross border", value: "CB", group: ""},
  {name: "Australia", value: "AU", group: "AP"},
  {name: "China", value: "CN", group: "AP"},
  {name: "India", value: "IN", group: "AP"},
  {name: "Japan", value: "JP", group: "AP"},
  {name: "New Zealand", value: "NZ", group: "AP"},
  {name: "South Korea", value: "KR", group: "AP"},
  {name: "Austria", value: "AT", group: "CE"},
  {name: "Belgium", value: "BE", group: "CE"},
  {name: "France", value: "FR", group: "CE"},
  {name: "Germany", value: "DE", group: "CE"},
  {name: "Ireland", value: "IE", group: "CE"},
  {name: "Netherlands", value: "NL", group: "CE"},
  {name: "Switzerland", value: "CH", group: "CE"},
  {name: "United Kingdom", value: "GB", group: "CE"},
  {name: "Canada", value: "CA", group: "NAS"},
  {name: "Denmark", value: "DK", group: "NAS"},
  {name: "Finland", value: "FI", group: "NAS"},
  {name: "Norway", value: "NO", group: "NAS"},
  {name: "Sweden", value: "SE", group: "NAS"},
  {name: "United States", value: "US", group: "NAS"},
  {name: "Bulgaria", value: "BG", group: "SEE"},
  {name: "Croatia", value: "HR", group: "SEE"},
  {name: "Czech Republic", value: "CZ", group: "SEE"},
  {name: "Hungary", value: "HU", group: "SEE"},
  {name: "Italy", value: "IT", group: "SEE"},
  {name: "Latvia", value: "LV", group: "SEE"},
  {name: "Poland", value: "PL", group: "SEE"},
  {name: "Portugal", value: "PT", group: "SEE"},
  {name: "Romania", value: "RO", group: "SEE"},
  {name: "Russia", value: "RU", group: "SEE"},
  {name: "Serbia", value: "RS", group: "SEE"},
  {name: "Slovakia", value: "SK", group: "SEE"},
  {name: "Slovenia", value: "SI", group: "SEE"},
  {name: "Spain", value: "ES", group: "SEE"},
  {name: "Ukraine", value: "UA", group: "SEE"},
];
