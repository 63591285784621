<template>
  <div class="landing-card-container">
    <component-base
      :numberValue="number"
      :textAfterNumber="
        $t('landingPage.assessmentComponents.assessmentAwaitingApproval')
      "
      :textWhenZero="
        $t('landingPage.assessmentComponents.assessmentsAwaitingApprovalNone')
      "
      :textWhenAny="
        $t('landingPage.assessmentComponents.assessmentsAwaitingApprovalAny')
      "
      :textButton="
        $t('landingPage.assessmentComponents.assessmentsAwaitingApprovalButton')
      "
      routeName="assessment-list"
      status="Submitted"
    >
    </component-base>
  </div>
</template>

<script>
import ComponentBase from "../base/number-of-outstanding-base-component";
import componentMixin from "../../../../mixins/number-of-elements-component-mixin";

export default {
  components: {
    ComponentBase,
  },
  mixins: [componentMixin("assessmentsAwaitingApproval")],
};
</script>

<style></style>
