<template>
  <div
    class="thumbnail"
    :style="{
      'background-image': `url(${backgroundImage})`,
    }"
  >
    <div class="zoom-in"></div>
  </div>
</template>
<script>
export default {
  props: {
    url: {
      Type: String,
    },
  },
  created() {
    this.getBackgroundImage();
  },
  data() {
    return {
      backgroundImage: null,
    };
  },
  methods: {
    getBackgroundImage() {
      this.$http
        .get(this.url, {responseType: "arraybuffer"})
        .then((response) => {
          let image = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              "",
            ),
          );
          this.backgroundImage = `data:${response.headers[
            "content-type"
          ].toLowerCase()};base64,${image}`;
        });
    },
  },
};
</script>
<style scoped></style>
