import {isNotEmpty} from "../common/helperFunctions";

export const serializeLoadOptions = (loadOptions, filter) => {
  let result = {};
  loadOptions = loadOptions || {};
  filter = filter || Object.keys(loadOptions);
  filter.forEach(function (i) {
    if (i in loadOptions && isNotEmpty(loadOptions[i])) {
      result[i] = JSON.stringify(loadOptions[i]);
    }
  });

  return result;
};
