<template>
  <div>
    <page-heading :pageTitle="pageTitle" :breadCrumbs="breadCrumbs" />
    <div
      class="fixed-action-container"
      v-if="$can('create', $subjects.schedule.subject)"
      @click="newScheduleButtonClick"
    >
      <i class="fas fa-plus"></i>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <dx-button
          :visible="$can('create', $subjects.schedule.subject)"
          @click="newScheduleButtonClick"
          type="normal"
          class="schedule-visit hidden-sm hidden-xs"
          stylingMode="contained"
          icon="plus"
          :text="this.$t('assessments.assessmentList.scheduleNewAssessment')"
        >
        </dx-button>
      </div>
    </div>

    <div class="card-container" style="margin-bottom: 90px">
      <div class="row">
        <div class="col-xl-3">
          <h2>
            <i class="fas fa-file-medical-alt"></i>
            {{ $t("assessments.assessmentList.title") }}
          </h2>
          <div class="borderless-small-dd">
            <audit-year-select />
          </div>
        </div>
        <div class="col-xl-9">
          <ul class="custom-tabs">
            <li @click="onTabChanged(event, 'All')" class="custom-tab-active">
              {{ $t("assessments.assessmentList.all") }}
            </li>
            <li @click="onTabChanged(event, 'NotStarted')">
              {{ $t("assessments.assessmentList.notStarted") }}
            </li>
            <li @click="onTabChanged(event, 'InProgress')">
              {{ $t("assessments.assessmentList.inProgress") }}
            </li>
            <li @click="onTabChanged(event, 'Submitted')">
              {{ $t("assessments.assessmentList.submitted") }}
            </li>
            <li @click="onTabChanged(event, 'Complete')">
              {{ $t("assessments.assessmentList.complete") }}
            </li>
            <li @click="onTabChanged(event, 'Cancelled')">
              {{ $t("assessments.assessmentList.cancelled") }}
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="content-block">
            <div
              class="pre-answered-response with-side-action mb-4"
              v-if="showPreFilteredBar"
            >
              <h3>
                <i class="fas fa-filter"></i>
                {{ $t("assessments.assessmentList.preFilteredHeading") }}
              </h3>

              <div class="mt-2">
                {{ $t("assessments.assessmentList.preFilteredText") }}
              </div>

              <div class="side-action-box" @click="clearGridFilters">
                <i class="fas fa-sync"></i>
                <p>{{ $t("assessments.assessmentList.clearFilters") }}</p>
              </div>
            </div>
            <div v-if="this.selectedAuditYear">
              <DxDataGrid
                class="dx-card thin-column-chooser"
                :data-source="dataSource()"
                :show-borders="false"
                key-expr="id"
                :ref="dataGridRefName"
                :focused-row-index="0"
                :focused-row-enabled="false"
                :column-auto-width="true"
                :column-hiding-enabled="false"
                :selection="{mode: 'single'}"
                :hover-state-enabled="true"
                :columns="colDefs"
                @content-ready="contentReady"
              >
                <DxHeaderFilter :visible="true" :allowSearch="true" />
                <DxColumnChooser :enabled="true" mode="dragAndDrop" />
                <DxColumnFixing :enabled="true" />
                <DxRemoteOperations
                  :filtering="false"
                  :paging="false"
                  :sorting="false"
                  :summary="false"
                  :grouping="false"
                  :group-paging="false"
                />

                <DxStateStoring
                  :enabled="true"
                  type="localStorage"
                  storage-key="storageAssessmentList"
                />

                <template #assessment-button-cell-template="{data}">
                  <div style="text-align: center">
                    <assessment-list-button
                      :text="setAssessmentListButtonText(data)"
                      @assessmentButtonClicked="onRowButtonClick(data)"
                    />
                    <span
                      class="table-button"
                      @click="editAssessmentVisitClick(data)"
                      :title="$t('assessments.assessmentList.editVisit')"
                      v-if="$can('update', $subjects.assessment.subject)"
                    >
                      <i class="far fa-edit"></i>
                    </span>
                  </div>
                </template>
                <template #retailer-template="{data}">
                  <div class="table-link" @click="onRowButtonClick(data)">
                    <i class="fas fa-location-arrow"></i
                    >{{ data.data.retailer }}
                  </div>
                </template>
                <template #status-template="{data}">
                  <label
                    class="table-status-label"
                    :class="setStatusClass(data)"
                    >{{ formatStatusCode(data) }}</label
                  >
                </template>
                <template #retailer-assessment-template="{data}">
                  <div>{{ formatRetailerAssessment(data) }}</div>
                </template>

                <DxPaging :page-size="10" />
                <DxPager :show-page-size-selector="true" :show-info="true" />
                <DxFilterRow :visible="true" />
              </DxDataGrid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";
import $axios from "../utils/axios-instance";
import {DxButton} from "devextreme-vue";
import pageHeading from "../components/page-heading";

import DxDataGrid, {
  DxFilterRow,
  DxPager,
  DxPaging,
  DxRemoteOperations,
  DxHeaderFilter,
  DxStateStoring,
  DxColumnChooser,
  DxColumnFixing,
} from "devextreme-vue/data-grid";
import AssessmentListButton from "../components/assessment/assessment-list-button";
import {isNotEmpty, toCamelCase} from "../common/helperFunctions";
import listColDefs from "../custom/assessment/assessmentListColDefs";
import AuditYearSelect from "../components/organisation/audit-year-select";
import subjects from "./../config/subjects";
import {AbilityBuilder} from "@casl/ability";

export default {
  components: {
    DxDataGrid,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxRemoteOperations,
    AssessmentListButton,
    DxButton,
    pageHeading,
    AuditYearSelect,
    DxHeaderFilter,
    DxStateStoring,
    DxColumnChooser,
    DxColumnFixing,
  },
  created() {
    this.getRole();
  },
  data() {
    return {
      dataGridRefName: "dataGrid",
      clearedFilter: false,
    };
  },
  computed: {
    pageTitle() {
      return this.$t("assessments.assessmentList.title");
    },
    breadCrumbs() {
      return [this.$t("assessments.assessmentList.breadcrumbTitle")];
    },
    showPreFilteredBar() {
      return this.$route.query.status && !this.clearedFilter;
    },
    userFullName() {
      return this.user.firstName + " " + this.user.surname;
    },
    colDefs() {
      let colDefs = JSON.parse(JSON.stringify(this.colDefsTranslatedLookups));
      return colDefs.map((x) =>
        x.caption
          ? {
              ...x,
              caption: this.$t(x.caption),
            }
          : x,
      );
    },
    colDefsTranslatedLookups() {
      let colDefs = JSON.parse(JSON.stringify(listColDefs));
      colDefs.forEach((element) => {
        if (element.lookup) {
          element.lookup.dataSource.store.data.forEach((x) => {
            if (x.name) {
              x.name = this.$t(x.name);
            }
          });
        }
      });
      return colDefs;
    },
    selectedAuditYear() {
      return this.$store.state.organisations.selectedAuditYear;
    },
    user() {
      return this.$store.state.auth.user;
    },
    isAuditManager() {
      if (this.user) {
        return this.user.roles.includes("AuditManager");
      }
      return false;
    },
    // isCentralAdmin() {
    //   if (this.user) {
    //     return this.user.roles.includes("CentralAdmin");
    //   }
    //   return false;
    // },
    // isRegionalNsc() {
    //   if (this.user) {
    //     return this.user.roles.includes("RegionalNsc");
    //   }
    //   return false;
    // },
    // isRetailer() {
    //   if (this.user) {
    //     return this.user.roles.includes("Retailer");
    //   }
    //   return false;
    // },
    // hasAssignedThirdParty() {
    //   if (this.user) {
    //     return this.user.hasAssignedThirdParty;
    //   }
    //   return false;
    // },
    isAuditor() {
      if (this.user) {
        return this.user.roles.includes("Auditor");
      }
      return false;
    },
  },
  watch: {
    // whenever selected audit year changes, this function will run
    selectedAuditYear: function (newValue, oldValue) {
      const loadOptions = {
        requireTotalCount: true,
        searchOperation: "contains",
        skip: 0,
        take: 20,
      };
      this.load(loadOptions);
    },
  },
  methods: {
    dataSource() {
      return new CustomStore({
        key: "id",
        load: this.load,
      });
    },
    getUserInitials(text) {
      if (text && text.length > 0) {
        return text
          .split(/\s/)
          .map((x) => x.charAt(0))
          .join("")
          .substring(0, 2);
      }
      return null;
    },
    async load(loadOptions) {
      if (this.selectedAuditYear === null) {
        return null;
      } else {
        let params = "?";
        [
          "skip",
          "take",
          "sort",
          "requireTotalCount",
          "requireGroupCount",
          "filter",
          "totalSummary",
          "group",
          "groupSummary",
        ].forEach(function (i) {
          if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
          }
        });
        params = params.slice(0, -1);
        const response = await $axios.get(
          `/assessments/Period/${this.selectedAuditYear}${params}`,
        );

        // const response = await $axios.get(`/assessments/Period/${this.selectedAuditYear}/{${params}`

        const json = await JSON.parse(JSON.stringify(response));
        return {
          data: json.data.data,
          totalCount: json.data.totalCount,
          summary: json.data.summary,
          groupCount: json.data.groupCount,
        };
      }
    },
    onRowButtonClick(data) {
      this.$router.push({
        name: "assessment",
        params: {id: data.data.id},
      });
    },

    //TODO: this is rather quick fix for the tab, but need to look into making it more generic
    onTabChanged(e, value) {
      if (!e) e = window.event;
      let sender = e.srcElement || e.target;

      while (sender && sender.nodeName.toLowerCase() != "li")
        sender = sender.parentNode;
      document
        .getElementsByClassName("custom-tab-active")[0]
        .classList.remove("custom-tab-active");
      sender.className = "custom-tab-active";
      const dataGrid = this.$refs[this.dataGridRefName].instance;
      if (value === "All") {
        dataGrid.clearFilter();
      } else {
        dataGrid.filter(["statusCode", "=", value]);
      }
    },
    contentReady(e) {
      // TODO:
      // "Temporary hack" - 20200309
      // This custom store needs to be encapsulated in state.
      this.$store.dispatch(
        "schedules/updateRetailers",
        e.component.getDataSource()._items,
      );
      if (this.$route.query.status && !this.clearedFilter) {
        const dataGrid = this.$refs[this.dataGridRefName].instance;
        let startValue = new Date();
        startValue.setSeconds(0);
        startValue.setMilliseconds(0);
        if (this.isAuditor) {
          dataGrid.filter(
            [
              ["statusCode", "=", this.$route.query.status],
              "or",
              ["statusCode", "=", "InProgress"],
            ],
            ["startDate", "<=", startValue],
            ["auditor", "=", this.userFullName],
          );
        } else if (this.isAuditManager) {
          dataGrid.filter(
            ["statusCode", "=", this.$route.query.status],
            ["startDate", "<=", startValue],
            ["auditManager", "=", this.userFullName],
          );
        }
      }
    },
    clearGridFilters() {
      const dataGrid = this.$refs[this.dataGridRefName].instance;
      this.clearedFilter = true;
      dataGrid.clearFilter();
    },
    getRole() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("auth/getUser")
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setAssessmentListButtonText(data) {
      // TODO:
      // change hard coded strings
      const {can} = new AbilityBuilder();

      if (data.data.isAssignedResource) {
        switch (data.data.statusCode) {
          case "NotStarted":
            return can("create", subjects.assessment.subject)
              ? this.$t("assessments.assessmentList.startAssessment")
              : this.$t("assessments.assessmentList.viewAssessment");
          case "InProgress":
            return can("update", subjects.assessment.subject)
              ? this.$t("assessments.assessmentList.editAssessment")
              : this.$t("assessments.assessmentList.viewAssessment");
          case "Submitted":
            return can("update", subjects.assessment.subject)
              ? this.$t("assessments.assessmentList.reviewAssessment")
              : this.$t("assessments.assessmentList.viewAssessment");
          default:
            this.$t("assessments.assessmentList.viewAssessment");
        }
      }
      return this.$t("assessments.assessmentList.viewAssessment");
    },
    formatStatusCode(data) {
      return this.$t(
        `assessments.assessmentList.${toCamelCase(data.data.statusCode)}`,
      );
    },
    formatRetailerAssessment(data) {
      if (data.data.retailerAssessment === "Self-Assessment") {
        return this.$t("assessments.assessmentList.selfAssessment");
      }
      if (data.data.retailerAssessment === "Auditor Visit") {
        return this.$t("assessments.assessmentList.auditorVisit");
      }
      return "";
    },
    setStatusClass(data) {
      let status = data.data.statusCode;
      if (status == "NotStarted" || status == "Cancelled") {
        return "red";
      } else if (status == "InProgress") {
        return "yellow";
      } else if (status == "Submitted") {
        return "submitted";
      } else if (status == "Complete") {
        return "green";
      } else {
        return "";
      }
    },
    newScheduleButtonClick() {
      this.$router.push({name: "schedule-assessment"});
    },
    editAssessmentVisitClick(data) {
      this.$router.push({
        name: "edit-assessment",
        params: {id: data.data.id},
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
