export default [
  {
    // Compulsory
    dataField: "id",
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: "supplierName",
    caption: "findings.findingList.supplierName",
    visible: true,
  },
  {
    dataField: "siteName",
    caption: "findings.findingList.siteName",
    visible: true,
  },
  {
    dataField: "requirementNumber",
    caption: "findings.findingList.requirementNumber",
    visible: true,
  },
  {
    dataField: "requirementTitle",
    caption: "findings.findingList.requirementTitle",
    visible: true,
  },
  {
    dataField: "capDate",
    caption: "findings.findingList.capDate",
    dataType: "date",
    format: "dd/MMM/yyyy",
    visible: true,
  },
  {
    dataField: "deadlineForApproval",
    caption: "findings.findingList.approvalDeadline",
    dataType: "date",
    format: "dd/MMM/yyyy",
    visible: true,
    sortOrder: "asc",
    sortIndex: 2,
  },
  {
    dataField: "actionStatusCode",
    cellTemplate: "status-cell-template",
    caption: "findings.findingList.status",
    visible: true,
  },
  {
    dataField: "leadReviewer",
    caption: "findings.findingList.leadReviewer",
    visible: true,
  },
  {
    dataField: "capStatus",
    caption: "orders.orderList.capStatus",
    visible: false,
    cellTemplate: "capStatus-cell-template",
    alignment: "left",
    lookup: {
      dataSource: {
        store: {
          type: "array",
          data: [
            {
              id: 1,
              name: "orders.orderList.capProcess",
            },
            {
              id: 3,
              name: "orders.orderList.capProcessExtensionByReviewer",
            },
            {
              id: 4,
              name: "orders.orderList.capProcessExtensionByICGorGIF",
            },
            {
              id: 5,
              name: "orders.orderList.capProcessUnableToVerify",
            },
            {
              id: 6,
              name: "orders.orderList.capProcessVerified",
            },
            {
              id: 7,
              name: "orders.orderList.iaNoFollowUpNeeded",
            },
          ],
          key: "id",
        },
      },
      valueExpr: "id", // contains the same values as the "statusId" field provides
      displayExpr: "name", // provides display values
    },
  },
  {
    dataField: "requirementLevel",
    caption: "findings.findingList.requirementLevel",
    visible: false,
  },
  {
    dataField: "description",
    caption: "findings.findingList.description",
    visible: false,
  },
  {
    dataField: "reviewStartDate",
    caption: "findings.findingList.reviewStartDate",
    dataType: "date",
    format: "dd/MMM/yyyy",
    visible: false,
  },
  {
    dataField: "supportReviewers",
    caption: "findings.findingList.supportReviewers",
    visible: false,
    allowHeaderFiltering: false,
    dataType: "string",
    calculateFilterExpression: function (
      filterValue,
      selectedFilterOperation,
      target,
    ) {
      if (target === "filterRow") {
        return function (data) {
          for (let i = 0; i < data.supportReviewers.length; i++) {
            const supportReviewers = data.supportReviewers[i].name;
            if (
              supportReviewers.toLowerCase().includes(filterValue.toLowerCase())
            )
              return true;
          }
          return false;
        };
      }
    },
  },
  {
    dataField: "businessOwners",
    caption: "orders.orderList.businessOwners",
    visible: false,
    cellTemplate: "businessOwners-template",
    allowHeaderFiltering: false,
    dataType: "string",
    width: 250,
    calculateFilterExpression: function (
      filterValue,
      selectedFilterOperation,
      target,
    ) {
      if (target === "filterRow") {
        return function (data) {
          for (let i = 0; i < data.businessOwners.length; i++) {
            const businessOwners = data.businessOwners[i].name;
            if (
              businessOwners.toLowerCase().includes(filterValue.toLowerCase())
            )
              return true;
          }
          return false;
        };
      }
    },
  },
  {
    dataField: "reviewParticipants",
    caption: "orders.orderList.reviewParticipants",
    visible: false,
    cellTemplate: "reviewParticipants-template",
    allowHeaderFiltering: false,
    dataType: "string",
    width: 250,
    calculateFilterExpression: function (
      filterValue,
      selectedFilterOperation,
      target,
    ) {
      if (target === "filterRow") {
        return function (data) {
          for (let i = 0; i < data.reviewParticipants.length; i++) {
            const reviewParticipants = data.reviewParticipants[i].name;
            if (
              reviewParticipants
                .toLowerCase()
                .includes(filterValue.toLowerCase())
            )
              return true;
          }
          return false;
        };
      }
    },
  },
  {
    dataField: "country",
    caption: "findings.findingList.country",
    visible: false,
  },
  {
    dataField: "reviewMode",
    caption: "findings.findingList.reviewMode",
    cellTemplate: "review-mode-template",
    visible: false,
  },
  {
    dataField: "reviewType",
    caption: "findings.findingList.reviewType",
    visible: false,
  },
  {
    dataField: "reviewEndDate",
    caption: "findings.findingList.reviewEndDate",
    visible: false,
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "rootCause",
    caption: "findings.findingList.rootCause",
    visible: false,
  },
  {
    dataField: "reviewParticipantTargetDate",
    caption: "findings.findingList.reviewParticipantTargetDate",
    visible: false,
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "caseResolutionDetails",
    caption: "findings.findingList.caseResolutionDetails",
    visible: false,
  },
  {
    cellTemplate: "assessment-button-cell-template",
    headerCellTemplate: "clear-filter-cell-template",
    fixed: true,
    fixedPosition: "right",
    visible: true,
    width: "5px",
  },
  {
    dataField: "responsiblePerson",
    caption: "findings.findingList.responsiblePerson",
    visible: false,
  },
  {
    dataField: "approvedDate",
    caption: "findings.findingList.approvedDate",
    visible: false,   
    dataType: "date",
    format: "dd/MMM/yyyy",
  }
];
