<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="followupStatusList"
      display-expr="name"
      :placeholder="$t('reporting.filters.followupStatusPlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      styling-mode="outlined"
      :value.sync="followupStatuses"
      value-expr="id"
      :wrapItemText="true"
      apply-value-mode="instantly"
      :max-displayed-tags="1"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import DxTagBox from "devextreme-vue/tag-box";

export default {
  components: {
    DxTagBox,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["followupStatuses"],
      "getFilter",
      "setFilter",
    ),
    followupStatusList() {
      return [
        {id: 1, name: this.$t("orders.reviewDetails.supplierPhasedOut")},
        {
          id: 2,
          name: this.$t("orders.reviewDetails.otherBizConsequenceApplied"),
        },
        {id: 3, name: this.$t("orders.reviewDetails.noBizConsequenceApplied")},
      ];
    },
  },
};
</script>

<style lang="sass" scoped></style>
