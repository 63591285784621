// This is where we define our column definitions
// for the assessment-scheduling view.

export const retailerColDefs = [
  {
    dataField: "name",
    caption: "Retailer",
    width: "auto",
    sortOrder: "asc",
    sortIndex: 1,
  },
  {
    dataField: "parentOne",
    caption: "Market",
    width: "auto",
  },
  {
    dataField: "orgCode",
    caption: "Dealer Code",
    width: "auto",
  },
  {
    dataField: "scheduledSelfAssessments",
    caption: "Self assessments",
    dataType: "number",
    width: "auto",
  },
  {
    dataField: "scheduledSiteVisits",
    caption: "Site Visits",
    dataType: "number",
    width: "auto",
  },
  {
    dataField: "address",
    caption: "Address",
  },
  // {
  //   dataField: "brand",
  //   caption: "Brand",
  // },
];
