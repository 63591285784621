<template>
  <div class="chart-container card-container">
    <div class="chart-container-header">
      <h1>
        {{ chartTitle }}
      </h1>
      <div class="chart-group-by-container">
        <dx-select-box
          display-expr="name"
          :items="groupByDataSource"
          styling-mode="filled"
          :value.sync="groupByValue"
          value-expr="id"
        />
      </div>
    </div>
    <dx-chart
      :data-source="dataSource"
      :ref="chartRef"
      @point-click="onPointClick($event)"
      @legend-click="onLegendClick($event)"
      @exporting="onExporting"
      @exported="onExported"
      @done="chartZoomIn"
      :resolve-label-overlapping="pointOverlappingOptions"
      :animation="{
        enabled: false,
      }"
    >
      <dx-size :height="chartHeight" :width="chartWidth" />
      <dx-loading-indicator :enabled="true" />
      <dx-scroll-bar :visible="true" />
      <dx-zoom-and-pan argument-axis="both" />
      <dx-data-prepare-settings :sorting-method="false" />
      <dx-common-axis-settings :color="color.axis">
        <dx-tick :color="color.axis" />
        <dx-label
          :visible="axisOn"
          :displayMode="axisLabelPosition"
          :rotationAngle="axisRotationAngle"
        >
          <dx-font :color="color.text" />
        </dx-label>
      </dx-common-axis-settings>
      <dx-argument-axis
        :visual-range="initialRange"
        position="bottom"
        :inverted="true"
      >
        <dx-label overlappingBehavior="stagger" />
      </dx-argument-axis>
      <dx-value-axis position="right" :allow-decimals="false">
        <dx-grid :color="color.grid" />
        <dx-title
          :text="$t('reporting.charts.findingStatusByLocationValueAxisTitle')"
        >
          <dx-font :color="color.text" />
        </dx-title>
      </dx-value-axis>
      <dx-common-series-settings
        argument-field="group"
        value-field="value"
        type="stackedbar"
        hover-mode="allArgumentPoints"
        selection-mode="allArgumentPoints"
      >
        <dx-label
          :visible="pointOn"
          :position="pointLabelPosition"
          :customize-text="customizeText"
        >
          <dx-connector :visible="connectorOn" />
        </dx-label>
      </dx-common-series-settings>
      <dx-series-template
        :customize-series="customizeSeries"
        name-field="series"
      />
      <dx-tooltip :enabled="true" content-template="tooltipTemplate" />
      <dx-legend
        orientation="horizontal"
        horizontal-alignment="center"
        vertical-alignment="bottom"
        item-text-position="bottom"
        :visible="legendOn"
      >
        <dx-font :color="color.text" />
      </dx-legend>
      <dx-export :enabled="true" background-color="#ffffff" />
      <template #tooltipTemplate="{data}">
        <span>{{ data.point.data.series }}: {{ data.point.data.value }}</span>
      </template>
    </dx-chart>
  </div>
</template>

<script>
import {
  DxArgumentAxis,
  DxChart,
  DxCommonAxisSettings,
  DxCommonSeriesSettings,
  DxDataPrepareSettings,
  DxExport,
  DxFont,
  DxGrid,
  DxLabel,
  DxLegend,
  DxLoadingIndicator,
  DxSeriesTemplate,
  DxTick,
  DxTitle,
  DxTooltip,
  DxValueAxis,
  DxSize,
  DxConnector,
  DxScrollBar,
  DxZoomAndPan,
} from "devextreme-vue/chart";
import DxSelectBox from "devextreme-vue/select-box";
import {CancelToken} from "axios";
import {mapFields} from "vuex-map-fields";
import {mapMutations} from "vuex";
import {
  locationStructureAndReviewDetailsGroupBy,
  locationAndStructureGroupBy,
} from "../../common/constants";
import findingStatusChartSeriesDefinitions from "../../custom/reporting/findingStatusChartSeriesDefinitions";
import chartParameters from "../../mixins/chart-parameters";

export default {
  mixins: [chartParameters],
  props: {...chartParameters.props},
  components: {
    DxArgumentAxis,
    DxChart,
    DxCommonAxisSettings,
    DxCommonSeriesSettings,
    DxDataPrepareSettings,
    DxExport,
    DxFont,
    DxGrid,
    DxLabel,
    DxLegend,
    DxLoadingIndicator,
    DxSelectBox,
    DxSeriesTemplate,
    DxTick,
    DxTitle,
    DxTooltip,
    DxValueAxis,
    DxSize,
    DxConnector,
    DxScrollBar,
    DxZoomAndPan,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["findingStatuses", "filters"],
      "getFilter",
      "setFilter",
    ),
    chart() {
      return this.$refs[this.chartRef].instance;
    },
    chartTitle() {
      return this.$t("reporting.charts.findingStatusByLocationChartTitle", {
        groupByText: this.groupByText,
      });
    },
    groupByText() {
      return this.groupByDataSource.find(
        (x) => x.id === this.groupByValue,
        this,
      ).name;
    },
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      dataSource: [],
      loadCancelToken: null,
      initialRange: {startValue: 0, length: 30},
      chartRef: "findingStatusByLocationChart",
      groupByDataSource: Object.entries(
        locationStructureAndReviewDetailsGroupBy,
      )
        .filter(
          (x) =>
            x[0] !== "Tertial" &&
            x[0] !== "IsThereReviewerCapacity" &&
            x[0] !== "Notification" &&
            x[0] !== "FollowUpStatus" &&
            x[0] !== "CapStatus",
        )
        .map(
          ([key, value]) => ({
            name: this.$t(`reporting.charts.groupBy${key}Title`),
            id: value,
          }),
          this,
        ),
      groupByValue: locationStructureAndReviewDetailsGroupBy.Site,
      seriesDefinitions: findingStatusChartSeriesDefinitions.map(
        (x) => ({...x, caption: this.$t(x.caption)}),
        this,
      ),
      color: {
        text: "black",
        axis: "#C4C4C4",
        grid: "#e8e8e8",
      },
    };
  },
  methods: {
    ...mapMutations("reportingFilters", ["applyFilters"]),
    onLegendClick(e) {
      if (!this.findingStatuses.includes(e.target._data[0].tag.statusCode)) {
        this.findingStatuses = [e.target._data[0].tag.statusCode];
        this.applyFilters();
      }
    },
    chartZoomIn() {
      this.chart.option("argumentAxis.visualRange", this.initialRange);
    },
    onPointClick(e) {
      if (!this.findingStatuses.includes(e.target.tag.statusCode)) {
        this.findingStatuses = [e.target.tag.statusCode];
        this.applyFilters();
      }
    },
    onExporting(e) {
      this.chart.option("title.text", this.chartTitle);
      this.chart.option("title.font.color", "black");
    },
    onExported(e) {
      this.chart.option("title.text", "");
    },
    customizeSeries(seriesName) {
      const series = this.seriesDefinitions.find(
        (x) => x.caption === seriesName,
      );
      return {
        color: series.color,
      };
    },
    customizeText(pointInfo) {
      if (this.pointLabelTextOn) {
        return `${pointInfo.seriesName}: ${pointInfo.value}`;
      } else {
        return pointInfo.value;
      }
    },
    countFindings(accumulator, group) {
      return Object.entries(group || {}).reduce(
        (groupFindingsCount, [statusName, statusFindingsCount]) => {
          return this.seriesDefinitions.find((x) => x.dataField === statusName)
            ? groupFindingsCount + statusFindingsCount
            : groupFindingsCount;
        },
        accumulator,
      );
    },
    async load() {
      this.chart.showLoadingIndicator();
      try {
        if (this.loadCancelToken) {
          this.loadCancelToken.cancel();
        }

        this.loadCancelToken = CancelToken.source();
        let response = await this.$http.post(
          "/reporting/findingstatusbylocation",
          {
            grouping: this.groupByValue,
            filter: this.filters,
          },
          {
            cancelToken: this.loadCancelToken.token,
          },
        );
        this.loadCancelToken = null;

        this.dataSource = response.data.columns
          .sort((a, b) => {
            return (
              a.subColumns.reduce(this.countFindings, 0) -
              b.subColumns.reduce(this.countFindings, 0)
            );
          })
          .reduce((acc, column) => {
            column.subColumns.forEach((subColumn) => {
              Object.entries(subColumn)
                .map(([key, value]) => {
                  let series = this.seriesDefinitions.find(
                    (x) => x.dataField === key,
                  );
                  return {
                    series: series,
                    value: value,
                  };
                })
                .filter((x) => x.series)
                .sort((a, b) => a.series.order - b.series.order)
                .forEach((x) => {
                  acc.push({
                    series: x.series.caption,
                    group: column.label,
                    stack: subColumn.label,
                    value: x.value,
                    tag: {
                      // TODO: change structure when returned from endpoint
                      location: column.label,
                      statusCode: x.series.dataField,
                    },
                  });
                }, this);
            }, this);

            return acc;
          }, []);

        this.chart.hideLoadingIndicator();
        this.dataLoaded();
      } catch (err) {
        this.dataSource = [];
      }
    },
  },
  watch: {
    filters() {
      this.load();
    },
    groupByValue() {
      this.load();
    },
  },
};
</script>

<style lang="sass" scoped></style>
