<template>
  <dx-data-grid
    :allow-column-resizing="true"
    :allow-column-reordering="true"
    :data-source="dataSource"
    :show-borders="true"
    :columns="colDefs"
    :column-min-width="150"
    :focused-row-index="0"
    :focused-row-enabled="false"
    columnResizingMode="widget"
  >
    <dx-remote-operations
      :filtering="false"
      :paging="false"
      :sorting="false"
      :summary="false"
      :grouping="false"
      :group-paging="false"
    />
    <dx-filter-row :visible="true" />
    <dx-paging :page-size="10" />
    <dx-pager :show-page-size-selector="true" :show-info="true" />
    <dx-column-chooser :enabled="true" :allowSearch="true" mode="dragAndDrop" />
    <dx-grouping :context-menu-enabled="true" />
    <dx-group-panel :visible="true" />
    <dx-scrolling column-rendering-mode="standard" />
    <dx-export :enabled="true" />
    <template #status-template="{data}">
      <div>{{ formatStatusCode(data) }}</div>
    </template>
    <template #custom-percentage-template="{data}">
      <div>{{ data.value }}%</div>
    </template>
    <template #audit-type-template="{data}">
      <div>{{ formatAuditType(data) }}</div>
    </template>
    <template #site-visit-type-template="{data}">
      <div>{{ formatSiteVisitType(data) }}</div>
    </template>
    <template #isThereReviewerCapacity-template="{data}">
      <div>
        {{ getIsThereReviewerCapacity(data.data.isThereReviewerCapacity) }}
      </div>
    </template>
    <template #followupStatus-template="{data}">
      <div>
        {{ getFollowupStatus(data.data.followupStatus) }}
      </div>
    </template>
    <template #complianceRate-template="{data}">
      <span>
        {{ getRoundedComplianceRate(data.data) }}
      </span>
    </template>
  </dx-data-grid>
</template>

<script>
import {mapState} from "vuex";
import columnDefinitions from "../../../custom/reporting/assessmentPerformanceGridColDefs";
import {
  DxDataGrid,
  DxScrolling,
  DxColumnChooser,
  DxGrouping,
  DxGroupPanel,
  DxExport,
  DxRemoteOperations,
  DxFilterRow,
  DxPager,
  DxPaging,
} from "devextreme-vue/data-grid";

import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {serializeLoadOptions} from "../../../utils/load-options-serializer";
import {
  toCamelCase,
  firstLetterToUppercase,
} from "../../../common/helperFunctions";
import {riskRating} from "../../../common/constants";
import roleTypes from "../../../config/roles";
import {Workbook} from "exceljs";
import fileDownload from "js-file-download";
import orgCountries from "../../../config/organisationCountries";

export default {
  components: {
    DxDataGrid,
    DxScrolling,
    DxColumnChooser,
    DxGrouping,
    DxGroupPanel,
    DxExport,
    DxRemoteOperations,
    DxFilterRow,
    DxPager,
    DxPaging,
  },
  data() {
    return {
      dataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.load,
        }),
      }),
      loadOptions: null,
    };
  },
  methods: {
    async load(loadOptions) {
      this.loadOptions = loadOptions;
      try {
        let assessmentPerformance = await this.$http.post(
          "reporting/assessmentPerformance",
          this.filters,
          {
            params: serializeLoadOptions(loadOptions),
          },
        );
        return assessmentPerformance.data.data.map((y) =>
          y.id
            ? {
                ...y,
                siteCountry: orgCountries.find((x) => x.value === y.siteCountry)
                  ?.name,
              }
            : null,
        );
      } catch (error) {
        console.log("Error during assessment performance data fetch", error);
        return [];
      }
    },
    getRoundedComplianceRate(data) {
      return data && data.assessmentScore != null
        ? `${Math.round(data.assessmentScore)}%`
        : null;
    },
    formatStatusCode(data) {
      return this.$t(
        `assessments.assessmentList.${toCamelCase(data.data.assessmentStatus)}`,
      );
    },
    formatAuditType(data) {
      switch (data.data.auditType) {
        case "SelfAssessment":
          return this.$t("assessments.assessmentList.selfAssessment");
        case "AuditorVisit":
          return this.$t("assessments.assessmentList.auditorVisit");
        default:
          return "";
      }
    },
    formatSiteVisitType(data) {
      switch (data.data.siteVisitType) {
        case "AuditVisit":
          return this.$t("landingPage.upcomingAssessment.visitTypeAuditVisit");
        case "FieldVisit":
          return this.$t("landingPage.upcomingAssessment.visitTypeFieldVisit");
        default:
          return "";
      }
    },
    getIsThereReviewerCapacity(data) {
      if (data !== null) {
        if (data) return "Yes";
        return "No";
      }
    },
    getFollowupStatus(data) {
      if (data !== null) {
        switch (data) {
          case "1":
            return this.$t("orders.reviewDetails.supplierPhasedOut");
          case "2":
            return this.$t("orders.reviewDetails.otherBizConsequenceApplied");
          case "3":
            return this.$t("orders.reviewDetails.noBizConsequenceApplied");
        }
      }
    },
    isInRole(role) {
      return this.$store.state.auth.user.roles.includes(role);
    },
  },
  computed: {
    ...mapState("reportingFilters", ["filters"]),
    colDefs() {
      return this.colDefsTranslatedLookups.map((x) => ({
        ...x,
        caption: this.$t(
          `reporting.grids.assessmentPerformanceCaption${firstLetterToUppercase(
            x.dataField,
          )}`,
        ),
      }));
    },
    colDefsTranslatedLookups() {
      let colDefs = JSON.parse(JSON.stringify(columnDefinitions));
      colDefs = this.isInRole(roleTypes.reviewParticipant.role)
        ? colDefs.filter((x) => x.dataField !== "assessmentRiskScore")
        : colDefs;
      colDefs.forEach((element) => {
        if (element.lookup) {
          element.lookup.dataSource.store.data.forEach((x) => {
            if (x.name) {
              x.name = this.$t(x.name);
            }
          });
        }
      });
      return colDefs;
    },
  },
  watch: {
    filters() {
      this.load(this.loadOptions);
      this.dataSource.reload();
    },
  },
};
</script>

<style></style>
