<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="siteVisitTypeDataSource"
      display-expr="name"
      :placeholder="$t('reporting.filters.reviewModePlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      :search-enabled="true"
      :search-mode="`contains`"
      :search-expr="`name`"
      styling-mode="outlined"
      :value.sync="siteVisitTypes"
      value-expr="id"
      apply-value-mode="instantly"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import DxTagBox from "devextreme-vue/tag-box";
import {reviewModes as reviewModeDefinitions} from "../../common/constants";
import {toCamelCase} from "../../common/helperFunctions";

export default {
  components: {
    DxTagBox,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["siteVisitTypes"],
      "getFilter",
      "setFilter",
    ),
  },
  data() {
    return {
      siteVisitTypeDataSource: Object.entries(reviewModeDefinitions).map(
        ([key, value]) => ({
          name: this.$t(`reporting.filters.${toCamelCase(key)}`),
          id: key,
        }),
      ),
    };
  },
};
</script>

<style lang="sass" scoped></style>
