export default [
  {
    // Compulsory
    dataField: "id",
    visible: false,
  },
  {
    dataField: "description",
    caption: "Description",
    cellTemplate: "description-cell-template",
  },
  {
    dataField: "capDate",
    caption: "capDate",
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "deadlineForApproval",
    caption: "approvalDeadline",
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    cellTemplate: "options-cell-template",
  },
];
