<template>
  <div class="chart-container card-container">
    <div class="chart-container-header">
      <h1>
        {{ chartTitle }}
      </h1>
    </div>
    <dx-chart
      :ref="chartRef"
      :data-source="dataSource"
      :resolve-label-overlapping="pointOverlappingOptions"
      :customize-point="customizePoint"
      @exporting="onExporting"
      @exported="onExported"
      :animation="{
        enabled: false,
      }"
    >
      <DxSize :height="chartHeight" :width="chartWidth" />
      <dx-loading-indicator :enabled="true" />
      <dx-common-axis-settings :color="color.axis">
        <dx-tick :color="color.axis" />
        <dx-label
          :visible="axisOn"
          :displayMode="axisLabelPosition"
          :rotationAngle="axisRotationAngle"
        >
          <dx-font :color="color.text" />
        </dx-label>
      </dx-common-axis-settings>
      <dx-argument-axis position="bottom">
        <dx-label overlappingBehavior="stagger" />
      </dx-argument-axis>
      <dx-value-axis position="left" :allow-decimals="false">
        <dx-grid :color="color.grid" />
      </dx-value-axis>
      <dx-common-series-settings
        argument-field="group"
        value-field="value"
        type="stackedbar"
        hover-mode="allArgumentPoints"
        selection-mode="allArgumentPoints"
      >
        <dx-label
          :visible="pointOn"
          :position="pointLabelPosition"
          :customize-text="customizeText"
        >
          <dx-connector :visible="connectorOn" />
        </dx-label>
      </dx-common-series-settings>
      <dx-series-template
        :customize-series="customizeSeries"
        name-field="series"
      />
      <dx-tooltip :enabled="true" content-template="tooltipTemplate" />
      <dx-legend
        orientation="horizontal"
        horizontal-alignment="center"
        vertical-alignment="bottom"
        item-text-position="bottom"
        :visible="legendOn"
      >
        <dx-font :color="color.text" />
      </dx-legend>
      <dx-export :enabled="true" background-color="#ffffff" />
      <template #tooltipTemplate="{data}">
        <span
          >{{ data.point.data.series }}:
          {{ data.point.data.value.toFixed(2) }}</span
        >
      </template>
    </dx-chart>
  </div>
</template>

<script>
import {
  DxArgumentAxis,
  DxChart,
  DxCommonAxisSettings,
  DxCommonSeriesSettings,
  DxExport,
  DxFont,
  DxGrid,
  DxLabel,
  DxLegend,
  DxLoadingIndicator,
  DxSeriesTemplate,
  DxTick,
  DxTooltip,
  DxValueAxis,
  DxSize,
  DxConnector,
} from "devextreme-vue/chart";
import {CancelToken} from "axios";
import {mapState} from "vuex";
import {generateColors, registerPalette} from "devextreme/viz/palette";
import {cool} from "../../../common/palettes";
import localeDatetimeFormat from "../../../mixins/locale-datetime-format";
import chartParameters from "../../../mixins/chart-parameters";

export default {
  mixins: [localeDatetimeFormat, chartParameters],
  props: {...chartParameters.props},
  components: {
    DxArgumentAxis,
    DxChart,
    DxCommonAxisSettings,
    DxCommonSeriesSettings,
    DxExport,
    DxFont,
    DxGrid,
    DxLabel,
    DxLegend,
    DxLoadingIndicator,
    DxSeriesTemplate,
    DxTick,
    DxTooltip,
    DxValueAxis,
    DxSize,
    DxConnector,
  },
  computed: {
    ...mapState("reportingFilters", ["filters"]),
    chart() {
      return this.$refs[this.chartRef].instance;
    },
    chartTitle() {
      return this.$t("reporting.charts.retailerAssessmentScoreChartTitle");
    },
    colors() {
      const items = this.dataSource;
      // Ensure distinct values
      const series = [...new Set(items.map((x) => x.series))];
      const palette = generateColors(this.palette.name, series.length);

      return Object.fromEntries(
        series.map((s, i) => [s, palette[i % palette.length]]),
      );
    },
    groupByText() {
      return this.groupByDataSource.find(
        (x) => x.id === this.groupByValue,
        this,
      ).name;
    },
  },
  created() {
    registerPalette(this.palette.name, this.palette.palette);
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      dataSource: [],
      loadCancelToken: null,
      chartRef: "assessmentScoresChart",
      palette: cool,
      stackTranslations: {
        auditScore: this.$t("reporting.charts.auditScoreStackTitle"),
        complianceScore: this.$t("reporting.charts.complianceScoreStackTitle"),
        finalScore: this.$t("reporting.charts.finalScoreStackTitle"),
      },
      color: {
        text: "black",
        axis: "#C4C4C4",
        grid: "#e8e8e8",
      },
    };
  },
  methods: {
    customizePoint(e) {
      return {
        color: this.colors[e.data.series],
      };
    },
    customizeSeries(seriesName) {
      return {
        color: this.colors[seriesName],
        valueField: "value",
      };
    },
    onExporting(e) {
      this.chart.option("title.text", this.chartTitle);
      this.chart.option("title.font.color", "black");
    },
    onExported(e) {
      this.chart.option("title.text", "");
    },
    customizeText(pointInfo) {
      if (this.pointLabelTextOn) {
        return `${pointInfo.seriesName}: ` + pointInfo.value.toFixed(2);
      } else {
        return pointInfo.value.toFixed(2);
      }
    },
    async load() {
      this.chart.showLoadingIndicator();
      try {
        if (this.loadCancelToken) {
          this.loadCancelToken.cancel();
        }

        this.loadCancelToken = CancelToken.source();
        let response = await this.$http.post(
          "/reporting/assessmentScores",
          this.filters,
          {
            cancelToken: this.loadCancelToken.token,
          },
        );
        this.loadCancelToken = null;

        this.dataSource = response.data
          .sort((a, b) => a.scheduledBy.localeCompare(b.scheduledBy))
          .reduce((acc, column) => {
            column.finalScore = column.finalScore - column.complianceScore;
            column.complianceScore = column.complianceScore - column.auditScore;

            Object.entries(column).forEach(([key, value]) => {
              let series = this.stackTranslations[key];
              if (series) {
                acc.push({
                  series: series,
                  group: `${this.localeDatetimeFormat(column.scheduledBy)} ${
                    column.scheduleType
                  }`,
                  value: value,
                });
              }
            }, this);
            return acc;
          }, []);

        this.chart.hideLoadingIndicator();
        this.dataLoaded();
      } catch (err) {
        this.dataSource = [];
      }
    },
  },
  watch: {
    filters() {
      this.load();
    },
  },
};
</script>

<style lang="sass" scoped></style>
