import roleTypes from "./custom/config/roles";
import subjects from "./config/subjects";

const {
  home,
  organisation,
  order,
  assessment,
  findingResolution,
  dashboard,
  userManagement,
  legal,
} = subjects;

export default [
  {
    text: "Home",
    path: "/home",
    icon: "home",
    translation: "menuItems.home",
    subject: home.subject,
  },
  {
    text: "Orders",
    path: "/orders-list",
    icon: "exportselected",
    translation: "menuItems.orders",
    subject: order.subject,
  },
  // TODO:
  // Discuss whether or not to just have the orders as a single point of entry
  // {
  //   text: "Assessments",
  //   path: "/assessment-list",
  //   icon: "exportselected",
  //   translation: "menuItems.assessments",
  //   subject: assessment.subject,
  // },
  {
    text: "Corrective action resolution",
    path: "/corrective-actions-list",
    icon: "paste",
    translation: "menuItems.findings",
    subject: findingResolution.subject,
  },
  {
    text: "Dashboard",
    path: "/reporting",
    icon: "chart",
    translation: "menuItems.reporting",
    subject: dashboard.subject,
  },
  {
    text: "Users",
    path: "/users-list",
    icon: "user",
    translation: "menuItems.users",
    subject: userManagement.subject,
  },
  {
    text: "Organisations",
    path: "/supplier-list",
    icon: "globe",
    translation: "menuItems.organisations",
    subject: organisation.subject,
  },
  {
    text: "Legal notices",
    path: "/legal-notices",
    icon: "bookmark",
    translation: "menuItems.legal",
    subject: legal.subject,
  },
];
