import {requestType, exemptionRequestStatus} from "../../common/constants";

export default [
  {
    // Compulsory
    dataField: "id",
    visible: false,
    showInColumnChooser: false,
  },
  {
    dataField: "orgType",
    caption: "organisations.organisationsList.orgType",
    visible: true,
  },
  {
    dataField: "ingkaUnit",
    caption: "organisations.organisationsList.ingkaUnit",
    visible: true,
  },
  {
    dataField: "siteType",
    caption: "organisations.organisationsList.siteType",
    visible: true,
  },
  {
    dataField: "number",
    caption: "organisations.organisationsList.orgCode",
    visible: true,
  },
  {
    dataField: "supplierName",
    caption: "organisations.organisationsList.supplierName",
    visible: true,
  },
  {
    dataField: "siteName",
    caption: "organisations.organisationsList.siteName",
    visible: true,
  },
  {
    dataField: "country",
    caption: "organisations.organisationsList.country",
    visible: true,
  },
  {
    dataField: "risk",
    caption: "organisations.organisationsList.risk",
    visible: true,
  },
  {
    dataField: "siteAddress",
    caption: "organisations.organisationsList.siteAddress",
    visible: false,
  },
  {
    dataField: "supplierStartDate",
    caption: "organisations.organisationsList.supplierStartDate",
    dataType: "date",
    format:"dd/MMM/yyyy",
    visible: false,
  },
  {
    dataField: "crossBorderCountries",
    caption: "organisations.organisationsList.crossBorderCountries",
    visible: false,
  },
  {
    dataField: "productOrServices",
    caption: "organisations.organisationsList.productOrServices",
    visible: false,
  },
  {
    dataField: "scopeOfInitialAssessment",
    caption: "organisations.organisationsList.scopeOfInitialAssessment",
    visible: false,
  },
  {
    dataField: "activeStatus",
    caption: "organisations.organisationsList.activeStatus",
    visible: false,
  },
  {
    caption: "",
    cellTemplate: "action-buttons-cell-template",
    headerCellTemplate: "clear-filter-cell-template",
    fixed: true,
    fixedPosition: "right",
    visible: true,
    width: "5px",
  },
];
