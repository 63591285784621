<template>
  <div>
    <page-heading
      ref="pageHeading"
      :pageTitle="pageTitle"
      :breadCrumbs="breadCrumbs"
    />
    <div class="row">
      <div class="col-sm-12">
        <div class="tabbed-details-menu mb-3 small-no-margin both-sides">
          <div
            class="assessment-subnav-tabs mb-3 left-active hidden-lg hidden-xl"
          >
            <div @click="filtersVisible = true">
              <i class="fas fa-filter mr-2"></i>
              {{ $t("reporting.filters.filtersTitle") }}
            </div>
          </div>
          <div class="assessment-subnav-tabs">
            <div
              :class="{'tab-element-selected': chartMode}"
              @click="chartMode = true"
            >
              <i class="fas fa-chart-bar" />
            </div>
            <div
              :class="{'tab-element-selected': !chartMode}"
              @click="
                chartMode = false;
                renderChart = true;
              "
            >
              <i class="fas fa-table" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!--TODO: extract left fixed filter menu into separate component (same applies to other dashboards) -->
      <div
        class="card-container left-fixed-with-heading"
        :class="{open: filtersVisible}"
        :style="{
          height: filterContainerHeight + 'px',
          top: filterContainerTopOffset + 'px',
        }"
      >
        <div
          class="hidden-lg hidden-xl close-section"
          @click="filtersVisible = false"
        >
          <i class="fas fa-times close-icon"></i>
        </div>
        <DxScrollView
          class="full-height-scrollview"
          show-scrollbar="onHover"
          :use-native="false"
        >
          <div
            class="report-filter-pane-items fixed"
            style="border: 0; padding: 0; box-shadow: none"
          >
            <location-filter />
            <div class="report-filter-pane-item">
              <div class="report-filter-pane-item-header">
                <i class="fas fa-globe-americas"></i>
                <h1>{{ $t("reporting.filters.assessmentStructureTitle") }}</h1>
              </div>
              <audit-period-filter />
              <assessment-structure-filter />
            </div>
            <div class="report-filter-pane-item">
              <div class="report-filter-pane-item-header">
                <i class="fas fa-chart-bar"></i>
                <h1>{{ $t("reporting.filters.assessmentDetailsTitle") }}</h1>
              </div>
              <review-type-filter />
              <site-visit-type-filter />
              <question-level-filter />
              <category-area-filter />
              <category-filter />
              <sub-category-filter />
              <requirement-filter />
            </div>
            <div class="report-filter-pane-item">
              <div class="report-filter-pane-item-header">
                <i class="far fa-clipboard"></i>
                <h1>{{ $t("reporting.filters.findingDetailsTitle") }}</h1>
              </div>
              <finding-status-filter />
            </div>
            <div class="report-filter-pane-item">
              <dx-button
                icon="refresh"
                styling-mode="outlined"
                @click="onApplyFilters"
                :text="$t('reporting.filters.applyFilters')"
                width="100%"
              />
            </div>
          </div>
        </DxScrollView>
      </div>
      <div v-show="chartMode" class="col-12 dashboard-indent">
        <div class="row">
          <div class="col-md-12">
            <chart-container>
              <template slot="item" slot-scope="props">
                <finding-status-by-location-chart
                  :chartHeight="props.chartHeight"
                  :chartWidth="props.chartWidth"
                  :isChartFullScreen="props.isChartFullScreen"
                  :legendOn="props.legendOn"
                  :axisOn="props.axisOn"
                  :axisLabelPosition="props.axisLabelPosition"
                  :axisRotationAngle="props.axisRotationAngle"
                  :pointOn="props.pointOn"
                  :connectorOn="props.connectorOn"
                  :pointLabelTextOn="props.pointLabelTextOn"
                  :pointLabelPosition="props.pointLabelPosition"
                  :pointOverlappingOptions="props.pointOverlappingOptions"
                  :dataLoaded="props.dataLoaded"
                />
              </template>
            </chart-container>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <chart-container>
              <template slot="item" slot-scope="props">
                <finding-activity-chart
                  :chartHeight="props.chartHeight"
                  :chartWidth="props.chartWidth"
                  :isChartFullScreen="props.isChartFullScreen"
                  :legendOn="props.legendOn"
                  :axisOn="props.axisOn"
                  :axisLabelPosition="props.axisLabelPosition"
                  :axisRotationAngle="props.axisRotationAngle"
                  :pointOn="props.pointOn"
                  :connectorOn="props.connectorOn"
                  :pointLabelTextOn="props.pointLabelTextOn"
                  :pointLabelPosition="props.pointLabelPosition"
                  :pointOverlappingOptions="props.pointOverlappingOptions"
                  :dataLoaded="props.dataLoaded"
                />
              </template>
            </chart-container>
          </div>
        </div>
      </div>
      <div v-show="!chartMode" class="col-12 dashboard-indent">
        <findings-analysis-grid v-if="renderChart" />
      </div>
    </div>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import ChartContainer from "../../components/reporting/chart-container";
import FindingsAnalysisGrid from "../../components/reporting/grids/findings-analysis-grid";
import FindingStatusByLocationChart from "../../components/reporting/finding-status-by-location-chart";
import FindingActivityChart from "../../components/reporting/finding-activity-chart";
import AssessmentStructureFilter from "../../components/reporting/assessment-structure-filter";
import AuditPeriodFilter from "../../components/reporting/audit-period-filter";
import ReviewTypeFilter from "../../components/reporting/review-type-filter";
import LocationFilter from "../../components/reporting/location-filter";
import SiteVisitTypeFilter from "../../components/reporting/site-visit-type-filter";
import FindingStatusFilter from "../../components/reporting/finding-status-filter";
import {DxScrollView} from "devextreme-vue/scroll-view";
import {mapMutations} from "vuex";
import pageHeading from "../../components/page-heading";
import CategoryAreaFilter from "../../components/reporting/category-area-filter.vue";
import CategoryFilter from "../../components/reporting/category-filter.vue";
import SubCategoryFilter from "../../components/reporting/sub-category-filter.vue";
import QuestionLevelFilter from "../../components/reporting/question-level-filter.vue";
import RequirementFilter from "../../components/reporting/requirement-filter.vue";

export default {
  components: {
    DxButton,
    FindingsAnalysisGrid,
    AssessmentStructureFilter,
    AuditPeriodFilter,
    ReviewTypeFilter,
    FindingStatusByLocationChart,
    LocationFilter,
    SiteVisitTypeFilter,
    FindingStatusFilter,
    pageHeading,
    FindingActivityChart,
    ChartContainer,
    DxScrollView,
    CategoryAreaFilter,
    CategoryFilter,
    SubCategoryFilter,
    QuestionLevelFilter,
    RequirementFilter,
  },
  computed: {
    pageTitle() {
      return this.$t("reporting.findingsDashboard.pageTitle");
    },
    breadCrumbs() {
      return [this.$t("reporting.pageTitle"), this.pageTitle];
    },
  },
  created() {
    this.clearFilters();
  },
  destroyed() {
    document
      .querySelector(".with-footer .dx-scrollable-container")
      .removeEventListener("scroll", this.filterSectionResizeHandler);
  },
  mounted() {
    this.filterSectionResizeHandler();
    document
      .querySelector(".with-footer .dx-scrollable-container")
      .addEventListener("scroll", this.filterSectionResizeHandler);
  },
  updated() {
    this.filterSectionResizeHandler();
  },
  methods: {
    ...mapMutations("reportingFilters", ["clearFilters", "applyFilters"]),
    onApplyFilters() {
      this.applyFilters();
      this.filtersVisible = false;
    },
    filterSectionResizeHandler(e) {
      let screenWidth = window.innerWidth;
      let screenHeight = window.innerHeight;
      let topBarHeight = 76;
      let headingHeight =
        screenWidth < 992 ? 0 : this.$refs.pageHeading.$el.clientHeight;
      let scrollTop = document.querySelector(
        ".with-footer .dx-scrollable-container",
      ).scrollTop;
      let topGap =
        scrollTop > headingHeight
          ? topBarHeight
          : topBarHeight + (headingHeight - scrollTop);
      this.filterContainerHeight = screenHeight - topGap;
      this.filterContainerTopOffset = topGap;
    },
  },
  data() {
    return {
      chartMode: true,
      renderChart: false,
      filtersVisible: false,
      filterContainerHeight: 0,
      filterContainerTopOffset: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.tabbed-details-menu {
  margin-top: 0;
}
</style>
