import { render, staticRenderFns } from "./assessment-answer-container.vue?vue&type=template&id=26da6a47&scoped=true&"
import script from "./assessment-answer-container.vue?vue&type=script&lang=js&"
export * from "./assessment-answer-container.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26da6a47",
  null
  
)

export default component.exports