<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="questionLevelsData"
      display-expr="name"
      :placeholder="$t('reporting.filters.levelPlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      styling-mode="outlined"
      :value.sync="questionLevels"
      value-expr="value"
      :wrapItemText="true"
      apply-value-mode="instantly"
      :max-displayed-tags="1"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import DxTagBox from "devextreme-vue/tag-box";

export default {
  components: {
    DxTagBox,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["questionLevels"],
      "getFilter",
      "setFilter",
    ),
  },
  data() {
    return {
      questionLevelsData: [
        {name: "Basic", value: "Basic"},
        {name: "Advanced", value: "Advanced"},
        {name: "Excellent", value: "Excellent"},
        {name: "Must", value: "Must"},
        {name: "", value: "Core"},
      ],
    };
  },
};
</script>

<style lang="sass" scoped></style>
