<template>
  <div>
    <page-heading :pageTitle="pageTitle" :breadCrumbs="breadCrumbs" />

    <div class="row">
      <div class="col-sm-12">
        <div class="content-block">
          <div
            class="dashboard-thumbnail card-container reporting-header-image-banner"
          >
            <div
              class="blurry-bg"
              style="background-color: transparent; backdrop-filter: blur(6px)"
            >
              <div
                class="dashboard-thumbnail-header"
                style="margin-top: 115px; margin-left: 30px; margin-right: 30px"
              >
                <i
                  style="color: white; border-color: white; border: 0"
                  class="fas fa-chart-pie mr-2"
                ></i>
                <h1>{{ $t("reporting.subTitle") }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-xl-4 col-lg-6 col-md-6"
        v-for="dashboard in dashboards"
        :key="dashboard.route"
      >
        <div class="content-block" style="margin-bottom: 5px">
          <div
            class="dashboard-thumbnail card-container"
            style="margin-bottom: 5px"
          >
            <div class="dashboard-thumbnail-header">
              <i class="fas fa-chart-bar mr-2"></i>
              <h1>{{ $t(dashboard.title) }}</h1>
            </div>
            <p class="mb-4">{{ $t(dashboard.summary) }}</p>
            <dx-button
              icon="filter"
              styling-mode="text"
              class="btn btn-medium"
              :text="$t('reporting.viewReportTitle')"
              type="normal"
              @click="viewReport(dashboard.route)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import dashboardDefinitions from "../custom/reporting/dashboardDefinitions";
import DxButton from "devextreme-vue/button";
import pageHeading from "../components/page-heading";

export default {
  components: {
    DxButton,
    pageHeading,
  },
  computed: {
    ...mapState("auth", ["user"]),
    pageTitle() {
      return this.$t("reporting.pageTitle");
    },
    breadCrumbs() {
      return [this.$t("reporting.subTitle")];
    },
    dashboards() {
      return this.user
        ? dashboardDefinitions.filter((d) =>
            d.roles.some((role) => this.user.roles.includes(role)),
          )
        : [];
    },
  },
  created() {
    this.setUser();
  },
  methods: {
    ...mapActions("auth", ["setUser"]),
    viewReport(route) {
      this.$router.push({name: route});
    },
  },
};
</script>

<style lang="scss" scoped></style>
