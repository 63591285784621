export default {
  props: {
    isChartFullScreen: Boolean,
    chartHeight: Number,
    chartWidth: Number,
    legendOn: Boolean,
    axisOn: Boolean,
    axisLabelPosition: String,
    axisRotationAngle: Number,
    pointOn: Boolean,
    connectorOn: Boolean,
    pointLabelTextOn: Boolean,
    pointLabelPosition: String,
    pointOverlappingOptions: String,
    dataLoaded: Function,
  },
  methods: {
    updateChart() {
      setTimeout(() => {
        this.$refs[this.chartRef].instance.render({force: true});
      });
    },
  },
  watch: {
    pointLabelTextOn: function () {
      this.updateChart();
    },
    isChartFullScreen: function () {
      this.updateChart();
    },
  },
};
