export const cool = Object.freeze({
  name: "Cool",
  palette: {
    simpleSet: ["#333348", "#4e5f7a", "#91a19f", "#d8dde0"],
    indicatingSet: ["#9e1b33", "#005a2b"],
    gradientSet: ["#131313", "#ffffff"],
  },
});

export const warm = Object.freeze({
  name: "Warm",
  palette: {
    simpleSet: ["#573535", "#a47245", "#bba56e", "#e4dbc6"],
    indicatingSet: ["#9e1b33", "#005a2b"],
    gradientSet: ["#131313", "#ffffff"],
  },
});

export const grey = Object.freeze({
  name: "Grey",
  palette: {
    simpleSet: ["#525252", "#8c8c8c", "#c4c4c4", "#e8e8e8"],
    indicatingSet: ["#9e1b33", "#005a2b"],
    gradientSet: ["#131313", "#ffffff"],
  },
});

export const palettes = Object.freeze([cool, warm, grey]);
