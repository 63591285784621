<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="orderStatusDataSource"
      display-expr="name"
      :placeholder="$t('reporting.filters.visitStatusPlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      styling-mode="outlined"
      :value.sync="visitStatuses"
      value-expr="id"
      apply-value-mode="instantly"
      :max-displayed-tags="1"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import DxTagBox from "devextreme-vue/tag-box";
import {orderStatuses} from "../../custom/config/workflows";

export default {
  components: {
    DxTagBox,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["visitStatuses"],
      "getFilter",
      "setFilter",
    ),
  },
  data() {
    return {
      orderStatusDataSource: Object.entries(orderStatuses)
        // eslint-disable-next-line no-unused-vars
        // .filter(
        //   ([key, value]) =>
        //     value != orderStatuses.ordered && value != orderStatuses.cancelled,
        // )
        .map(
          ([key, value]) => ({
            name: this.$t(`orders.orderList.${key}`),
            id: value,
          }),
          this,
        ),
    };
  },
};
</script>
<style lang="sass" scoped></style>
