import roles from "../../config/roles";
import {userActiveState} from "../../common/constants";

export default [
  {
    caption: "users.usersList.colDefsUsername",
    dataField: "username",
    dataType: "string",
  },
  {
    caption: "users.usersList.colDefsEmail",
    dataField: "email",
    dataType: "string",
  },
  {
    caption: "users.usersList.colDefsFirstName",
    dataField: "firstName",
    dataType: "string",
  },
  {
    caption: "users.usersList.colDefsLastName",
    dataField: "lastName",
    dataType: "string",
    sortIndex: "1",
    sortOrder: "asc",
  },
  {
    caption: "users.usersList.colDefsRole",
    dataField: "role",
    lookup: {
      dataSource: {
        store: {
          type: "array",
          data: [
            {
              id: roles.admin.role,
              name: "users.roles.admin",
            },
            {
              id: roles.orderer.role,
              name: "users.roles.orderer",
            },
            {
              id: roles.reviewer.role,
              name: "users.roles.reviewer",
            },
            {
              id: roles.reviewParticipant.role,
              name: "users.roles.reviewParticipant",
            },
            {
              id: roles.businessOwner.role,
              name: "users.roles.businessOwner",
            },
          ],
          key: "id",
        },
        cellTemplate: "role-template",
      },
      valueExpr: "id",
      displayExpr: "name",
    },
  },
  {
    caption: "users.usersList.colDefsJobTitle",
    dataField: "jobTitleName",
    dataType: "string",
  },
  {
    caption: "users.usersList.colDefsValidTo",
    dataField: "validTo",
    dataType: "date",
    visible: false,
  },
  {
    caption: "users.usersList.colDefsActiveStatus",
    dataField: "activeStatus",
    lookup: {
      dataSource: {
        store: {
          type: "array",
          data: [
            {
              id: userActiveState.Active,
              name: "users.usersList.active",
            },
            {
              id: userActiveState.Disabled,
              name: "users.usersList.disabled",
            },
          ],
          key: "id",
        },
        cellTemplate: "active-template",
      },
      valueExpr: "id",
      displayExpr: "name",
    },
  },
  {
    caption: "users.usersList.colDefsOptions",
    cellTemplate: "action-buttons-cell-template",
    headerCellTemplate: "clear-filter-cell-template",
    fixed: true,
    fixedPosition: "right",
    visible: true,
    width: "5px",
  },
  {
    caption: "users.usersList.colDefsSiteAccessCount",
    dataField: "siteAccessCount",
    visible: false,
    alignment: "left",
  },
];
