<template>
  <div>
    <component
      :is="questionComponent"
      :question="question"
      :answerOptions="answerOptions"
      :questionId="questionId"
      :preselectedValue="preselectedValue"
      :disabled="disabled"
      @set-answer="setSelectedAnswer"
    >
    </component>

    <br />

    <div
      v-if="question.guidanceFlags.includes('Mandatory Evidence Flag')"
      class="pre-answered-response"
    >
      <h3>
        <i class="fas fa-paperclip"></i>
        {{ $t("assessments.assessment.mandatoryEvidenceTitle") }}
      </h3>
      <p>
        {{ $t("assessments.assessment.mandatoryEvidence") }}
      </p>
    </div>
  </div>
</template>

<script>
import AssessmentAnswerRadioButton from "../assessment/assessment-answer-radio-button.vue";
import AssessmentAnswerSimpleYesNo from "../assessment/assessment-answer-dropdown.vue";
import AssessmentAnswerSingleLineText from "../assessment/assessment-answer-single-line-text-box.vue";
import AssessmentAnswerMultilineText from "../assessment/assessment-answer-multiline-text.vue";
import AssessmentAnswerDatebox from "../assessment/assessment-answer-datebox.vue";
import AssessmentAnswerSingleSelect from "../assessment/assessment-answer-dropdown.vue";
import AssessmentAnswerMultiSelect from "../assessment/assessment-answer-multi-select-checkbox.vue";
import {isNotEmpty} from "../../common/helperFunctions";

export default {
  components: {
    AssessmentAnswerSimpleYesNo,
    AssessmentAnswerSingleLineText,
    AssessmentAnswerMultilineText,
    AssessmentAnswerDatebox,
    AssessmentAnswerMultiSelect,
    AssessmentAnswerRadioButton,
    AssessmentAnswerSingleSelect,
  },
  props: {
    answerOptions: {
      Type: Array,
    },
    questionId: {
      Type: Number,
    },
    preselectedValue: {
      Type: String,
      default: undefined,
    },
    question: {
      Type: Object,
    },
    disabled: {
      Type: Boolean,
    },
  },

  computed: {
    questionComponent() {
      return `AssessmentAnswer${this.question.typeFlag}`;
    },
  },

  methods: {
    setSelectedAnswer(e) {
      if (isNotEmpty(e.value)) this.$emit("set-answer", e);
      else {
        if (e.value === "") this.$emit("set-answer", e);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
