<template>
  <div style="text-align: right">
    <span :style="{cursor: 'pointer'}" class="mr-4" @click="onDeleteClick">
      <i class="far fa-trash-alt"></i>
    </span>
    <span :style="{cursor: 'pointer'}" @click="onEditClick">
      <i class="far fa-edit"></i>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
    },
  },
  methods: {
    onDeleteClick() {
      this.$emit("delete-click", this.id);
    },
    onEditClick() {
      this.$emit("edit-click", this.id);
    },
  },
};
</script>

<style></style>
