import $axios from '../../utils/axios-instance';

export default {
    namespaced: true,
    state: {
        overview:  null,
    },
    mutations: {
        setOverview: (state, n) => state.overview = n,
    },
    actions: {
        getAssessmentOverviewData: ({ commit }) => $axios
            .get('dashboard/getAssessmentOverview')
            .then(x => commit("setOverview", x.data))
            .catch(x => console.error('Error during fetching assessment overview')),
    }
}