import {getField, updateField} from "vuex-map-fields";

const getDefaultState = () => {
  return {
    locations: [],
    locationData: [],
    assessmentStructures: [],
    filter: 1,
    selectedAuditYear: null,
    selectedOrdersListTab: null,
    selectedFindingsListTab: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getField,
  },
  actions: {
    updateFilter({commit}, filter) {
      commit("setFilter", filter);
    },
    updateOrdersListTab({commit}, selectedOrdersListTab) {
      commit("setOrdersListTab", selectedOrdersListTab);
    },
    updateFindingsListTab({commit}, selectedFindingsListTab) {
      commit("setFindingsListTab", selectedFindingsListTab);
    },
    updateAuditYear({commit}, selectedAuditYear) {
      commit("setAuditYear", selectedAuditYear);
    },
  },
  mutations: {
    updateField,
    setFilter(state, filterValue) {
      state.filter = filterValue;
    },
    setAuditYear(state, selectedAuditYear) {
      state.selectedAuditYear = selectedAuditYear;
    },
    setOrdersListTab(state, selectedOrdersListTab) {
      state.selectedOrdersListTab = selectedOrdersListTab;
    },
    setFindingsListTab(state, selectedFindingsListTab) {
      state.selectedFindingsListTab = selectedFindingsListTab;
    },
  },
};
