var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"review-details"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h2',{staticStyle:{"margin-bottom":"30px"}},[_c('i',{staticClass:"dx-icon dx-icon-exportselected",staticStyle:{"margin-right":"5px"}}),_vm._v(" "+_vm._s(_vm.$t("orders.reviewDetails.title"))+" ")])]),(_vm.orderStatus)?_c('div',{staticClass:"col-md-auto col-12 order-md-last"},[_c('label',{staticClass:"status-label",class:_vm.setStatusClass(_vm.orderStatus)},[_vm._v(" "+_vm._s(_vm.$t("orders.reviewDetails.stage"))+" "+_vm._s((": " + (_vm.formatStatus(_vm.orderStatus))))+" ")])]):_vm._e()]),_c('div',{staticClass:"container-breaker"},[_c('div',{staticClass:"content-block"},[(_vm.isInReviewerCapacityVisible)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.reviewDetails.isThereReviewerCapacity"))+" ")])]),_c('dx-select-box',{attrs:{"value":_vm.selectedIsThereReviewerCapacity,"data-source":_vm.reviewerCapacityOptions,"stylingMode":"outlined","display-expr":"name","value-expr":"val","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.reviewDetails.isThereReviewerCapacity'),
              }),"disabled":!_vm.$can(
                'update',
                _vm.$subjects.schedule.subject,
                'isThereReviewerCapacity'
              )},on:{"update:value":function($event){_vm.selectedIsThereReviewerCapacity=$event}}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t(
                      'orders.reviewDetails.isThereReviewerCapacity'
                    ),
                  })}})],1)],1)],1),_c('div',{staticClass:"col-lg-4"},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.reviewDetails.leadReviewer"))+" ")]),_c('dx-select-box',{ref:_vm.leadReviewerDropdownRef,attrs:{"value":_vm.selectedLeadReviewer,"data-source":_vm.leadReviewers,"stylingMode":"outlined","display-expr":"fullname","value-expr":"id","show-clear-button":_vm.showClearButton,"placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.reviewDetails.leadReviewer'),
              }),"disabled":!_vm.$can('update', _vm.$subjects.schedule.subject, 'leadReviewer') ||
              _vm.isNoReviewerCapacity},on:{"update:value":function($event){_vm.selectedLeadReviewer=$event},"value-changed":function($event){return _vm.onLeadReviewerChanged($event)}}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.reviewDetails.leadReviewer'),
                  })}})],1)],1)],1),_c('div',{ref:_vm.componentContainerRef,staticClass:"col-lg"},[_c('label',{staticClass:"bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.reviewDetails.supportReviewers"))+" ")]),_c('dx-tag-box',{attrs:{"defer-rendering":false,"data-source":_vm.supportReviewersDataSource,"display-expr":"fullname","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.reviewDetails.supportReviewers'),
              }),"show-clear-button":true,"show-drop-down-button":true,"show-selection-controls":true,"styling-mode":"outlined","value":_vm.selectedMultipleSupportReviewers,"value-expr":"id","wrapItemText":true,"max-displayed-tags":1,"apply-value-mode":"instantly","disabled":!_vm.$can(
                'update',
                _vm.$subjects.schedule.subject,
                'supportReviewers'
              ) || _vm.isNoReviewerCapacity},on:{"update:value":function($event){_vm.selectedMultipleSupportReviewers=$event}}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 start-date"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.reviewDetails.startDate"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.startDateToolTip,"close-on-outside-click":false,"target":"#divStartDate","position":"top"},on:{"update:visible":function($event){_vm.startDateToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.schedule.reviewStartDate"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divStartDate"},on:{"mouseenter":function($event){_vm.startDateToolTip = !_vm.startDateToolTip},"mouseleave":function($event){_vm.startDateToolTip = !_vm.startDateToolTip}}})]),_c('dx-date-box',{attrs:{"name":"startDate","stylingMode":"outlined","acceptCustomValue":false,"openOnFieldClick":true,"type":"date","validationMessageMode":"auto","pickerType":"calendar","use-mask-behavior":true,"display-format":"dd/MMM/yyyy","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.reviewDetails.startDate'),
              }),"disabled":!_vm.$can('update', _vm.$subjects.schedule.subject, 'startDate') ||
              _vm.orderStatus === 'Submitted' ||
              _vm.orderStatus === 'Completed' ||
              !_vm.canReschedule ||
              _vm.isNoReviewerCapacity},model:{value:(_vm.reviewStartDate),callback:function ($$v) {_vm.reviewStartDate=$$v},expression:"reviewStartDate"}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.reviewDetails.startDate'),
                  })}})],1)],1)],1),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.reviewDetails.endDate"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.reviewEndDateToolTip,"close-on-outside-click":false,"target":"#divReviewEndDate","position":"top"},on:{"update:visible":function($event){_vm.reviewEndDateToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.schedule.reviewEndDate"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divReviewEndDate"},on:{"mouseenter":function($event){_vm.reviewEndDateToolTip = !_vm.reviewEndDateToolTip},"mouseleave":function($event){_vm.reviewEndDateToolTip = !_vm.reviewEndDateToolTip}}})]),_c('dx-date-box',{attrs:{"name":"endDate","stylingMode":"outlined","acceptCustomValue":false,"openOnFieldClick":true,"type":"date","validationMessageMode":"auto","pickerType":"calendar","min":_vm.reviewStartDate,"use-mask-behavior":true,"display-format":"dd/MMM/yyyy","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.reviewDetails.endDate'),
              }),"disabled":!_vm.$can('update', _vm.$subjects.schedule.subject, 'endDate') ||
              _vm.orderStatus === 'Submitted' ||
              _vm.orderStatus === 'Completed' ||
              !_vm.canReschedule ||
              _vm.isNoReviewerCapacity},on:{"update:min":function($event){_vm.reviewStartDate=$event}},model:{value:(_vm.reviewEndDate),callback:function ($$v) {_vm.reviewEndDate=$$v},expression:"reviewEndDate"}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t('orders.reviewDetails.endDate'),
                  })}})],1)],1)],1)])])]),_c('div',{staticClass:"container-breaker"},[_c('div',{staticClass:"content-block"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h2',{staticStyle:{"margin-bottom":"30px"}},[_c('i',{staticClass:"far fa-calendar",staticStyle:{"margin-right":"5px"}}),_vm._v(" "+_vm._s(_vm.$t("orders.estimatedReviewTimeline.title"))+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.estimatedReviewTimeline.estReportDueDate"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.estReportDueDateToolTip,"close-on-outside-click":false,"target":"#divEstReportDueDate","position":"top"},on:{"update:visible":function($event){_vm.estReportDueDateToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.schedule.estimatedReportDueDate"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divEstReportDueDate"},on:{"mouseenter":function($event){_vm.estReportDueDateToolTip = !_vm.estReportDueDateToolTip},"mouseleave":function($event){_vm.estReportDueDateToolTip = !_vm.estReportDueDateToolTip}}})]),_c('dx-date-box',{attrs:{"name":"estReportDueDate","stylingMode":"outlined","acceptCustomValue":false,"openOnFieldClick":true,"type":"date","validationMessageMode":"auto","pickerType":"calendar","min":_vm.reviewStartDate,"use-mask-behavior":true,"display-format":"dd/MMM/yyyy","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t(
                  'orders.estimatedReviewTimeline.estReportDueDate'
                ),
              }),"disabled":!_vm.$can(
                'update',
                _vm.$subjects.schedule.subject,
                'estReportDueDate'
              ) ||
              _vm.orderStatus === 'Submitted' ||
              _vm.orderStatus === 'Completed' ||
              _vm.isNoReviewerCapacity},on:{"update:min":function($event){_vm.reviewStartDate=$event}},model:{value:(_vm.estReportDueDate),callback:function ($$v) {_vm.estReportDueDate=$$v},expression:"estReportDueDate"}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t(
                      'orders.estimatedReviewTimeline.estReportDueDate'
                    ),
                  })}})],1)],1)],1),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.estimatedReviewTimeline.estCAPDate"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.estCAPDateToolTip,"close-on-outside-click":false,"target":"#divEstCapDate","position":"top"},on:{"update:visible":function($event){_vm.estCAPDateToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.schedule.estimatedCapDate"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divEstCapDate"},on:{"mouseenter":function($event){_vm.estCAPDateToolTip = !_vm.estCAPDateToolTip},"mouseleave":function($event){_vm.estCAPDateToolTip = !_vm.estCAPDateToolTip}}})]),_c('dx-date-box',{attrs:{"name":"estCAPDate","stylingMode":"outlined","acceptCustomValue":false,"openOnFieldClick":true,"type":"date","validationMessageMode":"auto","pickerType":"calendar","min":_vm.reviewStartDate,"use-mask-behavior":true,"display-format":"dd/MMM/yyyy","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t('orders.estimatedReviewTimeline.estCAPDate'),
              }),"disabled":!_vm.$can('update', _vm.$subjects.schedule.subject, 'estCAPDate') ||
              _vm.orderStatus === 'Submitted' ||
              _vm.orderStatus === 'Completed' ||
              _vm.isNoReviewerCapacity},on:{"update:min":function($event){_vm.reviewStartDate=$event}},model:{value:(_vm.estCAPDate),callback:function ($$v) {_vm.estCAPDate=$$v},expression:"estCAPDate"}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t(
                      'orders.estimatedReviewTimeline.estCAPDate'
                    ),
                  })}})],1)],1)],1),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"required bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.estimatedReviewTimeline.estDeadlineApprovalDate"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.estDeadlineApprovalDateToolTip,"close-on-outside-click":false,"target":"#divEstDeadlineApprovalDate","position":"top"},on:{"update:visible":function($event){_vm.estDeadlineApprovalDateToolTip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.schedule.estimatedDeadlineForApproval"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"divEstDeadlineApprovalDate"},on:{"mouseenter":function($event){_vm.estDeadlineApprovalDateToolTip =
                  !_vm.estDeadlineApprovalDateToolTip},"mouseleave":function($event){_vm.estDeadlineApprovalDateToolTip =
                  !_vm.estDeadlineApprovalDateToolTip}}})]),_c('dx-date-box',{attrs:{"name":"estDeadlineApprovalDate","stylingMode":"outlined","acceptCustomValue":false,"openOnFieldClick":true,"type":"date","validationMessageMode":"auto","pickerType":"calendar","min":_vm.reviewStartDate,"use-mask-behavior":true,"display-format":"dd/MMM/yyyy","placeholder":_vm.$t('shared.select', {
                dataField: _vm.$t(
                  'orders.estimatedReviewTimeline.estDeadlineApprovalDate'
                ),
              }),"disabled":!_vm.$can(
                'update',
                _vm.$subjects.schedule.subject,
                'estDeadlineApprovalDate'
              ) ||
              _vm.orderStatus === 'Submitted' ||
              _vm.orderStatus === 'Completed' ||
              _vm.isNoReviewerCapacity},on:{"update:min":function($event){_vm.reviewStartDate=$event}},model:{value:(_vm.estDeadlineApprovalDate),callback:function ($$v) {_vm.estDeadlineApprovalDate=$$v},expression:"estDeadlineApprovalDate"}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":_vm.$t('shared.isRequired', {
                    dataField: _vm.$t(
                      'orders.estimatedReviewTimeline.estDeadlineApprovalDate'
                    ),
                  })}})],1)],1)],1)])])]),_c('div',{staticClass:"content-block"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.reviewDetails.capStatus"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.capStatusTooltip,"close-on-outside-click":false,"target":"#capStatusStatus","position":"top"},on:{"update:visible":function($event){_vm.capStatusTooltip=$event}}},[_c('div',{staticStyle:{"text-align":"left","white-space":"pre-line"}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.schedule.capStatus"))+" ")])])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"capStatusStatus"},on:{"mouseenter":function($event){_vm.capStatusTooltip = !_vm.capStatusTooltip},"mouseleave":function($event){_vm.capStatusTooltip = !_vm.capStatusTooltip}}})]),_c('dx-select-box',{attrs:{"value":_vm.selectedCAPStatus,"data-source":_vm.capStatusList,"wrapItemText":true,"stylingMode":"outlined","display-expr":"name","value-expr":"id","placeholder":_vm.$t('shared.select', {
              dataField: _vm.$t('orders.reviewDetails.capStatus'),
            }),"disabled":!_vm.$can('update', _vm.$subjects.schedule.subject, 'capStatus') ||
            (_vm.orderStatus !== 'Submitted' && _vm.orderStatus !== 'Completed') ||
            _vm.isNoReviewerCapacity},on:{"update:value":function($event){_vm.selectedCAPStatus=$event}}})],1),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"bold-label"},[_vm._v(" "+_vm._s(_vm.$t("orders.reviewDetails.contractFollowupStatus"))+" "),_c('DxTooltip',{attrs:{"visible":_vm.contractFollowupStatusTooltip,"close-on-outside-click":false,"target":"#contractFollowupStatus","position":"top"},on:{"update:visible":function($event){_vm.contractFollowupStatusTooltip=$event}}},[_vm._v(" "+_vm._s(_vm.$t("tooltips.schedule.contractFollowUpStatus"))+" ")])],1),_c('div',{staticClass:"tooltip",attrs:{"id":"contractFollowupStatus"},on:{"mouseenter":function($event){_vm.contractFollowupStatusTooltip = !_vm.contractFollowupStatusTooltip},"mouseleave":function($event){_vm.contractFollowupStatusTooltip = !_vm.contractFollowupStatusTooltip}}})]),_c('dx-select-box',{attrs:{"value":_vm.selectedFollowupStatus,"data-source":_vm.followupStatusList,"stylingMode":"outlined","display-expr":"name","value-expr":"id","placeholder":_vm.$t('shared.select', {
              dataField: _vm.$t('orders.reviewDetails.contractFollowupStatus'),
            }),"disabled":!_vm.$can(
              'update',
              _vm.$subjects.schedule.subject,
              'contractFollowupStatus'
            ) ||
            ![
              _vm.orderStatuses.submitted,
              _vm.orderStatuses.completed,
              _vm.orderStatuses.cancelled ].includes(_vm.orderStatus) ||
            _vm.isNoReviewerCapacity},on:{"update:value":function($event){_vm.selectedFollowupStatus=$event}}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }