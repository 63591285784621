<template>
  <div
    class="landing-card-container"
    :class="{'section-loader-on': loaderVisible}"
  >
    <div v-if="!upcomingAssessment" ref="no-upcoming-assessment">
      <h2>
        {{ $t("landingPage.upcomingAssessment.noUpcomingSelfAssessment") }}
        <!-- <i class="fas fa-check success"></i> -->
      </h2>
      <p style="margin-bottom: 0">
        {{
          $t(
            "landingPage.upcomingAssessment.noUpcomingSelfAssessmentParagraph",
          )
        }}.
      </p>
    </div>
    <div v-else>
      <div class="sectioned-part">
        <h2>
          {{ $t("landingPage.upcomingAssessment.nextSelfAssessmentIn") }}
          <span ref="days-to-assessment">{{
            upcomingAssessment.daysToAssessment
          }}</span>
          {{ $t("landingPage.upcomingAssessment.days") }}
        </h2>

        <div class="row mb-4">
          <div class="col-sm-6">
            <label>{{ $t("landingPage.upcomingAssessment.startDate") }}</label>
            <div class="assessment-start-date" ref="assessment-start-date">
              <i class="far fa-calendar-alt"></i>
              {{ formatDate(upcomingAssessment.startDate) }}
            </div>
          </div>
          <div class="col-sm-6">
            <label>{{ $t("landingPage.upcomingAssessment.endDate") }}</label>
            <div class="assessment-start-date" ref="assessment-end-date">
              <i class="far fa-calendar-alt"></i>
              {{ formatDate(upcomingAssessment.endDate) }}
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-6">
            <label>{{ $t("orders.orderDetails.reviewType") }}</label>
            <div class="assessment-start-date">
              <i class="far fa-calendar-alt"></i>
              {{ upcomingAssessment.reviewType }}
            </div>
          </div>
          <div class="col-6">
            <label>{{ $t("orders.orderDetails.siteName") }}</label>
            <div class="assessment-start-date">
              <i class="far fa-calendar-alt"></i>
              {{ upcomingAssessment.siteName }}
            </div>
          </div>
        </div>
      </div>

      <DxButton
        :text="$t('landingPage.upcomingAssessment.viewAssessment')"
        type="normal"
        class="btn btn-large"
        icon="chevronright"
        @click="
          $router.push({
            name: 'assessment',
            params: {id: upcomingAssessment.id},
          })
        "
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import DxButton from "devextreme-vue/button";
import {formatDate} from "../../../../common/helperFunctions";
import localeDatetimeFormat from "../../../../mixins/locale-datetime-format";

export default {
  components: {
    DxButton,
  },
  created() {
    this.getUpcomingSelfAssessment();
  },
  mixins: [localeDatetimeFormat],
  computed: {
    ...mapState("landingPage/upcomingAssessment", {
      upcomingAssessment: "upcomingSelfAssessment",
    }),
  },
  methods: {
    ...mapActions("landingPage/upcomingAssessment", [
      "getUpcomingSelfAssessment",
    ]),
    formatDate:formatDate
  },
  updated() {
    this.loaderVisible = false;
  },
  mounted() {
    if (this.upcomingAssessment != null) {
      this.loaderVisible = false;
    }
  },
  data() {
    return {
      loaderVisible: true,
    };
  },
};
</script>

<style></style>
