<template>
  <div class="card-container">
    <h2>
      <i class="fas fa-user"></i>
      {{ $t("userSettings.profileInformation.title") }}
    </h2>
    <div v-if="isNotEmpty(profileInfo)">
      <!-- Picture -->
      <div class="row p-2 align-items-center">
        <div class="col">
          <label class="">{{
            $t("userSettings.profileInformation.photoLabel")
          }}</label>
        </div>
        <div
          class="image-container col ml-auto p-2"
          @click="uploadPhotoPopupVisible = !uploadPhotoPopupVisible"
        >
          <div class="avatar">
            <div
              v-if="isNotEmpty(avatarImage)"
              class="user-image"
              :style="{
                backgroundImage: 'url(' + avatarImage + ')',
              }"
            />
            <div v-else class="user-image">
              {{
                `${getCapitalizedInitial(
                  profileInfo.firstName,
                )} ${getCapitalizedInitial(profileInfo.lastName)}`
              }}
            </div>
            <div class="avatar-cta">
              <div class="cta-plus">
                <div class="fas fa-camera"></div>
              </div>
            </div>
          </div>
        </div>
        <dx-popup
          :visible="uploadPhotoPopupVisible"
          :drag-enabled="false"
          :close-on-outside-click="true"
          :show-title="true"
          :max-width="'60%'"
          :title="$t('userSettings.profileInformation.uploadPhotoPopupLabel')"
          height="60%"
          width="60%"
          min-width="300px"
          @hiding="onUploadPhotoPopupClose"
        >
          <dx-toolbar-item
            widget="dxButton"
            toolbar="bottom"
            location="after"
            :options="uploadButtonOptions"
          />
          <dx-toolbar-item
            widget="dxButton"
            toolbar="bottom"
            location="after"
            :options="cancelButtonOptions"
          />

          <div class="row dropzone h-100" v-show="isEmpty(image.src)">
            <div class="col-md-12 h-100">
              <div class="content-block mt-0 h-100">
                <div
                  id="dropzone-external"
                  class="dropzone-container h-100"
                  :class="[
                    isDropZoneActive
                      ? 'dx-theme-accent-as-border-color dropzone-active'
                      : 'dx-theme-border-color',
                  ]"
                >
                  <div class="dropzone-items">
                    <i class="fas fa-images"></i>

                    <div class="dropzone-text">
                      <span>Drag & Drop the desired file</span>
                      <span>…or click to browse for a file instead.</span>
                    </div>
                  </div>
                </div>
                <dx-file-uploader
                  :multiple="false"
                  name="file"
                  drop-zone="#dropzone-external"
                  dialog-trigger="#dropzone-external"
                  ref="fileUploader"
                  upload-mode="useForm"
                  :visible="false"
                  @value-changed="imageValueChanged($event)"
                  @drop-zone-enter="onDropZoneEnter"
                  @drop-zone-leave="onDropZoneLeave"
                  :allowed-file-extensions="fileExtensionWhitelist"
                  :max-file-size="maxFileUploadSize"
                  :invalid-file-extension-message="
                    $t('attachments.errors.invalidFileExtensionMessage')
                  "
                  :invalid-max-file-size-message="
                    $t('attachments.errors.invalidMaxFileSizeMessage')
                  "
                />
              </div>
            </div>
          </div>
          <div class="row" v-show="isNotEmpty(image.src)">
            <div class="col-sm-12">
              <div class="cropper-container">
                <cropper
                  ref="cropper"
                  :key="cropperKey"
                  :style="{height: getCropperHeight()}"
                  :src="image.src"
                  :auto-zoom="true"
                  stencil-component="circle-stencil"
                  priority="visibleArea"
                />
              </div>
            </div>
          </div>
        </dx-popup>
      </div>
      <!-- Name -->
      <div class="row p-2">
        <label class="col">{{
          $t("userSettings.profileInformation.nameLabel")
        }}</label>
        <strong class="col">
          {{ `${profileInfo.firstName} ${profileInfo.lastName}` }}
        </strong>
      </div>
      <!-- Role -->
      <div class="row p-2">
        <label class="col">{{
          $t("userSettings.profileInformation.roleLabel")
        }}</label>
        <strong class="col">
          {{ getRoleName(profileInfo.role) }}
        </strong>
      </div>
      <!-- Third party -->
      <div class="row p-2" v-if="profileInfo.thirdParties.length > 0">
        <label class="col">{{
          $t("userSettings.profileInformation.thirdPartyLabel")
        }}</label>
        <strong class="col">
          {{ `${profileInfo.thirdParties.map((x) => x.name).join(", ")}` }}
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {
  isNotEmpty,
  isEmpty,
  getSecureImage,
} from "../../common/helperFunctions";
import {DxFileUploader} from "devextreme-vue";
import {DxPopup, DxToolbarItem} from "devextreme-vue/popup";

import roleTypes from "../../config/roles";
import {Cropper} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import {toCamelCase} from "../../common/helperFunctions";

const {VUE_APP_API_HOSTNAME} = process.env;
export default {
  data() {
    return {
      cropperKey: 1,
      isDropZoneActive: false,
      profileInfo: null,
      roleTypes: roleTypes,
      uploadPhotoPopupVisible: false,
      fileExtensionWhitelist: [".jpg", ".jpeg", ".png"],
      maxFileUploadSize: 10485760, //10mb
      image: {
        src: null,
        type: null,
      },
      avatarImage: null,
      cancelButtonOptions: {
        text: this.$t("userSettings.profileInformation.cancelButtonLabel"),
        onClick: () => {
          this.onUploadPhotoPopupClose();
        },
      },
    };
  },
  mounted() {
    this.load();
  },
  components: {
    DxPopup,
    Cropper,
    DxFileUploader,
    DxToolbarItem,
  },
  computed: {
    ...mapState("auth", ["user"]),
    fileUploader() {
      return this.$refs.fileUploader.instance;
    },

    uploadButtonOptions() {
      return {
        text: this.$t("userSettings.profileInformation.uploadPhotoButtonLabel"),
        onClick: () => {
          this.uploadCroppedPhoto();
        },
        type: "default",
        class: "btn btn-medium",
        visible: isNotEmpty(this.image.src),
      };
    },
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    ...mapActions("auth", ["setUser"]),
    isNotEmpty: isNotEmpty,
    isEmpty: isEmpty,
    onDropZoneEnter(e) {
      if (e.dropZoneElement.id === "dropzone-external") {
        this.isDropZoneActive = true;
      }
    },
    getCropperHeight() {
      let element = document.getElementsByClassName("dx-popup-content");
      if (element && element.length > 0) {
        return `${element[0].style.height}`;
      }
      return "300px";
    },
    resizeHandler() {
      if (isNotEmpty(this.image.src)) {
        this.cropperKey += 1;
      }
    },
    onDropZoneLeave(e) {
      if (e.dropZoneElement.id === "dropzone-external") {
        this.isDropZoneActive = false;
      }
    },
    async getAvatarImage() {
      if (this.profileInfo.pictureUrl) {
        this.avatarImage = await getSecureImage(
          `${VUE_APP_API_HOSTNAME}${this.profileInfo.pictureUrl}`,
        );
      }
    },
    getCapitalizedInitial(text) {
      if (text && text.length > 0) {
        return text[0].toUpperCase();
      }

      return null;
    },
    resetPhotoUploader() {
      this.image = {
        src: null,
        type: null,
      };
      this.fileUploader.reset();
    },
    imageValueChanged(e) {
      if (e.value && e.value[0] && e.component._files[0].isValid()) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(e.value[0]);

        this.image = {
          src: blob,
          type: e.value[0].type,
        };
      }
    },
    destroyed() {
      // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src);
      }
    },
    onUploadPhotoPopupClose() {
      this.uploadPhotoPopupVisible = false;
      this.resetPhotoUploader();
    },
    uploadCroppedPhoto() {
      const {canvas} = this.$refs.cropper.getResult();
      let userName = this.user.username;
      canvas.toBlob((blob) => {
        let formData = new FormData();
        formData.append(
          "File",
          blob,
          `${userName}.${blob.type.split("/").slice(-1)[0]}`,
        );
        this.$http
          .post("users/profile/avatar", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.setUser();
            this.load();
          });
      }, this.image.type);
      this.uploadPhotoPopupVisible = false;
      this.resetPhotoUploader();
    },
    async load() {
      let response = await this.$http.get("/users/current");
      this.profileInfo = response.data;
      if (isNotEmpty(this.profileInfo && this.profileInfo.pictureUrl)) {
        this.getAvatarImage();
      }
    },
    getRoleName(role) {
      return roleTypes[toCamelCase(role)].display;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/generated/variables.base.scss";
.avatar {
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  height: 60px;
  width: 60px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  vertical-align: middle;
  opacity: 1;

  .user-image {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    vertical-align: middle;
    color: #1e1e1e;
    width: 100%;
    height: 100%;
    line-height: 60px;
    background-color: #cccccc;
    background-size: cover;
  }
}
.avatar-cta {
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  align-content: center;
  opacity: 0;
  position: relative;
  bottom: 100%;
  width: 100%;
  height: 100%;

  .cta-plus {
    position: relative;
    top: 14px;
    font-size: 24px;
  }
}
.dropzone {
  align-self: baseline;
}
.image-container {
  :hover .user-image {
    opacity: 0.3;
    transition: 0.3s ease;
  }
  :hover .avatar-cta {
    opacity: 1;
  }
}

.upload-btn-row {
  height: 69px;
  position: absolute;
  bottom: 0;
  right: 25px;
}
.cropper {
  height: 300px;
}
.dx-fileuploader-input-wrapper {
  height: 170px;
}
.dx-button-text {
  line-height: 40px !important;
}

.cropper-container {
  padding: 0px 3px 35px 3px;
  margin-bottom: 30px;
}

.dropzone-container {
  width: 100%;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .dropzone-items {
    align-items: center;
    justify-content: center;
  }

  .fa-images {
    width: 100%;
    text-align: center;
    font-size: 34px;
    color: #5b778f;
  }
  .dropzone-text {
    width: 100%;
    text-align: center;
  }
}
#dropzone-external > * {
  pointer-events: none;
}
#dropzone-external.dropzone-active {
  border-style: solid;
}
.widget-container > span {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}
#dropzone-image {
  max-width: 100%;
  max-height: 100%;
}
#dropzone-text > span {
  font-weight: 100;
  opacity: 0.5;
}
</style>
