<template>
  <div>
    <page-heading :pageTitle="pageTitle" :breadCrumbs="breadCrumbs" />

    <div class="card-container" style="margin-bottom: 90px">
      <div class="row">
        <div class="col-xl-12">
          <h2>
            <i class="far fa-file-alt"></i>
            {{ pageTitle }}
          </h2>
          <p>Last updated: 17/11/2021</p>

          <p>
            You have been given access by a PwC firm (also referred to as “us”,
            or “we”) to this technology tool. These terms form an agreement
            between you and that PwC firm; please read them carefully.
          </p>
          <p>
            By using this technology tool, you agree to these terms. If you
            cannot comply with these terms, then you must cease using this
            technology tool.
          </p>
          <p>
            We may amend these terms from time to time. The amended terms will
            either be notified to you, or be available for you to view on (or
            when accessing) the technology tool.
          </p>

          <ol type="1">
            <li>
              <h3 class="mt-5">Use</h3>
              <ol type="a">
                <li>
                  <p>
                    Permitted use. You may only use this technology tool, the
                    content on it, and the outputs from it, for their intended
                    purpose and subject to these terms save as otherwise agreed
                    in writing.
                  </p>
                </li>
                <li>
                  Restrictions on use. You may not:
                  <ol type="i">
                    <li>
                      act or omit to act in any way that puts you or any entity
                      you work for in breach of any agreement with any PwC firm,
                      or any applicable law or regulation;
                    </li>
                    <li>
                      make this technology tool, the contents or the outputs
                      available to any person, other than as permitted by us;
                    </li>
                    <li>
                      copy, modify, disassemble, work around, by-pass, reverse
                      engineer or otherwise change this technology tool or its
                      underlying data, systems or software, or attempt to do so;
                    </li>
                    <li>
                      copy or use the contents or outputs, other than as
                      expressly permitted by us;
                    </li>
                    <li>
                      engage in or encourage unlawful, invasive, infringing,
                      defamatory or fraudulent activity (including to violate,
                      or encourage the violation of, the legal rights of
                      others); impersonate another person or otherwise
                      misrepresent your identity or other information about you;
                    </li>
                    <li>
                      remove or alter any identification, proprietary, copyright
                      or other notices on the technology tool or the contents or
                      outputs;
                    </li>
                    <li>
                      access or use the technology tool on any publicly
                      available equipment; or
                    </li>
                    <li>
                      bypass or attempt to bypass any of the access or security
                      controls or otherwise weaken or threaten the security of
                      the technology tool.
                    </li>
                  </ol>
                </li>
                <li>
                  Access. You agree to:
                  <ol type="i">
                    <li>
                      maintain your access credentials in strict confidence and
                      not share them with anyone else;
                    </li>
                    <li>
                      immediately notify us by email if anyone else obtains your
                      access credentials so that we can disable them and provide
                      you with new credentials; and
                    </li>
                    <li>
                      tell your PwC contact immediately if you lose your access
                      credentials or know or suspect someone else may know or
                      have access to them.
                    </li>
                  </ol>
                </li>
                <li>
                  Incorrect access. You agree to notify us immediately, and then
                  exit the technology tool, if you access content or outputs on
                  it that are not intended for you.
                </li>
              </ol>
            </li>
            <li>
              <h3 class="mt-5">No liability to you</h3>
              <ol type="a">
                <li>
                  Use is at your own risk. As between you and any PwC firm:
                  <ol type="i">
                    <li>
                      this technology tool, the content and outputs are provided
                      on an ‘as-is’ basis;
                    </li>
                    <li>
                      to the extent permitted by law, no warranties or other
                      provisions are implied into these terms. We do not warrant
                      that the operation of the technology tool will be
                      uninterrupted or error-free, or that the technology tool,
                      the content and outputs will be fit for any particular
                      purpose or be free of errors or defects;
                    </li>
                    <li>
                      to the extent permitted by law, no PwC firm accepts any
                      liability, responsibility or duty of care to you for the
                      technology tool, the content or outputs, or for any
                      consequences of you or anyone acting or refraining to act
                      in reliance on them, or for any decision based on them;
                      and
                    </li>
                    <li>
                      nothing in these terms excludes our liability for fraud,
                      or for anything else that cannot be law be limited.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h3 class="mt-5">Suspension or termination</h3>
              <ol type="a">
                <li>
                  Ending your access. We may immediately suspend or terminate
                  access to the technology tool if you use it other than as
                  permitted by us, or if we consider that your use of the
                  product could cause a detriment to us or others, or for any
                  other reason we require (e.g. maintenance, or to make any
                  changes or improvements).
                </li>
              </ol>
            </li>
            <li>
              <h3 class="mt-5">Your inputs</h3>
              <ol type="a">
                <li>
                  Your inputs. To the extent that you make or upload inputs of
                  any sort to the technology tool (which may include comments,
                  information, materials, data, or anything else), you grant: i)
                  us and our suppliers the right to host your inputs on the
                  technology tool or any other system we deem appropriate, and
                  use it to produce the outputs or for any other lawful business
                  purpose (which may include technical support and maintenance);
                  and ii) us and anyone we lawfully permit to access the
                  technology tool the ability to view, access and download those
                  inputs. You agree that you have all necessary rights in your
                  inputs to grant the rights set out in this section. No
                  payment, royalty or other consideration whatsoever will be
                  payable by us in connection with our use of such inputs. You
                  agree to reimburse us in full to the extent we incur any
                  liability as a result of your non-compliance with this
                  section.
                </li>
                <li>
                  Moderation - We may, in our discretion, moderate your inputs
                  and other content (including making content unavailable), for
                  example where the technology tool contains a discussion board
                  or other open forum. However, we are under no obligation to
                  oversee, monitor or moderate any content. The views expressed
                  by users of the technology tool do not necessarily represent
                  our views or values, nor are they supported or endorsed by us
                  or any PwC firm.
                </li>
              </ol>
            </li>
            <li>
              <h3 class="mt-5">Ownership</h3>
              <ol type="a">
                <li>
                  Intellectual property rights. We and our licensors own this
                  technology tool. You have a limited, non-transferable,
                  non-sublicensable licence to use them in accordance with these
                  terms only. (Our licensors may include an entity you work
                  for).
                </li>
              </ol>
            </li>
            <li>
              <h3 class="mt-5">Open Source</h3>
              <ol type="a">
                <li>
                  Open source software. Portions of the technology tool may use
                  open source components, a list of which may be available on
                  the technology tool or is otherwise available from us on
                  request. The source code and open source licensing terms
                  applicable to each open source component can be obtained
                  either by clicking on the related link, or from us on request.
                </li>
                <li>
                  Open source licence terms prevail. In the event of a conflict
                  between the terms of any open source license and these terms,
                  the open source license terms shall prevail.
                </li>
              </ol>
            </li>
            <li>
              <h3 class="mt-5">General</h3>
              <ol type="a">
                <li>
                  Disputes
                  <ol type="i">
                    <li>
                      If a dispute arises, you and we will attempt to resolve it
                      by discussion, negotiation and mediation before commencing
                      legal proceedings.
                    </li>
                    <li>
                      These terms and any dispute arising from them, whether
                      contractual or non-contractual, will be governed by the
                      law of the territory in which we operate, and be subject
                      to the exclusive jurisdiction of the courts of that
                      territory.
                    </li>
                    <li>
                      Any claims must be brought no later than 2 years after the
                      date the claimant should have been aware of the potential
                      claim and, in any event, no later than 4 years after any
                      alleged breach.
                    </li>
                  </ol>
                </li>
                <li>
                  Matters beyond reasonable control. We will not be liable to
                  you if we fail to meet our obligations due to matters beyond
                  our reasonable control.
                </li>
                <li>
                  Rights of third parties. A person who is not a party to these
                  terms has no rights to enforce them.
                </li>
              </ol>
            </li>
            <li>
              <h3 class="mt-5">Other</h3>
              <ol type="a">
                <li>
                  Your personal data. All information, rights and obligations
                  concerning personal data in relation to the technology tool is
                  contained in its privacy statement.
                </li>
              </ol>
            </li>
            <li>
              <h3 class="mt-5">Definitions</h3>
              <ol type="a">
                <li>
                  The following words and expressions in these terms have the
                  following meanings:
                  <ol type="i">
                    <li>
                      <strong>access credentials</strong> - the username and
                      password or other access details that you may use to
                      access the technology tool
                    </li>
                    <li>
                      <strong>content</strong> - the information, data, material
                      and any other content on the technology tool, other than
                      the outputs
                    </li>
                    <li>
                      <strong>outputs</strong> - any visualisation, graphic,
                      calculation, result or other output produced by the
                      functionality of the technology tool
                    </li>
                    <li>
                      <strong>PwC firm</strong> - a member firm in the PwC
                      network, each of which is a separate legal entity. For
                      further details, please see www.pwc.com/structure.
                    </li>
                    <li>
                      <strong>these terms</strong> - means the agreement formed
                      by this “terms of use” document
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>

          <template v-if="user && !user.hasAgreedTerms">
            <hr />
            <dx-button
              text="Accept"
              name="Accept"
              type="default"
              class="btn btn-large mr-4"
              icon="check"
              style="min-width: 150px"
              @click="agreeTerms"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageHeading from "../../components/page-heading";
import {DxButton} from "devextreme-vue";

export default {
  components: {
    pageHeading,
    DxButton,
  },
  methods: {
    agreeTerms() {
      this.$store.dispatch("auth/setAgreedTerms").then(() => {
        localStorage.setItem("agreedTerms", "true");
        this.$router.push({
          name: "home",
        });
      });
    },
  },
  created() {
    this.$store.dispatch("auth/setUser").then(() => {
      if (this.user.hasAgreedTerms && !this.$route.query.review) {
        this.$router.push({
          name: "home",
        });
      }
    });
  },
  computed: {
    pageTitle() {
      return this.$t("legalNotices.termsOfUse");
    },
    breadCrumbs() {
      return [this.$t("legalNotices.title"), this.pageTitle];
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style lang="scss" scoped></style>
