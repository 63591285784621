<template>
  <div class="row">
    <div class="col-lg-3">
      <div class="card-container">
        <div class="row">
          <i class="fas fa-chart-bar col-lg-3"></i>
          <div class="col-lg-9">
            <div class="key-stat-value">
              {{ toNumber(keyStats.assessmentCount) }}
            </div>

            <p class="key-stat-description">
              {{ $t("reporting.retailerDashboard.currentYearAssessments") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="card-container">
        <div class="row">
          <i class="fas fa-chart-bar col-lg-3"></i>
          <div class="col-lg-9">
            <div class="key-stat-value">
              {{ toNumber(keyStats.nonComplianceCount) }}
            </div>

            <p class="key-stat-description">
              {{ $t("reporting.retailerDashboard.currentYearNonCompliances") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="card-container">
        <div class="row">
          <i class="fas fa-chart-bar col-lg-3"></i>
          <div class="col-lg-9">
            <div class="key-stat-value">
              {{ toPercentage(keyStats.complianceScore) }}%
            </div>

            <p class="key-stat-description">
              {{ $t("reporting.retailerDashboard.currentYearComplianceScore") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="card-container">
        <div class="row">
          <i class="fas fa-chart-bar col-lg-3"></i>
          <div class="col-lg-9">
            <div class="key-stat-value">
              {{ toPercentage(keyStats.priorComplianceScore) }}%
            </div>

            <p class="key-stat-description">
              {{
                $t("reporting.retailerDashboard.previousYearComplianceScore")
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$http
      .get("reporting/getRetailerKeyStats")
      .then((x) => (this.keyStats = x.data))
      .catch((err) => console.error("Error during fetching keyStats", err));
  },
  data() {
    return {
      keyStats: {
        priorComplianceScore: null,
        complianceScore: null,
        nonComplianceCount: null,
        assessmentCount: null,
      },
    };
  },
  methods: {
    toNumber(input) {
      return input !== null ? input : "-";
    },
    toPercentage(input) {
      return input !== null ? input.toFixed(0) : "-";
    },
  },
};
</script>

<style scope>
.key-element {
  /* height: 150px; */
  padding: 10px;
  background: #ffffff;
  padding: 30px;
  box-shadow: 1px 1px 10px #e8e8e8;
  border-radius: 5px;
}
.key-stat-value {
  float: right;
  font-size: 32px;
}
.key-stat-description {
  font-size: 14px;
  margin: 0;
}
</style>
