import {isNotEmpty} from "../../common/helperFunctions";
import $axios from "../../utils/axios-instance";

export default {
  namespaced: true,
  state: {
    // Authentication state
    status: "",
    token: localStorage.getItem("token") || "",
    user: null,
  },
  actions: {
    login({commit}, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        $axios
          .post(`/security/authenticate`, user, {skipAuthRefresh: true})
          .then((resp) => {
            if (resp.data.mfaChallenge === true) {
              commit("auth_mfa");
              resolve(resp);
            }
            const token = resp.data.token;
            const refreshToken = resp.data.refreshToken;
            $axios.defaults.headers.common["Authorization"] = token;
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);

            commit("auth_success", token);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    async tokenRefresh({commit, dispatch}) {
      let tokenRefreshResponse = await $axios.post(
        "/security/refresh",
        {
          bearerToken: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("refreshToken"),
        },
        {skipAuthRefresh: true},
      );
      if (tokenRefreshResponse && tokenRefreshResponse.data) {
        localStorage.setItem("token", tokenRefreshResponse.data.token);
        localStorage.setItem(
          "refreshToken",
          tokenRefreshResponse.data.refreshToken,
        );
      } else {
        commit("auth_error");
        dispatch("logout");
      }
    },
    async wsfedLogin({commit}, payload) {
      try {
        commit("auth_request");
        let resp = await $axios.post(`/security/wsfed-authenticate`, payload, {
          skipAuthRefresh: true,
        });
        const token = resp.data.token;
        const refreshToken = resp.data.refreshToken;
        $axios.defaults.headers.common["Authorization"] = token;
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        commit("auth_success", token);
        return resp.data;
      } catch (err) {
        commit("auth_error");
        localStorage.removeItem("token");
        throw err;
      }
    },
    authenticateMfaCode({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        $axios
          .post(`/security/mfa`, payload, {skipAuthRefresh: true})
          .then((resp) => {
            const token = resp.data.token;
            const refreshToken = resp.data.refreshToken;

            commit("auth_success", token);
            $axios.defaults.headers.common["Authorization"] = token;
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
            if (isNotEmpty(resp.data.deviceToken)) {
              localStorage.setItem(
                "rememberUser",
                JSON.stringify({
                  expiryDate:
                    Date.now() +
                    process.env.VUE_APP_LOGIN_DAYS_BEFORE_REMEMBER_TOKEN_EXPIRY,
                  deviceToken: resp.data.deviceToken,
                }),
              );
            } else {
              localStorage.removeItem("rememberUser");
            }
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    changePassword({commit}, payload) {
      return new Promise((resolve, reject) => {
        $axios
          .post(`/security/changepassword`, {
            currentPassword: payload.password,
            newPassword: payload.newPassword,
          })
          .then((resp) => {
            const refreshToken = resp.data.refreshToken;
            localStorage.setItem("refreshToken", refreshToken);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    resetPassword({commit}, payload) {
      return new Promise((resolve, reject) => {
        $axios
          .post(`/security/resetpassword`, {
            password: payload.password,
            token: payload.token,
            username: payload.username,
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateCultureSettings({commit, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        $axios
          .post(`/users/cultureinfo`, {
            PreferredCultureCode: payload.preferredCultureCode,
          })
          .then((resp) => {
            dispatch("setUser");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setAgreedTerms({commit, dispatch}) {
      return new Promise((resolve, reject) => {
        $axios
          .post(`/users/profile/terms`)
          .then((resp) => {
            dispatch("setUser");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getUser({state, dispatch}) {
      return new Promise((resolve, reject) => {
        if (state.user) {
          resolve(state.user);
        } else {
          dispatch("setUser")
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
    setUser({commit}) {
      return new Promise((resolve, reject) => {
        $axios
          .get(`/users/profile`)
          .then((response) => {
            commit("updateUser", response.data);
            localStorage.setItem("agreedTerms", response.data.hasAgreedTerms);
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    logout({commit}) {
      return new Promise((resolve, reject) => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("agreedTerms");
        delete $axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, {token, user}) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    auth_mfa(state) {
      state.status = "mfa";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.user = null;
    },
    updateUser(state, user) {
      state.user = user;
    },
  },

  getters: {
    isLoggedIn: () => !!localStorage.token,
    authStatus: (state) => state.status,
    authToken: () => localStorage.getItem("token"),
    userAgreedTerms: (state) =>
      state.user && state.user.hasAgreedTerms === true,
  },
};
