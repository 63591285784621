<template>
  <div class="primary-container card-container">
    <div class="row">
      <div
        :class="`col-xl-3 action-${headerPosition}-container individual-box`"
      >
        <label>
          <DxTooltip
            :visible.sync="isToolTipVisible"
            :close-on-outside-click="false"
            target="#divComments"
            position="top"
          >
            {{ toolTipDescription }}
          </DxTooltip>
        </label>
        <div
          id="divComments"
          @mouseenter="commentsToolTip = !commentsToolTip"
          @mouseleave="commentsToolTip = !commentsToolTip"
        >
          <h2>
            <i class="far fa-comment"></i>
            {{ $t("assessments.assessment.comments") }}
            <span
              v-if="comments && comments.length"
              class="regular-indication"
              >{{ comments.length }}</span
            >
          </h2>

          <p class="mb-4">{{ headerDescription }}</p>
        </div>
        <template v-if="!isSelfContained">
          <dx-button
            v-if="!readOnly"
            :text="this.$t('assessments.assessment.addComment')"
            name="addComment"
            type="normal"
            stylingMode="contained"
            class="btn btn-medium"
            icon="plus"
            @click="$emit('add-comment-click')"
            :visible="showButton"
          />
        </template>
      </div>

      <div class="col-xl-9 comments-container">
        <p class="gdpr-disc">
          <i class="fas fa-exclamation-circle mr-2"></i>
          {{ $t("legalNotices.gdprDisclaimer") }}
        </p>
        <div v-if="isSelfContained" class="comments-add-container">
          <comments-add
            @submit-comment-click="$emit('submit-comment-click', $event)"
            :isSelfContained="isSelfContained"
            :comment="activeComment"
            :readOnly="readOnly"
            class="comments-add"
          ></comments-add>
        </div>
        <div
          v-if="comments == null || !comments.length"
          style="text-align: center"
        >
          <div class="no-records-fallback">
            <i class="far fa-comment"></i>
            <span class="darker"
              >{{ $t("assessments.assessment.currentlyNoComments") }}.</span
            >
          </div>
        </div>
        <template v-if="comments">
          <div v-for="item in comments" :key="item">
            <div class="comment">
              <div
                v-if="item.pictureUrl !== null"
                class="profile-picture large"
                :style="{
                  'background-image': 'url(' + item.pictureUrl + ')',
                }"
              ></div>
              <div class="user-initials large" v-else>
                {{ getUserInitials(item.createdBy) }}
              </div>
              <div class="comment-who">
                <span class="name-date-of-comment">{{
                  `${item.createdBy} ${$t("assessments.assessment.on")} `
                }}</span>
                <span class="name-date-of-comment">
                  {{ formatDate(new Date(item.createdOn)) }}
                </span>
              </div>
              <p style="
                word-wrap: break-word;
                white-space: pre-wrap;
                word-break: break-word;
                "
              >{{ item.comments }}
              </p>
              <div
                class="comment-icons"
                v-if="
                  showEditButtons &&
                  (item.isOwner || isInRole(roleTypes.admin.role))
                "
              >
                <data-grid-options
                  @delete-click="$emit('delete-comment-click', item)"
                  @edit-click="onEditCommentClick(item)"
                />
              </div>
            </div>
            <div
              class="edit-container"
              v-if="
                isSelfContained &&
                activeComment &&
                activeComment.commentsId === item.commentsId
              "
            >
              <div>
                <div>
                  <div class="element-margin">
                    <DxTextArea
                      :height="150"
                      :value.sync="activeComment.comments"
                      stylingMode="outlined"
                      value-change-event="change"
                    ></DxTextArea>
                  </div>

                  <dx-button
                    :text="$t('assessments.assessment.editComment')"
                    name="editComment"
                    type="default"
                    class="btn btn-large"
                    icon="check"
                    @click="onSaveButtonClick($event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {DxButton, DxTextArea, DxTooltip} from "devextreme-vue";
import DataGridOptions from "../../components/shared/datagrid-options";
import localeDatetimeFormat from "../../mixins/locale-datetime-format";
import CommentsAdd from "../../components/shared/comments-add";
import {maxCommentsLength} from "../../common/constants";
import {isNotEmpty, formatDate} from "../../common/helperFunctions";
import roleTypes from "../../config/roles";

export default {
  props: {
    comments: {
      Type: Array,
    },
    showButton: {
      Type: Boolean,
      default: true,
      required: false,
    },
    isSelfContained: {
      Type: Boolean,
      default: false,
      required: false,
    },
    showEditButtons: {
      Type: Boolean,
      default: false,
      required: false,
    },
    headerPosition: {
      Type: String,
      default: "left",
      required: false,
    },
    headerDescription: {
      Type: String,
      required: false,
      default: function () {
        return this.$t("shared.commentsDefaultDescription");
      },
    },
    toolTipDescription: {
      Type: String,
      required: false,
    },
    hasToolTip: {
      Type: Boolean,
      required: false,
      default: false,
    },
    readOnly: {
      Type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [localeDatetimeFormat],
  components: {
    DxButton,
    DataGridOptions,
    CommentsAdd,
    DxTextArea,
    DxTooltip,
  },
  data() {
    return {
      activeComment: null,
      maxCommentsLength: maxCommentsLength,
      commentsToolTip: false,
      roleTypes: roleTypes,
    };
  },
  computed: {
    charactersRemaining() {
      return isNotEmpty(this.activeComment)
        ? maxCommentsLength - this.activeComment.comments.length
        : maxCommentsLength;
    },
    isToolTipVisible() {
      return this.commentsToolTip && this.hasToolTip;
    },
  },
  methods: {
    formatDate: formatDate,
    onEditCommentClick(item) {
      if (this.isSelfContained) {
        this.activeComment = item;
      }
      this.$emit("edit-comment-click", item);
    },
    onSaveButtonClick() {
      if (
        isNotEmpty(this.activeComment) &&
        this.activeComment.comments.length > 0
      ) {
        this.$emit("save-comment-click", this.activeComment);
        this.activeComment = null;
      }
    },
    getUserInitials(text) {
      if (text && text.length > 0) {
        return text
          .split(/\s/)
          .map((x) => x.charAt(0))
          .join("")
          .substring(0, 2);
      }
      return null;
    },
    isInRole(role) {
      return this.$store.state.auth.user.roles.includes(role);
    },
  },
};
</script>

<style lang="scss">
@import "../../themes/generated/variables.base.scss";
@import "../../themes/custom-variables.scss";
.darker {
  color: #1e1e1e !important;
}
.comments-add-container {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $grey4;
}
</style>
