<template>
  <div class="card-container">
    <h2>
      <i class="far fa-user-circle"></i>
      {{ $t("schedules.scheduleAssessment.auditor") }}
    </h2>
    <p class="accent-color" v-if="selectedAuditor.length > 0">
      {{ $t("schedules.scheduleAssessment.selectedAuditor") }}:
      {{ selectedAuditorName() }}
    </p>
    <div v-if="auditors.length > 0 && retailerId !== 0">
      <DxList
        :data-source="auditors"
        class="list"
        selection-mode="single"
        :selected-items.sync="selectedAuditor"
        :disabled="readOnly"
      >
        <template #item="{data: item}">
          <div class="list-card">
            <div v-if="item.pictureUrl !== null">
              <div
                class="profile-picture"
                :style="{
                  'background-image': 'url(' + item.pictureUrl + ')',
                }"
              ></div>
            </div>

            <div v-else>
              <div v-if="item.firstName !== null && item.lastName !== null">
                <div class="profile-picture">
                  {{ userInitials(item) }}
                </div>
              </div>
            </div>

            <div class="name">{{ `${item.firstName} ${item.lastName}` }}</div>

            <div v-if="item.role === 'Audit Manager'">
              <div class="audit-manager-label">
                {{ $t("schedules.scheduleAssessment.auditManager") }}
              </div>
            </div>
            <!-- Hidden temporarily as we don't want to show this for the demo as there's no distance data.
                  This will be used later hence not deleting the code.
            <div class="distance-from-retailer">
              {{ item.distanceFromRetailer }}km
            </div>
            -->
            <i class="fas fa-check-circle"></i>
            <i class="far fa-circle"></i>
          </div>
        </template>
      </DxList>
    </div>
  </div>
</template>

<script>
import {DxList} from "devextreme-vue";
import {createHelpers} from "vuex-map-fields";
import {isEmpty, getSecureImage} from "../../common/helperFunctions";
const {VUE_APP_API_HOSTNAME} = process.env;

const {mapFields} = createHelpers({
  getterType: "schedules/getField",
  mutationType: "schedules/updateField",
});
export default {
  components: {
    DxList,
  },
  props: {
    retailerId: {type: Number},
    readOnly: {type: Boolean},
  },
  data() {
    return {
      auditors: [],
    };
  },
  created() {
    this.setAuditorsDataSource();
  },
  methods: {
    isEmpty: isEmpty,
    selectedAuditorName() {
      if (this.selectedAuditor.length > 0) {
        const auditor = this.availableAuditors.filter(
          (x) => x.id === this.selectedAuditor[0].id,
        );

        return `${auditor[0].firstName} ${auditor[0].lastName}`;
      }
      return undefined;
    },

    userInitials(item) {
      if (item.firstName && item.lastName) {
        return `${item.firstName.charAt(0).toUpperCase()}${item.lastName
          .charAt(0)
          .toUpperCase()}`;
      }
      return "";
    },
    async getAvatarImage() {
      if (this.user.pictureUrl) {
        return await getSecureImage(
          `${VUE_APP_API_HOSTNAME}${this.user.pictureUrl}`,
        );
      }
    },
    async setAuditorsDataSource() {
      let auditors = [];
      let availableAuditors = this.availableAuditors;
      for (let auditor of availableAuditors) {
        if (auditor.pictureUrl) {
          auditor = {
            ...auditor,
            pictureUrl: await getSecureImage(auditor.pictureUrl),
          };
        }
        auditors.push(auditor);
      }
      this.auditors = auditors;
    },
    // Temporarily hiding the auditor distance
    //selectedAuditorDistance() {
    //  if (this.selectedAuditor.length > 0) {
    //    const auditor = this.availableAuditors.filter(
    //      (x) => x.id === this.selectedAuditor[0].id,
    //    );
    //    return `${auditor[0].distanceFromRetailer}`;
    //  }
    //  return undefined;
    //},
  },
  computed: {
    ...mapFields(["selectedAuditor"]),
    availableAuditors() {
      return this.$store.state.schedules.availableAuditors;
    },
  },
  beforeDestroy() {
    this.selectedAuditor = [];
  },
  watch: {
    availableAuditors() {
      this.setAuditorsDataSource();
    },
  },
};
</script>

<style scoped lang="scss"></style>
