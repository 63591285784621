<template>
  <div>
    <div
      class="card-container ie-adj-landing-card"
      style="margin-top: 30px; padding-right: 60px; position: relative"
    >
      <p class="user-details">{{ user.firstName }} {{ user.surname }}</p>
      <h2 style="margin-bottom: 0">
        {{ $t("landingPage.landingBanner.title") }} {{ appName }}
      </h2>
    </div>
    <div class="row mt-5">
      <div class="col-xl-8 landing-charts">
        <landing-page-insights
          v-if="user"
          :role="user.roles[0]"
        ></landing-page-insights>
      </div>
      <div class="col-xl-4 landing-additional">
        <div class="card-container">
          <training-materials-component></training-materials-component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LandingPageInsights from "./landing-page-insights";
import TrainingMaterialsComponent from "./components/training-materials-component";

import {mapState} from "vuex";
export default {
  components: {
    LandingPageInsights,
    TrainingMaterialsComponent,
  },
  computed: {
    ...mapState("auth", ["user"]),
    appName() {
      return this.$appInfo.title;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../themes/generated/variables.base.scss";

.user-details {
  margin: 0 0 5px 0;
  font-size: 15px;
  color: #1e1e1e;
}
</style>
