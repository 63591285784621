import $axios from "../../utils/axios-instance.js";

export default {
    namespaced: true,
    state: {
        upcomingAssessment: null,
        upcomingSelfAssessment: null,
    },
    mutations: {
        setUpcomingAssessment: (state, n) => state.upcomingAssessment = n,
        setUpcomingSelfAssessment: (state, n) => state.upcomingSelfAssessment = n,
    },
    actions: {
        getUpcomingAssessment: ({ commit }) => $axios.get(`dashboard/getUpcomingAssessmentForCurrentUser`)
            .then(x => commit("setUpcomingAssessment", x.data))
            .catch(x => console.error('Error while getting upcoming assessment', x)),
        getUpcomingSelfAssessment: ({ commit }) => $axios.get(`dashboard/getUpcomingSelfAssessmentForCurrentUser`)
            .then(x => commit("setUpcomingSelfAssessment", x.data))
            .catch(x => console.error('Error while getting upcoming assessment', x)),
    },
}