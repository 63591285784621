<template>
  <div>
    <h2 v-if="helpNote || refLinks.length > 0">
      {{ $t("assessments.assessment.needHelp") }}
    </h2>

    <template v-if="helpNote">
      <div class="additional-info-item-container">
        <a
          @click="openNotes()"
          :title="$t('assessments.assessment.assessmentNotes')"
        >
          <i class="far fa-file-alt"></i
          >{{ $t("assessments.assessment.assessmentNotes") }}
        </a>
      </div>
    </template>

    <template v-if="refLinks">
      <div
        class="additional-info-item-container"
        v-for="item in refLinks"
        :key="item.materialId"
      >
        <a
          @click="retrieveLink(item)"
          target="_blank"
          :title="`${item.materialTitle}${item.bookmarkTitle}`"
          :href="item.link"
        >
          <i class="far fa-file-alt"></i>{{ item.materialTitle
          }}{{ item.bookmarkTitle }}
        </a>
      </div>
    </template>
    <div v-if="!helpNote && !refLinks.length > 0" style="text-align: center">
      <div class="no-records-fallback" style="margin-top: 20px">
        <i class="far fa-file-alt"></i>
        <span class="darker"
          >{{
            $t("assessments.assessment.currentlyNoHelpNoteOrRefLinks")
          }}.</span
        >
      </div>
    </div>

    <!-- Assessment notes popup -->
    <DxPopup
      :visible.sync="assessmentNotesVisible"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="false"
      :max-width="'100%'"
      padding="'10px'"
      :width="'450px'"
      :height="'auto'"
      :title="this.$t('assessments.assessment.assessmentNotes')"
    >
      <span>
        <i class="fas fa-info-circle"></i> &nbsp;<b>{{
          $t("assessments.assessment.additionalInformation")
        }}</b>
      </span>
      <p class="assessment-notes-body">{{ helpNote }}</p>
      <div class="close" @click="closeNotes()">
        <i class="fas fa-times"></i>
      </div>
    </DxPopup>
  </div>
</template>

<script>
import {DxPopup} from "devextreme-vue";
import {referenceLinkType} from "../../common/constants";
import downloadAuthAttachment from "../../mixins/download-auth-attachment";

export default {
  components: {
    DxPopup,
  },
  props: {
    refLinks: {
      Type: Object,
    },
    helpNote: {
      Type: String,
    },
  },
  mixins: [downloadAuthAttachment],
  data() {
    return {
      assessmentNotesVisible: false,
      apiUrl: process.env.VUE_APP_API_HOSTNAME,
      linkType: referenceLinkType,
    };
  },
  methods: {
    openNotes() {
      this.assessmentNotesVisible = true;
    },
    closeNotes() {
      this.assessmentNotesVisible = false;
    },
    retrieveLink(item) {
      item.linkType === this.linkType.Document
        ? this.downloadAuthAttachment(item.link)
        : "return false;";
    },
  },
};
</script>

<style lang="scss">
@import "../../themes/generated/variables.base.scss";
.darker {
  color: #1e1e1e !important;
}
p.assessment-notes-body {
  max-height: 400px;
  height: 80%;
  font-size: 16px;
  overflow: scroll;
  overflow-x: auto;
  white-space: pre-wrap;
}
</style>
