<template>
  <div v-if="auditYears.length">
    <dx-select-box
      :value="selectedAuditYear"
      :data-source="auditYears"
      stylingMode="outlined"
      display-expr="name"
      value-expr="id"
      :disabled="isDisabled"
      placeholder="Pick an audit year"
      @value-changed="selectedAuditYearChanged"
    ></dx-select-box>
  </div>
</template>

<script>
import {DxSelectBox} from "devextreme-vue";

export default {
  components: {
    DxSelectBox,
  },
  props: {
    isDisabled: {
      Type: Boolean,
      Default: false,
    },
  },
  created() {
    this.$store.dispatch("organisations/getAuditYears", true);
  },
  methods: {
    selectedAuditYearChanged(e) {
      this.$store.dispatch("organisations/updateSelectedAuditYear", e.value);
    },
  },
  computed: {
    selectedAuditYear() {
      return this.$store.state.organisations.selectedAuditYear;
    },
    auditYears() {
      return this.$store.state.organisations.auditYears;
    },
  },
  beforedestroy() {
    this.$store.dispatch("organisations/updateSelectedAuditYear", null);
  },
};
</script>

<style></style>
