<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="reviewersDataSource"
      display-expr="fullname"
      :placeholder="$t('reporting.filters.leadReviewerPlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      styling-mode="outlined"
      :value.sync="leadReviewers"
      :wrapItemText="true"
      apply-value-mode="instantly"
      :max-displayed-tags="1"
      value-expr="id"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapState} from "vuex";
import DxTagBox from "devextreme-vue/tag-box";
import $axios from "../../utils/axios-instance";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

export default {
  components: {
    DxTagBox,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["leadReviewers"],
      "getFilter",
      "setFilter",
    ),
  },
  data() {
    return {
      reviewersDataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.loadReviewers,
          loadMode: "raw",
        }),
      }),
    };
  },
  methods: {
    async loadReviewers() {
      const response = await $axios.get(`/users/GetUsersByRole/Reviewer,Admin`);
      response.data.push({id: 0, fullname: ""});
      return response.data;
    },
  },
};
</script>

<style lang="sass" scoped></style>
