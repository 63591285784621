<template>
  <div class="container-fluid" style="background: white">
    <div class="login-screen">
      <div class="row">
        <div class="col-sm-5 login-info-section hidden-xs">
          <div class="row login-ie-heading-adj">
            <div class="col-lg-8 offset-lg-2">
              <div class="login-header">
                <div style="height: 10vh"></div>
                <div class="title">Need help?</div>
                <p>
                  If you have questions please contact iReview Support at
                  <a
                    href="mailto:iReview.Support.gl@ingka.ikea.com"
                    target="_blank"
                    >iReview.Support.gl@ingka.ikea.com</a
                  >.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-7 login-form-section">
          <div class="row login-ie-form-adj">
            <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      title: this.$appInfo.title,
    };
  },
};
</script>

<style lang="scss"></style>
