<template>
  <div>
    <div class="mb-4">
      <div class="mb-2 mt-4">
        <label>{{ $t("userSettings.preferredLanguage") }}</label>
      </div>
      <dx-select-box
        :value.sync="selectedCultureCode"
        :data-source="supportedCultureCodes"
        stylingMode="outlined"
        display-expr="name"
        value-expr="value"
        item-template="item"
        field-template="field"
      >
        <template #field="{data}">
          <div class="d-flex flex-row flex-nowrap align-items-center">
            <span :class="`ml-2 ${getFlagIcon(data.value)}`"></span>
            <dx-text-box
              :value="data.name"
              :read-only="true"
              style="margin-bottom: 0px"
            />
          </div>
        </template>
        <template #item="{data}">
          <div class="d-flex flex-row flex-nowrap align-items-center">
            <span :class="`${getFlagIcon(data.value)}`"></span>
            <div class="ml-2">{{ data.name }}</div>
          </div>
        </template>
      </dx-select-box>
    </div>

    <hr/>
    <dx-button
      :use-submit-behavior="false"
      style="margin-right: 15px"
      :text="this.$t('userSettings.save')"
      @click="saveLocaleInfo()"
      type="default"
      class="btn btn-large"
      icon="check"
    />
  </div>
</template>

<script>
import supportedLocales from "../../custom/config/locales";
import {DxSelectBox, DxButton, DxTextBox} from "devextreme-vue";
import {mapState, mapActions} from "vuex";
import {isNotEmpty, loadLanguage} from "@/common/helperFunctions";
import {locale, loadMessages} from "devextreme/localization";
export default {
  components: {
    DxSelectBox,
    DxButton,
    DxTextBox,
  },
  data() {
    return {
      supportedCultureCodes: [...supportedLocales].sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
      selectedCultureCode: this.$i18n.locale,
    };
  },
  methods: {
    saveLocaleInfo() {
      this.updateCultureSettings({
        preferredCultureCode: this.selectedCultureCode,
      }).then(() => {
        this.$i18n.locale = this.selectedCultureCode;       
        loadMessages(
          loadLanguage(
            this.$i18n.locale.split("-").shift().toLowerCase(),
            this,
          ),
        );
        locale(this.$i18n.locale);
      });
    },
    getFlagIcon(value) {
      return `flag-icon flag-icon-${value.split("-").pop().toLowerCase()}`;
    },
    ...mapActions("auth", ["updateCultureSettings"]),
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  watch: {
    user(newValue) {
      if (newValue && isNotEmpty(newValue.preferredCultureCode)) {
        this.selectedCultureCode = newValue.preferredCultureCode;
      }
    },
  },
};
</script>
