<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="levels"
      display-expr="name"
      :placeholder="$t('reporting.filters.levelPlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      styling-mode="outlined"
      :value.sync="reviewLevels"
      value-expr="name"
      :wrapItemText="true"
      apply-value-mode="instantly"
      :max-displayed-tags="1"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapState} from "vuex";
import DxTagBox from "devextreme-vue/tag-box";

export default {
  components: {
    DxTagBox,
  },
  computed: {
    ...mapFields(
      "reportingFilters",
      ["reviewLevels"],
      "getFilter",
      "setFilter",
    ),
    ...mapState("orders", ["levels"]),
  },
};
</script>

<style lang="sass" scoped></style>
