<template>
  <div style="margin-bottom: 85px" :class="{loading: isLoading}">
    <page-heading :pageTitle="pageTitle" :breadCrumbs="breadCrumbs" />
    <div v-show="isEditPage()">
      <div class="fixed-action-container" @click="newUserClick">
        <i class="fas fa-plus"></i>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <dx-button
            @click="newUserClick"
            type="normal"
            class="schedule-visit hidden-sm hidden-xs"
            stylingMode="contained"
            icon="plus"
            :text="this.$t('users.usersList.createNewUser')"
          />
        </div>
      </div>
    </div>
    <div v-if="!isLoading">
      <dx-validation-group :ref="tabValidationRefKey" name="tabs">
        <div class="row">
          <div class="col-xl-12">
            <div
              class="primary-container card-container"
              style="padding-bottom: 0"
            >
              <h2>
                <i class="far fa-edit"></i>
                {{ $t("organisations.organisationsList.formTitle") }}
              </h2>
              <div class="mt-12">
                <dx-tabs
                  :data-source="tabs"
                  :selected-index="selectedTab"
                  @selection-changed="onSelectedTabChanged($event)"
                />

                <div
                  class="basicInformation"
                  v-show="selectedTab === organisationTabs.BasicInformation"
                >
                  <div
                    class="card-container xs-adjustment"
                    style="position: inherit"
                  >
                    <div class="content-block">
                      <div class="row">
                        <div class="col-lg-3">
                          <label class="required bold-label">
                            {{
                              $t("organisations.organisationsDetails.orgType")
                            }}
                          </label>
                          <dx-select-box
                            :value.sync="selectedOrgType"
                            :data-source="orgTypes"
                            display-expr="name"
                            value-expr="id"
                            stylingMode="outlined"
                            :placeholder="
                              $t('shared.select', {
                                dataField: $t(
                                  'organisations.organisationsDetails.orgType',
                                ),
                              })
                            "
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.orgType',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-select-box>
                        </div>
                        <div
                          class="col-lg-3"
                          v-if="selectedOrgType === organisationTypes.Supplier"
                        >
                          <div style="display: flex">
                            <label class="required bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.ingkaUnit",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="ingkaUnitTooltip"
                                :close-on-outside-click="false"
                                target="#ingkaUnit"
                                position="top"
                              >
                                {{ $t("tooltips.organisation.ingkaUnit") }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="ingkaUnit"
                              @mouseenter="ingkaUnitTooltip = !ingkaUnitTooltip"
                              @mouseleave="ingkaUnitTooltip = !ingkaUnitTooltip"
                            ></div>
                          </div>
                          <dx-select-box
                            :value.sync="selectedIngkaUnit"
                            :data-source="ingkaUnits"
                            stylingMode="outlined"
                            :placeholder="
                              $t('shared.select', {
                                dataField: $t(
                                  'organisations.organisationsDetails.ingkaUnit',
                                ),
                              })
                            "
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.ingkaUnit',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-select-box>
                        </div>
                        <div
                          class="col-lg-3"
                          v-if="selectedOrgType === organisationTypes.IngkaUnit"
                        >
                          <div style="display: flex">
                            <label class="required bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.siteType",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="siteTypeToolTip"
                                :close-on-outside-click="false"
                                target="#siteType"
                                position="top"
                              >
                                {{ $t("tooltips.organisation.siteType") }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="siteType"
                              @mouseenter="siteTypeToolTip = !siteTypeToolTip"
                              @mouseleave="siteTypeToolTip = !siteTypeToolTip"
                            ></div>
                          </div>
                          <dx-select-box
                            :value.sync="selectedIngkaSiteType"
                            :data-source="ingkaSiteTypes"
                            stylingMode="outlined"
                            :placeholder="
                              $t('shared.select', {
                                dataField: $t(
                                  'organisations.organisationsDetails.siteType',
                                ),
                              })
                            "
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                            <dx-validator>
                              <!-- <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.siteType',
                                    ),
                                  })
                                "
                              /> -->
                            </dx-validator>
                          </dx-select-box>
                        </div>
                        <div
                          class="col-lg-3"
                          v-if="selectedOrgType === organisationTypes.Supplier"
                        >
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.supplierNumber",
                              )
                            }}
                          </label>
                          <dx-text-box
                            :placeholder="
                              $t('shared.enter', {
                                dataField: $t(
                                  'organisations.organisationsDetails.supplierNumber',
                                ),
                              })
                            "
                            max-length="50"
                            stylingMode="outlined"
                            width="100%"
                            :value.sync="supplierNumber"
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                            <dx-validator>
                              <!-- <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.supplierNumber',
                                    ),
                                  })
                                "
                              /> -->
                            </dx-validator>
                          </dx-text-box>
                        </div>
                        <div
                          class="col-lg-3"
                          v-if="selectedOrgType === organisationTypes.IngkaUnit"
                        >
                          <div style="display: flex">
                            <label class="bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.siteNumber",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="siteNumberToolTip"
                                :close-on-outside-click="false"
                                target="#divSiteNumber"
                                position="top"
                              >
                                {{ $t("tooltips.organisation.siteNumber") }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="divSiteNumber"
                              @mouseenter="
                                siteNumberToolTip = !siteNumberToolTip
                              "
                              @mouseleave="
                                siteNumberToolTip = !siteNumberToolTip
                              "
                            ></div>
                          </div>
                          <dx-text-box
                            :placeholder="
                              $t('shared.enter', {
                                dataField: $t(
                                  'organisations.organisationsDetails.siteNumber',
                                ),
                              })
                            "
                            stylingMode="outlined"
                            width="100%"
                            :value.sync="siteNumber"
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                            <dx-validator>
                              <!-- <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.supplierNumber',
                                    ),
                                  })
                                "
                              /> -->
                            </dx-validator>
                          </dx-text-box>
                        </div>
                        <div
                          class="col-lg-3"
                          v-if="selectedOrgType === organisationTypes.Supplier"
                        >
                          <label class="required bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.supplierName",
                              )
                            }}
                          </label>
                          <dx-text-box
                            :placeholder="
                              $t('shared.enter', {
                                dataField: $t(
                                  'organisations.organisationsDetails.supplierName',
                                ),
                              })
                            "
                            stylingMode="outlined"
                            width="100%"
                            max-length="255"
                            :value.sync="supplierName"
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.supplierName',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-text-box>
                        </div>
                        <div class="col-lg-3">
                          <div style="display: flex">
                            <label class="required bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.siteName",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="siteNameToolTip"
                                :close-on-outside-click="false"
                                target="#siteName"
                                position="top"
                              >
                                {{ $t("tooltips.organisation.siteName") }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="siteName"
                              @mouseenter="siteNameToolTip = !siteNameToolTip"
                              @mouseleave="siteNameToolTip = !siteNameToolTip"
                            ></div>
                          </div>
                          <dx-text-box
                            :placeholder="
                              $t('shared.enter', {
                                dataField: $t(
                                  'organisations.organisationsDetails.siteName',
                                ),
                              })
                            "
                            stylingMode="outlined"
                            width="100%"
                            max-length="255"
                            :value.sync="siteName"
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.siteName',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-text-box>
                        </div>
                        <div class="col-lg-3">
                          <div style="display: flex">
                            <label class="required bold-label">
                              {{
                                $t("organisations.organisationsDetails.country")
                              }}
                            </label>
                          </div>
                          <dx-select-box
                            :value.sync="country"
                            :data-source="countriesList"
                            stylingMode="outlined"
                            display-expr="name"
                            value-expr="value"
                            item-template="item"
                            field-template="field"
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                            <template #field="{data}">
                              <div
                                v-if="data && data.value && data.name"
                                class="d-flex flex-fill flex-row flex-nowrap align-items-center"
                              >
                                <span
                                  :class="`ml-2 ${getFlagIcon(data.value)}`"
                                ></span>
                                <dx-text-box
                                  :value="data.name"
                                  :read-only="true"
                                  style="margin-bottom: 0px"
                                />
                              </div>
                              <div
                                class="d-flex flex-row flex-nowrap align-items-center"
                                v-else
                              >
                                <dx-text-box
                                  :placeholder="
                                    $t('shared.select', {
                                      dataField: $t(
                                        'organisations.organisationsDetails.country',
                                      ),
                                    })
                                  "
                                  :read-only="true"
                                  style="margin-bottom: 0px"
                                />
                              </div>
                            </template>
                            <template #item="{data}">
                              <div
                                class="d-flex flex-row flex-nowrap align-items-center"
                              >
                                <span
                                  :class="`${getFlagIcon(data.value)}`"
                                ></span>
                                <div class="ml-2">{{ data.name }}</div>
                              </div>
                            </template>
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.country',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-select-box>
                        </div>

                        <div class="col-lg-3" v-if="country === 'CB'">
                          <div style="display: flex">
                            <label class="bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.crossBorderCountries",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="crossBorderCountriesTooltip"
                                :close-on-outside-click="false"
                                target="#crossBorderCountries"
                                position="top"
                              >
                                {{
                                  $t(
                                    "organisations.organisationsDetails.countryToolTip",
                                  )
                                }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="crossBorderCountries"
                              @mouseenter="
                                crossBorderCountriesTooltip =
                                  !crossBorderCountriesTooltip
                              "
                              @mouseleave="
                                crossBorderCountriesTooltip =
                                  !crossBorderCountriesTooltip
                              "
                            ></div>
                          </div>
                          <dx-text-box
                            :placeholder="
                              $t('shared.enter', {
                                dataField: $t(
                                  'organisations.organisationsDetails.crossBorderCountries',
                                ),
                              })
                            "
                            stylingMode="outlined"
                            width="100%"
                            max-length="2000"
                            :value.sync="crossBorderCountries"
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          />
                        </div>
                        <div class="col-lg-3">
                          <div style="display: flex">
                            <label class="required bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.siteAddress",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="siteAddressToolTip"
                                :close-on-outside-click="false"
                                target="#divSiteAddress"
                                position="top"
                              >
                                {{ $t("tooltips.organisation.siteAddress") }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="divSiteAddress"
                              @mouseenter="
                                siteAddressToolTip = !siteAddressToolTip
                              "
                              @mouseleave="
                                siteAddressToolTip = !siteAddressToolTip
                              "
                            ></div>
                          </div>

                          <dx-text-box
                            :placeholder="
                              $t('shared.enter', {
                                dataField: $t(
                                  'organisations.organisationsDetails.siteAddress',
                                ),
                              })
                            "
                            stylingMode="outlined"
                            width="100%"
                            max-length="2000"
                            :value.sync="siteAddress"
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.siteAddress',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-text-box>
                        </div>

                        <div
                          class="col-lg-3"
                          v-if="selectedOrgType === organisationTypes.Supplier"
                        >
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.webSiteAddress",
                              )
                            }}
                          </label>
                          <dx-text-box
                            :placeholder="
                              $t('shared.enter', {
                                dataField: $t(
                                  'organisations.organisationsDetails.webSiteAddress',
                                ),
                              })
                            "
                            stylingMode="outlined"
                            width="100%"
                            :value.sync="webSiteAddress"
                            max-length="2000"
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                          </dx-text-box>
                        </div>
                        <div
                          class="col-lg-3"
                          v-if="selectedOrgType === organisationTypes.Supplier"
                        >
                          <div style="display: flex">
                            <label class="required bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.supplierStartDate",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="supplierStartDateTooltip"
                                :close-on-outside-click="false"
                                target="#divsupplierStartDate"
                                position="top"
                              >
                                {{
                                  $t("tooltips.organisation.supplierStartDate")
                                }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="divsupplierStartDate"
                              @mouseenter="
                                supplierStartDateTooltip =
                                  !supplierStartDateTooltip
                              "
                              @mouseleave="
                                supplierStartDateTooltip =
                                  !supplierStartDateTooltip
                              "
                            ></div>
                          </div>
                          <dx-date-box
                            :placeholder="
                              $t('shared.enter', {
                                dataField: $t(
                                  'organisations.organisationsDetails.supplierStartDate',
                                ),
                              })
                            "
                            :acceptCustomValue="false"
                            :openOnFieldClick="true"
                            type="date"
                            validationMessageMode="auto"
                            pickerType="calendar"
                            stylingMode="outlined"
                            width="100%"
                            :value.sync="supplierStartDate"
                            :use-mask-behavior="true"
                            display-format="dd/MMM/yyyy"
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.supplierStartDate',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-date-box>
                        </div>
                        <div
                          class="col-lg-3"
                          v-if="selectedOrgType === organisationTypes.Supplier"
                        >
                          <label class="required bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.productOrServices",
                              )
                            }}
                          </label>
                          <dx-text-box
                            :placeholder="
                              $t('shared.enter', {
                                dataField: $t(
                                  'organisations.organisationsDetails.productOrServices',
                                ),
                              })
                            "
                            stylingMode="outlined"
                            width="100%"
                            max-length="2000"
                            :value.sync="productOrServices"
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.productOrServices',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-text-box>
                        </div>
                        <div
                          class="col-lg-3"
                          v-if="
                            selectedOrgType === organisationTypes.Supplier &&
                            $can('update', $subjects.organisation.subject)
                          "
                        >
                          <div style="display: flex">
                            <label class="required bold-label">
                              {{
                                $t("organisations.organisationsDetails.risk")
                              }}
                              <DxTooltip
                                :visible.sync="riskToolTip"
                                :close-on-outside-click="false"
                                target="#divRisk"
                                position="top"
                              >
                                {{ $t("tooltips.organisation.risk") }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="divRisk"
                              @mouseenter="riskToolTip = !riskToolTip"
                              @mouseleave="riskToolTip = !riskToolTip"
                            ></div>
                          </div>
                          <dx-select-box
                            :value.sync="selectedRiskType"
                            :data-source="riskTypes"
                            stylingMode="outlined"
                            :placeholder="
                              $t('shared.select', {
                                dataField: $t(
                                  'organisations.organisationsDetails.risk',
                                ),
                              })
                            "
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.risk',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-select-box>
                        </div>
                        <div
                          class="col-lg-3"
                          v-if="
                            $can(
                              'update',
                              $subjects.organisation.subject,
                              'activeStatus',
                            )
                          "
                        >
                          <div style="display: flex">
                            <label class="required bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.activeStatus",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="activeStatusToolTip"
                                :close-on-outside-click="false"
                                target="#divActiveStatus"
                                position="top"
                              >
                                {{ $t("tooltips.organisation.activeStatus") }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="divActiveStatus"
                              @mouseenter="
                                activeStatusToolTip = !activeStatusToolTip
                              "
                              @mouseleave="
                                activeStatusToolTip = !activeStatusToolTip
                              "
                            ></div>
                          </div>
                          <dx-select-box
                            :value.sync="selectedStatus"
                            :data-source="statusOptions"
                            stylingMode="outlined"
                            :placeholder="
                              $t('shared.select', {
                                dataField: $t(
                                  'organisations.organisationsDetails.activeStatus',
                                ),
                              })
                            "
                            :disabled="
                              !$can('update', $subjects.organisation.subject)
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.activeStatus',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-select-box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="contractingDetails"
                  v-if="
                    organisationId &&
                    selectedOrgType === organisationTypes.Supplier
                  "
                  v-show="selectedTab === organisationTabs.ContractingDetails"
                >
                  <div
                    class="card-container xs-adjustment"
                    style="position: inherit"
                  >
                    <div class="content-block">
                      <div class="row">
                        <div class="col-lg-3">
                          <div style="display: flex">
                            <label class="required bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.validIWAYVersionSigned",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="validIWAYVersionSignedToolTip"
                                :close-on-outside-click="false"
                                target="#validIWAYVersionSigned"
                                position="top"
                              >
                                {{
                                  $t(
                                    "tooltips.organisation.validIwayVersionSigned",
                                  )
                                }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="validIWAYVersionSigned"
                              @mouseenter="
                                validIWAYVersionSignedToolTip =
                                  !validIWAYVersionSignedToolTip
                              "
                              @mouseleave="
                                validIWAYVersionSignedToolTip =
                                  !validIWAYVersionSignedToolTip
                              "
                            ></div>
                          </div>
                          <dx-select-box
                            :value.sync="selectedValidIWAYVersionSignedOption"
                            :data-source="validIWAYVersionSignedOptions"
                            stylingMode="outlined"
                            :placeholder="
                              $t('shared.select', {
                                dataField: $t(
                                  'organisations.organisationsDetails.validIWAYVersionSigned',
                                ),
                              })
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.validIWAYVersionSigned',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-select-box>
                        </div>
                        <div class="col-lg-3">
                          <div style="display: flex">
                            <label class="required bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.contractSignedAfterIA",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="contractSignedAfterIAToolTip"
                                :close-on-outside-click="false"
                                target="#divContractSignedAfterIA"
                                position="top"
                              >
                                {{
                                  $t(
                                    "tooltips.organisation.contractSignedAfterIA",
                                  )
                                }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="divContractSignedAfterIA"
                              @mouseenter="
                                contractSignedAfterIAToolTip =
                                  !contractSignedAfterIAToolTip
                              "
                              @mouseleave="
                                contractSignedAfterIAToolTip =
                                  !contractSignedAfterIAToolTip
                              "
                            ></div>
                          </div>
                          <dx-select-box
                            :value.sync="selectedContractSignedAfterIAOption"
                            :data-source="yesNoNAOptions"
                            stylingMode="outlined"
                            :placeholder="
                              $t('shared.select', {
                                dataField: $t(
                                  'organisations.organisationsDetails.contractSignedAfterIA',
                                ),
                              })
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.contractSignedAfterIA',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-select-box>
                        </div>

                        <div class="col-lg-3">
                          <div style="display: flex">
                            <label class="required bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.ICCSignedBySupplier",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="ICCSignedBySupplierTooltip"
                                :close-on-outside-click="false"
                                target="#ICCSignedBySupplier"
                                position="top"
                              >
                                {{
                                  $t(
                                    "tooltips.organisation.iccSignedBySupplier",
                                  )
                                }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="ICCSignedBySupplier"
                              @mouseenter="
                                ICCSignedBySupplierTooltip =
                                  !ICCSignedBySupplierTooltip
                              "
                              @mouseleave="
                                ICCSignedBySupplierTooltip =
                                  !ICCSignedBySupplierTooltip
                              "
                            ></div>
                          </div>
                          <dx-select-box
                            :value.sync="selectedICCSignedBySupplierOption"
                            :data-source="yesNoNAOptions"
                            stylingMode="outlined"
                            :placeholder="
                              $t('shared.select', {
                                dataField: $t(
                                  'organisations.organisationsDetails.ICCSignedBySupplier',
                                ),
                              })
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.ICCSignedBySupplier',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-select-box>
                        </div>
                        <div class="col-lg-3">
                          <div style="display: flex">
                            <label class="required bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.relevantIngkaSpecificationsSignedBySupplier",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="
                                  relevantIngkaSpecificationsSignedBySupplierToolTip
                                "
                                :close-on-outside-click="false"
                                target="#relevantIngkaSpecificationsSignedBySupplier"
                                position="top"
                              >
                                {{
                                  $t(
                                    "tooltips.organisation.relevantIgnkaSpecificationsSignedBySupplier",
                                  )
                                }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="relevantIngkaSpecificationsSignedBySupplier"
                              @mouseenter="
                                relevantIngkaSpecificationsSignedBySupplierToolTip =
                                  !relevantIngkaSpecificationsSignedBySupplierToolTip
                              "
                              @mouseleave="
                                relevantIngkaSpecificationsSignedBySupplierToolTip =
                                  !relevantIngkaSpecificationsSignedBySupplierToolTip
                              "
                            ></div>
                          </div>
                          <dx-select-box
                            :value.sync="
                              selectedRelevantIngkaSpecificationsSignedBySupplier
                            "
                            :data-source="yesNoNAOptions"
                            stylingMode="outlined"
                            :placeholder="
                              $t('shared.select', {
                                dataField: $t(
                                  'organisations.organisationsDetails.relevantIngkaSpecificationsSignedBySupplier',
                                ),
                              })
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t('shared.isRequired', {
                                    dataField: $t(
                                      'organisations.organisationsDetails.relevantIngkaSpecificationsSignedBySupplier',
                                    ),
                                  })
                                "
                              />
                            </dx-validator>
                          </dx-select-box>
                        </div>
                        <div class="col-lg-3">
                          <div style="display: flex">
                            <label class="bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.commentsToContractAfterIA",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="commentsToContractAfterIAToolTip"
                                :close-on-outside-click="false"
                                target="#divCommentsToContractAfterIA"
                                position="top"
                              >
                                {{
                                  $t(
                                    "tooltips.organisation.commentsToContractAfterIA",
                                  )
                                }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="divCommentsToContractAfterIA"
                              @mouseenter="
                                commentsToContractAfterIAToolTip =
                                  !commentsToContractAfterIAToolTip
                              "
                              @mouseleave="
                                commentsToContractAfterIAToolTip =
                                  !commentsToContractAfterIAToolTip
                              "
                            ></div>
                          </div>
                          <dx-text-area
                            :placeholder="
                              $t('shared.enter', {
                                dataField: $t(
                                  'organisations.organisationsDetails.commentsToContractAfterIA',
                                ),
                              })
                            "
                            stylingMode="outlined"
                            width="100%"
                            :value.sync="commentsToContractAfterIA"
                            :height="100"
                          />
                        </div>

                        <div class="col-lg-3">
                          <div style="display: flex">
                            <label class="bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.commentsToICC",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="commentsToICCToolTip"
                                :close-on-outside-click="false"
                                target="#divCommentsToICC"
                                position="top"
                              >
                                {{ $t("tooltips.organisation.commmentsToIcc") }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="divCommentsToICC"
                              @mouseenter="
                                commentsToICCToolTip = !commentsToICCToolTip
                              "
                              @mouseleave="
                                commentsToICCToolTip = !commentsToICCToolTip
                              "
                            ></div>
                          </div>
                          <dx-text-area
                            :placeholder="
                              $t('shared.enter', {
                                dataField: $t(
                                  'organisations.organisationsDetails.commentsToICC',
                                ),
                              })
                            "
                            stylingMode="outlined"
                            width="100%"
                            :value.sync="commentsToICC"
                            :height="100"
                          />
                        </div>

                        <div class="col-lg-3">
                          <div style="display: flex">
                            <label class="bold-label">
                              {{
                                $t(
                                  "organisations.organisationsDetails.IWAYExceptionsGivenToSupplier",
                                )
                              }}
                              <DxTooltip
                                :visible.sync="
                                  IWAYExceptionsGivenToSupplierTooltip
                                "
                                :close-on-outside-click="false"
                                target="#IWAYExceptionsGivenToSupplier"
                                position="top"
                              >
                                {{
                                  $t(
                                    "tooltips.organisation.iWayExceptionsGivenToSupplier",
                                  )
                                }}
                              </DxTooltip>
                            </label>
                            <div
                              class="tooltip"
                              id="IWAYExceptionsGivenToSupplier"
                              @mouseenter="
                                IWAYExceptionsGivenToSupplierTooltip =
                                  !IWAYExceptionsGivenToSupplierTooltip
                              "
                              @mouseleave="
                                IWAYExceptionsGivenToSupplierTooltip =
                                  !IWAYExceptionsGivenToSupplierTooltip
                              "
                            ></div>
                          </div>
                          <dx-text-area
                            styling-mode="outlined"
                            v-model="IWAYExceptionsGivenToSupplier"
                            :height="100"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="detailedInfo"
                  v-show="selectedTab === organisationTabs.DetailedInfo"
                >
                  <div
                    class="card-container xs-adjustment"
                    style="position: inherit"
                  >
                    <div class="content-block">
                      <div class="row">
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.numberOfSites",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="500"
                            :value.sync="numberOfSites"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.offSiteUnitDetails",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            :value.sync="offSiteUnitDetails"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.numberOfSiteBuildings",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="500"
                            :value.sync="numberOfSiteBuildings"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.approxBuildingSizeTotal",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="500"
                            :value.sync="approxBuildingSizeTotal"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.approxLandAreaTotal",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="500"
                            :value.sync="approxLandAreaTotal"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.environmentallyHarmfulSites",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            :value.sync="environmentallyHarmfulSites"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.workerAccomodationAddress",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="2000"
                            :value.sync="workerAccomodationAddress"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.vehicleTypes",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            :value.sync="vehicleTypes"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.numberOfWorkers",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            :value.sync="numberOfWorkers"
                            max-length="500"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.numberOfMigrantWorkers",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="500"
                            :value.sync="numberOfMigrantWorkers"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.nationalityOfMigrantWorkers",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            :value.sync="nationalityOfMigrantWorkers"
                            max-length="500"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.numberOfRecruitmentAgencyHires",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="500"
                            :value.sync="numberOfRecruitmentAgencyHires"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.languagesSpokenByWorkers",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="500"
                            :value.sync="languagesSpokenByWorkers"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.numberOfHomeBasedWorkers",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="500"
                            :value.sync="numberOfHomeBasedWorkers"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.contractTypes",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="2000"
                            :value.sync="contractTypes"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.siteSchedule",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="2000"
                            :value.sync="siteSchedule"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t("organisations.organisationsDetails.wageType")
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="200"
                            :value.sync="wageType"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.workerCollectiveAggreement",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="2000"
                            :value.sync="workerCollectiveAggreement"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.workingWeekDuration",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            :value.sync="workingWeekDuration"
                            max-length="2000"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t("organisations.organisationsDetails.shifts")
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            :value.sync="shifts"
                            max-length="2000"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t("organisations.organisationsDetails.breaks")
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="2000"
                            :value.sync="breaks"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.regularOverTime",
                              )
                            }}
                          </label>
                          <dx-select-box
                            :value.sync="regularOverTime"
                            :data-source="yesNoOptions"
                            display-expr="name"
                            value-expr="value"
                            stylingMode="outlined"
                            :placeholder="
                              $t('shared.select', {
                                dataField: $t(
                                  'organisations.organisationsDetails.regularOverTimePlaceholder',
                                ),
                              })
                            "
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.averageOverTime",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="2000"
                            :value.sync="averageOverTime"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.listRequiredPPE",
                              )
                            }}
                          </label>
                          <dx-text-box
                            stylingMode="outlined"
                            width="100%"
                            max-length="2000"
                            :value.sync="listRequiredPPE"
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.isPPEProvided",
                              )
                            }}
                          </label>
                          <dx-select-box
                            :value.sync="isPPEProvided"
                            :data-source="yesNoOptions"
                            display-expr="name"
                            value-expr="value"
                            stylingMode="outlined"
                            :placeholder="
                              $t('shared.select', {
                                dataField: $t(
                                  'organisations.organisationsDetails.isPPEProvidedPlaceholder',
                                ),
                              })
                            "
                          />
                        </div>
                        <div class="col-lg-6">
                          <label class="bold-label">
                            {{
                              $t(
                                "organisations.organisationsDetails.reviewDateAlarm",
                              )
                            }}
                          </label>
                          <dx-select-box
                            :value.sync="reviewDateAlarm"
                            :data-source="yesNoOptions"
                            display-expr="name"
                            value-expr="value"
                            stylingMode="outlined"
                            :placeholder="
                              $t('shared.select', {
                                dataField: $t(
                                  'organisations.organisationsDetails.reviewDateAlarmPlaceholder',
                                ),
                              })
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="userList"
                  v-show="selectedTab === organisationTabs.UserList"
                >
                  <div
                    class="card-container xs-adjustment"
                    style="position: inherit"
                  >
                    <div class="content-block">
                      <dx-data-grid
                        class="dx-card thin-column-chooser"
                        :ref="usersRefKey"
                        :data-source="dataSource"
                        :noDataText="this.$t('users.usersList.noData')"
                        :columns="colDefs"
                        :focused-row-index="0"
                        :focused-row-enabled="false"
                        :column-hiding-enabled="false"
                        :selection="{mode: 'single'}"
                        :hover-state-enabled="true"
                        :show-borders="false"
                        :allow-column-reordering="true"
                        :allow-column-resizing="true"
                        :column-min-width="150"
                        columnResizingMode="widget"
                      >
                        <DxExport :enabled="true" />
                        <DxColumnChooser
                          :enabled="true"
                          :allowSearch="true"
                          mode="dragAndDrop"
                        />
                        <DxColumnFixing :enabled="true" />
                        <DxStateStoring
                          :enabled="true"
                          type="localStorage"
                          storage-key="storageUserOrganisationList"
                        />
                        <dx-filter-row :visible="true" />
                        <dx-header-filter :visible="true" :allowSearch="true" />
                        <dx-paging :page-size="10" />
                        <dx-pager
                          :show-page-size-selector="true"
                          :show-info="true"
                        />
                        <dx-remote-operations
                          :filtering="false"
                          :paging="false"
                          :sorting="false"
                          :summary="false"
                          :grouping="false"
                          :group-paging="false"
                        />

                        <template #action-buttons-cell-template="{data}">
                          <div style="text-align: center">
                            <span
                              class="table-button"
                              @click="
                                $router.push({
                                  name: 'user-details',
                                  params: {id: data.data.id},
                                })
                              "
                              :title="$t('users.usersList.editUser')"
                            >
                              <i class="far fa-edit"></i>
                            </span>
                          </div>
                        </template>
                        <template #org-array-template="{data, column}">
                          <div>
                            {{ getOrgArrayCellData(data, column) }}
                          </div>
                        </template>
                      </dx-data-grid>
                    </div>
                  </div>
                </div>

                <div
                  class="attachment"
                  v-show="selectedTab === organisationTabs.Attachments"
                >
                  <div v-if="$can('update', $subjects.organisation.subject)">
                    <attachment-card-list
                      :attachments="ignkaInternalAttachments"
                      @delete-attachment="deleteAttachment($event)"
                      @add-attachment="
                        toggleSlideOut($event, 'attachments', true)
                      "
                      :headerDescription="
                        this.$t(
                          'organisations.organisationsDetails.ingkaInternalAttachmentsDescription',
                        )
                      "
                      @download-attachment="downloadAttachment($event)"
                      :showButton="true"
                      :showDeleteButton="true"
                      :title="
                        this.$t(
                          'organisations.organisationsDetails.ingkaInternalAttachments',
                        )
                      "
                      :toolTipDescription="
                        this.$t('tooltips.organisation.ingkaInternalAttachment')
                      "
                      :hasToolTip="true"
                      toolTipId="divIngkaInternalAttachments"
                    />
                  </div>
                  <div>
                    <attachment-card-list
                      :attachments="generalAttachments"
                      @delete-attachment="deleteAttachment($event)"
                      @add-attachment="
                        toggleSlideOut($event, 'attachments', false)
                      "
                      :headerDescription="
                        this.$t('shared.attachmentsDefaultDescription')
                      "
                      @download-attachment="downloadAttachment($event)"
                      :showButton="true"
                      :showDeleteButton="true"
                      :title="
                        this.$t(
                          'organisations.organisationsDetails.attachments',
                        )
                      "
                      :toolTipDescription="
                        this.$t('tooltips.organisation.attachments')
                      "
                      :hasToolTip="true"
                      toolTipId="divAttachments1"
                    />
                  </div>
                </div>
                <div v-show="selectedTab === organisationTabs.Comments">
                  <div>
                    <comments
                      :comments="comments"
                      @edit-comment-click="editCommentClick($event)"
                      @delete-comment-click="deleteCommentClick($event)"
                      @submit-comment-click="submitComment($event)"
                      @save-comment-click="editComment($event)"
                      :isSelfContained="true"
                      :showEditButtons="true"
                      :headerDescription="
                        this.$t(
                          'organisations.organisationsDetails.commentsDescription',
                        )
                      "
                      :toolTipDescription="
                        this.$t('tooltips.organisation.comments')
                      "
                      :hasToolTip="true"
                    />
                  </div>
                </div>
              </div>

              <div
                class="slide-out"
                ref="slideout-ref"
                :class="{open: isSlideOutOpen}"
              >
                <div class="close" @click="closeSlideOut()">
                  <i class="fas fa-times"></i>
                </div>
                <template v-if="slideOutType === 'attachments'">
                  <div class="content-block">
                    <h2 class="content-title">
                      <i class="fas fa-link"></i>
                      {{ $t("assessments.assessment.attachments") }}
                    </h2>
                  </div>
                  <attachment-upload
                    @upload-successful="onUploadSuccess"
                    uploadMode="instantly"
                    :uploadUrl="attachmentUploadUrl"
                  ></attachment-upload>
                </template>
              </div>
            </div>
            <hr />
          </div>
        </div>

        <div class="fixed-bottom-container z-index-1000">
          <div class="row">
            <div class="col-auto">
              <dx-button
                :text="$t('users.userDetails.back')"
                type="normal"
                styling-mode="contained"
                @click="exit"
                class="btn btn-large mr-3"
                icon="undo"
              ></dx-button>
              <dx-button
                :text="setButtonText"
                type="default"
                styling-mode="contained"
                @click="save"
                class="btn btn-large"
                icon="check"
              ></dx-button>
            </div>
          </div>
        </div>
      </dx-validation-group>
    </div>
  </div>
</template>

<script>
import pageHeading from "../components/page-heading";
import {
  DxTextBox,
  DxTextArea,
  DxButton,
  DxSelectBox,
  DxTooltip,
  DxDateBox,
} from "devextreme-vue";

import {
  DxDataGrid,
  DxFilterRow,
  DxHeaderFilter,
  DxRemoteOperations,
  DxPaging,
  DxPager,
  DxColumnChooser,
  DxColumnFixing,
  DxStateStoring,
  DxExport,
} from "devextreme-vue/data-grid";

import DxTabs from "devextreme-vue/tabs";
import {DxValidator, DxRequiredRule} from "devextreme-vue/validator";
import DxValidationGroup from "devextreme-vue/validation-group";
import notify from "devextreme/ui/notify";
import localeDatetimeFormat from "../mixins/locale-datetime-format";
import {isNotEmpty, isEmpty, isUnsavedComment} from "../common/helperFunctions";
import {mapFields} from "vuex-map-fields";
import {mapActions, mapState} from "vuex";
import orgCountries from "../config/organisationCountries";
import AttachmentCardList from "../components/shared/attachment-card-list.vue";
import AttachmentUpload from "../components/shared/attachment-upload.vue";
import {serializeLoadOptions} from "../utils/load-options-serializer";
import {fileUploadValidation} from "../common/constants";
import downloadAuthAttachment from "../mixins/download-auth-attachment";
import columnDefinitions from "../custom/organisation/usersListColDefs";
import Comments from "../components/shared/comments.vue";
import {
  organisationTabs,
  organisationTypes,
  ingkaUnits,
  riskTypes,
  siteTypes,
  statusOptions,
  siteManagementTabs,
  validIWAYVersionSignedOptions,
} from "../common/constants";
import {confirm, custom} from "devextreme/ui/dialog";
import PageHeading from "@/components/page-heading";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

const {VUE_APP_API_HOSTNAME} = process.env;

export default {
  components: {
    pageHeading,
    DxTabs,
    DxSelectBox,
    DxTextBox,
    DxTextArea,
    DxValidator,
    DxRequiredRule,
    DxButton,
    DxTooltip,
    DxValidationGroup,
    AttachmentCardList,
    AttachmentUpload,
    Comments,
    DxDateBox,
    DxDataGrid,
    DxFilterRow,
    DxHeaderFilter,
    DxRemoteOperations,
    PageHeading,
    DxPaging,
    DxPager,
    DxColumnChooser,
    DxColumnFixing,
    DxStateStoring,
    DxExport,
  },
  async beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (
      !(this.$router.history.pending.name === "login-form") &&
      (await this.confirmStayInDirtyForm())
    ) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  mounted() {
    if (this.isEditPage()) {
      this.getOrganisationDetails();
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  },
  mixins: [localeDatetimeFormat, downloadAuthAttachment],
  beforeDestroy() {
    (this.locations = []), (this.assessmentStructures = []);
    this.resetOrganisationState();
  },
  data() {
    return {
      dataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.load,
        }),
      }),
      selectedTab: 0,
      organisationId: 0,
      organisationTabs: organisationTabs,
      tabValidationRefKey: "tabValidationGroup",
      organisationTypes: organisationTypes,
      selectedOrgType: null,
      organisation: null,
      country: null,
      countries: orgCountries.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
      ),
      isLoading: true,
      orgTypes: [
        {
          id: 1,
          name: "Ingka unit",
        },
        {
          id: 2,
          name: "Supplier",
        },
      ],
      ingkaUnits: Object.values(ingkaUnits),
      selectedIngkaUnit: null,
      ingkaSiteTypes: Object.values(siteTypes),
      selectedIngkaSiteType: null,
      supplierNumber: null,
      siteNumber: null,
      supplierName: null,
      siteName: null,
      countryToolTip: false,
      crossBorderCountries: null,
      crossBorderCountriesTooltip: false,
      siteAddress: null,
      supplierStartDate: null,
      webSiteAddress: null,
      productOrServices: null,
      riskTypes: Object.values(riskTypes),
      selectedRiskType: null,
      statusOptions: Object.values(statusOptions),
      selectedStatus: statusOptions.Active,
      selectedContractSignedAfterIAOption: null,
      yesNoNAOptions: [
        this.$t("shared.yes"),
        this.$t("shared.no"),
        this.$t("shared.na"),
      ],
      commentsToContractAfterIA: null,
      selectedICCSignedBySupplierOption: null,
      ICCSignedBySupplierTooltip: false,
      commentsToICC: null,
      selectedValidIWAYVersionSignedOption: null,
      validIWAYVersionSignedOptions: validIWAYVersionSignedOptions,
      validIWAYVersionSignedToolTip: false,
      selectedRelevantIngkaSpecificationsSignedBySupplier: null,
      relevantIngkaSpecificationsSignedBySupplierToolTip: false,
      IWAYExceptionsGivenToSupplier: null,
      IWAYExceptionsGivenToSupplierTooltip: false,
      numberOfSites: null,
      offSiteUnitDetails: null,
      numberOfSiteBuildings: null,
      approxBuildingSizeTotal: null,
      approxLandAreaTotal: null,
      environmentallyHarmfulSites: null,
      workerAccomodationAddress: null,
      vehicleTypes: null,
      numberOfWorkers: null,
      numberOfMigrantWorkers: null,
      nationalityOfMigrantWorkers: null,
      numberOfRecruitmentAgencyHires: null,
      languagesSpokenByWorkers: null,
      numberOfHomeBasedWorkers: null,
      contractTypes: null,
      siteSchedule: null,
      wageType: null,
      workerCollectiveAggreement: null,
      workingWeekDuration: null,
      shifts: null,
      breaks: null,
      regularOverTime: null,
      averageOverTime: null,
      listRequiredPPE: null,
      isPPEProvided: null,
      reviewDateAlarm: null,
      yesNoOptions: [
        {name: this.$t("shared.yes"), value: true},
        {name: this.$t("shared.no"), value: false},
      ],
      slideOutType: "",
      internalAttachment: false,
      isSlideOutOpen: false,
      fileExtensionWhitelist: fileUploadValidation.fileExtensionWhitelist,
      maxFileUploadSize: fileUploadValidation.maxFileUploadSize,
      attachmentsBorderStyle: {},
      siteTypeToolTip: false,
      siteNameToolTip: false,
      siteNumberToolTip: false,
      siteAddressToolTip: false,
      supplierStartDateTooltip: false,
      riskToolTip: false,
      activeStatusToolTip: false,
      contractSignedAfterIAToolTip: false,
      commentsToContractAfterIAToolTip: false,
      commentsToICCToolTip: false,
      ingkaUnitTooltip: false,
      ingkaInternalAttachmentsToolTip: false,
      attachmentsToolTip: false,
      commentsToolTip: false,
      usersRefKey: "users-list",
    };
  },
  methods: {
    ...mapActions("organisations", [
      "postComment",
      "getCommentsList",
      "getComment",
      "updateComment",
      "deleteComment",
      "getAttachmentsList",
      "resetOrganisationState",
    ]),
    newUserClick() {
      this.$router.push({
        name: "user-details",
      });
    },
    async load(loadOptions) {
      let response = await this.$http.get(
        `/organisations/${this.organisationId}/GetUsersOfOrganisation`,
        {
          params: serializeLoadOptions(loadOptions),
        },
      );
      return response.data;
    },
    async deleteAttachment(e) {
      await this.$http.delete(`/organisations/${e}/attachment`);
      this.getAttachmentsList({organisationId: this.$route.params.id});
    },
    downloadAttachment(e) {
      if (!isNotEmpty(e.attachment)) return;
      this.downloadAuthAttachment(e.attachment.link);
    },
    toggleSlideOut(e, type, isInternalAttachment) {
      if (!isInternalAttachment) {
        let result = confirm(
          `<i>${this.$t("organisations.confirmAddGeneraAttachment")}</i>`,
          this.$t("organisations.addGeneralAttachment"),
        );
        result.then((dialogResult) => {
          if (dialogResult) {
            if (type === this.slideOutType && !this.isSlideOutOpen) {
              this.isSlideOutOpen = !this.isSlideOutOpen;
            } else if (!this.isSlideOutOpen) {
              this.isSlideOutOpen = true;
            }
            this.slideOutType = type;
            this.internalAttachment = isInternalAttachment;
          }
        });
      } else {
        if (type === this.slideOutType && !this.isSlideOutOpen) {
          this.isSlideOutOpen = !this.isSlideOutOpen;
        } else if (!this.isSlideOutOpen) {
          this.isSlideOutOpen = true;
        }
        this.slideOutType = type;
        this.internalAttachment = isInternalAttachment;
      }
    },
    closeSlideOut() {
      this.isSlideOutOpen = false;
      this.slideOutType = "";
    },
    onUploadSuccess() {
      this.closeSlideOut();
      this.getAttachmentsList({organisationId: this.$route.params.id});
    },
    attachmentsValueChanged(e) {
      this.files = e.value;

      for (let index = 0; index < e.value.length; index++) {
        let fileName = e.value[index].name;

        if (fileUploadValidation.validCharacters.test(fileName)) continue;
        if (!e.component._files[index].isValid()) {
          e.component._files[index].$statusMessage.append(
            this.$t("attachments.errors.invalidFileNameMessage"),
          );
        } else {
          e.component._files[index].$statusMessage.text(
            this.$t("attachments.errors.invalidFileNameMessage"),
          );
          e.component._files[index].$statusMessage.addClass("error-text");
          e.component._files[index].isValid = () => false;
        }
      }
    },
    onSelectedTabChanged(e) {
      this.selectedTab = e.addedItems.length > 0 ? e.addedItems[0].id : 0;
    },
    isEditPage() {
      return isNotEmpty(this.$route.params.id);
    },
    getFlagIcon(value) {
      if (isNotEmpty(value)) {
        return `flag-icon flag-icon-${value.toLowerCase()}`;
      }
      return "";
    },
    exit() {
      this.$router.push({name: "supplier-list"});
    },
    async save() {
      if (!this.validateTabs()) return;

      const baseRequestBody = {
        orgType:
          this.selectedOrgType === organisationTypes.IngkaUnit
            ? "Ingka unit"
            : "Supplier",
        siteName: this.siteName,
        country: this.country,
        crossBorderCountries: this.crossBorderCountries,
        siteAddress: this.siteAddress,
        activeStatus: this.selectedStatus,
      };

      let requestBody = {};
      if (this.selectedOrgType === organisationTypes.IngkaUnit) {
        requestBody = {
          ...baseRequestBody,
          siteType: this.selectedIngkaSiteType,
          siteNumber: this.siteNumber,
        };
      } else {
        requestBody = {
          ...baseRequestBody,
          ingkaUnit: this.selectedIngkaUnit,
          supplierNumber: this.supplierNumber,
          supplierName: this.supplierName,
          webSiteAddress: this.webSiteAddress,
          supplierStartDate: this.supplierStartDate
          ? this.$moment
              .utc(this.supplierStartDate)
              .local()
              .format("YYYY-MM-DDTHH:mm:ss")
              .slice(0, 10)
          : null,
          productOrServices: this.productOrServices,
          riskType: this.selectedRiskType,
        };
      }
      if (!this.organisationId) {
        this.isLoading = true;
        try {
          const response = await this.$http.post("/organisations", requestBody);
          this.organisationId =
            response && response.data ? response.data.id : null;
          this.isLoading = false;
          notify(this.$t("organisations.siteCreatedSuccessfully"), "success");
          if (this.organisationId) {
            this.$router.replace({
              name: "supplier-details",
              params: {id: this.organisationId},
            });
            this.getOrganisationDetails();
          }
        } catch (error) {
          this.isLoading = false;
          this.showError(error);
        }
      } else {
        if (isUnsavedComment()) {
          let result = await this.unsavedChangesAlert(
            this.$t("shared.unsavedComment"),
            this.$t("shared.unsavedCommentsTitle"),
          );
          if (result) return;
        }
        let result = confirm(
          `<i>${this.$t("organisations.confirmSiteUpdate")}</i>`,
          this.$t("organisations.saveDetails"),
        );
        result.then(async (dialogResult) => {
          if (dialogResult) {
            let formData = {};
            if (this.selectedOrgType === organisationTypes.Supplier) {
              formData = {
                ...requestBody,
                ...this.contractingDetails,
                ...this.detailedInformation,
              };
            } else {
              formData = {
                ...requestBody,
              };
            }

            try {
              this.isLoading = true;
              await this.$http.put(
                `/organisations/${this.organisationId}/updateDetails`,
                formData,
              );
              this.getOrganisationDetails();
              this.isLoading = false;
              notify(
                this.$t("organisations.siteUpdatedSuccessfully"),
                "success",
              );
            } catch (error) {
              this.isLoading = false;
              this.showError(error);
            }
          }
        });
      }
    },
    validateTabs() {
      const isValid = this.tabValidationGroup().validate().isValid;
      if (!isValid) {
        let tabs = "";
        tabs +=
          document
            .getElementsByClassName("basicInformation")[0]
            .getElementsByClassName("dx-invalid").length > 0
            ? siteManagementTabs[0].text
            : "";
        tabs +=
          document.getElementsByClassName("contractingDetails")[0] &&
          document
            .getElementsByClassName("contractingDetails")[0]
            .getElementsByClassName("dx-invalid").length > 0
            ? " " + siteManagementTabs[1].text
            : "";
        notify(`Please complete all mandatory fields across all tabs`, "error");
      }
      return isValid;
    },
    async submitComment(comment) {
      try {
        await this.postComment({
          comment: comment,
          organisationId: this.organisationId,
        });

        notify(
          this.$t("orders.orderDetails.successfullyAddedComment"),
          "success",
        );
        this.getCommentsList({organisationId: this.organisationId});
      } catch (err) {
        notify(err, "error");
      }
    },
    async editCommentClick(e) {
      await this.getComment(e.commentsId);
    },
    async editComment(e) {
      try {
        let payload = {
          commentId: e.commentsId,
          comment: e.comments,
        };
        await this.updateComment(payload);

        notify(
          this.$t("orders.orderDetails.successfullyEditedComment"),
          "success",
        );
      } catch (error) {
        notify(error.response.data.message || error, "error");
      }
    },
    async deleteCommentClick(e) {
      try {
        await this.deleteComment(e.commentsId);

        notify(
          this.$t("orders.orderDetails.successfullyDeletedComment"),
          "success",
        );
        this.getCommentsList({organisationId: this.organisationId});
      } catch (error) {
        notify(error.response.data.message || error, "error");
      }
    },
    tabValidationGroup() {
      return this.$refs[this.tabValidationRefKey].instance;
    },
    getOrganisationDetails() {
      this.organisationId = this.$route.params.id;
      this.$http.get(`/organisations/${this.$route.params.id}`).then((resp) => {
        this.organisation = resp.data;
        this.populateFormData();
        this.getAttachmentsList({organisationId: this.$route.params.id});
        this.getCommentsList({organisationId: this.$route.params.id});
      });
    },
    populateFormData() {
      this.selectedOrgType =
        this.organisation.organisationType === "Supplier"
          ? organisationTypes.Supplier
          : organisationTypes.IngkaUnit;
      this.siteName = this.organisation.siteName;
      this.country = this.organisation.organisationDetails.country;
      this.crossBorderCountries =
        this.organisation.organisationDetails.crossBorderCountries;
      this.siteAddress = this.organisation.siteAddress;
      this.selectedStatus = this.organisation.activeStatus;
      if (this.selectedOrgType === organisationTypes.IngkaUnit) {
        this.selectedIngkaSiteType =
          this.organisation.organisationDetails.siteType;
        this.siteNumber = this.organisation.orgCode;
      } else {
        this.selectedIngkaUnit =
          this.organisation.organisationDetails.ingkaUnit;
        this.supplierNumber = this.organisation.orgCode;
        this.supplierName = this.organisation.supplierName;
        this.webSiteAddress =
          this.organisation.organisationDetails.webSiteAddress;
        this.supplierStartDate =
          this.organisation.organisationDetails.supplierStartDate;
        this.productOrServices =
          this.organisation.organisationDetails.productOrServices;
        this.selectedRiskType = this.organisation.organisationDetails.riskType;

        this.selectedContractSignedAfterIAOption =
          this.organisation.organisationDetails.contractSignedAfterIA;
        this.commentsToContractAfterIA =
          this.organisation.organisationDetails.commentsToContractAfterIA;
        this.selectedICCSignedBySupplierOption =
          this.organisation.organisationDetails.iccSignedBySupplier;
        this.commentsToICC =
          this.organisation.organisationDetails.commentsToICC;
        this.selectedValidIWAYVersionSignedOption =
          this.organisation.organisationDetails.validIWAYVersionSigned;
        this.IWAYExceptionsGivenToSupplier =
          this.organisation.organisationDetails.iwayExceptionsGivenToSupplier;
        this.selectedRelevantIngkaSpecificationsSignedBySupplier =
          this.organisation.organisationDetails.relevantIngkaSpecificationsSignedBySupplier;

        this.numberOfSites =
          this.organisation.organisationDetails.numberOfSites;
        this.offSiteUnitDetails =
          this.organisation.organisationDetails.offSiteUnitDetails;
        this.numberOfSiteBuildings =
          this.organisation.organisationDetails.numberOfSiteBuildings;
        this.approxBuildingSizeTotal =
          this.organisation.organisationDetails.approxBuildingSizeTotal;
        this.approxLandAreaTotal =
          this.organisation.organisationDetails.approxTotalLandArea;
        this.environmentallyHarmfulSites =
          this.organisation.organisationDetails.environmentallyHarmfulSites;
        this.workerAccomodationAddress =
          this.organisation.organisationDetails.workerAccomodationAddress;
        this.vehicleTypes = this.organisation.organisationDetails.vehicleTypes;
        this.numberOfWorkers =
          this.organisation.organisationDetails.numberOfWorkers;
        this.numberOfMigrantWorkers =
          this.organisation.organisationDetails.numberOfMigrantWorkers;
        this.nationalityOfMigrantWorkers =
          this.organisation.organisationDetails.nationalityOfMigrantWorkers;
        this.numberOfRecruitmentAgencyHires =
          this.organisation.organisationDetails.numberOfRecruitmentAgencyHires;
        this.languagesSpokenByWorkers =
          this.organisation.organisationDetails.languageSpokenByWorkers;
        this.numberOfHomeBasedWorkers =
          this.organisation.organisationDetails.numberOfHomeBasedWorkers;
        this.contractTypes =
          this.organisation.organisationDetails.contractTypes;
        this.siteSchedule = this.organisation.organisationDetails.siteSchedule;
        this.wageType = this.organisation.organisationDetails.wageType;
        this.workerCollectiveAggreement =
          this.organisation.organisationDetails.workerCollectiveAggreement;
        this.workingWeekDuration =
          this.organisation.organisationDetails.workingWeekDuration;
        this.shifts = this.organisation.organisationDetails.shifts;
        this.breaks = this.organisation.organisationDetails.breaks;
        this.regularOverTime =
          this.organisation.organisationDetails.regularOvertime;
        this.averageOverTime =
          this.organisation.organisationDetails.averageOvertime;
        this.listRequiredPPE =
          this.organisation.organisationDetails.listRequiredPPE;
        this.isPPEProvided =
          this.organisation.organisationDetails.isPPEProvided;
        this.reviewDateAlarm =
          this.organisation.organisationDetails.reviewDateAlarm;
      }
    },
    hasValueChanged() {
      let hasValueChanged = false;
      if (this.organisation.organisationType === "Supplier") {
        const initialState = this.getSupplierInitialState;

        const currentState = this.getSupplierCurrentState;
        hasValueChanged = !(
          JSON.stringify(initialState, (k, v) =>
            v === undefined ? null : v,
          ) ===
          JSON.stringify(currentState, (k, v) => (v === undefined ? null : v))
        );
      } else {
        const initialState = this.getSiteInitialState;
        const currentState = this.getSiteCurrentState;
        hasValueChanged = !(
          JSON.stringify(initialState, (k, v) =>
            v === undefined ? null : v,
          ) ===
          JSON.stringify(currentState, (k, v) => (v === undefined ? null : v))
        );
      }
      return hasValueChanged;
    },
    unsavedChangesAlert(message, title) {
      return confirm(`<i>${message}</i>`, title);
    },

    async confirmStayInDirtyForm() {
      return (
        this.isEditPage() &&
        (isUnsavedComment() || this.hasValueChanged()) &&
        !(await this.unsavedChangesAlert(
          this.$t("shared.unsavedChangesConfirmation"),
          this.$t("shared.unsavedChangesTitle"),
        ))
      );
    },
    showError(error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message ===
          this.$t("organisations.duplicateSiteSupplierError")
      ) {
        custom({
          title: "Error",
          messageHtml: `${error.response.data.message}`,
          buttons: [
            {
              text: "Ok",
              stylingMode: "contained",
              type: "danger",
            },
          ],
        }).show();
      } else {
        notify(error.response.data.message || error, "error");
      }
    },
  },

  computed: {
    ...mapFields("sharedComponents", ["locations", "assessmentStructures"]),
    ...mapFields("organisations", [""]),
    ...mapState("organisations", [
      "selectedAuditYear",
      "comments",
      "comment",
      "orgDetails",
      "ignkaInternalAttachments",
      "generalAttachments",
    ]),
    ...mapState("auth", ["user"]),
    pageTitle() {
      return this.isEditPage()
        ? this.$t("organisations.organisationsDetails.titleEdit")
        : this.$t("organisations.organisationsDetails.titleCreate");
    },
    breadCrumbs() {
      return [this.$t("organisations.organisationsList.title"), this.pageTitle];
    },
    tabs() {
      if (isEmpty(this.$route.params.id)) {
        return siteManagementTabs.map((x) => {
          return {...x, visible: x.id === 0};
        });
      } else {
        let tabs = [...siteManagementTabs];
        tabs[0] = {...tabs[0], visible: true};
        tabs[1] = {
          ...tabs[1],
          visible:
            this.selectedOrgType === organisationTypes.Supplier &&
            this.$can("update", this.$subjects.organisation.subject),
        };
        tabs[2] = {
          ...tabs[2],
          visible: this.selectedOrgType === organisationTypes.Supplier,
        };
        tabs[3] = {...tabs[3], visible: true};
        tabs[4] = {...tabs[4], visible: true};
        tabs[5] = {...tabs[5], visible: true};
        return tabs;
      }
    },
    attachmentUploadUrl() {
      return `${VUE_APP_API_HOSTNAME}/organisations/${this.organisationId}/attachments/${this.internalAttachment}`;
    },
    setButtonText() {
      if (this.organisationId > 0) return this.$t("users.userDetails.save");
      else return this.$t("users.userDetails.next");
    },
    contractingDetails() {
      return {
        contractSignedAfterIA: this.selectedContractSignedAfterIAOption,
        commentsToContractAfterIA: this.commentsToContractAfterIA,
        iccSignedBySupplier: this.selectedICCSignedBySupplierOption,
        commentsToICC: this.commentsToICC,
        validIwayVersionSigned: this.selectedValidIWAYVersionSignedOption,
        relevantIngkaSpecificationsSignedBySupplier:
          this.selectedRelevantIngkaSpecificationsSignedBySupplier,
        iWayExceptionsGivenToSupplier: this.IWAYExceptionsGivenToSupplier,
      };
    },
    detailedInformation() {
      return {
        numberOfSites: this.numberOfSites,
        offSiteUnitDetails: this.offSiteUnitDetails,
        numberOfSiteBuildings: this.numberOfSiteBuildings,
        approxBuildingSizeTotal: this.approxBuildingSizeTotal,
        approxTotalLandArea: this.approxLandAreaTotal,
        environmentallyHarmfulSites: this.environmentallyHarmfulSites,
        workerAccomodationAddress: this.workerAccomodationAddress,
        vehicleTypes: this.vehicleTypes,
        numberOfWorkers: this.numberOfWorkers,
        numberOfMigrantWorkers: this.numberOfMigrantWorkers,
        nationalityOfMigrantWorkers: this.nationalityOfMigrantWorkers,
        numberOfRecruitmentAgencyHires: this.numberOfRecruitmentAgencyHires,
        languageSpokenByWorkers: this.languagesSpokenByWorkers,
        numberOfHomeBasedWorkers: this.numberOfHomeBasedWorkers,
        contractTypes: this.contractTypes,
        siteSchedule: this.siteSchedule,
        wageType: this.wageType,
        workerCollectiveAggreement: this.workerCollectiveAggreement,
        workingWeekDuration: this.workingWeekDuration,
        shifts: this.shifts,
        breaks: this.breaks,
        regularOverTime: this.regularOverTime,
        averageOverTime: this.averageOverTime,
        listRequiredPPE: this.listRequiredPPE,
        isPPEProvided: this.isPPEProvided,
        reviewDateAlarm: this.reviewDateAlarm,
      };
    },
    getSupplierInitialState() {
      return {
        activeStatus: this.organisation.activeStatus,
        orgCode: this.organisation.orgCode,
        organisationType: this.organisation.organisationType,
        ingkaUnit: this.organisation.organisationDetails.ingkaUnit,
        siteAddress: this.organisation.siteAddress,
        siteName: this.organisation.siteName,
        country: this.organisation.organisationDetails.country,
        supplierName: this.organisation.supplierName,
        webSiteAddress: this.organisation.organisationDetails.webSiteAddress,
        supplierStartDate: this.organisation.organisationDetails
          .supplierStartDate
          ? this.organisation.organisationDetails.supplierStartDate.slice(0, 10)
          : null,
        productOrServices:
          this.organisation.organisationDetails.productOrServices,
        riskType: this.organisation.organisationDetails.riskType,
        //contracting details
        contractSignedAfterIA:
          this.organisation.organisationDetails.contractSignedAfterIA,
        commentsToContractAfterIA:
          this.organisation.organisationDetails.commentsToContractAfterIA,
        iccSignedBySupplier:
          this.organisation.organisationDetails.iccSignedBySupplier,
        commentsToICC: this.organisation.organisationDetails.commentsToICC,
        validIWAYVersionSigned:
          this.organisation.organisationDetails.validIWAYVersionSigned,
        relevantIngkaSpecificationsSignedBySupplier:
          this.organisation.organisationDetails
            .relevantIngkaSpecificationsSignedBySupplier,
        iwayExceptionsGivenToSupplier:
          this.organisation.organisationDetails.iwayExceptionsGivenToSupplier,
        //detailed info
        numberOfSites: this.organisation.organisationDetails.numberOfSites,
        numberOfSiteBuildings:
          this.organisation.organisationDetails.numberOfSiteBuildings,
        approxTotalLandArea:
          this.organisation.organisationDetails.approxTotalLandArea,
        workerAccomodationAddress:
          this.organisation.organisationDetails.workerAccomodationAddress,
        numberOfWorkers: this.organisation.organisationDetails.numberOfWorkers,
        nationalityOfMigrantWorkers:
          this.organisation.organisationDetails.nationalityOfMigrantWorkers,
        languageSpokenByWorkers:
          this.organisation.organisationDetails.languageSpokenByWorkers,
        contractTypes: this.organisation.organisationDetails.contractTypes,
        wageType: this.organisation.organisationDetails.wageType,
        workingWeekDuration:
          this.organisation.organisationDetails.workingWeekDuration,
        breaks: this.organisation.organisationDetails.breaks,
        averageOvertime: this.organisation.organisationDetails.averageOvertime,
        isPPEProvided: this.organisation.organisationDetails.isPPEProvided,
        offSiteUnitDetails:
          this.organisation.organisationDetails.offSiteUnitDetails,
        approxBuildingSizeTotal:
          this.organisation.organisationDetails.approxBuildingSizeTotal,
        environmentallyHarmfulSites:
          this.organisation.organisationDetails.environmentallyHarmfulSites,
        vehicleTypes: this.organisation.organisationDetails.vehicleTypes,
        numberOfMigrantWorkers:
          this.organisation.organisationDetails.numberOfMigrantWorkers,
        numberOfRecruitmentAgencyHires:
          this.organisation.organisationDetails.numberOfRecruitmentAgencyHires,
        numberOfHomeBasedWorkers:
          this.organisation.organisationDetails.numberOfHomeBasedWorkers,
        siteSchedule: this.organisation.organisationDetails.siteSchedule,
        workerCollectiveAggreement:
          this.organisation.organisationDetails.workerCollectiveAggreement,
        shifts: this.organisation.organisationDetails.shifts,
        regularOvertime: this.organisation.organisationDetails.regularOvertime,
        listRequiredPPE: this.organisation.organisationDetails.listRequiredPPE,
        reviewDateAlarm: this.organisation.organisationDetails.reviewDateAlarm,
      };
    },
    getSupplierCurrentState() {
      return {
        activeStatus: this.selectedStatus,
        orgCode: this.supplierNumber,
        organisationType:
          this.selectedOrgType === organisationTypes.IngkaUnit
            ? "Ingka unit"
            : "Supplier",
        ingkaUnit: this.selectedIngkaUnit,
        siteAddress: this.siteAddress,
        siteName: this.siteName,
        country: this.country,
        supplierName: this.supplierName,
        webSiteAddress: this.webSiteAddress,
        supplierStartDate: this.supplierStartDate
          ? this.$moment
              .utc(this.supplierStartDate)
              .local()
              .format("YYYY-MM-DDTHH:mm:ss")
              .slice(0, 10)
          : null,
        productOrServices: this.productOrServices,
        riskType: this.selectedRiskType,
        //contracting details
        contractSignedAfterIA: this.selectedContractSignedAfterIAOption,
        commentsToContractAfterIA: this.commentsToContractAfterIA,
        iccSignedBySupplier: this.selectedICCSignedBySupplierOption,
        commentsToICC: this.commentsToICC,
        validIWAYVersionSigned: this.selectedValidIWAYVersionSignedOption,
        relevantIngkaSpecificationsSignedBySupplier:
          this.selectedRelevantIngkaSpecificationsSignedBySupplier,
        iwayExceptionsGivenToSupplier: this.IWAYExceptionsGivenToSupplier,
        //detailed info
        numberOfSites: this.numberOfSites,
        numberOfSiteBuildings: this.numberOfSiteBuildings,
        approxTotalLandArea: this.approxLandAreaTotal,
        workerAccomodationAddress: this.workerAccomodationAddress,
        numberOfWorkers: this.numberOfWorkers,
        nationalityOfMigrantWorkers: this.nationalityOfMigrantWorkers,
        languageSpokenByWorkers: this.languagesSpokenByWorkers,
        contractTypes: this.contractTypes,
        wageType: this.wageType,
        workingWeekDuration: this.workingWeekDuration,
        breaks: this.breaks,
        averageOvertime: this.averageOverTime,
        isPPEProvided: this.isPPEProvided,
        offSiteUnitDetails: this.offSiteUnitDetails,
        approxBuildingSizeTotal: this.approxBuildingSizeTotal,
        environmentallyHarmfulSites: this.environmentallyHarmfulSites,
        vehicleTypes: this.vehicleTypes,
        numberOfMigrantWorkers: this.numberOfMigrantWorkers,
        numberOfRecruitmentAgencyHires: this.numberOfRecruitmentAgencyHires,
        numberOfHomeBasedWorkers: this.numberOfHomeBasedWorkers,
        siteSchedule: this.siteSchedule,
        workerCollectiveAggreement: this.workerCollectiveAggreement,
        shifts: this.shifts,
        regularOvertime: this.regularOverTime,
        listRequiredPPE: this.listRequiredPPE,
        reviewDateAlarm: this.reviewDateAlarm,
      };
    },
    getSiteInitialState() {
      return {
        activeStatus: this.organisation.activeStatus,
        orgCode: this.organisation.orgCode,
        organisationType: this.organisation.organisationType,
        siteAddress: this.organisation.siteAddress,
        siteName: this.organisation.siteName,
        country: this.organisation.organisationDetails.country,
        siteType: this.organisation.organisationDetails.siteType,
      };
    },
    getSiteCurrentState() {
      return {
        activeStatus: this.selectedStatus,
        orgCode: this.siteNumber,
        organisationType:
          this.selectedOrgType === organisationTypes.IngkaUnit
            ? "Ingka unit"
            : "Supplier",
        siteAddress: this.siteAddress,
        siteName: this.siteName,
        country: this.country,
        siteType: this.selectedIngkaSiteType,
      };
    },
    countriesList() {
      return [
        this.countries.find((x) => x.name === "Cross border"),
        ...this.countries.filter((x) => x.name !== "Cross border"),
      ];
    },
    colDefs() {
      return this.colDefsTranslatedLookups.map((x) => ({
        ...x,
        caption: this.$t(x.caption),
      }));
    },
    colDefsTranslatedLookups() {
      let colDefs = [...columnDefinitions];

      colDefs.forEach((element) => {
        if (element.lookup) {
          element.lookup.dataSource.store.data.forEach((x) => {
            if (x.name) {
              x.name = this.$t(x.name);
            }
          });
        }
      });
      return colDefs;
    },
  },
  watch: {
    tabs() {
      this.selectedTab = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.z-index-1000 {
  z-index: 1000;
}

.loading::before {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff52;
  background-image: url(../assets/loader.gif);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 600px;
  content: "_";
  color: transparent;
  z-index: 99999;
}

.page-indicator .indicator-icon.rejected {
  background: #c42828;
}
</style>
