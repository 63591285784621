import {assessmentStatuses} from "../../custom/config/workflows";

export default Object.freeze([
  {
    dataField: "retailer",
  },
  {
    dataField: "market",
  },
  {
    dataField: "subRegion",
  },
  {
    dataField: "region",
  },
  {
    dataField: "thirdParty",
  },
  {
    dataField: "auditType",
    cellTemplate: "retailer-audit-type-template",
  },
  {
    dataField: "siteVisitType",
    cellTemplate: "retailer-site-visit-type-template",
  },
  {
    dataField: "auditPeriod",
  },
  {
    dataField: "programmeTypes",
  },
  {
    dataField: "startDate",
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "endDate",
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "auditManager",
  },
  {
    dataField: "auditor",
  },
  {
    dataField: "assessmentStatus",
    lookup: {
      dataSource: {
        store: {
          type: "array",
          data: [
            {
              id: assessmentStatuses.notStarted,
              name: "assessments.assessmentList.notStarted",
            },
            {
              id: assessmentStatuses.inProgress,
              name: "assessments.assessmentList.inProgress",
            },
            {
              id: assessmentStatuses.submitted,
              name: "assessments.assessmentList.submitted",
            },
            {
              id: assessmentStatuses.complete,
              name: "assessments.assessmentList.complete",
            },
            {
              id: assessmentStatuses.cancelled,
              name: "assessments.assessmentList.cancelled",
            },
          ],
          key: "id",
        },
      },
      valueExpr: "id", // contains the same values as the "statusId" field provides
      displayExpr: "name", // provides display values
    },
    cellTemplate: "status-template",
  },
  {
    dataField: "auditScore",
    dataType: "number",
    cellTemplate: "custom-percentage-template",
  },
  {
    dataField: "complianceScore",
    dataType: "number",
    cellTemplate: "custom-percentage-template",
  },
  {
    dataField: "finalScore",
    dataType: "number",
    cellTemplate: "custom-percentage-template",
  },
  {
    dataField: "findingTitle",
  },
  {
    dataField: "findingStatus",
    visible: true,
    cellTemplate: "finding-status-template",
  },
  // {
  //   dataField: "findingBrands",
  // },
  {
    dataField: "findingRootCause",
  },
  {
    dataField: "findingDescription",
  },
  {
    dataField: "findingResolutionText",
  },
  {
    dataField: "findingSubmittedOn",
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "findingSubmittedBy",
  },
  {
    dataField: "findingApprovedOn",
    dataType: "date",
    format: "dd/MMM/yyyy",
  },
  {
    dataField: "findingApprovedBy",
  },
  {
    dataField: "daysToResolveFinding",
    dataType: "number",
  },
  {
    dataField: "daysToApproveFinding",
    dataType: "number",
  },
]);
