<template>
  <div class="primary-container card-container">
    <div class="row">
      <div class="col-xl-3 action-left-container individual-box">
        <label>
          <DxTooltip
            :visible.sync="internalNoteToolTip"
            :close-on-outside-click="false"
            target="#divInternalNote"
            position="top"
          >
            {{ $t("tooltips.assessment.internalNotes") }}
          </DxTooltip>
        </label>
        <div>
          <h2>
            <i class="far fa-sticky-note"></i>
            {{ $t("assessments.assessment.notes") }}
          </h2>
          <p class="mb-4">
            {{ $t("assessments.assessment.addNoteParagraph") }}.
          </p>
        </div>
      </div>

      <div class="col-xl-9 comments-container">
        <p class="gdpr-disc">
          <i class="fas fa-exclamation-circle mr-2"></i>
          {{ $t("legalNotices.gdprDisclaimer") }}
        </p>
        <div>
          <div
            class="element-margin"
            id="divInternalNote"
            @mouseenter="internalNoteToolTip = !internalNoteToolTip"
            @mouseleave="internalNoteToolTip = !internalNoteToolTip"
          >
            <DxTextArea
              :height="150"
              v-model="note"
              stylingMode="outlined"
              @value-changed="setInternalNotes($event)"
              :disabled="isReadOnly"
            ></DxTextArea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DxTextArea, DxTooltip} from "devextreme-vue";
import localeDatetimeFormat from "../../mixins/locale-datetime-format";
import {isNotEmpty, getUserInitials} from "../../common/helperFunctions";
import $axios from "../../utils/axios-instance";
import notify from "devextreme/ui/notify";
import {mapActions, mapState} from "vuex";

export default {
  props: {
    assessmentId: {
      Type: Number,
      required: true,
    },
    questionId: {
      Type: Number,
      required: true,
    },
    readOnly: {
      Type: Boolean,
      default: true,
      required: false,
    },
  },
  mixins: [localeDatetimeFormat],
  components: {
    DxTextArea,
    DxTooltip,
  },
  data() {
    return {
      note: null,
      isEditable: false,
      internalNoteToolTip: false,
    };
  },
  computed: {
    ...mapState("assessments", ["internalNote"]),
    isReadOnly() {
      return this.readOnly;
    },
  },
  watch: {
    internalNote() {
      this.note = this.internalNote ? this.internalNote.note : null;
    },
    questionId() {
      if (this.internalNote === null) {
        this.note = null;
      }
    },
  },
  created() {
    if (this.internalNote !== null) {
      this.note = this.internalNote.note;
    }
  },
  beforeDestroy() {
    this.note = null;
    this.updateInternalNote(null);
  },
  methods: {
    ...mapActions("assessments", ["getInternalNote", "updateInternalNote"]),
    getUserInitials: getUserInitials,
    async getNote() {
      await this.getInternalNote({
        assessmentId: this.assessmentId,
        questionId: this.questionId,
      });
    },
    async setInternalNotes(e) {
      if (!e.event) return;
      try {
        if (this.internalNote === null && isNotEmpty(e.value)) {
          await $axios.post(
            `/assessments/${this.assessmentId}/questions/${this.questionId}/note`,
            {note: this.note},
          );
        }
        if (
          this.internalNote !== null &&
          this.internalNote.note !== this.note
        ) {
          await $axios.put(`/assessments/note/${this.internalNote.noteId}`, {
            note: this.note,
          });
        }
        this.getNote();
      } catch (err) {
        notify(err, "error");
      }
    },
  },
};
</script>

<style></style>
