<template>
  <div
    class="landing-card-container"
    :class="{'section-loader-on': loaderVisible}"
  >
    <div v-if="overview && overview.assessments && overview.assessments.length">
      <h2>
        {{ $t("landingPage.assessmentOverview.assessments") }}
        <router-link :to="{name: 'orders-list'}">{{
          $t("landingPage.assessmentOverview.viewAllAssessments")
        }}</router-link>
      </h2>
      <div class="row assessment-cards-container" style="margin-bottom: -20px">
        <div
          class="col-sm-6"
          v-for="assessment in overview.assessments"
          :key="assessment"
          @click="navigateToAssessment(assessment)"
          :style="[
            assessment.reviewModes === reviewModes.SelfAssessment ||
            assessment.assessmentStatus === 'Submitted' ||
            assessment.assessmentStatus === 'Complete'
              ? {}
              : {'pointer-events': 'none'},
          ]"
        >
          <div class="assessment-card">
            <div class="card-header"> 
              <div class="organisation-name"> 
                {{assessment.organisationName}} 
              </div>
            <div class="date">
              <i class="far fa-calendar"></i>
              {{ formatDate(assessment.startDate) }}
            </div>
            </div>
            <div class="assessment-type">
              {{ assessment.assessmentType }}
            </div>
            <div class="stat-box">
              {{ $t("landingPage.assessmentOverview.openFindings") }}
              <span>{{ assessment.openFindings }}</span>
            </div>
            <div class="stat-box">
              {{ $t("landingPage.assessmentOverview.submittedFindings") }}
              <span>{{ assessment.submittedFindings }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h2>
        {{ $t("landingPage.assessmentOverview.assessments") }}
      </h2>
      <p class="mb-0">
        {{ $t("landingPage.assessmentOverview.noAssessments") }}
      </p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {toCamelCase, formatDate} from "../../../../common/helperFunctions";
import localeDatetimeFormat from "../../../../mixins/locale-datetime-format";
import {reviewModes} from "../../../../common/constants";

export default {
  components: {},
  created() {
    this.getAssessmentOverviewData();
  },
  mixins: [localeDatetimeFormat],
  computed: {
    ...mapState("landingPage/assessmentOverview", ["overview"]),
  },
  methods: {
    toCamelCase: toCamelCase,
      formatDate: formatDate,
    ...mapActions("landingPage/assessmentOverview", [
      "getAssessmentOverviewData",
    ]),
    toPercentage(input) {
      return input === 0 ? "-" : `${input.toFixed(0)}`;
    },
    shouldShowExclamationMark(assessment) {
      return assessment.complianceRate > 0 && assessment.complianceRate !== 100;
    },
    navigateToAssessment(assessment) {
      this.$router.push({name: "assessment", params: {id: assessment.id}});
    },
  },
  updated() {
    this.loaderVisible = false;
  },
  data() {
    return {
      loaderVisible: true,
      reviewModes: reviewModes,
    };
  },
};
</script>

<style scoped></style>
