<template>
  <div
    ref="fixedSection"
    class="fixed-section"
    :class="{
      mobile: retailerDetailsShown,
      notificationDisplayed: globalWarningNotificationMessage,
    }"

  >
    <div ref="sideNavTopPart">
      <div v-if="details && questionCount > 0">
        <div
          class="content-block retailer-with-progress"
          v-on:click="OpenMobileQuestionNav"
          :class="{active: isMobileQuestionNavActive}"
        >
          <div class="questionnaire-progress-section">
            <radial-progress-bar
              :progressValue="getCompletionPercentage()"
            ></radial-progress-bar>

            <div>
              <p class="status">{{ statusFormat() }}</p>
              <h2
                @click="toggleRetailerDetails"
                :class="{open: retailerDetailsShown}"
                class="content-title expand"
              >
                {{ assessmentDetails.organisation.name }}
              </h2>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="retailer-info" :class="{open: retailerDetailsShown}">
            <div>
              {{ assessmentDetails.organisation.supplierName }}
            </div>
            <div>
              <i class="fas fa-map-marker-alt"></i>
              {{ assessmentDetails.organisation.country }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="list-container" style="margin-top: 45px">
      <div :class="{loadingNextQ: isLoadingActive}">
        <div v-if="menuData">
          <div ref="navFilter">
            <div
              class="option content-block custom-cp-container"
              :class="{open: isFilterOpen}"
              v-if="isFilterOpen"
            >
              <label>{{
                $t("assessments.assessment.filterSelectedOptions")
              }}</label>
              <DxSelectBox
                :data-source="questionOptionsDataSource"
                styling-mode="outlined"
                :show-clear-button="true"
                @value-changed="selectedOptionChanged"
              ></DxSelectBox>
            </div>
          </div>

          <div
            class="dx-list-custom-filter"
            :class="{open: isFilterOpen}"
            @click="toggleNavFilter"
          >
            <i class="fas fa-filter"></i>
          </div>

          <DxList
            :data-source="menuDataSource"
            :grouped="true"
            :collapsible-groups="true"
            show-scrollbar="always"
            selection-mode="single"
            :height="navListHeight"
            :selected-item-keys.sync="selectedQuestion"
            keyExpr="questionId"
            @selection-changed="handleSelectionChange"
            @content-ready="handleMenuContentReady"
            ref="list"
            :search-enabled="true"
            :search-mode="searchMode"
            :search-expr="getCategoryItemTitle"
            :search-editor-options="searchOptions"
          >
            <template #group="{data: group}">
              <div
                class="row no-gutters"
                :class="getCategoryItemStatusIcon(group.items)"
              >
                <div class="col">
                  {{ getCategoryTitle(group.key) }}
                </div>
                <div class="col-auto findings-count">
                  <!--The amound of findings per section-->
                  <!-- <span>Findings: {{ 3 }}</span> -->
                </div>
              </div>
            </template>
            <template #item="{data: item}">
              <!-- question-success || question-alert || question-selected || both -->
              <div>
                <div
                  style="
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  :class="getQuestionStatusIcon(item)"
                >
                  {{ getCategoryItemTitle(item) }}
                </div>
              </div>
            </template>
          </DxList>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DxList from "devextreme-vue/list";
import DxSelectBox from "devextreme-vue/select-box";
import DataSource from "devextreme/data/data_source";
import {mapActions, mapState} from "vuex";
import RadialProgressBar from "../shared/radial-progress-bar";
import {QuestionOptions} from "../../common/constants";

export default {
  components: {
    DxList,
    RadialProgressBar,
    DxSelectBox,
  },
  props: {
    data: {
      Type: Array,
    },
    assessmentDetails: {
      Type: Object,
    },
    currentQuestionId: {
      Type: Number,
    },
  },
  created() {
    this.questionsCompleted = this.assessmentDetails.questionsCompleted;
    this.questionCount = this.assessmentDetails.questionsCompliant;
    this.selectedQuestion = [this.currentQuestionId];
    window.addEventListener("resize", this.sideMenuResizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.sideMenuResizeHandler);
  },
  mounted() {
    this.sideMenuResizeHandler();
  },
  //TODO: This is wrong, there should be a DX implementation - poorly documented so had to hack it out
  updated() {
    this.sideMenuResizeHandler();
    if (!this.hasLoaded) {
      let groups = document.getElementsByClassName("dx-list-group");
      let groupBodies = document.getElementsByClassName("dx-list-group-body");
      for (let x = 1; x < groups.length; x++) {
        groups[x].className += " dx-list-group-collapsed";
        groupBodies[x].style.height = "0px";
      }
    }
  },
  data() {
    return {
      isMobileQuestionNavActive: false,
      isLoadingActive: false,
      questionsCompleted: 1,
      questionCount: 0,
      selectedQuestion: 0,
      details: null,
      hasLoaded: false,
      retailerDetailsShown: false,
      searchMode: "contains",
      isDropDownBoxOpened: false,
      selectedOption: null,
      isFilterOpen: false,
      navListHeight: 0,
      searchOptions: {
        placeholder: this.$t("assessments.assessment.searchPlaceholder"),
        stylingMode: "underlined",
      },
    };
  },
  methods: {
    ...mapActions("assessments", ["getProgrammes"]),
    OpenMobileQuestionNav: function () {
      this.isMobileQuestionNavActive = !this.isMobileQuestionNavActive;
    },
    toggleNavFilter: function () {
      this.isFilterOpen = !this.isFilterOpen;
    },
    statusFormat() {
      return (
        this.$t("assessments.assessment.completion") +
        ": " +
        this.questionsCompleted +
        "/" +
        this.questionCount +
        " " +
        this.$t("assessments.assessment.questions")
      );
    },
    getCompletionPercentage() {
      return Math.round((this.questionsCompleted / this.questionCount) * 100);
    },
    toggleRetailerDetails() {
      if (this.retailerDetailsShown) {
        this.retailerDetailsShown = false;
      } else {
        this.retailerDetailsShown = true;
      }
    },
    getCategoryTitle(item) {
      if (item) {
        const parts = item.split("|");
        return parts[parts.length - 1];
      }

      return "";
    },
    getCategoryItemTitle(item) {
      const question = item.questionText;
      const questionNumber =
        item.questionNumber !== null ? item.questionNumber : "";
      return `${questionNumber} ${question}`;
    },
    getCategoryItemStatusIcon(questions) {
      let statusIconClass = "category-positive";
      const excludedCategoriesFromFindingsCheck = ["review info", "review summary"];

      for (let item of questions) {
        let categoryTitle = item.categoryTitle.split("|").slice(-1).pop().toLowerCase();
        if (item.answer.value) {
          if (
            ["Not OK"].includes(item.answer.text) &&
            !item.hasFindings 
            && !excludedCategoriesFromFindingsCheck.includes(categoryTitle)
          ) {
            return "category-negative";
          }
          if (["Gap identified"].includes(item.answer.text) || item.hasFindings)
           statusIconClass = "category-findings";
        } else {
          if (
            statusIconClass !== "category-findings" &&
            item.isRequiredQuestion
          ) {
            statusIconClass = "category-required";
          }
        }
      }

      return statusIconClass;
    },
    getQuestionStatusIcon(question) {
      //TODO: the question-warning class is ready to be used when the flag is done
      switch (question.answer.text) {
        case QuestionOptions.NotOk:
          if (question.hasFindings) {
            return "question-warning";
          } else {
            return "question-alert";
          }
        case QuestionOptions.GapIdentified:
            return "question-warning";
        case null:
        case undefined:
          if (question.isRequiredQuestion) return "question-required";
          else return "";
        case QuestionOptions.NA:
        case QuestionOptions.NotReviewed:
          return "question-success";
        default:
          if (question.hasFindings) return "question-warning";
          else return "question-success";
      }
    },
    handleSelectionChange(id) {
      this.isLoadingActive = true;
      if (id !== null) {
        this.$emit("selection-changed", id);
        this.hasLoaded = true;
        if (this.retailerDetailsShown) {
          this.toggleRetailerDetails();
        }

        document
          .querySelector(".with-footer .dx-scrollable-container")
          .scrollTo(0, 0);
      }
    },
    handleMenuContentReady: function (e) {
      // Used in search/filter scenario
      if (e.component.option("searchValue") == "" && !this.selectedOption) {
        this.collapseListGroups();
      }
      setTimeout(() => (this.isLoadingActive = false), 0);
    },
    collapseListGroups() {
      if (
        this.question &&
        this.question.questionId === this.currentQuestionId
      ) {
        let categories = this.listView._dataSource._items;
        const currentCategoryIndex = categories.findIndex(
          (x) => this.getCategoryTitle(x.key) === this.question.category,
          this,
        );

        categories.forEach(function (item) {
          let groupIndex = categories.indexOf(item);
          if (groupIndex !== currentCategoryIndex) {
            this.listView.collapseGroup(groupIndex);
          }
        }, this);
      }
    },
    selectedOptionChanged(e) {
      this.selectedOption = e.value;
    },
    sideMenuResizeHandler(e) {
      let screenWidth = window.innerWidth;
      let assessmentNavigationPaddingDeduction = screenWidth <= 992 ? 120 : 66;
      this.navListHeight =
        this.$refs.fixedSection.clientHeight -
        this.$refs.sideNavTopPart.clientHeight -
        this.$refs.navFilter.clientHeight -
        assessmentNavigationPaddingDeduction;

      this.refreshScrollLocation();
    },
    refreshScrollLocation() {
      if (this.hasLoaded) {
        let categories = this.listView._dataSource._items;
        if (categories && categories.length > 0) {
          const currentCategoryIndex = categories.findIndex(
            (x) => this.getCategoryTitle(x.key) === this.question.category,
            this,
          );

          const itemPos = categories[currentCategoryIndex].items
            .map((x) => x.questionId)
            .indexOf(this.question.questionId);

          this.listView.scrollToItem({
            group: currentCategoryIndex,
            item: itemPos,
          });
        }
      }
    },
  },
  watch: {
    // whenever question changes, this function will run
    currentQuestionId: function (newValue) {
      this.selectedQuestion = [newValue];
    },
    question: function () {
      this.collapseListGroups();
    },
    assessmentDetails: function (newValue) {
      this.details = newValue;
      this.questionCount = newValue.questionsCompliant;
      this.questionsCompleted = newValue.questionsCompleted;
    },
  },
  computed: {
    ...mapState("assessments", ["menuData", "programmes", "question"]),
    ...mapState("sharedComponents", ["globalWarningNotificationMessage"]),
    menuDataSource() {
      const source = new DataSource({
        store: this.flatMenuData,
        group: "categoryTitle",
      });
      const option = this.selectedOption;
      source.filter((item) =>
        option && item.answer
          ? item.answer.text
            ? item.answer.text.toLowerCase() === option.toLowerCase()
            : false
          : true,
      );
      return source;
    },
    flatMenuData() {
      let data = this.menuData.reduce(function (accumulator, currentValue) {
        currentValue.items.forEach((item) =>
          accumulator.push({
            ...item,
            // add leading zeros to sequence because of string sort
            categoryTitle: `${String(currentValue.displaySequence).padStart(
              2,
              "0",
            )}|${currentValue.title}`,
          }),
        );

        return accumulator;
      }, []);
      return data;
    },
    questionOptionsDataSource() {
      return new DataSource({
        store: this.getQuestionOptions,
      });
    },
    getQuestionOptions() {
      return this.assessmentDetails.questionOptions;
    },
    listView: function () {
      return this.$refs["list"].instance;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../themes/generated/variables.base.scss";

.status {
  color: #1e1e1e;
}
</style>
