<template>
    <div class="global-warning">
        <i class="fas fa-exclamation"></i> {{message}}
    </div>
</template>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
</style>

<script>
export default {
  props: {
    message: null,
  },    
  data() {
    return {
      isActive: false 
    };
  },
  methods: {
    togglePopup() {
        this.isActive = this.isActive ? false : true;
    }
  },
    computed: {
        currentRouteName() {
            return this.$route.name;
        }
    }
};
</script>
