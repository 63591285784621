import {getField, updateField} from "vuex-map-fields";
import {assessmentStructureType} from "../../common/constants";

const getDefaultState = () => {
  const state = {
    assessmentStructures: [],
    // TODO: (Debt)
    // Figure out a way to initialise default state by
    // calling GetAuditYears first
    auditPeriods: [1],
    reviewTypes: [],
    locations: [],
    programmeTypes: [],
    siteVisitTypes: [],
    visitStatuses: [],
    brands: [],
    rootCauses: [],
    findingStatuses: [],
    assessments: [],
    reviewLevels: [],
    leadReviewers: [],
    complianceStatuses: [],
    siteCategories: [],
    siteCategoriesArea: [],
    siteSubCategories: [],
    isThereReviewerCapacity: null,
    orderTertials: [],
    riskRatings: [],
    notifications: [],
    followupStatuses: [],
    capStatuses: [],
    questionLevels: [],
    requirements: [],
  };
  const filters = aggregateFilters(state);

  return {
    ...state,
    filters: filters,
  };
};

const aggregateFilters = (filters) => {
  const locations = filters.locations;
  const questions = filters.assessmentStructures
    .filter((x) => x.type === assessmentStructureType.Question)
    .map((x) => x.id);
  const sections = filters.assessmentStructures
    .filter((x) => x.type === assessmentStructureType.Section)
    .map((x) => x.id);

  return {
    auditPeriods: filters.auditPeriods,
    reviewTypes: filters.reviewTypes,
    brands: filters.brands,
    categories: filters.assessmentStructures,
    findingStatuses: filters.findingStatuses,
    locations: locations,
    questions: questions,
    findingRootCauses: filters.rootCauses,
    sections: sections,
    reviewModes: filters.siteVisitTypes,
    reviewStatuses: filters.visitStatuses,
    assessments: filters.assessments,
    reviewLevels: filters.reviewLevels,
    leadReviewers: filters.leadReviewers,
    complianceStatuses: filters.complianceStatuses,
    siteCategories: filters.siteCategories,
    siteCategoriesArea: filters.siteCategoriesArea,
    siteSubCategories: filters.siteSubCategories,
    isThereReviewerCapacity: filters.isThereReviewerCapacity,
    orderTertials: filters.orderTertials,
    riskRatings: filters.riskRatings,
    notifications: filters.notifications,
    followupStatuses: filters.followupStatuses.map((x) => x.toString()),
    capStatuses: filters.capStatuses.map((x) => x.toString()),
    questionLevels: filters.questionLevels,
    requirements: filters.requirements,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getFilter(state) {
      return getField(state);
    },
  },
  mutations: {
    clearFilters(state) {
      Object.assign(state, getDefaultState());
    },
    setFilter(state, field) {
      updateField(state, {...field, value: field.value || []});
    },
    applyFilters(state) {
      state.filters = aggregateFilters(state);
    },
  },
};
