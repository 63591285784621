export default Object.freeze([
  {
    dataField: "auditScore",
    caption: "reporting.charts.auditScoreStackTitle",
    order: 1,
  },
  {
    dataField: "averageScore",
    caption: "reporting.charts.complianceScoreStackTitle",
    order: 2,
  },
  {
    dataField: "finalScore",
    caption: "reporting.charts.finalScoreStackTitle",
    order: 3,
  },
]);
