<template>
  <div></div>
</template>

<script>
import {mapActions} from "vuex";
import notify from "devextreme/ui/notify";
import {isNotEmpty} from "../common/helperFunctions";

export default {
  data() {
    return {
      title: "",
    };
  },
  mounted() {
    this.login();
  },
  methods: {
    ...mapActions("auth", ["wsfedLogin"]),

    async login() {
      if (
        !isNotEmpty(this.$route.query.username) ||
        !isNotEmpty(this.$route.query.token)
      ) {
        this.$router.push({name: "login-form"});
        return;
      }
      try {
        await this.wsfedLogin({
          username: this.$route.query.username,
          token: this.$route.query.token,
        });

        this.$router.push({name: "home"});
      } catch (err) {
        notify("Unable to login with SSO", "error");
        this.$router.push({name: "login-form"});
      }
    },
  },
};
</script>
